import React, { useState, useEffect, useRef } from "react";

// Composant StockForm
export const StockForm = ({ initialData, onSave, categories, onCancel }) => {
  const defaultFormData = {
    nom: "",
    reference: "",
    categorie: "",
    sousCategorie: "",
    nouvelleSousCategorie: "",
    quantite: 0,
    quantiteMin: 0,
    prixAchat: "",
    prixRevente: "", // Ajout du champ pour le prix de vente
    description: "",
    fournisseur: "",
    emplacement: "",
    image: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [imagePreview, setImagePreview] = useState(null);
  const [isCategorieInputVisible, setIsCategorieInputVisible] = useState(false);
  const [isSousCategorieInputVisible, setIsSousCategorieInputVisible] = useState(false);
  const [sousCategories, setSousCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const imageInputRef = useRef(null);

  // Charger les données initiales si disponibles (pour modification)
  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      if (initialData.image) {
        setImagePreview(initialData.image);
      }
      // Charger les sous-catégories correspondant à la catégorie sélectionnée
      if (initialData.categorie) {
        loadSousCategories(initialData.categorie);
      }
    } else {
      setFormData(defaultFormData);
      setImagePreview(null);
    }
  }, [initialData]);

  // Charger les sous-catégories pour une catégorie donnée
  const loadSousCategories = (categorie) => {
    // Récupérer toutes les sous-catégories
    const allSousCategories = JSON.parse(localStorage.getItem('sous_categories') || '{}');
    // Récupérer les sous-catégories de la catégorie spécifiée
    const categorySousCategories = allSousCategories[categorie] || [];
    setSousCategories(categorySousCategories);
  };

  // Gérer le changement des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    // Pour les champs numériques, convertir en nombre
    if (name === "quantite" || name === "quantiteMin" || name === "prixAchat" || name === "prixRevente") {
      const numValue =
        type === "number" ? (value === "" ? "" : parseFloat(value)) : value;
      setFormData((prevData) => ({
        ...prevData,
        [name]: numValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Effacer l'erreur du champ modifié
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  // Gérer le changement de catégorie
  const handleCategorieChange = (e) => {
    const value = e.target.value;

    // Si l'utilisateur sélectionne "Nouvelle catégorie", afficher le champ de saisie
    if (value === "nouvelle") {
      setIsCategorieInputVisible(true);
      setFormData((prevData) => ({
        ...prevData,
        categorie: "",
        sousCategorie: "",
      }));
      setSousCategories([]);
    } else {
      setIsCategorieInputVisible(false);
      setFormData((prevData) => ({
        ...prevData,
        categorie: value,
        nouvelleCategorie: "",
        sousCategorie: "",
      }));

      // Charger les sous-catégories pour cette catégorie
      loadSousCategories(value);
    }
  };

  // Gérer le changement de sous-catégorie
  const handleSousCategorieChange = (e) => {
    const value = e.target.value;

    // Si l'utilisateur sélectionne "Nouvelle sous-catégorie", afficher le champ de saisie
    if (value === "nouvelle") {
      setIsSousCategorieInputVisible(true);
      setFormData((prevData) => ({
        ...prevData,
        sousCategorie: "",
      }));
    } else {
      setIsSousCategorieInputVisible(false);
      setFormData((prevData) => ({
        ...prevData,
        sousCategorie: value,
        nouvelleSousCategorie: "",
      }));
    }
  };

  // Gérer l'upload d'image
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = function (event) {
        const imageData = event.target.result;
        setImagePreview(imageData);
        setFormData((prevData) => ({
          ...prevData,
          image: imageData,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  // Fonction pour simuler un clic sur l'input de type file
  const triggerImageUpload = () => {
    imageInputRef.current.click();
  };

  // Validation du formulaire
  const validateForm = () => {
    const newErrors = {};

    if (!formData.nom) {
      newErrors.nom = "Le nom de l'article est requis";
    }

    if (!formData.reference) {
      newErrors.reference = "La référence est requise";
    }

    if (!formData.categorie && !isCategorieInputVisible) {
      newErrors.categorie = "La catégorie est requise";
    }

    if (isCategorieInputVisible && !formData.nouvelleCategorie) {
      newErrors.nouvelleCategorie =
        "Veuillez entrer un nom pour la nouvelle catégorie";
    }

    if (isSousCategorieInputVisible && !formData.nouvelleSousCategorie) {
      newErrors.nouvelleSousCategorie =
        "Veuillez entrer un nom pour la nouvelle sous-catégorie";
    }

    if (formData.quantite < 0) {
      newErrors.quantite = "La quantité ne peut pas être négative";
    }

    if (formData.quantiteMin < 0) {
      newErrors.quantiteMin = "La quantité minimale ne peut pas être négative";
    }

    if (formData.prixAchat && formData.prixAchat < 0) {
      newErrors.prixAchat = "Le prix d'achat ne peut pas être négatif";
    }

    if (formData.prixRevente && formData.prixRevente < 0) {
      newErrors.prixRevente = "Le prix de vente ne peut pas être négatif";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // S'assurer que les valeurs numériques sont bien des nombres
      const dataToSave = {
        ...formData,
        quantite: parseFloat(formData.quantite) || 0,
        quantiteMin: parseFloat(formData.quantiteMin) || 0,
        prixAchat: parseFloat(formData.prixAchat) || 0,
        prixRevente: parseFloat(formData.prixRevente) || 0,
      };

      // Si une nouvelle catégorie est utilisée, l'ajouter aux données
      if (isCategorieInputVisible) {
        dataToSave.categorie = formData.nouvelleCategorie.toLowerCase();
        dataToSave.sousCategorie = ""; // Pas de sous-catégorie pour une nouvelle catégorie
      }

      // Si une nouvelle sous-catégorie est utilisée, l'ajouter aux données et l'enregistrer
      if (isSousCategorieInputVisible && formData.nouvelleSousCategorie) {
        dataToSave.sousCategorie = formData.nouvelleSousCategorie.toLowerCase();
        
        // Enregistrer la nouvelle sous-catégorie dans le localStorage
        const allSousCategories = JSON.parse(localStorage.getItem('sous_categories') || '{}');
        const categorySousCategories = allSousCategories[dataToSave.categorie] || [];
        
        if (!categorySousCategories.includes(dataToSave.sousCategorie)) {
          categorySousCategories.push(dataToSave.sousCategorie);
          allSousCategories[dataToSave.categorie] = categorySousCategories;
          localStorage.setItem('sous_categories', JSON.stringify(allSousCategories));
        }
      }

      // Si c'est un nouvel article, initialiser l'historique
      if (!initialData) {
        dataToSave.historique = [
          {
            date: new Date().toISOString(),
            quantityBefore: 0,
            quantityAfter: dataToSave.quantite,
            difference: dataToSave.quantite,
            notes: "Création initiale du stock",
          },
        ];
      }

      onSave(dataToSave);
    }
  };

  return (
    <form className="stock-form" onSubmit={handleSubmit}>
      <div className="form-layout">
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="nom">Nom de l'article:*</label>
            <input
              type="text"
              id="nom"
              name="nom"
              required
              value={formData.nom}
              onChange={handleInputChange}
              className={errors.nom ? "error" : ""}
              placeholder="Ex: Huile essentielle de lavande"
            />
            {errors.nom && <div className="error-message">{errors.nom}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="reference">Référence:*</label>
            <input
              type="text"
              id="reference"
              name="reference"
              required
              value={formData.reference}
              onChange={handleInputChange}
              className={errors.reference ? "error" : ""}
              placeholder="Ex: HL-LAV-100"
            />
            {errors.reference && (
              <div className="error-message">{errors.reference}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="categorie">Catégorie:*</label>
            <select
              id="categorie"
              name="categorie"
              required={!isCategorieInputVisible}
              value={formData.categorie}
              onChange={handleCategorieChange}
              className={errors.categorie ? "error" : ""}
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </option>
              ))}
              <option value="nouvelle">+ Nouvelle catégorie</option>
            </select>
            {errors.categorie && (
              <div className="error-message">{errors.categorie}</div>
            )}
          </div>

          {isCategorieInputVisible && (
            <div className="form-group">
              <label htmlFor="nouvelle-categorie">Nouvelle catégorie:*</label>
              <input
                type="text"
                id="nouvelle-categorie"
                name="nouvelleCategorie"
                required
                value={formData.nouvelleCategorie}
                onChange={handleInputChange}
                className={errors.nouvelleCategorie ? "error" : ""}
                placeholder="Ex: Huiles, Crèmes, Accessoires..."
              />
              {errors.nouvelleCategorie && (
                <div className="error-message">{errors.nouvelleCategorie}</div>
              )}
            </div>
          )}

          {/* Champ de sous-catégorie (visible seulement si une catégorie valide est sélectionnée) */}
          {formData.categorie && !isCategorieInputVisible && (
            <div className="form-group">
              <label htmlFor="sousCategorie">Sous-catégorie:</label>
              <select
                id="sousCategorie"
                name="sousCategorie"
                value={formData.sousCategorie}
                onChange={handleSousCategorieChange}
              >
                <option value="">Aucune sous-catégorie</option>
                {sousCategories.map((sousCategory, index) => (
                  <option key={index} value={sousCategory}>
                    {sousCategory.charAt(0).toUpperCase() + sousCategory.slice(1)}
                  </option>
                ))}
                <option value="nouvelle">+ Nouvelle sous-catégorie</option>
              </select>
            </div>
          )}

          {/* Champ pour nouvelle sous-catégorie (visible seulement si 'Nouvelle sous-catégorie' est sélectionné) */}
          {isSousCategorieInputVisible && (
            <div className="form-group">
              <label htmlFor="nouvelle-sous-categorie">Nouvelle sous-catégorie:*</label>
              <input
                type="text"
                id="nouvelle-sous-categorie"
                name="nouvelleSousCategorie"
                required
                value={formData.nouvelleSousCategorie}
                onChange={handleInputChange}
                className={errors.nouvelleSousCategorie ? "error" : ""}
                placeholder="Ex: Bio, Synthétique, Premium..."
              />
              {errors.nouvelleSousCategorie && (
                <div className="error-message">{errors.nouvelleSousCategorie}</div>
              )}
            </div>
          )}

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="quantite">Quantité en stock:*</label>
              <input
                type="number"
                id="quantite"
                name="quantite"
                required
                min="0"
                step="1"
                value={formData.quantite}
                onChange={handleInputChange}
                className={errors.quantite ? "error" : ""}
              />
              {errors.quantite && (
                <div className="error-message">{errors.quantite}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="quantiteMin">Seuil d'alerte:</label>
              <input
                type="number"
                id="quantiteMin"
                name="quantiteMin"
                min="0"
                step="1"
                value={formData.quantiteMin}
                onChange={handleInputChange}
                className={errors.quantiteMin ? "error" : ""}
              />
              {errors.quantiteMin && (
                <div className="error-message">{errors.quantiteMin}</div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="prixAchat">Prix d'achat (€):</label>
            <div className="price-input">
              <input
                type="number"
                id="prixAchat"
                name="prixAchat"
                min="0"
                step="0.01"
                value={formData.prixAchat}
                onChange={handleInputChange}
                className={errors.prixAchat ? "error" : ""}
                placeholder="0.00"
              />
              <span className="price-symbol">€</span>
            </div>
            {errors.prixAchat && (
              <div className="error-message">{errors.prixAchat}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="prixRevente">Prix de vente (€):</label>
            <div className="price-input">
              <input
                type="number"
                id="prixRevente"
                name="prixRevente"
                min="0"
                step="0.01"
                value={formData.prixRevente}
                onChange={handleInputChange}
                className={errors.prixRevente ? "error" : ""}
                placeholder="0.00"
              />
              <span className="price-symbol">€</span>
            </div>
            {errors.prixRevente && (
              <div className="error-message">{errors.prixRevente}</div>
            )}
            <div className="quantity-info">
              Le prix auquel le produit sera vendu dans la caisse.
            </div>
          </div>
        </div>

        <div className="form-column">
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              rows="3"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Décrivez cet article..."
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="fournisseur">Fournisseur:</label>
            <input
              type="text"
              id="fournisseur"
              name="fournisseur"
              value={formData.fournisseur}
              onChange={handleInputChange}
              placeholder="Nom du fournisseur"
            />
          </div>

          <div className="form-group">
            <label htmlFor="emplacement">Emplacement en stock:</label>
            <input
              type="text"
              id="emplacement"
              name="emplacement"
              value={formData.emplacement}
              onChange={handleInputChange}
              placeholder="Ex: Étagère A, Tiroir 2..."
            />
          </div>

          <div className="form-group">
            <label>Image:</label>
            <div className="image-upload-container">
              <div className="image-preview" onClick={triggerImageUpload}>
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Aperçu"
                    className="preview-img"
                  />
                ) : (
                  <div className="no-image">
                    <i className="image-icon">📷</i>
                    <span>Cliquez pour ajouter une image</span>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="article-image"
                accept="image/*"
                onChange={handleImageChange}
                ref={imageInputRef}
                style={{ display: "none" }}
              />
              <button
                type="button"
                className="image-btn"
                onClick={triggerImageUpload}
              >
                {imagePreview ? "Changer l'image" : "Ajouter une image"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-actions">
        <button type="submit" className="submit-btn">
          {initialData ? "Mettre à jour" : "Enregistrer"}
        </button>
        <button type="button" className="cancel-btn" onClick={onCancel}>
          Annuler
        </button>
      </div>
    </form>
  );
};

// Composant StockItemsList
export const StockItemsList = ({ items, onEdit, onDelete, onUpdateStock }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [updateQuantity, setUpdateQuantity] = useState("");
  const [updateNotes, setUpdateNotes] = useState("");
  const [showHistory, setShowHistory] = useState(null);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyItem, setHistoryItem] = useState(null);

  // Formater le prix en euros
  const formatPrice = (price) => {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(price || 0);
  };

  // Formater une date pour l'affichage
  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  // Ouvrir le modal de mise à jour de stock
  const openUpdateModal = (item) => {
    setCurrentItem(item);
    setUpdateQuantity(item.quantite.toString());
    setUpdateNotes("");
    setShowUpdateModal(true);
  };

  // Fonction pour soumettre la mise à jour du stock
  const handleUpdateStock = () => {
    const newQuantity = parseFloat(updateQuantity);

    if (isNaN(newQuantity) || newQuantity < 0) {
      alert("Veuillez entrer une quantité valide (nombre positif)");
      return;
    }

    onUpdateStock(currentItem.id, newQuantity, updateNotes);
    setShowUpdateModal(false);
  };

  // Afficher le modal d'historique
  const showHistoryModal = (item) => {
    setHistoryItem(item);
    setIsHistoryModalOpen(true);
    document.body.style.overflow = 'hidden'; // Empêcher le défilement du fond
  };

  // Fermer le modal d'historique
  const closeHistoryModal = () => {
    setIsHistoryModalOpen(false);
    setHistoryItem(null);
    document.body.style.overflow = ''; // Réactiver le défilement
  };

  // Déterminer la classe de couleur en fonction du niveau de stock
  const getStockLevelClass = (item) => {
    if (item.quantite === 0) return "stock-level-empty";
    if (item.quantite <= item.quantiteMin) return "stock-level-critical";
    if (item.quantite <= item.quantiteMin * 2) return "stock-level-low";
    return "stock-level-normal";
  };

  return (
    <div className="stock-list">
      {items.length === 0 ? (
        <div className="no-items">
          <p>
            Aucun article en stock trouvé. Créez votre premier article en
            cliquant sur l'onglet "Nouvel Article".
          </p>
        </div>
      ) : (
        <div className="stock-grid">
          {items.map((item) => (
            <div key={item.id} className="stock-card">
              <div className="stock-header">
                <div className="stock-image">
                  {item.image ? (
                    <img src={item.image} alt={item.nom} />
                  ) : (
                    <div className="placeholder-image">
                      {item.nom?.charAt(0) || "A"}
                    </div>
                  )}
                </div>
                <div className="stock-title">
                  <h3>{item.nom}</h3>
                  <div className="stock-ref">Ref: {item.reference}</div>
                  <div className="stock-category">
                    {item.categorie && (
                      <span className="category-badge">
                        {item.categorie.charAt(0).toUpperCase() + item.categorie.slice(1)}
                      </span>
                    )}
                    {item.sousCategorie && (
                      <span className="subcategory-badge">
                        {item.sousCategorie.charAt(0).toUpperCase() + item.sousCategorie.slice(1)}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="stock-details">
                <div className="stock-info">
                  <div className={`stock-quantity ${getStockLevelClass(item)}`}>
                    <div className="stock-quantity-label">En stock:</div>
                    <div className="stock-quantity-value">{item.quantite}</div>
                  </div>

                  <div className="stock-min-max">
                    <div className="stock-min">
                      <span className="stock-label">Seuil min:</span>
                      <span className="stock-value">{item.quantiteMin}</span>
                    </div>

                    {item.prixAchat > 0 && (
                      <div className="stock-price">
                        <span className="stock-label">Prix d'achat:</span>
                        <span className="stock-value">
                          {formatPrice(item.prixAchat)}
                        </span>
                      </div>
                    )}
                    
                    {item.prixRevente > 0 && (
                      <div className="stock-price">
                        <span className="stock-label">Prix de vente:</span>
                        <span className="stock-value">
                          {formatPrice(item.prixRevente)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {item.description && (
                  <div className="stock-description">
                    <p>{item.description}</p>
                  </div>
                )}

                {item.fournisseur && (
                  <div className="stock-supplier">
                    <span className="stock-label">Fournisseur:</span>
                    <span className="stock-supplier-name">
                      {item.fournisseur}
                    </span>
                  </div>
                )}

                {item.emplacement && (
                  <div className="stock-location">
                    <span className="stock-label">Emplacement:</span>
                    <span className="stock-location-value">
                      {item.emplacement}
                    </span>
                  </div>
                )}
              </div>

              <div className="stock-actions">
                <button
                  className="action-btn stock-update-btn"
                  onClick={() => openUpdateModal(item)}
                >
                  Ajuster Stock
                </button>
                <button
                  className="action-btn stock-history-btn"
                  onClick={() => showHistoryModal(item)}
                >
                  Voir Historique
                </button>
                <button
                  className="action-btn edit-btn"
                  onClick={() => onEdit(item.id)}
                >
                  Modifier
                </button>
                <button
                  className="action-btn delete-btn"
                  onClick={() => onDelete(item.id)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal d'historique avec arrière-plan flouté */}
      {isHistoryModalOpen && historyItem && (
        <div className="history-modal-overlay" onClick={closeHistoryModal}>
          <div className="history-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-history-modal" onClick={closeHistoryModal}>×</button>
            <h3>Historique des mouvements - {historyItem.nom}</h3>
            
            {historyItem.historique && historyItem.historique.length > 0 ? (
              <div className="history-table-container">
                <table className="history-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Avant</th>
                      <th>Après</th>
                      <th>Mouvement</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyItem.historique.slice().reverse().map((movement, index) => (
                      <tr key={index}>
                        <td>{formatDate(movement.date)}</td>
                        <td>{movement.quantityBefore}</td>
                        <td>{movement.quantityAfter}</td>
                        <td className={movement.difference < 0 ? 'negative' : 'positive'}>
                          {movement.difference > 0 ? `+${movement.difference}` : movement.difference}
                        </td>
                        <td className="history-note">{movement.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="no-history">Aucun historique disponible pour cet article.</p>
            )}
          </div>
        </div>
      )}

      {/* Modal de mise à jour du stock */}
      {showUpdateModal && currentItem && (
        <div className="modal">
          <div className="modal-content stock-update-modal">
            <span
              className="close-modal"
              onClick={() => setShowUpdateModal(false)}
            >
              &times;
            </span>
            <h2>Ajuster le stock</h2>
            <h3>{currentItem.nom}</h3>

            <div className="form-group">
              <label htmlFor="update-quantity">Nouvelle quantité:</label>
              <input
                type="number"
                id="update-quantity"
                value={updateQuantity}
                onChange={(e) => setUpdateQuantity(e.target.value)}
                min="0"
                step="1"
              />
              <div className="quantity-info">
                Quantité actuelle: {currentItem.quantite}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="update-notes">
                Notes (raison du changement):
              </label>
              <textarea
                id="update-notes"
                value={updateNotes}
                onChange={(e) => setUpdateNotes(e.target.value)}
                placeholder="Ex: Livraison reçue, utilisation pour prestation, inventaire..."
                rows="3"
              ></textarea>
            </div>

            <div className="form-actions">
              <button className="submit-btn" onClick={handleUpdateStock}>
                Valider
              </button>
              <button
                className="cancel-btn"
                onClick={() => setShowUpdateModal(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};