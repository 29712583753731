import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Register } from './Register';
import { AuthAdapter } from '../../services/AuthAdapter';
import './Login.css';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isFirstUser, setIsFirstUser] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Vérifier s'il s'agit du premier utilisateur
  useEffect(() => {
    const checkFirstUser = async () => {
      try {
        const result = await AuthAdapter.checkFirstUser();
        setIsFirstUser(result.is_first_user);
      } catch (error) {
        console.error("Erreur lors de la vérification du premier utilisateur:", error);
      }
    };

    checkFirstUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      console.log("Tentative de connexion avec:", email);
      const success = await login(email, password);
      
      console.log("Résultat de login:", success);
      
      if (success) {
        console.log("Connexion réussie, redirection vers la page d'accueil");
        
        // Utiliser window.location.href pour une redirection forcée
        window.location.href = '/';
      }
    } catch (err) {
      console.error("Erreur lors de la connexion:", err);
      setError(err.message || 'Échec de la connexion');
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleRegister = () => {
    setShowRegister(!showRegister);
    setError(null);
  };

  const handleRegisterSuccess = () => {
    // Redirection automatique après inscription réussie
    window.location.href = '/';
  };

  // Afficher le formulaire d'inscription pour le premier utilisateur
  if (isFirstUser) {
    return (
      <div className="login-container">
        <div className="first-use-message">
          <h2>Bienvenue sur TicketTime</h2>
          <p>Il semble que vous soyez le premier à utiliser cette application. Veuillez créer un compte administrateur pour commencer.</p>
        </div>
        <Register 
          onRegisterSuccess={handleRegisterSuccess} 
          onCancel={() => setIsFirstUser(false)}
          isFirstAdmin={true}
        />
      </div>
    );
  }

  // Afficher le formulaire d'inscription si demandé
  if (showRegister) {
    return (
      <div className="login-container">
        <Register 
          onRegisterSuccess={handleRegisterSuccess} 
          onCancel={handleToggleRegister}
          isFirstAdmin={false}
        />
      </div>
    );
  }

  // Afficher le formulaire de connexion par défaut
  return (
    <div className="login-container">
      <div className="login-form-container">
        <h2>Connexion</h2>
        
        <form onSubmit={handleSubmit} className="login-form">
          {error && <div className="error-message">{error}</div>}
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
              placeholder="Votre email"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
              placeholder="Votre mot de passe"
            />
          </div>
          
          <button 
            type="submit" 
            className="login-btn"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion en cours...' : 'Se connecter'}
          </button>
        </form>

        {/* Section pour créer un compte */}
        <div className="register-link">
          <p>Vous n'avez pas encore de compte ?</p>
          <button 
            type="button"
            className="register-btn" 
            onClick={handleToggleRegister}
            disabled={isLoading}
          >
            Créer un compte
          </button>
        </div>
      </div>
    </div>
  );
};