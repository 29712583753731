import React, { useState, useEffect } from 'react';

export const ProductSelector = ({ isOpen, onClose, onSelectProduct, stockItems }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [localStock, setLocalStock] = useState([]);
  const [animatingItemId, setAnimatingItemId] = useState(null);
  const [animatingStockIds, setAnimatingStockIds] = useState([]);

  // Charger les catégories et sous-catégories au chargement
  useEffect(() => {
    if (!isOpen) return;
    
    // Copier les données de stock pour manipulation locale
    setLocalStock([...stockItems]);
    
    // Extraire les catégories uniques
    const uniqueCategories = [...new Set(stockItems.map(item => item.categorie))].filter(Boolean);
    setCategories(uniqueCategories);
    
    // Extraire les sous-catégories
    const subCategoriesMap = {};
    stockItems.forEach(item => {
      if (item.categorie && item.sousCategorie) {
        if (!subCategoriesMap[item.categorie]) {
          subCategoriesMap[item.categorie] = [];
        }
        if (!subCategoriesMap[item.categorie].includes(item.sousCategorie)) {
          subCategoriesMap[item.categorie].push(item.sousCategorie);
        }
      }
    });
    setSubCategories(subCategoriesMap);
    
    // Réinitialiser les filtres
    setSelectedCategory('');
    setSelectedSubCategory('');
    setSearchTerm('');
    
    // Filtrer les articles pour n'inclure que ceux en stock
    setFilteredItems(stockItems.filter(item => item.quantite > 0));
  }, [isOpen, stockItems]);

  // Mettre à jour les articles filtrés lorsque les filtres changent
  useEffect(() => {
    let result = localStock.filter(item => item.quantite > 0);
    
    // Appliquer le filtre de catégorie
    if (selectedCategory) {
      result = result.filter(item => item.categorie === selectedCategory);
    }
    
    // Appliquer le filtre de sous-catégorie
    if (selectedSubCategory) {
      result = result.filter(item => item.sousCategorie === selectedSubCategory);
    }
    
    // Appliquer le filtre de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(item => 
        (item.nom && item.nom.toLowerCase().includes(term)) ||
        (item.reference && item.reference.toLowerCase().includes(term))
      );
    }
    
    setFilteredItems(result);
  }, [selectedCategory, selectedSubCategory, searchTerm, localStock]);

  // Formater le prix en euros
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(price || 0);
  };

  // Gérer la sélection d'un produit
  const handleSelectProduct = (product) => {
    onSelectProduct(product);
    // Notification ajout
    const productName = product.nom || 'Produit';
    const notification = document.createElement('div');
    notification.className = 'product-added-notification';
    notification.innerHTML = `<span>${productName}</span> ajouté au panier`;
    
    // Ajouter au DOM
    document.body.appendChild(notification);
    
    // Animation et suppression après un délai
    setTimeout(() => {
      notification.classList.add('show');
      setTimeout(() => {
        notification.classList.remove('show');
        setTimeout(() => {
          document.body.removeChild(notification);
        }, 300);
      }, 2000);
    }, 10);
  };

  return (
    isOpen && (
      <div className="product-selector-overlay">
        <div className="product-selector-content">
          <div className="product-selector-header">
            <h2>Sélectionner un produit</h2>
            <button className="close-selector" onClick={onClose}>&times;</button>
          </div>
          
          <div className="product-selector-filters">
            <div className="search-container">
              <input 
                type="text" 
                placeholder="Rechercher un produit..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="category-filters">
              <select 
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedSubCategory('');
                }}
              >
                <option value="">Toutes les catégories</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </option>
                ))}
              </select>
              
              {selectedCategory && subCategories[selectedCategory] && subCategories[selectedCategory].length > 0 && (
                <select 
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                >
                  <option value="">Toutes les sous-catégories</option>
                  {subCategories[selectedCategory].map((subCategory, index) => (
                    <option key={index} value={subCategory}>
                      {subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          
          <div className="product-selector-grid">
            {filteredItems.length === 0 ? (
              <div className="no-products">
                <p>Aucun produit ne correspond à votre recherche ou n'est disponible en stock.</p>
              </div>
            ) : (
              filteredItems.map(product => (
                <div 
                  key={product.id} 
                  className={`product-item ${animatingItemId === product.id ? 'item-added-animation' : ''}`}
                  onClick={() => handleSelectProduct(product)}
                >
                  <div className="product-item-image">
                    {product.image ? (
                      <img src={product.image} alt={product.nom} />
                    ) : (
                      <div className="placeholder-image">
                        {product.nom?.charAt(0) || 'P'}
                      </div>
                    )}
                  </div>
                  <div className="product-item-details">
                    <h3 className="product-item-name">{product.nom}</h3>
                    <div className="product-item-ref">Ref: {product.reference}</div>
                    <div className="product-item-category">
                      {product.categorie && (
                        <span className="category-badge">
                          {product.categorie.charAt(0).toUpperCase() + product.categorie.slice(1)}
                        </span>
                      )}
                      {product.sousCategorie && (
                        <span className="subcategory-badge">
                          {product.sousCategorie.charAt(0).toUpperCase() + product.sousCategorie.slice(1)}
                        </span>
                      )}
                    </div>
                    <div className="product-item-price-stock">
                      <div className="product-item-price">
                        {formatPrice(product.prixRevente || product.prixAchat * 1.5)}
                      </div>
                      <div className={`product-item-stock ${localStock.find(item => item.id === product.id)?.quantite <= 5 ? 'low-stock' : ''}`}>
                        En stock: <strong className={animatingStockIds.includes(product.id) ? 'stock-change' : ''}>{localStock.find(item => item.id === product.id)?.quantite || product.quantite}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );
};