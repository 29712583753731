import React, { useState, useEffect, useRef } from 'react';
import './ClientForm.css';

export const ClientForm = ({ initialData, onSave, onCancel }) => {
  const defaultFormData = {
    nom: '',
    prenom: '',
    'date-naissance': '',
    telephone: '',
    adresse: '',
    email: '',
    socialNetworks: [
      { type: 'facebook', value: '' },
      { type: 'instagram', value: '' }
    ],
    notes: '',
    photo: ''
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [photoPreview, setPhotoPreview] = useState(null);
  const photoInputRef = useRef(null);

  // Réseaux sociaux disponibles pour l'ajout
  const availableSocialNetworks = [
    { type: 'facebook', label: 'Facebook', icon: '📘' },
    { type: 'instagram', label: 'Instagram', icon: '📸' },
    { type: 'twitter', label: 'Twitter/X', icon: '🐦' },
    { type: 'linkedin', label: 'LinkedIn', icon: '💼' },
    { type: 'tiktok', label: 'TikTok', icon: '🎵' },
    { type: 'youtube', label: 'YouTube', icon: '▶️' },
    { type: 'snapchat', label: 'Snapchat', icon: '👻' },
    { type: 'pinterest', label: 'Pinterest', icon: '📌' },
    { type: 'whatsapp', label: 'WhatsApp', icon: '📱' },
    { type: 'autre', label: 'Autre', icon: '🔗' }
  ];

  // Charger les données initiales si disponibles (pour modification)
  useEffect(() => {
    if (initialData) {
      // Gérer les anciens formats de données pour les réseaux sociaux
      let updatedData = { ...initialData };
      
      // Si le client n'a pas encore de socialNetworks au nouveau format
      if (!updatedData.socialNetworks) {
        const networks = [];
        
        // Migrer les anciennes données
        if (updatedData.facebook) {
          networks.push({ type: 'facebook', value: updatedData.facebook });
        }
        if (updatedData.instagram) {
          networks.push({ type: 'instagram', value: updatedData.instagram });
        }
        if (updatedData['autre-reseau']) {
          networks.push({ type: 'autre', value: updatedData['autre-reseau'] });
        }
        
        // Si aucun réseau n'est trouvé, initialiser avec les réseaux par défaut
        updatedData.socialNetworks = networks.length > 0 ? networks : defaultFormData.socialNetworks;
      }
      
      setFormData(updatedData);
      
      if (updatedData.photo) {
        setPhotoPreview(updatedData.photo);
      }
    } else {
      setFormData(defaultFormData);
      setPhotoPreview(null);
    }
  }, [initialData]);

  // Gérer le changement des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Gérer le changement des réseaux sociaux
  const handleSocialNetworkChange = (index, value) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks[index].value = value;
    
    setFormData(prevData => ({
      ...prevData,
      socialNetworks: updatedNetworks
    }));
  };

  // Ajouter un nouveau réseau social
  const addSocialNetwork = () => {
    // Trouver tous les types déjà utilisés
    const usedTypes = formData.socialNetworks.map(network => network.type);
    
    // Trouver le premier type disponible qui n'est pas encore utilisé
    const availableType = availableSocialNetworks.find(network => 
      !usedTypes.includes(network.type)
    );
    
    if (availableType) {
      const updatedNetworks = [...formData.socialNetworks, { type: availableType.type, value: '' }];
      
      setFormData(prevData => ({
        ...prevData,
        socialNetworks: updatedNetworks
      }));
    } else {
      alert('Tous les types de réseaux sociaux sont déjà ajoutés.');
    }
  };

  // Supprimer un réseau social
  const removeSocialNetwork = (index) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks.splice(index, 1);
    
    setFormData(prevData => ({
      ...prevData,
      socialNetworks: updatedNetworks
    }));
  };

  // Changer le type d'un réseau social
  const changeSocialNetworkType = (index, newType) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks[index].type = newType;
    
    setFormData(prevData => ({
      ...prevData,
      socialNetworks: updatedNetworks
    }));
  };

  // Gérer l'upload de photo
  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      
      reader.onload = function (event) {
        const photoData = event.target.result;
        setPhotoPreview(photoData);
        setFormData(prevData => ({
          ...prevData,
          photo: photoData
        }));
      };
      
      reader.readAsDataURL(file);
    }
  };

  // Fonction pour simuler un clic sur l'input de type file
  const triggerPhotoUpload = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Préparation des données à sauvegarder
    const clientData = {
      ...formData,
      // Assurez-vous de ne pas enregistrer de réseaux vides
      socialNetworks: formData.socialNetworks.filter(network => network.value.trim() !== '')
    };
    
    onSave(clientData);
  };

  // Obtenir l'icône et le label pour un type de réseau social
  const getSocialNetworkInfo = (type) => {
    const network = availableSocialNetworks.find(n => n.type === type);
    return network || { label: 'Autre', icon: '🔗' };
  };

  // Réinitialisation du formulaire
  const handleReset = () => {
    if (initialData) {
      // Si c'est une modification, revenir à l'état initial
      onCancel();
    } else {
      // Si c'est un ajout, vider le formulaire
      setFormData(defaultFormData);
      setPhotoPreview(null);
    }
  };

  return (
    <form id="client-form" className="client-form" onSubmit={handleSubmit}>
      <div className="form-columns">
        {/* Section photo */}
        <div className="photo-section">
          <div className="photo-upload">
            <div 
              className="photo-preview" 
              onClick={triggerPhotoUpload}
            >
              {photoPreview ? (
                <img 
                  src={photoPreview} 
                  alt="Aperçu de la photo" 
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                />
              ) : (
                <i className="photo-icon">📷</i>
              )}
            </div>
            <input 
              type="file" 
              id="client-photo" 
              accept="image/*" 
              ref={photoInputRef}
              onChange={handlePhotoChange}
              style={{ display: 'none' }}
            />
            <button 
              type="button" 
              className="photo-btn"
              onClick={triggerPhotoUpload}
            >
              {photoPreview ? 'Changer la photo' : 'Ajouter une photo'}
            </button>
          </div>
        </div>
        
        {/* Section informations principales */}
        <div className="form-fields">
          <div className="form-group">
            <label htmlFor="nom">Nom*:</label>
            <input 
              type="text" 
              id="nom" 
              name="nom" 
              required
              value={formData.nom}
              onChange={handleInputChange}
              placeholder="Nom de famille"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="prenom">Prénom*:</label>
            <input 
              type="text" 
              id="prenom" 
              name="prenom" 
              required
              value={formData.prenom}
              onChange={handleInputChange}
              placeholder="Prénom"
            />
          </div>
          
          <div className="form-columns">
            <div className="form-column">
              <div className="form-group">
                <label htmlFor="telephone">Téléphone:</label>
                <input 
                  type="tel" 
                  id="telephone" 
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  placeholder="Numéro de téléphone"
                />
              </div>
            </div>
            
            <div className="form-column">
              <div className="form-group">
                <label htmlFor="date-naissance">Date de naissance:</label>
                <input 
                  type="date" 
                  id="date-naissance" 
                  name="date-naissance"
                  value={formData['date-naissance']}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input 
              type="email" 
              id="email" 
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="adresse@email.com"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="adresse">Adresse:</label>
            <textarea 
              id="adresse" 
              name="adresse" 
              rows="2"
              value={formData.adresse}
              onChange={handleInputChange}
              placeholder="Adresse complète"
            ></textarea>
          </div>
          
          {/* Section pour les réseaux sociaux */}
          <div className="social-media-container">
            <h3>Réseaux sociaux</h3>
            <div className="social-networks">
              {formData.socialNetworks.map((network, index) => {
                const { icon, label } = getSocialNetworkInfo(network.type);
                return (
                  <div key={index} className="social-network">
                    <div className="social-network-icon">{icon}</div>
                    <select
                      value={network.type}
                      onChange={(e) => changeSocialNetworkType(index, e.target.value)}
                      className="social-network-select"
                    >
                      {availableSocialNetworks.map(option => (
                        <option 
                          key={option.type} 
                          value={option.type}
                          disabled={formData.socialNetworks.some(
                            (n, i) => n.type === option.type && i !== index
                          )}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <input 
                      type="text" 
                      className="social-network-input"
                      value={network.value} 
                      onChange={(e) => handleSocialNetworkChange(index, e.target.value)}
                      placeholder={`Profil ${label}`}
                    />
                    {formData.socialNetworks.length > 1 && (
                      <button 
                        type="button" 
                        className="remove-social-btn"
                        onClick={() => removeSocialNetwork(index)}
                        aria-label={`Supprimer ${label}`}
                      >
                        ×
                      </button>
                    )}
                  </div>
                );
              })}
              
              {formData.socialNetworks.length < availableSocialNetworks.length && (
                <button 
                  type="button" 
                  className="add-social-btn"
                  onClick={addSocialNetwork}
                >
                  + Ajouter un réseau social
                </button>
              )}
            </div>
          </div>
          
          {/* Section notes */}
          <div className="form-group">
            <label htmlFor="notes">Notes:</label>
            <textarea 
              id="notes" 
              name="notes" 
              rows="3"
              value={formData.notes || ''}
              onChange={handleInputChange}
              placeholder="Notes additionnelles sur le client..."
            ></textarea>
          </div>
        </div>
      </div>
      
      <div className="form-actions">
        <button type="submit" className="submit-btn">
          {initialData ? 'Mettre à jour' : 'Enregistrer'}
        </button>
        <button type="button" className="reset-btn" onClick={handleReset}>
          Annuler
        </button>
      </div>
    </form>
  );
};