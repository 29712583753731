import React from 'react';
import './Prestations.css';

export const PrestationsList = ({ prestationsByCategory, onEdit, onDelete }) => {
  // Formater le prix en euros
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };

  // Formater la durée en heures et minutes
  const formatDuration = (minutes) => {
    if (!minutes) return 'Non spécifiée';
    
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours === 0) {
      return `${mins} min`;
    } else if (mins === 0) {
      return `${hours} h`;
    } else {
      return `${hours} h ${mins} min`;
    }
  };

  // Vérifier s'il y a des prestations dans toutes les catégories
  const hasPrestations = Object.values(prestationsByCategory || {}).some(
    categoryItems => categoryItems && categoryItems.length > 0
  );

  return (
    <div className="prestations-list">
      {!hasPrestations ? (
        <div className="no-prestations">
          <p>Aucune prestation trouvée. Créez votre première prestation en cliquant sur l'onglet "Nouvelle Prestation".</p>
        </div>
      ) : (
        <>
          {Object.entries(prestationsByCategory).map(([category, prestations]) => (
            <div key={category} className="category-section">
              <h3 className="category-title">
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h3>
              <div className="prestations-grid">
                {prestations.map((prestation) => (
                  <div 
                    key={prestation.id} 
                    className="prestation-card"
                    style={{ borderTop: `4px solid ${prestation.couleur || '#c6b291'}` }}
                  >
                    <div className="prestation-header">
                      <div className="prestation-image">
                        {prestation.image ? (
                          <img src={prestation.image} alt={prestation.nom} />
                        ) : (
                          <div className="placeholder-image" style={{ backgroundColor: prestation.couleur || '#c6b291' }}>
                            {prestation.nom?.charAt(0) || 'P'}
                          </div>
                        )}
                      </div>
                      <div className="prestation-title">
                        <h3>{prestation.nom}</h3>
                        <div className="prestation-category">
                          {prestation.categorie && (
                            <span className="category-badge">
                              {prestation.categorie.charAt(0).toUpperCase() + prestation.categorie.slice(1)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="prestation-details">
                      <div className="prestation-info">
                        <div className="prestation-price">
                          <div className="price-item">
                            <span className="price-label">Prix HT:</span>
                            <span className="price-value">{formatPrice(prestation.prixHT)}</span>
                          </div>
                          <div className="price-item">
                            <span className="price-label">Prix TTC:</span>
                            <span className="price-value">{formatPrice(prestation.prixTTC)}</span>
                          </div>
                        </div>
                        <div className="prestation-duration">
                          <i className="duration-icon">⏱️</i>
                          <span>{formatDuration(prestation.duree)}</span>
                        </div>
                      </div>
                      
                      {prestation.description && (
                        <div className="prestation-description">
                          <p>{prestation.description}</p>
                        </div>
                      )}
                    </div>
                    
                    <div className="prestation-actions">
                      <button 
                        className="action-btn edit-btn" 
                        onClick={() => onEdit(prestation.id)}
                      >
                        Modifier
                      </button>
                      <button 
                        className="action-btn delete-btn" 
                        onClick={() => onDelete(prestation.id)}
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};