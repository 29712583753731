// MigrationTool.js
import { AgendaService } from './AgendaService';


export const migrateLocalStorageToServer = async () => {
    try {
      // Vérifier si la migration a déjà été effectuée
      const migrationDone = localStorage.getItem('agenda_migration_done');
      if (migrationDone === 'true') {
        console.log('Migration déjà effectuée');
        return;
      }
      
      // Récupérer les rendez-vous du localStorage
      const localAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      
      // Récupérer les paramètres du localStorage
      const localSettings = JSON.parse(localStorage.getItem('agenda_settings') || 'null');
      
      // Migrer les rendez-vous vers le serveur
      if (localAppointments.length > 0) {
        console.log(`Migration de ${localAppointments.length} rendez-vous...`);
        
        for (const appointment of localAppointments) {
          try {
            // Formater le rendez-vous pour l'API
            const apiAppointment = {
              client_name: appointment.client_name,
              client_phone: appointment.client_phone,
              client_email: appointment.client_email,
              date: appointment.date,
              start: appointment.start,
              duration: appointment.duration,
              type: appointment.type,
              notes: appointment.notes || "",
              color: appointment.color || "#c6b291",
              is_recurring: appointment.isRecurring || false,
              recurrence_type: appointment.recurrenceType || null,
              recurrence_interval: appointment.recurrenceInterval || null,
              recurrence_end_date: appointment.recurrenceEndDate || null,
              recurrence_end_after: appointment.recurrenceEndAfter || null,
              recurrence_end_type: appointment.recurrenceEndType || null,
              reminder: appointment.reminder || false,
              reminder_time: appointment.reminderTime || null
            };
            
            await AgendaService.createAppointment(apiAppointment);
            console.log(`Rendez-vous "${appointment.client_name} - ${appointment.date}" migré avec succès`);
          } catch (error) {
            console.error(`Erreur lors de la migration du rendez-vous:`, error);
          }
        }
      }
      
      // Migrer les paramètres vers le serveur
      if (localSettings) {
        console.log('Migration des paramètres...');
        
        try {
          const apiSettings = {
            start_hour: localSettings.startHour,
            end_hour: localSettings.endHour,
            time_step: localSettings.timeStep,
            closed_days: localSettings.closedDays || [],
            closing_hours: localSettings.closingHours || {},
            enable_recurrence: localSettings.enableRecurrence || false,
            enable_export: localSettings.enableExport || false,
            enable_client_integration: localSettings.enableClientIntegration || false,
            enable_reminders: localSettings.enableReminders || false,
            enable_year_view: localSettings.enableYearView || false,
            default_reminder_time: localSettings.defaultReminderTime || 60
          };
          
          await AgendaService.updateSettings(apiSettings);
          console.log('Paramètres migrés avec succès');
        } catch (error) {
          console.error('Erreur lors de la migration des paramètres:', error);
        }
      }
      
      // Marquer la migration comme terminée
      localStorage.setItem('agenda_migration_done', 'true');
      console.log('Migration terminée');
      
      return true;
    } catch (error) {
      console.error('Erreur lors de la migration:', error);
      return false;
    }
  };