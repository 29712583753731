import React, { useState, useEffect } from "react";
import { BarcodeScanner } from "../Stock/BarcodeScanner";
import { CaisseSettings } from "./CaisseSettings";
import { ProductSelector } from "./ProductSelector";
import { PrestationSelector } from "./PrestationSelector";
import { ClientSelector } from "./ClientSelector";
import { GiftCardSelector } from "./GiftCardSelector";
import { ImportExportComponent } from "./ImportExportComponent";
import { DataSyncService } from "../../services/DataSyncService";
import "./Caisse.css";

export const Caisse = () => {
  const [clientType, setClientType] = useState("agenda");
  const [selectedClient, setSelectedClient] = useState("");
  const [clientInfo, setClientInfo] = useState(null);
  const [passageNom, setPassageNom] = useState("");
  const [passagePrenom, setPassagePrenom] = useState("");
  const [clients, setClients] = useState([]);
  const [clientAppointments, setClientAppointments] = useState([]);
  const [prestations, setPrestations] = useState([]);
  const [panier, setPanier] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [subtotalHT, setSubtotalHT] = useState(0);
  const [discountType, setDiscountType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [freeItemId, setFreeItemId] = useState("");
  const [freeItems, setFreeItems] = useState([]);
  const [useFreeSale, setUseFreeSale] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [isRefundMode, setIsRefundMode] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showTransactionList, setShowTransactionList] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [showRefundForm, setShowRefundForm] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [stockItems, setStockItems] = useState([]);
  const [scanCooldown, setScanCooldown] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [importExportMessage, setImportExportMessage] = useState(null);

  // États pour les sélecteurs modaux
  const [showClientSelector, setShowClientSelector] = useState(false);
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [showPrestationSelector, setShowPrestationSelector] = useState(false);
  const [showGiftCardSelector, setShowGiftCardSelector] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [settings, setSettings] = useState({
    clientRequired: true,
    passageNomRequired: true,
    passagePrenomRequired: true,
    paymentMethodRequired: true,
    notesRequired: false,
    allowProductSale: true,
    allowPrestationSale: true,
    allowGiftCardSale: true,
    defaultPaymentMethod: "cash",
    automaticPriceCalculation: true,
    showHTValues: true,
    vatRate: 20,
  });

  // Loguer toutes les requêtes réseau
  useEffect(() => {
    // Loguer toutes les requêtes réseau
    const originalFetch = window.fetch;
    window.fetch = function (...args) {
      // Log l'URL et les options
      console.log("🔍 Fetch appelé avec:", args[0], args[1] || {});
      return originalFetch
        .apply(this, args)
        .then((response) => {
          console.log(`🔍 Réponse pour ${args[0]}: status=${response.status}`);
          // Clone la réponse pour pouvoir la lire tout en la retournant
          return response;
        })
        .catch((error) => {
          console.error(`🔍 Erreur pour ${args[0]}:`, error);
          throw error;
        });
    };

    return () => {
      // Restaurer la fonction fetch originale
      window.fetch = originalFetch;
    };
  }, []);

  // Fonction pour vérifier la connectivité
  const checkConnectivity = () => {
    fetch("https://tickettime.fr/api/ping/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        console.log("🔄 Test de connectivité:", response.status);
        alert(`Test de connectivité: ${response.status}`);
      })
      .catch((error) => {
        console.error("🔄 Erreur de connectivité:", error);
        alert(`Erreur de connectivité: ${error.message}`);
      });
  };

  // Charger les données au chargement du composant
  useEffect(() => {
    // Charger les clients
    const storedClients = JSON.parse(localStorage.getItem("clients") || "[]");
    setClients(storedClients);

    // Charger les prestations
    const storedPrestations = JSON.parse(
      localStorage.getItem("prestations") || "[]"
    );
    setPrestations(storedPrestations);

    // Charger les transactions
    const storedTransactions = JSON.parse(
      localStorage.getItem("transactions") || "[]"
    );
    setTransactions(storedTransactions);

    // Charger les articles du stock
    const storedStockItems = JSON.parse(
      localStorage.getItem("stock_items") || "[]"
    );
    setStockItems(storedStockItems);

    // Charger les cartes cadeaux
    const storedGiftCards = JSON.parse(
      localStorage.getItem("gift_cards") || "[]"
    );
    setGiftCards(storedGiftCards);

    // Créer une liste des produits disponibles pour les produits offerts
    const availableItems = [
      ...storedPrestations.map((p) => ({
        id: `prestation_${p.id}`,
        nom: p.nom,
        type: "prestation",
        targetId: p.id,
        prix: p.prixTTC || p.prix || 0,
      })),
      ...storedStockItems
        .filter((s) => s.quantite > 0)
        .map((s) => ({
          id: `product_${s.id}`,
          nom: s.nom,
          type: "product",
          targetId: s.id,
          prix: s.prixRevente || s.prixAchat * 1.5 || 0,
        })),
    ];
    setFreeItems(availableItems);

    // Charger les paramètres de la caisse
    const storedSettings = JSON.parse(
      localStorage.getItem("caisse_settings") || "{}"
    );
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...storedSettings,
    }));

    // Définir la méthode de paiement par défaut selon les paramètres
    if (storedSettings.defaultPaymentMethod) {
      setPaymentMethod(storedSettings.defaultPaymentMethod);
    }
  }, []);

  // Mettre à jour les totaux à chaque changement du panier ou de la remise
  useEffect(() => {
    // Calculer le sous-total en tenant compte des quantités (TTC)
    const newSubtotal = panier.reduce(
      (sum, item) => sum + (item.prix || 0) * (item.quantite || 1),
      0
    );
    setSubtotal(newSubtotal);

    // Calculer le sous-total HT
    const vatRate = settings.vatRate / 100 || 0.2;
    const newSubtotalHT = panier.reduce((sum, item) => {
      const prixHT = item.prixHT || item.prix / (1 + vatRate);
      return sum + prixHT * (item.quantite || 1);
    }, 0);
    setSubtotalHT(newSubtotalHT);

    // Calculer la remise
    let newDiscountAmount = 0;

    if (!useFreeSale) {
      // Remise classique en pourcentage ou montant fixe
      if (discountType === "percentage") {
        newDiscountAmount = newSubtotal * (discountValue / 100);
      } else {
        newDiscountAmount = discountValue;
      }

      // S'assurer que la remise ne dépasse pas le sous-total
      newDiscountAmount = Math.min(newDiscountAmount, newSubtotal);
      setDiscountAmount(newDiscountAmount);
    } else {
      // Produit offert
      if (freeItemId) {
        const selectedFreeItem = freeItems.find(
          (item) => item.id === freeItemId
        );
        if (selectedFreeItem) {
          newDiscountAmount = selectedFreeItem.prix;
          setDiscountAmount(newDiscountAmount);
        }
      } else {
        setDiscountAmount(0);
      }
    }

    // Calculer le total TTC
    const newTotal = newSubtotal - newDiscountAmount;
    // En mode remboursement, le total est négatif
    setTotal(isRefundMode ? -newTotal : newTotal);

    // Calculer le total HT
    const newTotalHT = newSubtotalHT - newDiscountAmount / (1 + vatRate);
    setTotalHT(isRefundMode ? -newTotalHT : newTotalHT);
  }, [
    panier,
    discountType,
    discountValue,
    isRefundMode,
    freeItemId,
    useFreeSale,
    settings.vatRate,
  ]);

  // Gérer l'importation de données
  const handleDataImport = (type, data) => {
    if (!data || data.length === 0) return;

    switch (type) {
      case "transactions":
        // Fusion avec les transactions existantes en évitant les doublons
        const existingTransactionIds = transactions.map((t) => t.id);
        const newTransactions = data.filter(
          (t) => !existingTransactionIds.includes(t.id)
        );
        const updatedTransactions = [...transactions, ...newTransactions];
        setTransactions(updatedTransactions);
        localStorage.setItem(
          "transactions",
          JSON.stringify(updatedTransactions)
        );
        setImportExportMessage({
          type: "success",
          text: `${newTransactions.length} nouvelle(s) transaction(s) importée(s)`,
        });
        break;

      case "clients":
        // Fusion avec les clients existants en évitant les doublons
        const existingClientIds = clients.map((c) => c.id);
        const newClients = data.filter(
          (c) => !existingClientIds.includes(c.id)
        );
        const updatedClients = [...clients, ...newClients];
        setClients(updatedClients);
        localStorage.setItem("clients", JSON.stringify(updatedClients));
        setImportExportMessage({
          type: "success",
          text: `${newClients.length} nouveau(x) client(s) importé(s)`,
        });
        break;

      case "stock":
        // Fusion avec les articles existants en évitant les doublons
        const existingStockIds = stockItems.map((s) => s.id);
        const newStockItems = data.filter(
          (s) => !existingStockIds.includes(s.id)
        );
        const updatedStockItems = [...stockItems, ...newStockItems];
        setStockItems(updatedStockItems);
        localStorage.setItem("stock_items", JSON.stringify(updatedStockItems));
        setImportExportMessage({
          type: "success",
          text: `${newStockItems.length} nouvel(s) article(s) importé(s)`,
        });
        break;

      case "prestations":
        // Fusion avec les prestations existantes en évitant les doublons
        const existingPrestationIds = prestations.map((p) => p.id);
        const newPrestations = data.filter(
          (p) => !existingPrestationIds.includes(p.id)
        );
        const updatedPrestations = [...prestations, ...newPrestations];
        setPrestations(updatedPrestations);
        localStorage.setItem("prestations", JSON.stringify(updatedPrestations));
        setImportExportMessage({
          type: "success",
          text: `${newPrestations.length} nouvelle(s) prestation(s) importée(s)`,
        });
        break;

      default:
        setImportExportMessage({
          type: "error",
          text: `Type d'importation non pris en charge: ${type}`,
        });
    }

    // Masquer le message après un certain délai
    setTimeout(() => {
      setImportExportMessage(null);
    }, 5000);
  };

  // Charger les rendez-vous d'un client
  const loadClientAppointments = (clientId) => {
    if (!clientId) {
      setClientAppointments([]);
      return;
    }

    const appointments = JSON.parse(
      localStorage.getItem("appointments") || "[]"
    );

    // Filtrer les rendez-vous du client
    const clientAppts = appointments.filter((a) => a.client === clientId);

    // Trier les rendez-vous par date (du plus récent au plus ancien)
    clientAppts.sort((a, b) => new Date(b.date) - new Date(a.date));

    setClientAppointments(clientAppts);
  };

  // Gérer la sélection d'un client depuis le modal
  const handleClientSelection = (selectedClientData) => {
    if (selectedClientData.type === "agenda") {
      setClientType("agenda");
      setSelectedClient(selectedClientData.clientId);

      // Charger les informations du client sélectionné
      const client = clients.find((c) => c.id == selectedClientData.clientId);
      if (client) {
        setClientInfo(client);
      }

      // Charger les rendez-vous du client
      loadClientAppointments(selectedClientData.clientId);
    } else {
      setClientType("passage");
      setPassageNom(selectedClientData.nom);
      setPassagePrenom(selectedClientData.prenom);
      setClientInfo({
        nom: selectedClientData.nom,
        prenom: selectedClientData.prenom,
        type: "passage",
      });
    }
  };

  // Ajouter une prestation au panier
  const addPrestationToPanier = (prestation) => {
    if (!prestation) return;

    // Vérifier si la prestation est déjà dans le panier
    const existingItemIndex = panier.findIndex(
      (item) => item.prestationId == prestation.id
    );

    if (existingItemIndex >= 0) {
      // Incrémenter la quantité si la prestation existe déjà
      const updatedPanier = [...panier];
      updatedPanier[existingItemIndex] = {
        ...updatedPanier[existingItemIndex],
        quantite: (updatedPanier[existingItemIndex].quantite || 1) + 1,
      };
      setPanier(updatedPanier);
    } else {
      // Ajouter une nouvelle prestation au panier
      const vatRate = settings.vatRate / 100 || 0.2;
      const prixTTC = prestation.prixTTC || prestation.prix || 0;
      const prixHT = prestation.prixHT || prixTTC / (1 + vatRate);

      const newItem = {
        id: Date.now(), // ID unique pour cet élément du panier
        prestationId: prestation.id,
        nom: prestation.nom,
        prix: prixTTC,
        prixHT: prixHT,
        quantite: 1,
      };

      setPanier([...panier, newItem]);
    }
  };

  // Ajouter un produit au panier
  const addProductToPanier = (product) => {
    if (!product) return;

    // Vérifier si l'article est en stock
    if (product.quantite <= 0) {
      alert(`L'article ${product.nom} n'est plus en stock.`);
      return;
    }

    // Vérifier si ce produit est déjà dans le panier
    const existingItemIndex = panier.findIndex(
      (item) => item.stockItemId == product.id
    );

    if (existingItemIndex >= 0) {
      // Incrémenter la quantité si le produit existe déjà
      const updatedPanier = [...panier];
      updatedPanier[existingItemIndex] = {
        ...updatedPanier[existingItemIndex],
        quantite: (updatedPanier[existingItemIndex].quantite || 1) + 1,
      };
      setPanier(updatedPanier);
      return;
    }

    // Rechercher si la prestation correspondante existe
    const matchingPrestation = prestations.find(
      (p) =>
        p.reference === product.reference ||
        p.nom.toLowerCase() === product.nom.toLowerCase()
    );

    if (matchingPrestation) {
      // Ajouter la prestation au panier
      addPrestationToPanier(matchingPrestation);
    } else {
      // Créer un nouvel élément de panier basé sur l'article du stock
      const vatRate = settings.vatRate / 100 || 0.2;
      const prixTTC = product.prixRevente || product.prixAchat * 1.5;
      const prixHT = prixTTC / (1 + vatRate);

      const newItem = {
        id: Date.now(),
        prestationId: null, // Pas lié à une prestation existante
        nom: product.nom,
        prix: prixTTC,
        prixHT: prixHT,
        quantite: 1,
        stockItemId: product.id, // Référence à l'article de stock
        reference: product.reference,
      };

      setPanier([...panier, newItem]);
    }
  };

  // Ajouter une carte cadeau au panier
  const addGiftCardToPanier = (giftCard) => {
    if (!giftCard) return;

    // Vérifier si cette carte cadeau est déjà dans le panier
    const existingItemIndex = panier.findIndex(
      (item) => item.giftCardId && item.giftCardId === giftCard.id
    );

    if (existingItemIndex >= 0) {
      // Incrémenter la quantité si la carte cadeau existe déjà
      const updatedPanier = [...panier];
      updatedPanier[existingItemIndex] = {
        ...updatedPanier[existingItemIndex],
        quantite: (updatedPanier[existingItemIndex].quantite || 1) + 1,
      };
      setPanier(updatedPanier);
    } else {
      // Les cartes cadeaux ne sont pas soumises à la TVA
      const newItem = {
        id: Date.now(),
        giftCardId: giftCard.id,
        nom: `Carte Cadeau ${giftCard.montant}€ - ${giftCard.type}`,
        prix: giftCard.montant,
        prixHT: giftCard.montant, // Même valeur car pas de TVA sur cartes cadeaux
        quantite: 1,
      };

      setPanier([...panier, newItem]);
    }
  };

  // Ajouter une prestation au panier depuis un rendez-vous
  const addRdvToPanier = (rdvId) => {
    const rdv = clientAppointments.find((a) => a.id == rdvId);

    if (rdv && rdv.type) {
      // Rechercher les prestations correspondant au type de rendez-vous
      const matchingPrestations = prestations.filter(
        (p) =>
          p.nom.toLowerCase().includes(rdv.type.toLowerCase()) ||
          p.categorie.toLowerCase().includes(rdv.type.toLowerCase())
      );

      if (matchingPrestations.length > 0) {
        const prestation = matchingPrestations[0];
        addPrestationToPanier(prestation);
      } else {
        alert("Aucune prestation correspondante trouvée pour ce rendez-vous.");
      }
    } else {
      alert("Ce rendez-vous n'a pas de type spécifié.");
    }
  };

  // Fonction pour gérer la détection d'un code-barres
  const handleBarcodeDetected = (barcode) => {
    console.log("Code-barres détecté dans la caisse:", barcode);

    // Si nous sommes en période de refroidissement, ignorer cette détection
    if (scanCooldown) {
      return;
    }

    // Activer le refroidissement pour éviter les détections multiples
    setScanCooldown(true);
    setTimeout(() => setScanCooldown(false), 3000); // 3 secondes de refroidissement

    // Rechercher l'article correspondant dans le stock
    const stockItem = stockItems.find((item) => item.reference === barcode);

    if (stockItem) {
      if (stockItem.quantite <= 0) {
        alert(`L'article ${stockItem.nom} n'est plus en stock.`);
        setShowScanner(false);
        return;
      }

      // Ajouter le produit au panier (la logique d'incrémentation est gérée dans addProductToPanier)
      addProductToPanier(stockItem);

      // Afficher un message de confirmation
      alert(`Article "${stockItem.nom}" ajouté au panier.`);
    } else {
      alert(
        `Aucun article avec le code-barres ${barcode} n'a été trouvé dans le stock.`
      );
    }

    setShowScanner(false);
  };

  // Supprimer un élément du panier
  const removeFromPanier = (itemId) => {
    setPanier(panier.filter((item) => item.id !== itemId));
  };

  // Vider le panier
  const clearPanier = () => {
    if (
      panier.length > 0 &&
      window.confirm("Êtes-vous sûr de vouloir vider le panier ?")
    ) {
      setPanier([]);
      // Réinitialiser aussi les remises
      setDiscountValue(0);
      setUseFreeSale(false);
      setFreeItemId("");
    }
  };

  // Modifier la quantité d'un élément du panier
  const updateQuantity = (itemId, newQuantity) => {
    if (newQuantity < 1) return;

    // Si le produit est un article de stock, vérifier la disponibilité
    const panierItem = panier.find((item) => item.id === itemId);
    if (panierItem?.stockItemId) {
      const stockItem = stockItems.find((s) => s.id === panierItem.stockItemId);
      if (stockItem && newQuantity > stockItem.quantite) {
        alert(
          `Il n'y a que ${stockItem.quantite} article(s) disponible(s) en stock.`
        );
        newQuantity = stockItem.quantite;
      }
    }

    const updatedPanier = panier.map((item) =>
      item.id === itemId ? { ...item, quantite: newQuantity } : item
    );

    setPanier(updatedPanier);
  };

  // Vérifier si les champs obligatoires sont remplis
  const validateRequiredFields = () => {
    // Vérifier si le client est sélectionné (si requis)
    if (settings.clientRequired) {
      if (clientType === "agenda" && !selectedClient) {
        alert("Veuillez sélectionner un client.");
        return false;
      }

      if (clientType === "passage") {
        if (settings.passageNomRequired && !passageNom) {
          alert("Veuillez saisir le nom du client de passage.");
          return false;
        }

        if (settings.passagePrenomRequired && !passagePrenom) {
          alert("Veuillez saisir le prénom du client de passage.");
          return false;
        }
      }
    }

    // Vérifier si une méthode de paiement est sélectionnée (si requis)
    if (settings.paymentMethodRequired && !paymentMethod) {
      alert("Veuillez sélectionner une méthode de paiement.");
      return false;
    }

    // Vérifier si les notes sont renseignées pour un remboursement (si requis)
    if (isRefundMode && settings.notesRequired && !refundReason) {
      alert("Veuillez saisir la raison du remboursement.");
      return false;
    }

    return true;
  };

  // Fonction pour tester directement l'endpoint
  const testEndpoints = () => {
    // Créer une transaction test simplifiée
    const testTransaction = {
      transaction_id: "test_" + Date.now(),
      date: new Date().toISOString(),
      client_info: { nom: "Test", prenom: "Client" },
      items: [{ nom: "Produit test", prix: 10, quantite: 1 }],
      total: 10,
      payment_method: "cash",
    };

    console.log("Test de l'endpoint /api/caisse/transactions/simple/...");

    // Méthode avec fetch direct
    fetch("/api/caisse/transactions/simple/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(testTransaction),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("✅ Test réussi:", data);
        alert("Test réussi! Voir la console pour les détails.");
      })
      .catch((error) => {
        console.error("❌ Test échoué:", error);
        alert("Test échoué! Voir la console pour les détails.");
      });
  };

  // Nouveau test direct avec URL absolue et relative
  const testDirectFetch = () => {
    // Transaction minimale pour test
    const testData = {
      transaction_id: "test_" + Date.now(),
      date: new Date().toISOString(),
      client_info: { nom: "Test", prenom: "Client" },
      items: [{ nom: "Test item", prix: 10, quantite: 1 }],
      total: 10,
      payment_method: "cash",
    };

    console.log("🟣 Test avec fetch direct - Données:", testData);

    // Essayer avec URL absolue
    try {
      fetch("https://tickettime.fr/api/caisse/transactions/simple/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(testData),
      })
        .then((response) => {
          console.log("🟣 Réponse reçue, statut:", response.status);
          return response.json();
        })
        .then((data) => {
          console.log("🟣 Succès:", data);
          alert("Test avec URL absolue réussi!");
        })
        .catch((error) => {
          console.error("🟣 Erreur avec URL absolue:", error);
        });
    } catch (error) {
      console.error("🟣 Exception test absolu:", error);
    }

    // Essayer avec URL relative
    try {
      fetch("/api/caisse/transactions/simple/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(testData),
      })
        .then((response) => {
          console.log(
            "🟣 Réponse reçue, statut (URL relative):",
            response.status
          );
          return response.json();
        })
        .then((data) => {
          console.log("🟣 Succès (URL relative):", data);
          alert("Test avec URL relative réussi!");
        })
        .catch((error) => {
          console.error("🟣 Erreur avec URL relative:", error);
        });
    } catch (error) {
      console.error("🟣 Exception test relatif:", error);
    }
  };

  // Traiter le paiement
  const processPayment = () => {
    // Afficher un message de débogage au tout début
    console.log("🟠 DÉBUT de processPayment - Tentative d'encaissement");

    if (panier.length === 0) {
      alert("Le panier est vide.");
      return;
    }

    // Vérifier les champs obligatoires
    if (!validateRequiredFields()) {
      return;
    }

    // Récupérer les informations du client
    let clientData = {};

    if (clientType === "agenda") {
      if (settings.clientRequired && !selectedClient) {
        alert("Veuillez sélectionner un client.");
        return;
      }

      const client = clients.find((c) => c.id == selectedClient);

      if (client) {
        clientData = {
          id: client.id,
          nom: client.nom,
          prenom: client.prenom,
          type: "agenda",
        };
      }
    } else {
      // Client de passage
      if (settings.clientRequired) {
        if (settings.passageNomRequired && !passageNom) {
          alert("Veuillez saisir le nom du client de passage.");
          return;
        }

        if (settings.passagePrenomRequired && !passagePrenom) {
          alert("Veuillez saisir le prénom du client de passage.");
          return;
        }
      }

      clientData = {
        id: "passage_" + Date.now(),
        nom: passageNom,
        prenom: passagePrenom,
        type: "passage",
      };
    }

    // Vérifier si une méthode de paiement est sélectionnée (si requis)
    if (settings.paymentMethodRequired && !paymentMethod) {
      alert("Veuillez sélectionner une méthode de paiement.");
      return;
    }

    // Vérifier si les notes sont renseignées pour un remboursement (si requis)
    if (isRefundMode && settings.notesRequired && !refundReason) {
      alert("Veuillez saisir la raison du remboursement.");
      return;
    }

    // Créer la transaction
    const transaction = {
      id: Date.now(),
      date: new Date().toISOString(),
      client: clientData,
      items: [...panier],
      subtotal,
      subtotalHT,
      discount: discountAmount,
      useFreeSale,
      freeItemId,
      total: Math.abs(total), // Toujours stocker le montant positif
      totalHT: Math.abs(totalHT),
      paymentMethod,
      type: isRefundMode ? "refund" : "sale",
      refundReason: isRefundMode ? refundReason : "",
      refundedTransaction:
        isRefundMode && selectedTransaction ? selectedTransaction.id : null,
    };

    // Format adapté pour l'API Django
    const formattedTransaction = {
      transaction_id: transaction.id.toString(),
      date: transaction.date,
      client_info: transaction.client,
      items: transaction.items,
      subtotal: parseFloat(transaction.subtotal) || 0,
      subtotal_ht: parseFloat(transaction.subtotalHT) || 0,
      discount: parseFloat(transaction.discount) || 0,
      use_free_sale: Boolean(transaction.useFreeSale),
      free_item_id: transaction.freeItemId || null,
      total: parseFloat(transaction.total) || 0,
      total_ht: parseFloat(transaction.totalHT) || 0,
      payment_method: transaction.paymentMethod || "cash",
      type: transaction.type || "sale",
      refund_reason: transaction.refundReason || "",
      refunded_transaction: transaction.refundedTransaction || null,
    };

    console.log(
      "🟠 Envoi de la transaction avec un fetch direct",
      formattedTransaction
    );

    // Test de connexion - Ce log permet de vérifier que la fonction est correctement appelée
    console.log(
      "🟠 Token d'accès utilisé:",
      localStorage.getItem("accessToken")
    );

    // IMPORTANT: Test direct avec des options explicites
    try {
      fetch("/api/caisse/transactions/simple/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(formattedTransaction),
        credentials: "same-origin",
      })
        .then((response) => {
          console.log("🟠 Réponse reçue, statut HTTP:", response.status);
          if (!response.ok) {
            console.error("🔴 Erreur HTTP:", response.status);
            throw new Error(`Erreur HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("🟢 Réponse du serveur:", data);

          // Mettre à jour le stock pour chaque article du panier lié à un article de stock
          const stockUpdates = [];

          panier.forEach((item) => {
            if (item.stockItemId) {
              const stockItem = stockItems.find(
                (s) => s.id === item.stockItemId
              );
              if (stockItem) {
                const currentQuantity = stockItem.quantite;
                const newQuantity = isRefundMode
                  ? currentQuantity + item.quantite // Ajouter au stock en cas de remboursement
                  : Math.max(0, currentQuantity - item.quantite); // Soustraire du stock en cas de vente

                // Créer une entrée d'historique
                const movement = {
                  date: new Date().toISOString(),
                  quantityBefore: currentQuantity,
                  quantityAfter: newQuantity,
                  difference: newQuantity - currentQuantity,
                  notes: isRefundMode
                    ? `Remboursement - Transaction #${transaction.id}`
                    : `Vente - Transaction #${transaction.id}`,
                };

                // Mettre à jour l'article dans le stock
                const updatedStockItem = {
                  ...stockItem,
                  quantite: newQuantity,
                  historique: [...(stockItem.historique || []), movement],
                };

                stockUpdates.push(updatedStockItem);
              }
            }
          });

          // Mettre à jour le stockage local
          if (stockUpdates.length > 0) {
            const updatedStockItems = stockItems.map((item) => {
              const update = stockUpdates.find((u) => u.id === item.id);
              return update || item;
            });

            setStockItems(updatedStockItems);
            localStorage.setItem(
              "stock_items",
              JSON.stringify(updatedStockItems)
            );
          }

          // Enregistrer la transaction
          const updatedTransactions = [...transactions, transaction];
          setTransactions(updatedTransactions);
          localStorage.setItem(
            "transactions",
            JSON.stringify(updatedTransactions)
          );

          // Réinitialiser le panier et les données du client de passage
          setPanier([]);
          if (clientType === "passage") {
            setPassageNom("");
            setPassagePrenom("");
          }

          // Réinitialiser les remises
          setDiscountValue(0);
          setUseFreeSale(false);
          setFreeItemId("");

          // Réinitialiser le mode remboursement si actif
          if (isRefundMode) {
            setIsRefundMode(false);
            setSelectedTransaction(null);
            setRefundReason("");
            setShowRefundForm(false);
          }

          // Afficher un message de confirmation
          alert(
            isRefundMode
              ? "Remboursement effectué avec succès !"
              : "Paiement effectué avec succès !"
          );
        })
        .catch((error) => {
          console.error("🔴 Erreur lors de l'envoi de la transaction:", error);
          alert("Erreur: " + error.message);

          // Enregistrer localement malgré l'erreur
          const updatedTransactions = [...transactions, transaction];
          setTransactions(updatedTransactions);
          localStorage.setItem(
            "transactions",
            JSON.stringify(updatedTransactions)
          );
        });
    } catch (error) {
      console.error("🔴 Exception lors de l'appel fetch:", error);
      alert("Exception: " + error.message);
    }
  };

  // Formater un montant en euros
  const formatCurrency = (amount) => {
    // S'assurer que amount est un nombre
    const safeAmount = typeof amount === "number" ? amount : 0;
    return safeAmount.toFixed(2) + " €";
  };

  // Formater une date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
    });
  };

  // Activer le mode remboursement
  const toggleRefundMode = () => {
    setIsRefundMode(!isRefundMode);
    setPanier([]);
    setShowTransactionList(isRefundMode ? false : true);
    setShowRefundForm(false);
    setSelectedTransaction(null);
  };

  // Sélectionner une transaction pour remboursement
  const selectTransactionForRefund = (transaction) => {
    setSelectedTransaction(transaction);

    // Ajouter les produits de la transaction au panier pour remboursement
    const refundItems = transaction.items.map((item) => ({
      ...item,
      id: Date.now() + Math.random(), // Nouvel ID unique
      quantite: item.quantite || 1,
    }));

    setPanier(refundItems);
    setShowTransactionList(false);
    setShowRefundForm(true);
  };

  // Traduire les méthodes de paiement en français
  const translatePaymentMethod = (method) => {
    const methods = {
      cash: "Espèces",
      card: "Carte bancaire",
      cheque: "Chèque",
      gift: "Carte cadeau",
    };

    return methods[method] || method;
  };

  // Obtenir le texte à afficher pour les informations client
  const getClientDisplayText = () => {
    if (clientInfo) {
      if (clientType === "agenda") {
        return `${clientInfo.prenom || ""} ${clientInfo.nom || ""}`.trim();
      } else {
        return (
          `${passagePrenom || ""} ${passageNom || ""}`.trim() + " (passage)"
        );
      }
    }
    return "Aucun client sélectionné";
  };

  // Obtenir le nombre d'articles dans le panier
  const getPanierItemCount = () => {
    return panier.reduce((sum, item) => sum + (item.quantite || 1), 0);
  };

  // Appliquer la classe pour les champs obligatoires
  const getRequiredClass = (fieldName) => {
    // Vérifier si le champ est obligatoire selon les paramètres
    if (
      (fieldName === "client" && settings.clientRequired) ||
      (fieldName === "passageNom" &&
        settings.passageNomRequired &&
        clientType === "passage") ||
      (fieldName === "passagePrenom" &&
        settings.passagePrenomRequired &&
        clientType === "passage") ||
      (fieldName === "paymentMethod" && settings.paymentMethodRequired) ||
      (fieldName === "refundReason" && settings.notesRequired && isRefundMode)
    ) {
      return "required";
    }
    return "";
  };

  // Basculer entre remise standard et produit offert
  const toggleDiscountType = (type) => {
    if (type === "standard") {
      setUseFreeSale(false);
    } else {
      setUseFreeSale(true);
    }
  };

  return (
    <section className="content-section active">
      <h1>Caisse</h1>

      <div className="caisse-header-actions">
        <ImportExportComponent
          transactions={transactions}
          clients={clients}
          stockItems={stockItems}
          prestations={prestations}
          onDataImport={handleDataImport}
        />
        <button
          className="caisse-settings-btn"
          onClick={() => setShowSettingsModal(true)}
        >
          <i>⚙️</i> Paramètres
        </button>
        {isRefundMode ? (
          <button
            className="refund-toggle-btn active"
            onClick={toggleRefundMode}
          >
            Retour au mode vente
          </button>
        ) : (
          <button className="refund-toggle-btn" onClick={toggleRefundMode}>
            Passer en mode remboursement
          </button>
        )}
        <button
          onClick={testEndpoints}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#f0ad4e",
            color: "white",
            border: "none",
            borderRadius: "4px",
          }}
        >
          Tester API
        </button>
        <button
          onClick={testDirectFetch}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#9b59b6",
            color: "white",
            border: "none",
            borderRadius: "4px",
          }}
        >
          Test Direct
        </button>
        <button
          onClick={checkConnectivity}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#3498db",
            color: "white",
            border: "none",
            borderRadius: "4px",
          }}
        >
          Test Connectivité
        </button>
      </div>

      {/* Message d'import/export */}
      {importExportMessage && (
        <div className={`import-export-message ${importExportMessage.type}`}>
          {importExportMessage.type === "success" ? "✓ " : "⚠️ "}
          {importExportMessage.text}
          <button
            className="close-message"
            onClick={() => setImportExportMessage(null)}
          >
            &times;
          </button>
        </div>
      )}

      {isRefundMode && showTransactionList && (
        <div className="transaction-list-container">
          <h3>Sélectionnez une transaction à rembourser</h3>
          <div className="transaction-list">
            {transactions.filter((t) => t.type !== "refund").length > 0 ? (
              <table className="transactions-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Montant</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions
                    .filter((t) => t.type !== "refund")
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((transaction) => (
                      <tr key={transaction.id}>
                        <td>{formatDate(transaction.date)}</td>
                        <td>
                          {transaction.client
                            ? `${transaction.client.prenom || ""} ${
                                transaction.client.nom || ""
                              }`.trim()
                            : "Client inconnu"}
                        </td>
                        <td>{formatCurrency(transaction.total)}</td>
                        <td>
                          <button
                            className="select-transaction-btn"
                            onClick={() =>
                              selectTransactionForRefund(transaction)
                            }
                          >
                            Rembourser
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <p className="no-transactions">Aucune transaction à rembourser</p>
            )}
          </div>
        </div>
      )}

      {isRefundMode && showRefundForm && (
        <div className="refund-form">
          <h3>Détails du remboursement</h3>
          <div className={`form-group ${getRequiredClass("refundReason")}`}>
            <label htmlFor="refund-reason">Raison du remboursement:</label>
            <textarea
              id="refund-reason"
              placeholder="Veuillez indiquer la raison du remboursement..."
              value={refundReason}
              onChange={(e) => setRefundReason(e.target.value)}
            ></textarea>
          </div>
        </div>
      )}

      {!isRefundMode && (
        <>
          {/* Cards en grille côte à côte */}
          <div className="caisse-grid">
            {/* Carte Client */}
            <div
              className="caisse-card"
              onClick={() => setShowClientSelector(true)}
            >
              <div className="caisse-card-header">Client</div>
              <div className="caisse-card-body">
                <div className="caisse-card-icon">👤</div>
                <div className="caisse-client-info">
                  {clientInfo
                    ? getClientDisplayText()
                    : "Sélectionner un client"}
                </div>
              </div>
            </div>

            {/* Carte Produits */}
            {settings.allowProductSale && (
              <div
                className="caisse-card"
                onClick={() => setShowProductSelector(true)}
              >
                <div className="caisse-card-header">Produits</div>
                <div className="caisse-card-body">
                  <div className="caisse-card-icon">📦</div>
                  <div className="caisse-card-description">
                    Ajouter des produits au panier
                  </div>
                </div>
              </div>
            )}

            {/* Carte Prestations */}
            {settings.allowPrestationSale && (
              <div
                className="caisse-card"
                onClick={() => setShowPrestationSelector(true)}
              >
                <div className="caisse-card-header">Prestations</div>
                <div className="caisse-card-body">
                  <div className="caisse-card-icon">💆</div>
                  <div className="caisse-card-description">
                    Ajouter des prestations au panier
                  </div>
                </div>
              </div>
            )}

            {/* Carte Cartes Cadeaux */}
            {settings.allowGiftCardSale && (
              <div
                className="caisse-card"
                onClick={() => setShowGiftCardSelector(true)}
              >
                <div className="caisse-card-header">Cartes Cadeaux</div>
                <div className="caisse-card-body">
                  <div className="caisse-card-icon">🎁</div>
                  <div className="caisse-card-description">
                    Ajouter des cartes cadeaux au panier
                  </div>
                </div>
              </div>
            )}

            {/* Carte Scanner */}
            <div className="caisse-card" onClick={() => setShowScanner(true)}>
              <div className="caisse-card-header">Scanner</div>
              <div className="caisse-card-body">
                <div className="caisse-card-icon">📱</div>
                <div className="caisse-card-description">
                  Scanner un code-barres pour ajouter au panier
                </div>
              </div>
            </div>
          </div>

          {/* Panier */}
          <div className="panier-container">
            <div className="panier-header">
              <span>
                Panier ({getPanierItemCount()} article
                {getPanierItemCount() > 1 ? "s" : ""})
              </span>
              <div className="panier-header-actions">
                <button
                  className="clear-panier-btn"
                  onClick={clearPanier}
                  disabled={panier.length === 0}
                >
                  Vider
                </button>
              </div>
            </div>

            {panier.length === 0 ? (
              <div className="empty-panier">
                <p>
                  Le panier est vide. Ajoutez des produits ou des prestations.
                </p>
              </div>
            ) : (
              <table className="panier-table">
                <thead>
                  <tr>
                    <th>Article</th>
                    <th>Prix unitaire HT</th>
                    <th>Prix unitaire TTC</th>
                    <th>Quantité</th>
                    <th>Total HT</th>
                    <th>Total TTC</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody id="panier-items">
                  {panier.map((item) => (
                    <tr key={item.id}>
                      <td data-label="Article">{item.nom}</td>
                      <td data-label="Prix unitaire HT">
                        {formatCurrency(item.prixHT || 0)}
                      </td>
                      <td data-label="Prix unitaire TTC">
                        {formatCurrency(item.prix || 0)}
                      </td>
                      <td data-label="Quantité" className="quantity-cell">
                        <div className="quantity-control">
                          <button
                            className="quantity-btn"
                            onClick={() =>
                              updateQuantity(item.id, (item.quantite || 1) - 1)
                            }
                            disabled={isRefundMode}
                          >
                            -
                          </button>
                          <span className="quantity-value">
                            {item.quantite || 1}
                          </span>
                          <button
                            className="quantity-btn"
                            onClick={() =>
                              updateQuantity(item.id, (item.quantite || 1) + 1)
                            }
                            disabled={isRefundMode}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td data-label="Total HT">
                        {formatCurrency(
                          (item.prixHT || 0) * (item.quantite || 1)
                        )}
                      </td>
                      <td data-label="Total TTC">
                        {formatCurrency(
                          (item.prix || 0) * (item.quantite || 1)
                        )}
                      </td>
                      <td data-label="Actions">
                        <button
                          className="remove-item"
                          onClick={() => removeFromPanier(item.id)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="subtotal-row">
                    <td colSpan="4">Sous-total</td>
                    <td>{formatCurrency(subtotalHT)}</td>
                    <td>{formatCurrency(subtotal)}</td>
                    <td></td>
                  </tr>
                  <tr className="discount-row">
                    <td colSpan="4">
                      <div className="discount-selector">
                        <div className="discount-type-tabs">
                          <span
                            className={`discount-tab ${
                              !useFreeSale ? "active" : ""
                            }`}
                            onClick={() => toggleDiscountType("standard")}
                          >
                            Remise
                          </span>
                          <span
                            className={`discount-tab ${
                              useFreeSale ? "active" : ""
                            }`}
                            onClick={() => toggleDiscountType("free")}
                          >
                            Produit offert
                          </span>
                        </div>

                        {!useFreeSale ? (
                          // Remise standard
                          <div className="standard-discount">
                            <input
                              type="number"
                              id="discount-value"
                              value={discountValue}
                              min="0"
                              step="0.01"
                              onChange={(e) =>
                                setDiscountValue(
                                  parseFloat(e.target.value) || 0
                                )
                              }
                            />
                            <select
                              id="discount-type"
                              value={discountType}
                              onChange={(e) => setDiscountType(e.target.value)}
                              className="discount-type-select"
                            >
                              <option value="percentage">%</option>
                              <option value="amount">€</option>
                            </select>
                          </div>
                        ) : (
                          // Produit offert
                          <div className="free-item-discount">
                            <select
                              id="free-item-select"
                              value={freeItemId}
                              onChange={(e) => setFreeItemId(e.target.value)}
                              className="free-item-select"
                            >
                              <option value="">
                                Sélectionner un produit à offrir
                              </option>
                              {freeItems.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.nom} ({formatCurrency(item.prix)})
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {formatCurrency(
                        discountAmount / (1 + settings.vatRate / 100)
                      )}
                    </td>
                    <td>{formatCurrency(discountAmount)}</td>
                    <td></td>
                  </tr>
                  <tr className="total-row">
                    <td colSpan="4">Total</td>
                    <td>{formatCurrency(Math.abs(totalHT))}</td>
                    <td>{formatCurrency(Math.abs(total))}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>

          {/* Section Paiement */}
          <div className="caisse-section">
            <h3>Paiement</h3>
            <div className="payment-section">
              <div
                className={`payment-methods ${getRequiredClass(
                  "paymentMethod"
                )}`}
              >
                <div className="payment-method">
                  <input
                    type="radio"
                    id="payment-cash"
                    name="payment-method"
                    value="cash"
                    checked={paymentMethod === "cash"}
                    onChange={() => setPaymentMethod("cash")}
                  />
                  <label htmlFor="payment-cash">Espèces</label>
                </div>
                <div className="payment-method">
                  <input
                    type="radio"
                    id="payment-card"
                    name="payment-method"
                    value="card"
                    checked={paymentMethod === "card"}
                    onChange={() => setPaymentMethod("card")}
                  />
                  <label htmlFor="payment-card">Carte bancaire</label>
                </div>
                <div className="payment-method">
                  <input
                    type="radio"
                    id="payment-cheque"
                    name="payment-method"
                    value="cheque"
                    checked={paymentMethod === "cheque"}
                    onChange={() => setPaymentMethod("cheque")}
                  />
                  <label htmlFor="payment-cheque">Chèque</label>
                </div>
                <div className="payment-method">
                  <input
                    type="radio"
                    id="payment-gift"
                    name="payment-method"
                    value="gift"
                    checked={paymentMethod === "gift"}
                    onChange={() => setPaymentMethod("gift")}
                  />
                  <label htmlFor="payment-gift">Carte cadeau</label>
                </div>
              </div>

              <button
                id="validate-payment"
                className="validate-btn"
                onClick={processPayment}
                disabled={panier.length === 0}
              >
                Encaisser
              </button>
            </div>
          </div>
        </>
      )}

      {/* Scanner de code-barres */}
      {showScanner && (
        <BarcodeScanner
          onDetected={handleBarcodeDetected}
          onClose={() => setShowScanner(false)}
        />
      )}

      {/* Sélecteur de client */}
      <ClientSelector
        isOpen={showClientSelector}
        onClose={() => setShowClientSelector(false)}
        onSelectClient={handleClientSelection}
        clients={clients}
        clientAppointments={clientAppointments}
        loadClientAppointments={loadClientAppointments}
        addRdvToPanier={addRdvToPanier}
        settings={settings}
      />

      {/* Sélecteur de produits */}
      <ProductSelector
        isOpen={showProductSelector}
        onClose={() => setShowProductSelector(false)}
        onSelectProduct={addProductToPanier}
        stockItems={stockItems.filter((item) => item.quantite > 0)}
      />

      {/* Sélecteur de prestations */}
      <PrestationSelector
        isOpen={showPrestationSelector}
        onClose={() => setShowPrestationSelector(false)}
        onSelectPrestation={addPrestationToPanier}
        prestations={prestations}
      />

      {/* Sélecteur de cartes cadeaux */}
      {settings.allowGiftCardSale && (
        <GiftCardSelector
          isOpen={showGiftCardSelector}
          onClose={() => setShowGiftCardSelector(false)}
          onSelectGiftCard={addGiftCardToPanier}
          giftCards={giftCards}
          clients={clients}
          selectedClient={clientInfo}
          onCreateClient={(newClient) => {
            const updatedClients = [...clients, newClient];
            setClients(updatedClients);
            localStorage.setItem("clients", JSON.stringify(updatedClients));
            return newClient;
          }}
        />
      )}

      {/* Modal des paramètres de caisse */}
      <CaisseSettings
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
    </section>
  );
};
