import React, { useState, useRef, useEffect } from 'react';
import './BarcodeScanner.css';

// Importe la bibliothèque Quagga pour la lecture de codes-barres
import Quagga from 'quagga';

export const BarcodeScanner = ({ onDetected, onClose }) => {
  const [scanning, setScanning] = useState(false);
  const [error, setError] = useState(null);
  const [lastResult, setLastResult] = useState(null);
  const scannerRef = useRef(null);
  const lastDetectionTime = useRef(0);
  const detectionBufferRef = useRef({});
  
  // Configuration et initialisation du scanner
  useEffect(() => {
    // Si l'élément de référence n'existe pas encore, ne rien faire
    if (!scannerRef.current) return;

    // Configuration de Quagga avec des paramètres améliorés
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: scannerRef.current,
        constraints: {
          width: { min: 800 },
          height: { min: 600 },
          facingMode: "environment", // Utiliser la caméra arrière
          aspectRatio: { min: 1, max: 2 }
        },
      },
      locator: {
        patchSize: "medium",
        halfSample: true
      },
      numOfWorkers: navigator.hardwareConcurrency || 4,
      frequency: 10,
      decoder: {
        readers: [
          "ean_reader", // Lire les codes EAN (supermarchés)
          "ean_8_reader", // EAN-8
          "code_128_reader", // Code 128
          "code_39_reader", // Code 39
          "upc_reader", // UPC
          "upc_e_reader" // UPC-E
        ]
      },
      locate: true
    }, function(err) {
      if (err) {
        console.error("Erreur d'initialisation de la caméra:", err);
        setError("Erreur d'initialisation de la caméra: " + err.message);
        return;
      }
      // La caméra est initialisée, démarrer le scan
      console.log("Scanner initialisé avec succès");
      setScanning(true);
      Quagga.start();
    });

    // Gestionnaire d'événements pour la détection de code-barres avec anti-rebond
    Quagga.onDetected((result) => {
      // Vérifier que le résultat est valide
      if (result && result.codeResult && result.codeResult.code) {
        const code = result.codeResult.code;
        const confidence = result.codeResult.confidence;
        const now = Date.now();
        
        console.log("Code détecté:", code, "avec une confiance de:", confidence);
        
        // Ignorer les détections avec une confiance trop faible
        if (confidence < 0.7) {
          console.log("Confiance trop faible, code ignoré");
          return;
        }
        
        // Compter les occurrences de chaque code
        if (!detectionBufferRef.current[code]) {
          detectionBufferRef.current[code] = 0;
        }
        
        detectionBufferRef.current[code]++;
        
        // Vérifier si nous avons assez d'occurrences et si assez de temps s'est écoulé
        // depuis la dernière détection validée (3 secondes)
        if (detectionBufferRef.current[code] >= 2 && (now - lastDetectionTime.current) > 3000) {
          console.log("Code validé:", code);
          
          // Réinitialiser le tampon de détection
          detectionBufferRef.current = {};
          
          // Mettre à jour le temps de la dernière détection
          lastDetectionTime.current = now;
          
          // Afficher le code détecté
          setLastResult(code);
          
          // Jouer un son pour indiquer une lecture réussie
          playBeepSound();
          
          // Éviter de fermer immédiatement pour permettre à l'utilisateur de voir le code détecté
          setTimeout(() => {
            // Arrêter la caméra avant de fermer
            Quagga.stop();
            
            // Envoyer le code détecté via le callback
            onDetected(code);
          }, 500);
        }
      }
    });

    // Nettoyer lors du démontage du composant
    return () => {
      if (scanning) {
        console.log("Arrêt du scanner");
        Quagga.stop();
      }
    };
  }, [onDetected, scanning]);

  // Fonction pour jouer un son de confirmation
  const playBeepSound = () => {
    try {
      const audio = new Audio();
      audio.src = 'data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU2LjM2LjEwMAAAAAAAAAAAAAAA//OEAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAAEAAABIADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV6urq6urq6urq6urq6urq6urq6urq6urq6v////////////////////////////////8AAAAATGF2YzU2LjQxAAAAAAAAAAAAAAAAJAAAAAAAAAAAASDs90hvAAAAAAAAAAAAAAAAAAAA//MUZAAAAAGkAAAAAAAAA0gAAAAATEFN//MUZAMAAAGkAAAAAAAAA0gAAAAARTMu//MUZAYAAAGkAAAAAAAAA0gAAAAAOTku//MUZAkAAAGkAAAAAAAAA0gAAAAANVVV';
      audio.play();
    } catch (e) {
      console.error("Impossible de jouer le son:", e);
    }
  };

  const handleManualClose = () => {
    console.log("Fermeture manuelle du scanner");
    Quagga.stop();
    onClose();
  };

  return (
    <div className="barcode-scanner-container">
      <div className="barcode-scanner-header">
        <h2>Scanner un code-barres</h2>
        <button className="close-scanner" onClick={handleManualClose}>×</button>
      </div>
      
      <div className="scanner-content">
        {error ? (
          <div className="scanner-error">
            <p>{error}</p>
            <p>Veuillez vérifier que vous avez autorisé l'accès à la caméra.</p>
          </div>
        ) : (
          <>
            <div className="scanner-viewfinder" ref={scannerRef}></div>
            <div className="scanner-instructions">
              <p>Placez le code-barres à l'intérieur du cadre</p>
              {lastResult && <p>Code détecté: {lastResult}</p>}
            </div>
          </>
        )}
      </div>
      
      <div className="scanner-footer">
        <button className="cancel-scan" onClick={handleManualClose}>Annuler</button>
      </div>
    </div>
  );
};