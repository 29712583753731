import React, { useState, useEffect } from 'react';
import { Register } from './Register';
import './UserManagement.css';
import { useAuth } from './AuthContext';

export const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false); // État pour afficher le formulaire Register.js
  const [currentUser, setCurrentUser] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    companyName: '', // Ajout du champ pour le nom de l'entreprise
    email: '',
    password: '',
    confirmPassword: '',
    role: 'personnel'
  });
  const [editMode, setEditMode] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser: authUser } = useAuth(); // Récupérer l'utilisateur courant du contexte

  useEffect(() => {
    // Charger les utilisateurs
    const loadedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    setUsers(loadedUsers);
    
    // Charger l'utilisateur actuellement connecté
    setCurrentUser(authUser);
  }, [authUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleAddUser = () => {
    // Utiliser le composant Register pour créer un nouvel utilisateur
    setShowRegisterForm(true);
    setShowAddUserForm(false);
    setEditMode(false);
    setError('');
  };

  const handleEditUser = (user) => {
    setEditMode(true);
    setEditUserId(user.id);
    setFormData({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      birthDate: user.birthDate || '',
      companyName: user.companyName || '', // Récupération du nom de l'entreprise
      email: user.email,
      password: '',
      confirmPassword: '',
      role: user.role
    });
    setShowAddUserForm(true);
    setShowRegisterForm(false);
    setError('');
  };

  const handleDeleteUser = (userId) => {
    // Empêcher la suppression de son propre compte
    if (userId === currentUser?.id) {
      alert('Vous ne pouvez pas supprimer votre propre compte.');
      return;
    }

    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      const updatedUsers = users.filter(user => user.id !== userId);
      localStorage.setItem('users', JSON.stringify(updatedUsers));
      setUsers(updatedUsers);
    }
  };

  const validateForm = () => {
    if (!formData.firstName || !formData.lastName || !formData.birthDate || !formData.email || !formData.companyName) {
      setError('Tous les champs sont obligatoires');
      return false;
    }
    
    // Validation du format de date DD/MM/YYYY
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!dateRegex.test(formData.birthDate)) {
      setError('La date de naissance doit être au format JJ/MM/AAAA');
      return false;
    }

    if (!editMode && (!formData.password || !formData.confirmPassword)) {
      setError('Les mots de passe sont obligatoires');
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return false;
    }

    if (formData.password && formData.password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères');
      return false;
    }

    // Vérifier si l'email est déjà utilisé (sauf pour l'utilisateur en cours d'édition)
    const emailExists = users.some(user => 
      user.email.toLowerCase() === formData.email.toLowerCase() && 
      user.id !== editUserId
    );

    if (emailExists) {
      setError('Cet email est déjà utilisé');
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (editMode) {
      // Mettre à jour l'utilisateur existant
      const updatedUsers = users.map(user => {
        if (user.id === editUserId) {
          return {
            ...user,
            firstName: formData.firstName,
            lastName: formData.lastName,
            name: `${formData.firstName} ${formData.lastName}`,
            birthDate: formData.birthDate,
            companyName: formData.companyName, // Mise à jour du nom de l'entreprise
            email: formData.email,
            role: formData.role,
            ...(formData.password ? { password: formData.password } : {})
          };
        }
        return user;
      });

      localStorage.setItem('users', JSON.stringify(updatedUsers));
      setUsers(updatedUsers);
      
      // Si l'utilisateur édite son propre compte, mettre à jour currentUser dans localStorage
      if (editUserId === currentUser?.id) {
        const updatedCurrentUser = {
          ...currentUser,
          firstName: formData.firstName,
          lastName: formData.lastName,
          name: `${formData.firstName} ${formData.lastName}`,
          birthDate: formData.birthDate,
          companyName: formData.companyName, // Mise à jour du nom de l'entreprise
          email: formData.email,
          role: formData.role
        };
        
        localStorage.setItem('currentUser', JSON.stringify(updatedCurrentUser));
      }
      
      alert('Utilisateur mis à jour avec succès !');
    }

    setShowAddUserForm(false);
  };

  // Gérer le succès de l'enregistrement d'un nouvel utilisateur via Register.js
  const handleRegisterSuccess = (newUser) => {
    // Recharger la liste des utilisateurs
    const updatedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    setUsers(updatedUsers);
    
    setShowRegisterForm(false);
    alert('Utilisateur créé avec succès !');
  };

  // Filtrer les utilisateurs en fonction du terme de recherche
  const filteredUsers = users.filter(user => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      user.name.toLowerCase().includes(searchTermLower) ||
      user.email.toLowerCase().includes(searchTermLower) ||
      user.role.toLowerCase().includes(searchTermLower) ||
      (user.companyName && user.companyName.toLowerCase().includes(searchTermLower)) // Inclure le nom de l'entreprise dans la recherche
    );
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="user-management-container">
      <div className="user-management-header">
        <h2>Gestion des Utilisateurs</h2>
        <div className="user-management-actions">
          <div className="search-container">
            <input
              type="text"
              placeholder="Rechercher un utilisateur..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="add-user-btn" onClick={handleAddUser}>
            Ajouter un utilisateur
          </button>
        </div>
      </div>

      {showRegisterForm && (
        <Register 
          onRegisterSuccess={handleRegisterSuccess}
          onCancel={() => setShowRegisterForm(false)}
          isFirstAdmin={false}
          createdByAdmin={true}
        />
      )}

      {showAddUserForm && !showRegisterForm && (
        <div className="user-form-container">
          <h3>{editMode ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">Prénom</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Prénom de l'utilisateur"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="lastName">Nom</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Nom de l'utilisateur"
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="birthDate">Date de naissance</label>
              <input
                type="text"
                id="birthDate"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleInputChange}
                placeholder="JJ/MM/AAAA"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="companyName">Nom de l'entreprise</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Nom de l'entreprise"
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email de l'utilisateur"
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="password">{editMode ? 'Nouveau mot de passe' : 'Mot de passe'}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder={editMode ? "Laisser vide pour ne pas changer" : "Mot de passe"}
                  required={!editMode}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirmer le mot de passe"
                  required={!editMode || formData.password !== ''}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="role">Rôle</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="personnel">Personnel</option>
                <option value="admin">Administrateur</option>
              </select>
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <div className="form-actions">
              <button 
                type="button" 
                className="cancel-form-btn"
                onClick={() => setShowAddUserForm(false)}
              >
                Annuler
              </button>
              <button type="submit" className="submit-form-btn">
                {editMode ? 'Mettre à jour' : 'Ajouter'}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="users-table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Entreprise</th> {/* Nouvelle colonne pour l'entreprise */}
              <th>Rôle</th>
              <th>Date de création</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length === 0 ? (
              <tr>
                <td colSpan="6" className="no-users-message">
                  Aucun utilisateur trouvé.
                </td>
              </tr>
            ) : (
              filteredUsers.map(user => (
                <tr key={user.id} className={user.id === currentUser?.id ? 'current-user-row' : ''}>
                  <td>
                    {user.name}
                    {user.id === currentUser?.id && <span className="current-user-badge">Vous</span>}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.companyName || "Non spécifiée"}</td> {/* Affichage du nom de l'entreprise */}
                  <td>
                    <span className={`role-badge ${user.role}`}>
                    {user.role === 'admin' ? 'Administrateur' : 'Personnel'}
                    </span>
                  </td>
                  <td>{user.dateCreated ? formatDate(user.dateCreated) : 'Non disponible'}</td>
                  <td>
                    <div className="user-actions">
                      <button
                        className="edit-user-btn"
                        onClick={() => handleEditUser(user)}
                      >
                        Modifier
                      </button>
                      {user.id !== currentUser?.id && (
                        <button
                          className="delete-user-btn"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          Supprimer
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};