import React, { useState, useEffect } from 'react';
import { PrestationForm } from './PrestationForm';
import { PrestationsList } from './PrestationsList';
import './Prestations.css';

// Service de gestion des prestations optimisé pour la production
const PrestationService = {
  // Fonction utilitaire pour gérer les erreurs réseau
  handleNetworkError: (error, message) => {
    console.error(message, error);
    return { error: true, message: error.message || "Erreur de connexion au serveur" };
  },
  
  // Fonction utilitaire pour obtenir les en-têtes d'authentification
  getAuthHeaders: () => {
    const accessToken = localStorage.getItem('accessToken');
    return {
      'Content-Type': 'application/json',
      'Authorization': accessToken ? `Bearer ${accessToken}` : ''
    };
  },
  
  // Récupérer toutes les prestations
  getPrestations: async () => {
    try {
      const response = await fetch('/api/services/', {
        headers: PrestationService.getAuthHeaders(),
        credentials: 'include'
      });
      
      if (response.status === 401) {
        // Session expirée ou non authentifiée
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok) {
        return { error: true, status: response.status };
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur API (getPrestations):");
    }
  },
  
  // Récupérer les catégories
  getCategories: async () => {
    try {
      const response = await fetch('/api/services/categories/', {
        headers: PrestationService.getAuthHeaders()
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok) {
        return { error: true, status: response.status };
      }
      
      return await response.json();
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la récupération des catégories:");
    }
  },
  
  // Créer une nouvelle prestation
  createPrestation: async (prestationData) => {
    try {
      // Préparer les données pour l'API
      const apiData = {
        name: prestationData.nom,
        category: prestationData.categorieId,
        duration: parseInt(prestationData.duree),
        price: parseFloat(prestationData.prixHT),
        price_with_tax: parseFloat(prestationData.prixTTC),
        color: prestationData.couleur,
        description: prestationData.description || '',
        is_active: true
      };
      
      const response = await fetch('/api/services/', {
        method: 'POST',
        headers: PrestationService.getAuthHeaders(),
        body: JSON.stringify(apiData)
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok) {
        let errorData = {};
        try {
          errorData = await response.json();
        } catch (e) {}
        
        return { 
          error: true, 
          status: response.status, 
          message: errorData.detail || `Erreur ${response.status}: Impossible de créer la prestation` 
        };
      }
      
      return await response.json();
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la création de la prestation:");
    }
  },
  
  // Mettre à jour une prestation
  updatePrestation: async (prestationData) => {
    try {
      const apiData = {
        name: prestationData.nom,
        category: prestationData.categorieId,
        duration: parseInt(prestationData.duree),
        price: parseFloat(prestationData.prixHT),
        price_with_tax: parseFloat(prestationData.prixTTC),
        color: prestationData.couleur,
        description: prestationData.description || '',
        is_active: true
      };
      
      const response = await fetch(`/api/services/${prestationData.id}/`, {
        method: 'PUT',
        headers: PrestationService.getAuthHeaders(),
        body: JSON.stringify(apiData)
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok) {
        return { error: true, status: response.status };
      }
      
      return await response.json();
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la mise à jour de la prestation:");
    }
  },
  
  // Supprimer une prestation
  deletePrestation: async (id) => {
    try {
      const response = await fetch(`/api/services/${id}/`, {
        method: 'DELETE',
        headers: PrestationService.getAuthHeaders()
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok && response.status !== 404) {
        return { error: true, status: response.status };
      }
      
      return { success: true };
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la suppression de la prestation:");
    }
  },
  
  // Créer une catégorie
  createCategory: async (categoryName, categoryDescription = '') => {
    try {
      const response = await fetch('/api/services/categories/', {
        method: 'POST',
        headers: PrestationService.getAuthHeaders(),
        body: JSON.stringify({
          name: categoryName,
          description: categoryDescription
        })
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok) {
        return { error: true, status: response.status };
      }
      
      return await response.json();
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la création de la catégorie:");
    }
  },
  
  // Supprimer une catégorie
  deleteCategory: async (id) => {
    try {
      const response = await fetch(`/api/services/categories/${id}/`, {
        method: 'DELETE',
        headers: PrestationService.getAuthHeaders()
      });
      
      if (response.status === 401) {
        return { error: true, status: 401, local: true };
      }
      
      if (!response.ok && response.status !== 404) {
        return { error: true, status: response.status };
      }
      
      return { success: true };
    } catch (error) {
      return PrestationService.handleNetworkError(error, "Erreur lors de la suppression de la catégorie:");
    }
  }
};

export const Prestations = () => {
  const [activeTab, setActiveTab] = useState('liste-prestations');
  const [prestations, setPrestations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]); // Stockage des données complètes de catégories
  const [editPrestation, setEditPrestation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLocalMode, setIsLocalMode] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  // Charger les données au chargement du composant ou après reconnexion
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      
      try {
        // 1. Récupérer les catégories
        const categoriesResponse = await PrestationService.getCategories();
        
        if (categoriesResponse.error) {
          // En cas d'erreur avec les catégories, passer en mode local
          setIsLocalMode(true);
          
          // Charger les catégories du stockage local
          const storedCategories = JSON.parse(localStorage.getItem('categories') || '[]');
          setCategories(storedCategories);
          
          // Créer des données de catégories fictives pour le mode local
          const fakeCategoriesData = storedCategories.map(categoryName => ({
            id: `local_${categoryName.replace(/\s+/g, '_')}`,
            name: categoryName,
            description: ''
          }));
          setCategoriesData(fakeCategoriesData);
        } else {
          // Si les catégories sont récupérées avec succès
          const categoryNames = categoriesResponse.results.map(cat => cat.name.toLowerCase());
          setCategories(categoryNames);
          setCategoriesData(categoriesResponse.results || []);
          localStorage.setItem('categories', JSON.stringify(categoryNames));
        }
        
        // 2. Récupérer les prestations
        const prestationsResponse = await PrestationService.getPrestations();
        
        if (prestationsResponse.error) {
          // En cas d'erreur avec les prestations, passer en mode local
          setIsLocalMode(true);
          
          // Charger les prestations du stockage local
          const storedPrestations = JSON.parse(localStorage.getItem('prestations') || '[]');
          setPrestations(storedPrestations);
        } else {
          // Si les prestations sont récupérées avec succès
          const formattedPrestations = prestationsResponse.results.map(service => ({
            id: service.id,
            nom: service.name,
            categorie: service.category_name || 'Sans catégorie',
            categorieId: service.category,
            duree: service.duration,
            prixHT: service.price,
            prixTTC: service.price_with_tax,
            couleur: service.color || '#c6b291',
            description: service.description || '',
            isActive: service.is_active
          }));
          
          setPrestations(formattedPrestations);
          localStorage.setItem('prestations', JSON.stringify(formattedPrestations));
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setIsLocalMode(true);
        
        // Charger les données du stockage local
        const storedPrestations = JSON.parse(localStorage.getItem('prestations') || '[]');
        const storedCategories = JSON.parse(localStorage.getItem('categories') || '[]');
        
        setPrestations(storedPrestations);
        setCategories(storedCategories);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [refreshKey]);

  // Fonction pour trouver l'ID d'une catégorie par son nom
  const getCategoryIdByName = (categoryName) => {
    const category = categoriesData.find(cat => 
      cat.name.toLowerCase() === categoryName.toLowerCase()
    );
    return category ? category.id : null;
  };

  // Fonction pour ajouter une nouvelle prestation
  const addPrestation = async (prestationData) => {
    try {
      setIsLoading(true);
      
      // Trouver ou créer la catégorie si nécessaire
      if (prestationData.categorie && !isLocalMode) {
        const categoryId = getCategoryIdByName(prestationData.categorie);
        
        if (categoryId) {
          // Catégorie existante
          prestationData.categorieId = categoryId;
        } else {
          // Nouvelle catégorie à créer
          const newCategoryResult = await PrestationService.createCategory(prestationData.categorie);
          
          if (!newCategoryResult.error) {
            prestationData.categorieId = newCategoryResult.id;
            
            // Mettre à jour les listes de catégories
            const updatedCategoriesData = [...categoriesData, newCategoryResult];
            setCategoriesData(updatedCategoriesData);
            
            const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
            setCategories(updatedCategories);
            localStorage.setItem('categories', JSON.stringify(updatedCategories));
          } else if (newCategoryResult.local) {
            setIsLocalMode(true);
          }
        }
      }
      
      // Créer la prestation
      let result;
      
      if (!isLocalMode) {
        result = await PrestationService.createPrestation(prestationData);
        
        if (result.error) {
          if (result.local) {
            setIsLocalMode(true);
          } else {
            throw new Error(result.message || "Erreur lors de la création de la prestation");
          }
        }
      }
      
      // En mode local ou si l'API a échoué
      if (isLocalMode || (result && result.error)) {
        // Créer un ID local
        const localId = `local_${Date.now()}`;
        
        // Créer la prestation localement
        const newPrestation = {
          id: localId,
          nom: prestationData.nom,
          categorie: prestationData.categorie || 'Sans catégorie',
          categorieId: prestationData.categorieId || null,
          duree: prestationData.duree,
          prixHT: prestationData.prixHT,
          prixTTC: prestationData.prixTTC,
          couleur: prestationData.couleur || '#c6b291',
          description: prestationData.description || '',
          isActive: true
        };
        
        // Mettre à jour l'état et le stockage local
        const updatedPrestations = [...prestations, newPrestation];
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        // Ajouter la catégorie si elle est nouvelle
        if (prestationData.categorie && !categories.includes(prestationData.categorie.toLowerCase())) {
          const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
          setCategories(updatedCategories);
          localStorage.setItem('categories', JSON.stringify(updatedCategories));
        }
        
        alert('Prestation enregistrée localement.');
      } else {
        // Si l'API a réussi
        const newPrestation = {
          id: result.id,
          nom: result.name,
          categorie: result.category_name || prestationData.categorie || 'Sans catégorie',
          categorieId: result.category,
          duree: result.duration,
          prixHT: result.price,
          prixTTC: result.price_with_tax,
          couleur: result.color || '#c6b291',
          description: result.description || '',
          isActive: result.is_active
        };
        
        const updatedPrestations = [...prestations, newPrestation];
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        alert('Prestation enregistrée avec succès !');
      }
      
      // Réinitialiser l'état d'édition et revenir à la liste
      setEditPrestation(null);
      setActiveTab('liste-prestations');
      
    } catch (error) {
      console.error("Erreur lors de l'ajout de la prestation:", error);
      alert(`Erreur: ${error.message}`);
      
      // Mode dégradé - enregistrement local uniquement
      const localId = `local_${Date.now()}`;
      const newPrestation = {
        id: localId,
        nom: prestationData.nom,
        categorie: prestationData.categorie || 'Sans catégorie',
        categorieId: null,
        duree: prestationData.duree,
        prixHT: prestationData.prixHT,
        prixTTC: prestationData.prixTTC,
        couleur: prestationData.couleur || '#c6b291',
        description: prestationData.description || '',
        isActive: true
      };
      
      const updatedPrestations = [...prestations, newPrestation];
      setPrestations(updatedPrestations);
      localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
      
      if (prestationData.categorie && !categories.includes(prestationData.categorie.toLowerCase())) {
        const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
      }
      
      alert('Prestation enregistrée localement uniquement.');
      setEditPrestation(null);
      setActiveTab('liste-prestations');
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour modifier une prestation existante
  const updatePrestation = async (prestationData) => {
    try {
      setIsLoading(true);
      
      // Même logique que pour l'ajout concernant la gestion des catégories...
      if (prestationData.categorie && !isLocalMode) {
        const categoryId = getCategoryIdByName(prestationData.categorie);
        
        if (categoryId) {
          prestationData.categorieId = categoryId;
        } else {
          const newCategoryResult = await PrestationService.createCategory(prestationData.categorie);
          
          if (!newCategoryResult.error) {
            prestationData.categorieId = newCategoryResult.id;
            
            const updatedCategoriesData = [...categoriesData, newCategoryResult];
            setCategoriesData(updatedCategoriesData);
            
            const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
            setCategories(updatedCategories);
            localStorage.setItem('categories', JSON.stringify(updatedCategories));
          } else if (newCategoryResult.local) {
            setIsLocalMode(true);
          }
        }
      }
      
      let result;
      
      if (!isLocalMode) {
        result = await PrestationService.updatePrestation(prestationData);
        
        if (result.error) {
          if (result.local) {
            setIsLocalMode(true);
          } else {
            throw new Error(result.message || "Erreur lors de la modification de la prestation");
          }
        }
      }
      
      // En mode local ou si l'API a échoué
      if (isLocalMode || (result && result.error)) {
        // Mise à jour locale
        const updatedPrestation = {
          ...prestationData,
          categorie: prestationData.categorie || 'Sans catégorie',
        };
        
        const updatedPrestations = prestations.map(p => 
          p.id === prestationData.id ? updatedPrestation : p
        );
        
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        if (prestationData.categorie && !categories.includes(prestationData.categorie.toLowerCase())) {
          const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
          setCategories(updatedCategories);
          localStorage.setItem('categories', JSON.stringify(updatedCategories));
        }
        
        alert('Prestation modifiée localement.');
      } else {
        // Si l'API a réussi
        const updatedPrestation = {
          id: result.id,
          nom: result.name,
          categorie: result.category_name || prestationData.categorie || 'Sans catégorie',
          categorieId: result.category,
          duree: result.duration,
          prixHT: result.price,
          prixTTC: result.price_with_tax,
          couleur: result.color || '#c6b291',
          description: result.description || '',
          isActive: result.is_active
        };
        
        const updatedPrestations = prestations.map(p => 
          p.id === prestationData.id ? updatedPrestation : p
        );
        
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        alert('Prestation modifiée avec succès !');
      }
      
      setEditPrestation(null);
      setActiveTab('liste-prestations');
      
    } catch (error) {
      console.error("Erreur lors de la modification de la prestation:", error);
      alert(`Erreur: ${error.message}`);
      
      // Mode dégradé
      const updatedPrestation = {
        ...prestationData,
        categorie: prestationData.categorie || 'Sans catégorie',
      };
      
      const updatedPrestations = prestations.map(p => 
        p.id === prestationData.id ? updatedPrestation : p
      );
      
      setPrestations(updatedPrestations);
      localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
      
      if (prestationData.categorie && !categories.includes(prestationData.categorie.toLowerCase())) {
        const updatedCategories = [...categories, prestationData.categorie.toLowerCase()];
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
      }
      
      alert('Prestation modifiée localement uniquement.');
      setEditPrestation(null);
      setActiveTab('liste-prestations');
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer une prestation
  const deletePrestation = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette prestation ?')) {
      try {
        setIsLoading(true);
        
        let result;
        
        if (!isLocalMode) {
          result = await PrestationService.deletePrestation(id);
          
          if (result.error) {
            if (result.local) {
              setIsLocalMode(true);
            } else {
              throw new Error(result.message || "Erreur lors de la suppression de la prestation");
            }
          }
        }
        
        // Suppression locale dans tous les cas
        const updatedPrestations = prestations.filter(p => p.id !== id);
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        if (isLocalMode || (result && result.error)) {
          alert('Prestation supprimée localement.');
        } else {
          alert('Prestation supprimée avec succès !');
        }
        
      } catch (error) {
        console.error("Erreur lors de la suppression de la prestation:", error);
        
        // Mode dégradé
        const updatedPrestations = prestations.filter(p => p.id !== id);
        setPrestations(updatedPrestations);
        localStorage.setItem('prestations', JSON.stringify(updatedPrestations));
        
        alert('Prestation supprimée localement uniquement.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fonction pour éditer une prestation
  const editPrestationHandler = (id) => {
    const prestationToEdit = prestations.find(prestation => prestation.id === id);
    if (prestationToEdit) {
      setEditPrestation(prestationToEdit);
      setActiveTab('fiche-prestation');
    }
  };

  // Fonction pour ajouter une nouvelle catégorie
  const addCategory = async () => {
    if (!newCategory.trim()) {
      alert('Veuillez entrer un nom de catégorie');
      return;
    }
    
    const categoryLower = newCategory.trim().toLowerCase();
    
    // Vérifier si la catégorie existe déjà
    if (categories.includes(categoryLower)) {
      alert('Cette catégorie existe déjà');
      return;
    }
    
    try {
      setIsLoading(true);
      
      let result;
      
      if (!isLocalMode) {
        result = await PrestationService.createCategory(categoryLower);
        
        if (result.error) {
          if (result.local) {
            setIsLocalMode(true);
          } else {
            throw new Error(result.message || "Erreur lors de la création de la catégorie");
          }
        }
      }
      
      // En mode local ou si l'API a échoué
      if (isLocalMode || (result && result.error)) {
        // Mise à jour locale
        const updatedCategories = [...categories, categoryLower];
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
        
        // Ajouter aux données complètes
        const newCategoryData = { 
          id: `local_${categoryLower.replace(/\s+/g, '_')}`,
          name: categoryLower,
          description: ''
        };
        setCategoriesData([...categoriesData, newCategoryData]);
        
        alert('Catégorie ajoutée localement.');
      } else {
        // Si l'API a réussi
        const updatedCategories = [...categories, categoryLower];
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
        
        setCategoriesData([...categoriesData, result]);
        
        alert('Catégorie ajoutée avec succès !');
      }
      
      setNewCategory('');
      setIsModalOpen(false);
      
    } catch (error) {
      console.error("Erreur lors de l'ajout de la catégorie:", error);
      
      // Mode dégradé
      const updatedCategories = [...categories, categoryLower];
      setCategories(updatedCategories);
      localStorage.setItem('categories', JSON.stringify(updatedCategories));
      
      const newCategoryData = { 
        id: `local_${categoryLower.replace(/\s+/g, '_')}`,
        name: categoryLower,
        description: ''
      };
      setCategoriesData([...categoriesData, newCategoryData]);
      
      alert('Catégorie ajoutée localement uniquement.');
      setNewCategory('');
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer une catégorie
  const deleteCategory = async (category) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer la catégorie "${category}" ?`)) {
      // Vérifier si des prestations utilisent cette catégorie
      const prestationsWithCategory = prestations.filter(p => 
        p.categorie.toLowerCase() === category.toLowerCase()
      );
      
      if (prestationsWithCategory.length > 0) {
        alert(`Impossible de supprimer cette catégorie car elle est utilisée par ${prestationsWithCategory.length} prestation(s).`);
        return;
      }
      
      try {
        setIsLoading(true);
        
        const categoryData = categoriesData.find(cat => 
          cat.name.toLowerCase() === category.toLowerCase()
        );
        
        let result;
        
        if (!isLocalMode && categoryData && !categoryData.id.startsWith('local_')) {
          result = await PrestationService.deleteCategory(categoryData.id);
          
          if (result.error) {
            if (result.local) {
              setIsLocalMode(true);
            } else {
              throw new Error(result.message || "Erreur lors de la suppression de la catégorie");
            }
          }
        }
        
        // Suppression locale dans tous les cas
        const updatedCategories = categories.filter(c => c !== category);
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
        
        const updatedCategoriesData = categoriesData.filter(cat => 
          cat.name.toLowerCase() !== category.toLowerCase()
        );
        setCategoriesData(updatedCategoriesData);
        
        if (isLocalMode || (result && result.error)) {
          alert('Catégorie supprimée localement.');
        } else {
          alert('Catégorie supprimée avec succès !');
        }
        
      } catch (error) {
        console.error("Erreur lors de la suppression de la catégorie:", error);
        
        // Mode dégradé
        const updatedCategories = categories.filter(c => c !== category);
        setCategories(updatedCategories);
        localStorage.setItem('categories', JSON.stringify(updatedCategories));
        
        const updatedCategoriesData = categoriesData.filter(cat => 
          cat.name.toLowerCase() !== category.toLowerCase()
        );
        setCategoriesData(updatedCategoriesData);
        
        alert('Catégorie supprimée localement uniquement.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Filtrer les prestations selon le terme de recherche et la catégorie sélectionnée
  const filteredPrestations = prestations.filter(prestation => {
    // Filtrer par terme de recherche
    const searchMatch = searchTerm === '' || 
      (prestation.nom && prestation.nom.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (prestation.categorie && prestation.categorie.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (prestation.description && prestation.description.toLowerCase().includes(searchTerm.toLowerCase()));
    
    // Filtrer par catégorie
    const categoryMatch = filterCategory === '' || prestation.categorie.toLowerCase() === filterCategory.toLowerCase();
    
    return searchMatch && categoryMatch;
  });

  // Trier les prestations par catégorie puis par nom
  const sortedPrestations = [...filteredPrestations].sort((a, b) => {
    if (a.categorie === b.categorie) {
      return a.nom.localeCompare(b.nom);
    }
    return a.categorie.localeCompare(b.categorie);
  });

  // Regrouper les prestations par catégorie
  const prestationsByCategory = sortedPrestations.reduce((acc, prestation) => {
    const category = prestation.categorie || 'Sans catégorie';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(prestation);
    return acc;
  }, {});

  // Gérer le filtre par catégorie
  const handleCategoryFilter = (category) => {
    if (filterCategory === category) {
      // Si on clique sur la catégorie déjà sélectionnée, on désactive le filtre
      setFilterCategory('');
    } else {
      setFilterCategory(category);
    }
  };

  // Fonction pour essayer de reconnecter et rafraîchir les données
  const tryReconnect = () => {
    setIsLoading(true);
    setIsLocalMode(false);
    // Incrémenter la clé pour déclencher un rechargement complet des données
    setRefreshKey(prevKey => prevKey + 1);
  };

  return (
    <section className="content-section active">
      <h1>Prestations</h1>
      
      {/* Bannière mode local */}
      {isLocalMode && (
        <div className="offline-banner">
          <div className="offline-message">
            Mode local activé - Les modifications sont uniquement sauvegardées sur cet appareil
          </div>
          <button 
            className="reconnect-btn"
            onClick={tryReconnect}
            disabled={isLoading}
          >
            Essayer de reconnecter
          </button>
        </div>
      )}
      
      {/* Indicateur de chargement */}
      {isLoading && <div className="loading-indicator">Chargement en cours...</div>}
      
      {/* Onglets de navigation secondaire */}
      <div className="tabs-container">
        <button 
          className={`tab-btn ${activeTab === 'liste-prestations' ? 'active' : ''}`}
          onClick={() => setActiveTab('liste-prestations')}
          disabled={isLoading}
        >
          Liste des Prestations
        </button>
        <button 
          className={`tab-btn ${activeTab === 'fiche-prestation' ? 'active' : ''}`}
          onClick={() => {
            setEditPrestation(null); // Réinitialiser pour une nouvelle prestation
            setActiveTab('fiche-prestation');
          }}
          disabled={isLoading}
        >
          Nouvelle Prestation
        </button>
        <button 
          className="tab-btn tab-btn-category"
          onClick={() => setIsModalOpen(true)}
          disabled={isLoading}
        >
          Gérer les Catégories
        </button>
      </div>
      
      {/* Contenu des onglets */}
      <div className="tab-content-container">
        {/* Liste des prestations */}
        {activeTab === 'liste-prestations' && (
          <div id="liste-prestations" className="tab-content active">
            <div className="prestations-header">
              <div className="search-container">
                <input 
                  type="text" 
                  id="search-prestation" 
                  placeholder="Rechercher une prestation..." 
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className="prestations-actions">
                <button 
                  className="add-category-btn"
                  onClick={() => setIsModalOpen(true)}
                  disabled={isLoading}
                >
                  Gérer les catégories
                </button>
              </div>
            </div>
            
            {/* Filtres par catégorie */}
            {categories.length > 0 && (
              <div className="categories-container">
                <h3>Filtrer par catégorie</h3>
                <div className="category-buttons">
                  <button
                    className={`category-btn ${filterCategory === '' ? 'active' : ''}`}
                    onClick={() => setFilterCategory('')}
                    disabled={isLoading}
                  >
                    Tout
                  </button>
                  {categories.map((category, index) => (
                    <button
                      key={index}
                      className={`category-btn ${filterCategory === category ? 'active' : ''}`}
                      onClick={() => handleCategoryFilter(category)}
                      disabled={isLoading}
                    >
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
            )}
            
            <PrestationsList 
              prestationsByCategory={prestationsByCategory}
              onEdit={editPrestationHandler}
              onDelete={deletePrestation}
              isLoading={isLoading}
            />
          </div>
        )}
        
        {/* Fiche prestation */}
        {activeTab === 'fiche-prestation' && (
          <div id="fiche-prestation" className="tab-content active">
            <h2>{editPrestation ? 'Modifier une Prestation' : 'Nouvelle Prestation'}</h2>
            <PrestationForm 
              initialData={editPrestation}
              existingCategories={categories}
              onSave={editPrestation ? updatePrestation : addPrestation}
              onCancel={() => {
                setEditPrestation(null);
                setActiveTab('liste-prestations');
              }}
              isLoading={isLoading}
              isLocalMode={isLocalMode}
            />
          </div>
        )}
      </div>
      
      {/* Modal de gestion des catégories */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content category-modal">
            <span className="close-modal" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2>Gestion des Catégories</h2>
            
            <div className="category-form">
              <div className="form-group">
                <label htmlFor="new-category">Nouvelle catégorie:</label>
                <div className="category-input-group">
                  <input 
                    type="text" 
                    id="new-category" 
                    value={newCategory}
                    onChange={e => setNewCategory(e.target.value)}
                    disabled={isLoading}
                  />
                  <button 
                    className="add-btn"
                    onClick={addCategory}
                    disabled={isLoading}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
            
            <div className="categories-list">
              <h3>Catégories existantes</h3>
              {categories.length === 0 ? (
                <p className="no-categories">Aucune catégorie n'a été créée.</p>
              ) : (
                <ul>
                  {categories.map((category, index) => (
                    <li key={index} className="category-item">
                      <span className="category-name">{category.charAt(0).toUpperCase() + category.slice(1)}</span>
                      <button 
                        className="delete-category-btn"
                        onClick={() => deleteCategory(category)}
                        disabled={isLoading}
                      >
                        Supprimer
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};