// SyncService.js

import { useEffect } from 'react';
import { AgendaService } from './AgendaService';

/**
 * Hook de synchronisation des rendez-vous avec le serveur
 * Permet de maintenir à jour l'interface utilisateur après les modifications
 * 
 * @param {Function} setAppointments - Fonction setState pour mettre à jour les rendez-vous dans React
 * @returns {Object} - Fonctions de synchronisation
 */
export const useSyncRendezVous = (setAppointments) => {
  /**
   * Synchronise les rendez-vous depuis le serveur et met à jour l'état React
   */
  const syncAppointments = async () => {
    try {
      console.log("SyncService: Synchronisation des rendez-vous depuis le serveur...");
      
      // Récupérer les rendez-vous depuis l'API
      const data = await AgendaService.getAppointments();
      
      // Normaliser les données pour s'assurer d'avoir un tableau
      const appointmentsArray = Array.isArray(data) 
        ? data 
        : (data && typeof data === 'object' ? data.appointments || data.results || [] : []);
      
      // Formater les rendez-vous pour l'interface
      const formattedAppointments = appointmentsArray.map(app => ({
        ...app,
        // S'assurer que les propriétés essentielles existent et sont correctement typées
        id: app.id,
        date: app.date,
        start: app.start,
        duration: parseInt(app.duration || '60'),
        client_name: app.client_name || 'Client sans nom',
        type: app.type || 'Rendez-vous',
        color: app.color || '#c6b291',
        notes: app.notes || '',
        // Normaliser les noms de propriétés pour les fonctionnalités avancées
        isRecurring: app.is_recurring || app.isRecurring || false,
        recurrenceType: app.recurrence_type || app.recurrenceType,
        recurrenceInterval: app.recurrence_interval || app.recurrenceInterval,
        recurrenceEndType: app.recurrence_end_type || app.recurrenceEndType,
        recurrenceEndDate: app.recurrence_end_date || app.recurrenceEndDate,
        recurrenceEndAfter: app.recurrence_end_after || app.recurrenceEndAfter,
        reminder: app.reminder || app.has_reminder || false,
        reminderTime: app.reminder_time || app.reminderTime
      }));
      
      console.log("SyncService: Rendez-vous récupérés et formatés:", formattedAppointments);
      
      // Mettre à jour l'état React avec les rendez-vous récupérés
      setAppointments(formattedAppointments);
      
      // Mettre à jour localStorage pour maintenir la cohérence
      localStorage.setItem('appointments', JSON.stringify(formattedAppointments));
      
      return formattedAppointments;
    } catch (error) {
      console.error('SyncService: Erreur lors de la synchronisation des rendez-vous:', error);
      
      // En cas d'erreur, essayer d'utiliser les données locales
      const localAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      setAppointments(localAppointments);
      
      throw error;
    }
  };

  /**
   * Enregistre un rendez-vous et synchronise l'interface
   * 
   * @param {Object} appointmentData - Données du rendez-vous à enregistrer
   * @param {number|null} appointmentId - ID du rendez-vous (null pour création)
   * @returns {Promise<Object>} - Le rendez-vous créé ou mis à jour
   */
  const saveAndSyncAppointment = async (appointmentData, appointmentId = null) => {
    try {
      console.log(`SyncService: ${appointmentId ? 'Mise à jour' : 'Création'} d'un rendez-vous...`, appointmentData);
      
      let savedAppointment;
      
      if (appointmentId) {
        // Mise à jour d'un rendez-vous existant
        savedAppointment = await AgendaService.updateAppointment(
          appointmentId,
          appointmentData
        );
      } else {
        // Ajout d'un nouveau rendez-vous
        savedAppointment = await AgendaService.createAppointment(
          appointmentData
        );
      }
      
      console.log("SyncService: Rendez-vous enregistré avec succès:", savedAppointment);
      
      // Synchroniser pour mettre à jour l'interface
      await syncAppointments();
      
      return savedAppointment;
    } catch (error) {
      console.error("SyncService: Erreur lors de l'enregistrement du rendez-vous:", error);
      
      // En cas d'erreur, mettre à jour l'interface avec la version locale
      const localAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      
      if (appointmentId) {
        // Mise à jour locale
        const updatedAppointments = localAppointments.map(app => 
          app.id === appointmentId ? { ...app, ...appointmentData } : app
        );
        
        localStorage.setItem('appointments', JSON.stringify(updatedAppointments));
        setAppointments(updatedAppointments);
      } else {
        // Création locale avec un ID temporaire
        const newAppointment = {
          ...appointmentData,
          id: `local_${Date.now()}`
        };
        
        const updatedAppointments = [...localAppointments, newAppointment];
        localStorage.setItem('appointments', JSON.stringify(updatedAppointments));
        setAppointments(updatedAppointments);
        
        return newAppointment;
      }
      
      throw error;
    }
  };

  /**
   * Supprime un rendez-vous et synchronise l'interface
   * 
   * @param {number|string} id - ID du rendez-vous à supprimer
   * @returns {Promise<boolean>} - Résultat de la suppression
   */
  const deleteAndSyncAppointment = async (id) => {
    try {
      console.log(`SyncService: Suppression du rendez-vous ${id}...`);
      
      // Supprimer sur le serveur
      await AgendaService.deleteAppointment(id);
      
      console.log("SyncService: Rendez-vous supprimé avec succès");
      
      // Synchroniser pour mettre à jour l'interface
      await syncAppointments();
      
      return true;
    } catch (error) {
      console.error("SyncService: Erreur lors de la suppression du rendez-vous:", error);
      
      // En cas d'erreur, mettre à jour l'interface avec la version locale
      const localAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      const filteredAppointments = localAppointments.filter(app => app.id !== id);
      
      localStorage.setItem('appointments', JSON.stringify(filteredAppointments));
      setAppointments(filteredAppointments);
      
      throw error;
    }
  };

  // Synchroniser automatiquement au chargement du composant
  useEffect(() => {
    console.log("SyncService: Synchronisation initiale des rendez-vous...");
    syncAppointments().catch(err => {
      console.error("SyncService: Erreur lors de la synchronisation initiale:", err);
    });
  }, []);

  return {
    syncAppointments,
    saveAndSyncAppointment,
    deleteAndSyncAppointment
  };
};

/**
 * Hook pour synchroniser les paramètres de l'agenda
 * 
 * @param {Function} setAgendaSettings - Fonction setState pour mettre à jour les paramètres
 * @returns {Object} - Fonctions de synchronisation des paramètres
 */
export const useSyncAgendaSettings = (setAgendaSettings) => {
  /**
   * Synchronise les paramètres depuis le serveur
   */
  const syncSettings = async () => {
    try {
      console.log("SyncService: Synchronisation des paramètres de l'agenda...");
      
      // Récupérer les paramètres depuis l'API
      const settings = await AgendaService.getSettings();
      
      if (settings) {
        // Convertir le format API au format de l'interface
        const formattedSettings = {
          startHour: settings.start_hour || 8,
          endHour: settings.end_hour || 18,
          timeStep: settings.time_step || 15,
          closedDays: settings.closed_days || [],
          closingHours: settings.closing_hours || {},
          enableRecurrence: settings.enable_recurrence || false,
          enableExport: settings.enable_export || false,
          enableClientIntegration: settings.enable_client_integration || false,
          enableReminders: settings.enable_reminders || false,
          enableYearView: settings.enable_year_view || false,
          defaultReminderTime: settings.default_reminder_time || 60
        };
        
        // Mettre à jour l'état React
        setAgendaSettings(formattedSettings);
        
        // Mettre à jour le stockage local
        localStorage.setItem('agenda_settings', JSON.stringify(formattedSettings));
        
        return formattedSettings;
      }
    } catch (error) {
      console.error("SyncService: Erreur lors de la synchronisation des paramètres:", error);
      
      // Utiliser les données locales en cas d'erreur
      const localSettings = JSON.parse(localStorage.getItem('agenda_settings') || '{}');
      setAgendaSettings(localSettings);
      
      throw error;
    }
  };

  /**
   * Enregistre et synchronise les paramètres
   * 
   * @param {Object} settingsData - Paramètres à enregistrer
   * @returns {Promise<Object>} - Les paramètres mis à jour
   */
  const saveAndSyncSettings = async (settingsData) => {
    try {
      console.log("SyncService: Enregistrement des paramètres de l'agenda...", settingsData);
      
      // Convertir au format API
      const apiSettings = {
        start_hour: settingsData.startHour,
        end_hour: settingsData.endHour,
        time_step: settingsData.timeStep,
        closed_days: settingsData.closedDays,
        closing_hours: settingsData.closingHours,
        enable_recurrence: settingsData.enableRecurrence,
        enable_export: settingsData.enableExport,
        enable_client_integration: settingsData.enableClientIntegration,
        enable_reminders: settingsData.enableReminders,
        enable_year_view: settingsData.enableYearView,
        default_reminder_time: settingsData.defaultReminderTime
      };
      
      // Envoyer au serveur
      await AgendaService.updateSettings(apiSettings);
      
      // Mettre à jour l'état React
      setAgendaSettings(settingsData);
      
      // Mettre à jour le stockage local
      localStorage.setItem('agenda_settings', JSON.stringify(settingsData));
      
      return settingsData;
    } catch (error) {
      console.error("SyncService: Erreur lors de l'enregistrement des paramètres:", error);
      
      // Mise à jour locale en cas d'erreur
      localStorage.setItem('agenda_settings', JSON.stringify(settingsData));
      setAgendaSettings(settingsData);
      
      throw error;
    }
  };

  // Synchroniser au chargement du composant
  useEffect(() => {
    syncSettings().catch(err => {
      console.error("SyncService: Erreur lors de la synchronisation initiale des paramètres:", err);
    });
  }, []);

  return {
    syncSettings,
    saveAndSyncSettings
  };
};

// Export pour faciliter l'import
export default {
  useSyncRendezVous,
  useSyncAgendaSettings
};