import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { AuthAdapter } from '../../services/AuthAdapter';

export const Register = ({ onRegisterSuccess, onCancel, isFirstAdmin = false, createdByAdmin = false }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState(createdByAdmin ? 'personnel' : 'admin');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Fonction pour formater automatiquement la date de naissance
  const handleBirthDateChange = (e) => {
    const input = e.target.value;
    
    // Supprimer tous les caractères non numériques sauf les barres obliques
    const cleaned = input.replace(/[^\d/]/g, '');
    
    // Ne pas traiter si la valeur est vide ou inchangée
    if (cleaned === '' || cleaned === birthDate) {
      setBirthDate(cleaned);
      return;
    }
    
    // Gérer la suppression de caractères
    if (cleaned.length < birthDate.length) {
      setBirthDate(cleaned);
      return;
    }
    
    // Limiter la longueur totale à 10 caractères (format JJ/MM/AAAA)
    if (cleaned.length > 10) {
      return;
    }
    
    // Ajouter automatiquement les barres obliques
    let formatted = cleaned;
    
    // Ajouter un / après les 2 premiers chiffres (JJ/)
    if (cleaned.length === 2 && !cleaned.includes('/')) {
      formatted = cleaned + '/';
    } 
    // Ajouter un / après les 5 premiers caractères (JJ/MM/)
    else if (cleaned.length === 5 && cleaned.charAt(2) === '/' && !cleaned.includes('/', 3)) {
      formatted = cleaned + '/';
    }
    // Si l'utilisateur tape un troisième chiffre sans barre oblique, insérer une barre
    else if (cleaned.length === 3 && !cleaned.includes('/')) {
      formatted = cleaned.substring(0, 2) + '/' + cleaned.substring(2);
    }
    // Si l'utilisateur tape un sixième caractère sans deuxième barre oblique, l'insérer
    else if (cleaned.length === 6 && cleaned.charAt(2) === '/' && !cleaned.includes('/', 3)) {
      formatted = cleaned.substring(0, 5) + '/' + cleaned.substring(5);
    }
    
    setBirthDate(formatted);
  };

  const validateForm = () => {
    // Validation
    if (!firstName || !lastName || !birthDate || !email || !password || !confirmPassword || !companyName) {
      setError('Veuillez remplir tous les champs');
      return false;
    }

    // Validation du format de date DD/MM/YYYY
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!dateRegex.test(birthDate)) {
      setError('La date de naissance doit être au format JJ/MM/AAAA');
      return false;
    }

    // Vérifier si la date est valide (par exemple, pas 31/02)
    const [day, month, year] = birthDate.split('/').map(Number);
    const dateObj = new Date(year, month - 1, day);
    if (
      dateObj.getDate() !== day || 
      dateObj.getMonth() !== month - 1 || 
      dateObj.getFullYear() !== year
    ) {
      setError('La date de naissance est invalide');
      return false;
    }

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return false;
    }

    if (password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères');
      return false;
    }

    // Vérifier si l'email existe déjà localement
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    const emailExists = users.some(user => user.email.toLowerCase() === email.toLowerCase());
    if (emailExists) {
      setError('Cet email est déjà utilisé');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    
    try {
      // Formatage de la date pour l'API (conversion de DD/MM/YYYY à YYYY-MM-DD)
      const [day, month, year] = birthDate.split('/');
      const formattedBirthDate = `${year}-${month}-${day}`;
      
      // Préparation des données pour l'API
      const userData = {
        first_name: firstName,
        last_name: lastName,
        birth_date: formattedBirthDate,
        company_name: companyName,
        email: email,
        password: password,
        role: role === 'personnel' ? 'employee' : role // Conversion du rôle pour le backend
      };

      console.log("Données à envoyer:", userData);

      // Utiliser l'adaptateur pour enregistrer l'utilisateur
      const result = await AuthAdapter.registerUser(userData);
      
      // Si c'est le premier administrateur ou non créé par un admin, connexion automatique
      if (isFirstAdmin || !createdByAdmin) {
        localStorage.setItem('currentUser', JSON.stringify(result.user));
        
        // Rediriger vers la page d'accueil après un court délai
        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      }
      
      // Notifier le composant parent du succès
      if (onRegisterSuccess) {
        onRegisterSuccess(result.user);
      }
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      setError(error.message || "Une erreur est survenue lors de l'inscription");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-form-container">
      <h2>{isFirstAdmin ? 'Créer un compte administrateur' : 'Créer un compte'}</h2>
      
      <form className="register-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">Prénom</label>
            <input
              type="text"
              id="firstName"
              placeholder="Votre prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={loading}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="lastName">Nom</label>
            <input
              type="text"
              id="lastName"
              placeholder="Votre nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={loading}
            />
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="birthDate">Date de naissance</label>
          <input
            type="text"
            id="birthDate"
            placeholder="JJ/MM/AAAA"
            value={birthDate}
            onChange={handleBirthDateChange}
            disabled={loading}
            maxLength={10}
          />
          <div className="date-format-hint">Format: JJ/MM/AAAA (exemple: 01/01/1990)</div>
        </div>
        
        <div className="form-group">
          <label htmlFor="companyName">Nom de l'entreprise</label>
          <input
            type="text"
            id="companyName"
            placeholder="Nom de votre entreprise"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            disabled={loading}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Votre email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            placeholder="Créez un mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="confirm-password">Confirmer le mot de passe</label>
          <input
            type="password"
            id="confirm-password"
            placeholder="Confirmez votre mot de passe"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading}
          />
        </div>
        
        {/* Afficher le sélecteur de rôle uniquement si créé par un admin déjà connecté */}
        {createdByAdmin && (
          <div className="form-group">
            <label htmlFor="role">Rôle</label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              disabled={loading}
            >
              <option value="personnel">Employé</option>
              <option value="admin">Administrateur</option>
            </select>
            <div className="role-info">
              Note: Un administrateur a accès à toutes les fonctionnalités et peut gérer les utilisateurs.
            </div>
          </div>
        )}
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="form-actions">
          <button 
            type="button" 
            className="cancel-btn" 
            onClick={onCancel}
            disabled={loading}
          >
            Annuler
          </button>
          <button 
            type="submit" 
            className="register-submit-btn"
            disabled={loading}
          >
            {loading ? 'Création en cours...' : (isFirstAdmin ? 'Créer un compte administrateur' : 'Créer un compte')}
          </button>
        </div>
      </form>
      
      {/* Afficher le lien de connexion uniquement pour le premier admin */}
      {isFirstAdmin && (
        <div className="login-link">
          <p>Vous avez déjà un compte ?</p>
          <button 
            className="login-link-btn" 
            onClick={onCancel}
            disabled={loading}
          >
            Se connecter
          </button>
        </div>
      )}
    </div>
  );
};