// AgendaService.js - Version complète et robuste
import { ClientService } from "../../services/ClientService";

export const AgendaService = {
  // Fonction pour vérifier si l'utilisateur est authentifié
  isAuthenticated: () => {
    try {
      // Vérifier plusieurs sources pour être certain
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const currentUser = JSON.parse(
        localStorage.getItem("currentUser") || "null"
      );

      // Autoriser l'authentification si un des éléments existe
      return !!accessToken || !!refreshToken || !!currentUser;
    } catch (error) {
      console.error(
        "Erreur lors de la vérification d'authentification:",
        error
      );
      return false;
    }
  },

  // Fonction pour obtenir un token CSRF
  getCsrfToken: () => {
    try {
      return (
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrftoken="))
          ?.split("=")[1] || ""
      );
    } catch (error) {
      console.error("Erreur lors de la récupération du token CSRF:", error);
      return "";
    }
  },

  // Fonction pour obtenir les headers HTTP avec authentification
  getHeaders: () => {
    try {
      // Récupérer le token depuis plusieurs sources possibles
      const accessToken = localStorage.getItem("accessToken");

      // Headers de base
      const headers = {
        "Content-Type": "application/json",
      };

      // Ajouter le token s'il existe
      if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
      }

      // Ajouter CSRF si disponible
      const csrfToken = AgendaService.getCsrfToken();
      if (csrfToken) {
        headers["X-CSRFToken"] = csrfToken;
      }

      return headers;
    } catch (error) {
      console.error("Erreur lors de la création des headers:", error);
      return { "Content-Type": "application/json" };
    }
  },

  // Fonction pour rediriger vers la page de connexion
  redirectToLogin: () => {
    // On ne redirige pas immédiatement pour éviter les boucles
    if (window.location.pathname !== "/login") {
      console.log("Redirection vers la page de connexion...");

      // Sauvegarder l'URL actuelle pour y revenir après la connexion
      sessionStorage.setItem("redirectAfterLogin", window.location.pathname);

      // Rediriger avec un délai pour éviter les conditions de course
      setTimeout(() => {
        window.location.href = "/login";
      }, 100);
    }
  },

  // Récupérer tous les rendez-vous
  getAppointments: async () => {
    try {
      console.log(
        "AgendaService: Tentative de récupération des rendez-vous..."
      );

      // Même si isAuthenticated() renvoie false, nous essayons quand même la requête
      // car DataSyncService semble réussir à se connecter
      const response = await fetch("/api/appointments/appointments/", {
        method: "GET",
        headers: AgendaService.getHeaders(),
        credentials: "include", // Important: inclure les cookies
      });

      // Statut 401 = non authentifié
      if (response.status === 401) {
        console.warn("Erreur 401: Non authentifié pour les rendez-vous");

        // Utiliser les données locales
        return JSON.parse(localStorage.getItem("appointments") || "[]");
      }

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();

      // Normalisez le format de retour - assurez-vous que nous retournons toujours un tableau
      if (Array.isArray(data)) {
        return data;
      } else if (data && typeof data === "object") {
        // Si c'est un objet, chercher des propriétés qui pourraient contenir nos rendez-vous
        if (Array.isArray(data.results)) return data.results;
        if (Array.isArray(data.appointments)) return data.appointments;
        if (Array.isArray(data.data)) return data.data;

        // Si aucune propriété familière n'est trouvée mais que l'objet a des propriétés numériques (comme un tableau)
        if (Object.keys(data).some((key) => !isNaN(parseInt(key)))) {
          return Object.values(data);
        }
      }

      // En dernier recours, retourner un tableau vide
      console.warn("Format de réponse inattendu, retour d'un tableau vide");
      return [];
    } catch (error) {
      console.error(
        "AgendaService: Erreur de récupération des rendez-vous:",
        error
      );

      // En cas d'erreur, utiliser les données locales
      return JSON.parse(localStorage.getItem("appointments") || "[]");
    }
  },

  // Récupérer les rendez-vous dans une plage de dates
  getAppointmentsByDateRange: async (startDate, endDate) => {
    try {
      console.log(
        `AgendaService: Récupération des rendez-vous du ${startDate} au ${endDate}...`
      );

      const response = await fetch(
        `/api/appointments/appointments/by_date_range/?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: AgendaService.getHeaders(),
          credentials: "include",
        }
      );

      if (response.status === 401) {
        console.warn(
          "Erreur 401: Non authentifié pour les rendez-vous par date"
        );

        // Filtrer les rendez-vous locaux par date
        const appointments = JSON.parse(
          localStorage.getItem("appointments") || "[]"
        );
        return appointments.filter(
          (app) => app.date >= startDate && app.date <= endDate
        );
      }

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();

      // Normalisez le format de retour - assurez-vous que nous retournons toujours un tableau
      if (Array.isArray(data)) {
        return data;
      } else if (data && typeof data === "object") {
        if (Array.isArray(data.results)) return data.results;
        if (Array.isArray(data.appointments)) return data.appointments;
        if (Array.isArray(data.data)) return data.data;

        if (Object.keys(data).some((key) => !isNaN(parseInt(key)))) {
          return Object.values(data);
        }
      }

      console.warn("Format de réponse inattendu, retour d'un tableau vide");
      return [];
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la récupération des rendez-vous par date:",
        error
      );

      // Filtrer les rendez-vous locaux par date
      const appointments = JSON.parse(
        localStorage.getItem("appointments") || "[]"
      );
      return appointments.filter(
        (app) => app.date >= startDate && app.date <= endDate
      );
    }
  },

  // Créer un nouveau rendez-vous
  createAppointment: async (appointmentData) => {
    try {
      console.log(
        "AgendaService: Création d'un nouveau rendez-vous...",
        appointmentData
      );

      // Générer un ID local si aucun n'est fourni
      if (!appointmentData.id) {
        appointmentData.id = `local_${Date.now()}_${Math.floor(
          Math.random() * 1000
        )}`;
      }

      // Ajouter un timestamp de modification
      appointmentData.last_modified = new Date().toISOString();

      // Créer une copie des données pour l'API
      const apiData = { ...appointmentData };

      // Ne pas inclure le champ client s'il est vide ou null
      if (apiData.client === "" || apiData.client === undefined || apiData.client === null) {
        delete apiData.client;
      }

      // Vérifier si client_name est présent
      if (!apiData.client_name) {
        console.warn(
          "Avertissement: création d'un rendez-vous sans nom de client"
        );
      }

      // Tentative d'envoi au serveur
      const formattedData = AgendaService.formatAppointmentData(apiData);
      console.log(
        "Données formatées envoyées au serveur:",
        JSON.stringify(formattedData)
      );

      const response = await fetch("/api/appointments/appointments/", {
        method: "POST",
        headers: AgendaService.getHeaders(),
        body: JSON.stringify(formattedData),
        credentials: "include",
      });

      // Gérer l'authentification échouée ou les autres erreurs
      if (!response.ok) {
        // Tenter de récupérer les détails de l'erreur pour le débogage
        let errorDetails = "";
        try {
          const errorData = await response.json();
          errorDetails = JSON.stringify(errorData);
          console.error("Détails de l'erreur:", errorData);
        } catch (e) {
          console.error("Impossible de parser la réponse d'erreur");
        }

        // Si non authentifié, stocker localement
        if (response.status === 401) {
          console.warn("Erreur 401: Stockage local du rendez-vous");

          const appointments = JSON.parse(
            localStorage.getItem("appointments") || "[]"
          );
          appointments.push(appointmentData);
          localStorage.setItem("appointments", JSON.stringify(appointments));

          return appointmentData;
        }

        throw new Error(`Erreur HTTP: ${response.status} - ${errorDetails}`);
      }

      // Succès - renvoyer les données du serveur
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la création du rendez-vous:",
        error
      );

      // En cas d'erreur, stocker localement
      const appointments = JSON.parse(
        localStorage.getItem("appointments") || "[]"
      );
      appointments.push(appointmentData);
      localStorage.setItem("appointments", JSON.stringify(appointments));

      return appointmentData;
    }
  },

  // Mettre à jour un rendez-vous existant
  updateAppointment: async (id, appointmentData) => {
    try {
      console.log(
        `AgendaService: Mise à jour du rendez-vous ${id}...`,
        appointmentData
      );

      // Ajouter un timestamp de modification
      appointmentData.last_modified = new Date().toISOString();

      // Créer une copie des données pour l'API
      const apiData = { ...appointmentData };

      // Gérer correctement le champ client
      if (apiData.client === "" || apiData.client === undefined || apiData.client === null) {
        delete apiData.client;
      }

      // Tentative de mise à jour sur le serveur
      const response = await fetch(`/api/appointments/appointments/${id}/`, {
        method: "PUT",
        headers: AgendaService.getHeaders(),
        body: JSON.stringify(AgendaService.formatAppointmentData(apiData)),
        credentials: "include",
      });

      // Gérer les erreurs
      if (!response.ok) {
        // Tenter de récupérer les détails de l'erreur
        let errorDetails = "";
        try {
          const errorData = await response.json();
          errorDetails = JSON.stringify(errorData);
          console.error("Détails de l'erreur:", errorData);
        } catch (e) {
          console.error("Impossible de parser la réponse d'erreur");
        }

        // Si non authentifié, mettre à jour localement
        if (response.status === 401) {
          console.warn("Erreur 401: Mise à jour locale du rendez-vous");

          const appointments = JSON.parse(
            localStorage.getItem("appointments") || "[]"
          );
          const index = appointments.findIndex((app) => app.id === id);

          if (index !== -1) {
            appointments[index] = {
              ...appointments[index],
              ...appointmentData,
            };
            localStorage.setItem("appointments", JSON.stringify(appointments));
          }

          return appointmentData;
        }

        throw new Error(`Erreur HTTP: ${response.status} - ${errorDetails}`);
      }

      // Succès - renvoyer les données du serveur
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la mise à jour du rendez-vous:",
        error
      );

      // En cas d'erreur, mettre à jour localement
      const appointments = JSON.parse(
        localStorage.getItem("appointments") || "[]"
      );
      const index = appointments.findIndex((app) => app.id === id);

      if (index !== -1) {
        appointments[index] = { ...appointments[index], ...appointmentData };
        localStorage.setItem("appointments", JSON.stringify(appointments));
      }

      return appointmentData;
    }
  },

  // Supprimer un rendez-vous
  deleteAppointment: async (id) => {
    try {
      console.log(`AgendaService: Suppression du rendez-vous ${id}...`);

      // Tentative de suppression sur le serveur
      const response = await fetch(`/api/appointments/appointments/${id}/`, {
        method: "DELETE",
        headers: AgendaService.getHeaders(),
        credentials: "include",
      });

      // Gérer l'authentification échouée ou les autres erreurs
      if (!response.ok && response.status !== 404) {
        // Si non authentifié ou autre erreur, supprimer localement
        if (response.status === 401) {
          console.warn("Erreur 401: Suppression locale du rendez-vous");
        } else {
          console.warn(
            `Erreur ${response.status}: Suppression locale du rendez-vous quand même`
          );
        }

        const appointments = JSON.parse(
          localStorage.getItem("appointments") || "[]"
        );
        const filteredAppointments = appointments.filter(
          (app) => app.id !== id
        );
        localStorage.setItem(
          "appointments",
          JSON.stringify(filteredAppointments)
        );

        return true;
      }

      // Succès - supprimer aussi localement pour synchroniser
      const appointments = JSON.parse(
        localStorage.getItem("appointments") || "[]"
      );
      const filteredAppointments = appointments.filter((app) => app.id !== id);
      localStorage.setItem(
        "appointments",
        JSON.stringify(filteredAppointments)
      );

      return true;
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la suppression du rendez-vous:",
        error
      );

      // En cas d'erreur, supprimer localement quand même
      const appointments = JSON.parse(
        localStorage.getItem("appointments") || "[]"
      );
      const filteredAppointments = appointments.filter((app) => app.id !== id);
      localStorage.setItem(
        "appointments",
        JSON.stringify(filteredAppointments)
      );

      return true;
    }
  },

  // Récupérer les paramètres de l'agenda
  getSettings: async () => {
    try {
      console.log("AgendaService: Tentative de récupération des paramètres...");

      // Même approche permissive que pour getAppointments
      const response = await fetch("/api/appointments/settings/my_settings/", {
        method: "GET",
        headers: AgendaService.getHeaders(),
        credentials: "include",
      });

      if (response.status === 401) {
        console.warn("Erreur 401: Non authentifié pour les paramètres");

        // Utiliser les données locales ou des valeurs par défaut
        const storedSettings = JSON.parse(
          localStorage.getItem("agenda_settings") || "null"
        );

        if (storedSettings) {
          return storedSettings;
        }

        // Valeurs par défaut
        return {
          start_hour: 8,
          end_hour: 18,
          time_step: 15,
          closed_days: [0, 6],
          closing_hours: {},
          enable_recurrence: false,
          enable_export: false,
          enable_client_integration: false,
          enable_reminders: false,
          enable_year_view: false,
          default_reminder_time: 60,
        };
      }

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();

      // Vérifier et normaliser le format de données
      const settingsData = data && typeof data === "object" ? data : {};

      // Si les paramètres sont encapsulés dans une propriété 'settings', l'extraire
      const actualSettings = settingsData.settings || settingsData;

      // Stocker localement pour une utilisation hors ligne
      localStorage.setItem("agenda_settings", JSON.stringify(actualSettings));

      return actualSettings;
    } catch (error) {
      console.error(
        "AgendaService: Erreur de récupération des paramètres:",
        error
      );

      // En cas d'erreur, utiliser les données locales ou des valeurs par défaut
      const storedSettings = JSON.parse(
        localStorage.getItem("agenda_settings") || "null"
      );

      if (storedSettings) {
        return storedSettings;
      }

      // Valeurs par défaut
      return {
        start_hour: 8,
        end_hour: 18,
        time_step: 15,
        closed_days: [0, 6],
        closing_hours: {},
        enable_recurrence: false,
        enable_export: false,
        enable_client_integration: false,
        enable_reminders: false,
        enable_year_view: false,
        default_reminder_time: 60,
      };
    }
  },

  // Mettre à jour les paramètres de l'agenda
  updateSettings: async (settingsData) => {
    try {
      console.log(
        "AgendaService: Mise à jour des paramètres de l'agenda...",
        settingsData
      );

      // Ajouter un timestamp de modification
      settingsData.last_modified = new Date().toISOString();

      // Tentative de mise à jour sur le serveur
      const response = await fetch(
        "/api/appointments/settings/update_settings/",
        {
          method: "PUT",
          headers: AgendaService.getHeaders(),
          body: JSON.stringify(AgendaService.formatSettingsData(settingsData)),
          credentials: "include",
        }
      );

      // Gérer l'authentification échouée ou les autres erreurs
      if (!response.ok) {
        // Si non authentifié, mettre à jour localement
        if (response.status === 401) {
          console.warn("Erreur 401: Mise à jour locale des paramètres");
        } else {
          console.warn(
            `Erreur ${response.status}: Mise à jour locale des paramètres quand même`
          );
        }

        localStorage.setItem("agenda_settings", JSON.stringify(settingsData));
        return settingsData;
      }

      // Succès - renvoyer les données du serveur
      const data = await response.json();

      // Extraire les paramètres si encapsulés dans une propriété
      const actualSettings = data.settings || data;

      // Mettre à jour le stockage local
      localStorage.setItem("agenda_settings", JSON.stringify(actualSettings));

      return actualSettings;
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la mise à jour des paramètres:",
        error
      );

      // En cas d'erreur, mettre à jour localement
      localStorage.setItem("agenda_settings", JSON.stringify(settingsData));

      return settingsData;
    }
  },

  // Gestion des rendez-vous récurrents
  getRecurringAppointments: async (startDate, endDate, appointmentId) => {
    try {
      console.log(
        `AgendaService: Récupération des rendez-vous récurrents pour ${appointmentId}...`
      );

      const response = await fetch(
        `/api/appointments/appointments/${appointmentId}/recurring/?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: AgendaService.getHeaders(),
          credentials: "include",
        }
      );

      if (response.status === 401) {
        console.warn("Erreur 401: Calcul local des rendez-vous récurrents");
        return AgendaService.calculateRecurringAppointmentsLocally(
          appointmentId,
          startDate,
          endDate
        );
      }

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();

      // Normaliser le format des données
      let appointments = [];
      if (Array.isArray(data)) {
        appointments = data;
      } else if (data && typeof data === "object") {
        if (Array.isArray(data.results)) appointments = data.results;
        else if (Array.isArray(data.appointments))
          appointments = data.appointments;
        else if (Array.isArray(data.occurrences))
          appointments = data.occurrences;
        else {
          // Essayer d'extraire les valeurs si ce sont des clés numériques
          if (Object.keys(data).some((key) => !isNaN(parseInt(key)))) {
            appointments = Object.values(data);
          }
        }
      }

      return appointments;
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la récupération des rendez-vous récurrents:",
        error
      );
      return AgendaService.calculateRecurringAppointmentsLocally(
        appointmentId,
        startDate,
        endDate
      );
    }
  },

  // Calcul local des rendez-vous récurrents
  calculateRecurringAppointmentsLocally: (
    appointmentId,
    startDate,
    endDate
  ) => {
    // Implémentation simplifiée - dans un cas réel, cette logique serait plus complexe
    console.log("Calcul local des rendez-vous récurrents...");

    const appointments = JSON.parse(
      localStorage.getItem("appointments") || "[]"
    );
    const appointment = appointments.find((app) => app.id === appointmentId);

    if (!appointment || !appointment.isRecurring) {
      return [];
    }

    // Génération simplifiée des occurrences récurrentes
    const result = [];

    // Ajouter un traitement plus avancé ici selon vos besoins...

    return result;
  },

  // Fonctions utilitaires de formatage de données
  formatAppointmentData: (data) => {
    // Créer une copie des données pour ne pas modifier l'original
    let formattedData = { ...data };
    
    // Gérer correctement le champ client
    if (formattedData.client === "" || formattedData.client === undefined || formattedData.client === null) {
      // Supprimer complètement le champ client au lieu de l'envoyer comme null
      delete formattedData.client;
    } else if (typeof formattedData.client === "object" && formattedData.client !== null && formattedData.client.id) {
      // Si c'est un objet avec un ID, extraire uniquement l'ID
      formattedData.client = formattedData.client.id;
    }

    return {
      id: formattedData.id,
      client: formattedData.client, // Sera absent si supprimé ci-dessus
      client_name: formattedData.client_name,
      client_phone: formattedData.client_phone,
      client_email: formattedData.client_email,
      service: formattedData.service || null,
      date: formattedData.date,
      start: formattedData.start,
      duration: formattedData.duration,
      type: formattedData.type,
      notes: formattedData.notes,
      color: formattedData.color,
      is_recurring: formattedData.isRecurring,
      recurrence_type: formattedData.recurrenceType,
      recurrence_interval: formattedData.recurrenceInterval,
      recurrence_end_date: formattedData.recurrenceEndDate,
      recurrence_end_after: formattedData.recurrenceEndAfter,
      recurrence_end_type: formattedData.recurrenceEndType,
      reminder: formattedData.reminder,
      reminder_time: formattedData.reminderTime,
    };
  },

  formatSettingsData: (data) => {
    return {
      start_hour: data.startHour,
      end_hour: data.endHour,
      time_step: data.timeStep,
      closed_days: data.closedDays,
      closing_hours: data.closingHours,
      enable_recurrence: data.enableRecurrence,
      enable_export: data.enableExport,
      enable_client_integration: data.enableClientIntegration,
      enable_reminders: data.enableReminders,
      enable_year_view: data.enableYearView,
      default_reminder_time: data.defaultReminderTime,
    };
  },

  // Fonction pour gérer les conflits d'édition
  handleEditConflict: (localData, serverData) => {
    // Implémentation simple: utiliser les données les plus récentes
    const localDate = new Date(localData.last_modified || 0);
    const serverDate = new Date(serverData.last_modified || 0);

    if (serverDate > localDate) {
      return serverData;
    }

    return localData;
  },

  // Fonctions pour la gestion hors ligne
  scheduleSync: (type, data) => {
    try {
      const syncQueue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      syncQueue.push({
        type,
        data,
        timestamp: new Date().toISOString(),
      });
      localStorage.setItem("sync_queue", JSON.stringify(syncQueue));
      console.log(
        `AgendaService: Planification de la synchronisation de ${type} pour plus tard`
      );
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors de la planification de la synchronisation:",
        error
      );
    }
  },

  // Synchronisation des données en file d'attente
  processSyncQueue: async () => {
    try {
      if (!navigator.onLine) {
        console.log("AgendaService: Hors ligne, synchronisation reportée");
        return;
      }

      const syncQueue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      if (syncQueue.length === 0) {
        return;
      }

      console.log(
        `AgendaService: Traitement de ${syncQueue.length} éléments en attente de synchronisation...`
      );

      for (let i = 0; i < syncQueue.length; i++) {
        const item = syncQueue[i];

        try {
          switch (item.type) {
            case "appointment_create":
              await AgendaService.createAppointment(item.data);
              break;
            case "appointment_update":
              await AgendaService.updateAppointment(item.data.id, item.data);
              break;
            case "appointment_delete":
              await AgendaService.deleteAppointment(item.data.id);
              break;
            case "settings_update":
              await AgendaService.updateSettings(item.data);
              break;
          }

          // Supprimer l'élément traité de la file d'attente
          syncQueue.splice(i, 1);
          i--; // Ajuster l'index
        } catch (error) {
          console.error(
            `AgendaService: Erreur lors de la synchronisation de ${item.type}:`,
            error
          );
          // Conserver l'élément dans la file d'attente pour une tentative ultérieure
        }
      }

      localStorage.setItem("sync_queue", JSON.stringify(syncQueue));
      console.log(
        `AgendaService: Synchronisation terminée, ${syncQueue.length} éléments restants`
      );
    } catch (error) {
      console.error(
        "AgendaService: Erreur lors du traitement de la file de synchronisation:",
        error
      );
    }
  },

  // Obtenir le statut de synchronisation
  getSyncStatus: async () => {
    try {
      const lastSync = localStorage.getItem("last_sync") || null;
      const syncQueue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      const conflicts = JSON.parse(
        localStorage.getItem("sync_conflicts") || "[]"
      );
      const backups = JSON.parse(localStorage.getItem("backups") || "[]");
      const settings = AgendaService.getSyncSettings();

      return {
        lastSync,
        queueLength: syncQueue.length,
        conflicts: conflicts.filter((c) => !c.resolved),
        backups,
        settings,
        isOnline: navigator.onLine,
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du statut de synchronisation:",
        error
      );
      return {
        lastSync: null,
        queueLength: 0,
        conflicts: [],
        backups: [],
        settings: {},
        isOnline: navigator.onLine,
      };
    }
  },

  // Obtenir les paramètres de synchronisation
  getSyncSettings: () => {
    try {
      const settings = JSON.parse(
        localStorage.getItem("sync_settings") || "{}"
      );
      return {
        autoSyncEnabled: true,
        syncFrequency: settings.syncFrequency || 5,
        syncOnStartup: true,
        syncOnShutdown: true,
        syncWifiOnly: false,
        syncAppointmentDays: settings.syncAppointmentDays || 90,
        syncTransactionDays: settings.syncTransactionDays || 90,
        conflictResolution: settings.conflictResolution || "askUser",
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des paramètres de synchronisation:",
        error
      );return {
        autoSyncEnabled: true,
        syncFrequency: 5,
        syncOnStartup: true,
        syncOnShutdown: true,
        syncWifiOnly: false,
        syncAppointmentDays: 90,
        syncTransactionDays: 90,
        conflictResolution: "askUser",
      };
    }
  },

  // Résoudre un conflit
  resolveConflict: async (conflictId, resolution) => {
    try {
      const conflicts = JSON.parse(
        localStorage.getItem("sync_conflicts") || "[]"
      );
      const conflictIndex = conflicts.findIndex((c) => c.id === conflictId);

      if (conflictIndex === -1) {
        throw new Error("Conflit non trouvé");
      }

      const conflict = conflicts[conflictIndex];

      // Appliquer la résolution
      if (resolution === "useLocal") {
        // Utiliser la version locale
        switch (conflict.type) {
          case "appointment":
            await AgendaService.pushAppointmentToServer(conflict.localVersion);
            break;
          case "transaction":
            await AgendaService.pushTransactionToServer(conflict.localVersion);
            break;
          case "client":
            await ClientService.updateClient(conflict.localVersion);
            break;
          // Ajoutez d'autres types de conflits si nécessaire
        }
      } else if (resolution === "useServer") {
        // Utiliser la version du serveur
        switch (conflict.type) {
          case "appointment":
            {
              const appointments = JSON.parse(
                localStorage.getItem("appointments") || "[]"
              );
              const index = appointments.findIndex(
                (a) => a.id === conflict.serverVersion.id
              );
              if (index !== -1) {
                appointments[index] = conflict.serverVersion;
                localStorage.setItem(
                  "appointments",
                  JSON.stringify(appointments)
                );
              }
            }
            break;
          case "transaction":
            {
              const transactions = JSON.parse(
                localStorage.getItem("transactions") || "[]"
              );
              const index = transactions.findIndex(
                (t) => t.id === conflict.serverVersion.id
              );
              if (index !== -1) {
                transactions[index] = conflict.serverVersion;
                localStorage.setItem(
                  "transactions",
                  JSON.stringify(transactions)
                );
              }
            }
            break;
          case "client":
            {
              const clients = JSON.parse(
                localStorage.getItem("clients") || "[]"
              );
              const index = clients.findIndex(
                (c) => c.id === conflict.serverVersion.id
              );
              if (index !== -1) {
                clients[index] = {
                  ...clients[index],
                  ...conflict.serverVersion,
                  server_id: conflict.serverVersion.id,
                };
                localStorage.setItem("clients", JSON.stringify(clients));
              }
            }
            break;
          // Ajoutez d'autres types de conflits si nécessaire
        }
      }

      // Marquer le conflit comme résolu
      conflicts[conflictIndex].resolved = true;
      conflicts[conflictIndex].resolution = resolution;
      conflicts[conflictIndex].resolvedAt = new Date().toISOString();

      localStorage.setItem("sync_conflicts", JSON.stringify(conflicts));

      return { success: true, conflict: conflicts[conflictIndex] };
    } catch (error) {
      console.error("Erreur lors de la résolution du conflit:", error);
      throw error;
    }
  },

  // Synchronisation rapide avant fermeture
  quickSync: async () => {
    console.log("Exécution de la synchronisation rapide...");

    if (!navigator.onLine) {
      console.log("Mode hors ligne, synchronisation rapide ignorée");
      return { success: false, mode: "offline" };
    }

    try {
      // Traiter les files d'attente
      await AgendaService.processSyncQueue();

      return { success: true, mode: "online" };
    } catch (error) {
      console.error("Erreur lors de la synchronisation rapide:", error);
      return { success: false, error: error.message };
    }
  },

  // Fonction utilitaire pour envoyer un rendez-vous au serveur (utilisé pour la résolution de conflit)
  pushAppointmentToServer: async (appointment) => {
    try {
      if (appointment.id && appointment.id.toString().startsWith("local_")) {
        // C'est un ID local, créer un nouveau rendez-vous sur le serveur
        const { id, ...appointmentData } = appointment;
        return await AgendaService.createAppointment(appointmentData);
      } else {
        // C'est un ID de serveur, mettre à jour
        return await AgendaService.updateAppointment(
          appointment.id,
          appointment
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du rendez-vous au serveur:", error);
      throw error;
    }
  },

  // Fonction utilitaire pour envoyer une transaction au serveur (utilisé pour la résolution de conflit)
  pushTransactionToServer: async (transaction) => {
    try {
      // Implémentation fictive - à adapter selon votre application
      console.log("Envoi de la transaction au serveur:", transaction);
      return transaction;
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de la transaction au serveur:",
        error
      );
      throw error;
    }
  },
};

export default AgendaService;