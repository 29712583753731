import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export const ImportExportComponent = ({ 
  transactions = [], 
  clients = [], 
  stockItems = [], 
  prestations = [],
  onDataImport = () => {}
}) => {
  const [showModal, setShowModal] = useState(false);
  const [importType, setImportType] = useState('transactions');
  const [exportType, setExportType] = useState('transactions');
  const [importFormat, setImportFormat] = useState('csv');
  const [exportFormat, setExportFormat] = useState('csv');
  const [importStatus, setImportStatus] = useState('');
  const [exportStatus, setExportStatus] = useState('');
  const [importErrorMsg, setImportErrorMsg] = useState('');
  const [fileInputKey, setFileInputKey] = useState(Date.now()); // Pour réinitialiser l'input file

  // Ouvrir le modal
  const openModal = () => {
    setShowModal(true);
    setImportStatus('');
    setExportStatus('');
    setImportErrorMsg('');
  };

  // Fermer le modal
  const closeModal = () => {
    setShowModal(false);
    setImportStatus('');
    setExportStatus('');
    setImportErrorMsg('');
    // Réinitialiser l'input file
    setFileInputKey(Date.now());
  };

  // Fonction pour gérer l'import de fichier
  const handleFileImport = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImportStatus('loading');
    setImportErrorMsg('');

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const fileContent = event.target.result;
        let importedData;

        // Traiter selon le format
        if (importFormat === 'csv') {
          Papa.parse(fileContent, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (results) => {
              if (results.errors.length > 0) {
                setImportStatus('error');
                setImportErrorMsg('Erreur lors de l\'analyse du CSV: ' + results.errors[0].message);
                return;
              }
              
              processImportedData(results.data);
            },
            error: (error) => {
              setImportStatus('error');
              setImportErrorMsg('Erreur lors de l\'analyse du CSV: ' + error.message);
            }
          });
        } else if (importFormat === 'json') {
          try {
            importedData = JSON.parse(fileContent);
            processImportedData(importedData);
          } catch (e) {
            setImportStatus('error');
            setImportErrorMsg('Erreur lors de l\'analyse du JSON: ' + e.message);
          }
        } else if (importFormat === 'excel') {
          try {
            const workbook = XLSX.read(fileContent, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            importedData = XLSX.utils.sheet_to_json(worksheet);
            processImportedData(importedData);
          } catch (e) {
            setImportStatus('error');
            setImportErrorMsg('Erreur lors de l\'analyse du fichier Excel: ' + e.message);
          }
        }
      } catch (e) {
        setImportStatus('error');
        setImportErrorMsg('Erreur lors du traitement du fichier: ' + e.message);
      }
    };

    reader.onerror = () => {
      setImportStatus('error');
      setImportErrorMsg('Erreur lors de la lecture du fichier');
    };

    if (importFormat === 'excel') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
  };

  // Fonction pour traiter les données importées
  const processImportedData = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      setImportStatus('error');
      setImportErrorMsg('Aucune donnée valide trouvée dans le fichier');
      return;
    }

    // Vérifier et traiter les données selon le type
    try {
      let processedData;
      
      switch (importType) {
        case 'transactions':
          // Validation et transformation des transactions
          processedData = data.map(item => {
            // Vérifier les champs essentiels
            if (!item.id || !item.date) {
              throw new Error('Données de transaction incomplètes');
            }
            
            // Transformer les dates si nécessaire
            return {
              ...item,
              id: parseInt(item.id) || Date.now(),
              date: new Date(item.date).toISOString(),
              items: Array.isArray(item.items) ? item.items : (typeof item.items === 'string' ? JSON.parse(item.items) : []),
              client: typeof item.client === 'string' ? JSON.parse(item.client) : (item.client || {})
            };
          });
          break;
          
        case 'clients':
          // Validation et transformation des clients
          processedData = data.map(item => {
            // Vérifier les champs essentiels
            if (!item.nom) {
              throw new Error('Données client incomplètes (nom manquant)');
            }
            
            return {
              ...item,
              id: parseInt(item.id) || Date.now()
            };
          });
          break;
          
        case 'stock':
          // Validation et transformation des articles de stock
          processedData = data.map(item => {
            // Vérifier les champs essentiels
            if (!item.nom) {
              throw new Error('Données d\'article incomplètes (nom manquant)');
            }
            
            return {
              ...item,
              id: parseInt(item.id) || Date.now(),
              quantite: parseInt(item.quantite) || 0,
              quantiteMin: parseInt(item.quantiteMin) || 0,
              prixAchat: parseFloat(item.prixAchat) || 0,
              prixRevente: parseFloat(item.prixRevente) || 0,
              historique: Array.isArray(item.historique) ? item.historique : []
            };
          });
          break;
          
        case 'prestations':
          // Validation et transformation des prestations
          processedData = data.map(item => {
            // Vérifier les champs essentiels
            if (!item.nom) {
              throw new Error('Données de prestation incomplètes (nom manquant)');
            }
            
            return {
              ...item,
              id: parseInt(item.id) || Date.now(),
              prix: parseFloat(item.prix) || 0,
              prixHT: parseFloat(item.prixHT) || 0,
              prixTTC: parseFloat(item.prixTTC) || 0,
              duree: parseInt(item.duree) || 30
            };
          });
          break;
          
        default:
          throw new Error('Type d\'importation non pris en charge');
      }
      
      // Notifier le composant parent des données importées
      onDataImport(importType, processedData);
      
      setImportStatus('success');
    } catch (e) {
      setImportStatus('error');
      setImportErrorMsg('Erreur lors du traitement des données: ' + e.message);
    }
  };

  // Fonction pour exporter les données
  const handleExport = () => {
    setExportStatus('loading');

    try {
      let dataToExport;
      let fileName;
      
      // Sélectionner les données à exporter
      switch (exportType) {
        case 'transactions':
          dataToExport = [...transactions];
          fileName = 'transactions';
          break;
          
        case 'clients':
          dataToExport = [...clients];
          fileName = 'clients';
          break;
          
        case 'stock':
          dataToExport = [...stockItems];
          fileName = 'stock';
          break;
          
        case 'prestations':
          dataToExport = [...prestations];
          fileName = 'prestations';
          break;
          
        default:
          throw new Error('Type d\'exportation non pris en charge');
      }
      
      // Exporter selon le format
      if (exportFormat === 'csv') {
        const csv = Papa.unparse(dataToExport);
        downloadFile(csv, `${fileName}_${formatDate(new Date())}.csv`, 'text/csv');
      } else if (exportFormat === 'json') {
        const json = JSON.stringify(dataToExport, null, 2);
        downloadFile(json, `${fileName}_${formatDate(new Date())}.json`, 'application/json');
      } else if (exportFormat === 'excel') {
        // Création du workbook et worksheet
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
        
        // Générer le fichier XLSX
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        // Télécharger le fichier
        if (navigator.msSaveBlob) {
          // IE/Edge
          navigator.msSaveBlob(blob, `${fileName}_${formatDate(new Date())}.xlsx`);
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}_${formatDate(new Date())}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
      
      setExportStatus('success');
    } catch (e) {
      setExportStatus('error');
      console.error('Erreur d\'exportation:', e);
    }
  };

  // Fonction utilitaire pour télécharger un fichier
  const downloadFile = (content, fileName, contentType) => {
    const blob = new Blob([content], { type: contentType });
    
    if (navigator.msSaveBlob) {
      // IE/Edge
      navigator.msSaveBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  // Fonction pour formater la date
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  return (
    <>
      <button className="import-export-btn" onClick={openModal}>
        <i className="icon">📊</i> Import/Export
      </button>
      
      {showModal && (
        <div className="import-export-modal-overlay">
          <div className="import-export-modal-content">
            <div className="import-export-modal-header">
              <h2>Import/Export de données</h2>
              <button className="close-modal" onClick={closeModal}>&times;</button>
            </div>
            
            <div className="import-export-tabs">
              <div className="import-export-tab active">Importation/Exportation</div>
            </div>
            
            <div className="import-export-body">
              <div className="import-section">
                <h3>Importer des données</h3>
                
                <div className="import-form">
                  <div className="form-group">
                    <label htmlFor="import-type">Type de données:</label>
                    <select 
                      id="import-type" 
                      value={importType} 
                      onChange={(e) => setImportType(e.target.value)}
                    >
                      <option value="transactions">Transactions</option>
                      <option value="clients">Clients</option>
                      <option value="stock">Stock</option>
                      <option value="prestations">Prestations</option>
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="import-format">Format:</label>
                    <select 
                      id="import-format" 
                      value={importFormat} 
                      onChange={(e) => setImportFormat(e.target.value)}
                    >
                      <option value="csv">CSV</option>
                      <option value="json">JSON</option>
                      <option value="excel">Excel</option>
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="import-file">Fichier:</label>
                    <input 
                      type="file" 
                      id="import-file"
                      key={fileInputKey}
                      accept={
                        importFormat === 'csv' 
                          ? ".csv" 
                          : importFormat === 'json' 
                            ? ".json" 
                            : ".xlsx, .xls"
                      }
                      onChange={handleFileImport}
                    />
                  </div>
                  
                  {importStatus === 'loading' && (
                    <div className="status-message loading">
                      Importation en cours...
                    </div>
                  )}
                  
                  {importStatus === 'success' && (
                    <div className="status-message success">
                      Importation réussie!
                    </div>
                  )}
                  
                  {importStatus === 'error' && (
                    <div className="status-message error">
                      Erreur: {importErrorMsg}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="export-section">
                <h3>Exporter des données</h3>
                
                <div className="export-form">
                  <div className="form-group">
                    <label htmlFor="export-type">Type de données:</label>
                    <select 
                      id="export-type" 
                      value={exportType} 
                      onChange={(e) => setExportType(e.target.value)}
                    >
                      <option value="transactions">Transactions</option>
                      <option value="clients">Clients</option>
                      <option value="stock">Stock</option>
                      <option value="prestations">Prestations</option>
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="export-format">Format:</label>
                    <select 
                      id="export-format" 
                      value={exportFormat} 
                      onChange={(e) => setExportFormat(e.target.value)}
                    >
                      <option value="csv">CSV</option>
                      <option value="json">JSON</option>
                      <option value="excel">Excel</option>
                    </select>
                  </div>
                  
                  <button 
                    className="export-btn" 
                    onClick={handleExport}
                  >
                    Exporter
                  </button>
                  
                  {exportStatus === 'loading' && (
                    <div className="status-message loading">
                      Exportation en cours...
                    </div>
                  )}
                  
                  {exportStatus === 'success' && (
                    <div className="status-message success">
                      Exportation réussie!
                    </div>
                  )}
                  
                  {exportStatus === 'error' && (
                    <div className="status-message error">
                      Erreur lors de l'exportation.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};