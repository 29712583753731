import React, { useState, useEffect } from 'react';

export const CaisseSettings = ({ isOpen, onClose }) => {
  const [settings, setSettings] = useState({
    // Vente
    allowProductSale: true,
    allowPrestationSale: true,
    allowGiftCardSale: true,
    
    // Affichage
    showHTValues: true,
    vatRate: 20,
    
    // Validation
    clientRequired: true,
    passageNomRequired: true,
    passagePrenomRequired: true,
    paymentMethodRequired: true,
    notesRequired: false,
    
    // Paiement
    defaultPaymentMethod: 'cash',
    automaticPriceCalculation: true
  });

  useEffect(() => {
    // Charger les paramètres depuis le localStorage
    const storedSettings = JSON.parse(localStorage.getItem('caisse_settings') || '{}');
    
    // Fusionner avec les paramètres par défaut
    setSettings(prevSettings => ({
      ...prevSettings,
      ...storedSettings
    }));
  }, []);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: newValue
    }));
  };

  const handleSave = () => {
    // Enregistrer les paramètres dans le localStorage
    localStorage.setItem('caisse_settings', JSON.stringify(settings));
    
    // Fermer le modal
    onClose();
    
    // Afficher un message de confirmation
    alert('Paramètres de caisse enregistrés avec succès !');
  };

  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content settings-modal">
          <span className="close-modal" onClick={onClose}>&times;</span>
          <h2>Paramètres de caisse</h2>
          
          <div className="settings-section">
            <h3>Options de vente</h3>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="allowProductSale" 
                    checked={settings.allowProductSale} 
                    onChange={handleInputChange}
                  />
                  Autoriser la vente de produits
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="allowPrestationSale" 
                    checked={settings.allowPrestationSale} 
                    onChange={handleInputChange}
                  />
                  Autoriser la vente de prestations
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="allowGiftCardSale" 
                    checked={settings.allowGiftCardSale} 
                    onChange={handleInputChange}
                  />
                  Autoriser la vente de cartes cadeaux
                </label>
              </div>
            </div>
          </div>
          
          <div className="settings-section">
            <h3>Options d'affichage</h3>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="showHTValues" 
                    checked={settings.showHTValues} 
                    onChange={handleInputChange}
                  />
                  Afficher les valeurs HT dans le panier
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>Taux de TVA (%):</label>
                <input 
                  type="number" 
                  name="vatRate" 
                  value={settings.vatRate} 
                  onChange={handleInputChange}
                  min="0"
                  max="100"
                  step="0.1"
                  className="small-input"
                />
                <p className="setting-description">
                  Taux utilisé pour le calcul automatique des prix HT/TTC
                </p>
              </div>
            </div>
          </div>
          
          <div className="settings-section">
            <h3>Paramètres de validation</h3>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="clientRequired" 
                    checked={settings.clientRequired} 
                    onChange={handleInputChange}
                  />
                  Client obligatoire pour les ventes
                </label>
                <p className="setting-description">
                  Le client doit être sélectionné ou ses informations renseignées pour valider une vente
                </p>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="passageNomRequired" 
                    checked={settings.passageNomRequired} 
                    onChange={handleInputChange}
                  />
                  Nom obligatoire pour les clients de passage
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="passagePrenomRequired" 
                    checked={settings.passagePrenomRequired} 
                    onChange={handleInputChange}
                  />
                  Prénom obligatoire pour les clients de passage
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="paymentMethodRequired" 
                    checked={settings.paymentMethodRequired} 
                    onChange={handleInputChange}
                  />
                  Méthode de paiement obligatoire
                </label>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="notesRequired" 
                    checked={settings.notesRequired} 
                    onChange={handleInputChange}
                  />
                  Notes obligatoires pour les remboursements
                </label>
              </div>
            </div>
          </div>
          
          <div className="settings-section">
            <h3>Options de paiement</h3>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>
                  <input 
                    type="checkbox" 
                    name="automaticPriceCalculation" 
                    checked={settings.automaticPriceCalculation} 
                    onChange={handleInputChange}
                  />
                  Calcul automatique des prix TTC/HT
                </label>
                <p className="setting-description">
                  Si activé, le système calculera automatiquement les prix TTC ou HT selon les informations disponibles
                </p>
              </div>
            </div>
            
            <div className="settings-row">
              <div className="setting-option">
                <label>Méthode de paiement par défaut:</label>
                <select 
                  name="defaultPaymentMethod" 
                  value={settings.defaultPaymentMethod} 
                  onChange={handleInputChange}
                >
                  <option value="cash">Espèces</option>
                  <option value="card">Carte bancaire</option>
                  <option value="cheque">Chèque</option>
                  <option value="gift">Carte cadeau</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="form-actions">
            <button className="submit-btn" onClick={handleSave}>
              Enregistrer
            </button>
            <button className="cancel-btn" onClick={onClose}>
              Annuler
            </button>
          </div>
        </div>
      </div>
    )
  );
};