import React, { useEffect } from 'react';
import { Agenda } from './Agenda';
import { migrateLocalStorageToServer } from './MigrationTool';

export const WrappedAgenda = (props) => {
  // Effectuer la migration des données au montage du composant
  useEffect(() => {
    const performMigration = async () => {
      try {
        await migrateLocalStorageToServer();
      } catch (error) {
        console.error('Erreur lors de la migration des données:', error);
      }
    };
    
    performMigration();
  }, []);

  // Rendre le composant Agenda
  return <Agenda {...props} />;
};