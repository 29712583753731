// src/services/DataSyncService.js

import { authFetch } from "./AuthAdapter"; // Utilise authFetch pour inclure le token JWT

/**
 * Service de synchronisation des données entre le stockage local et le serveur
 * Gère l'authentification, les conflits et les sauvegardes
 * Fonctionne automatiquement en arrière-plan
 */
class DataSyncServiceClass {
  constructor() {
    this.syncListeners = [];
    this.isSyncing = false;
    this.syncInterval = null;
    this.lastSync = localStorage.getItem("last_sync") || null;
    this.syncQueue = []; // Sera chargé depuis localStorage au besoin
    this.retryAttempts = 0;
    this.maxRetries = 3;
    this.serverUrl = this.getServerUrl();

    // Ajouter des gestionnaires d'événements pour le cycle de vie de l'application
    this.setupLifecycleHandlers();

    // Initialiser la synchronisation automatique
    this.initAutoSync();
  }

  /**
   * Obtenir l'URL du serveur en fonction de l'environnement
   */
  getServerUrl() {
    // Utilise la logique d'AuthAdapter pour la cohérence
    return window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
      ? "http://localhost:8000" // URL de base, les paths API seront ajoutés
      : ""; // Chemin relatif si servi par le même serveur/proxy
  }

  /**
   * Configure les gestionnaires d'événements pour le cycle de vie de l'application
   */
  setupLifecycleHandlers() {
    // Synchroniser avant la fermeture de la page
    window.addEventListener("beforeunload", (event) => {
      // Vérifier s'il y a des éléments en file d'attente non traités
      const queueStatus = this.checkQueueStatus();
      if (queueStatus.hasAnyPendingRequests && navigator.onLine) {
        // Tenter une synchro rapide (juste la queue)
        // Note: L'exécution asynchrone n'est pas garantie ici.
        // C'est une tentative "best effort".
        console.log(
          "Tentative de traitement de la file d'attente avant fermeture..."
        );
        this.processQueuedSyncs().catch((err) =>
          console.error("Erreur synchro fermeture:", err)
        );
        // Pour certaines opérations critiques, une approche synchrone (obsolète)
        // ou l'API Beacon pourrait être envisagée, mais c'est complexe.
      }
    });

    // Synchroniser lorsque l'application revient au premier plan
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log(
          "Application visible, déclenchement de la synchronisation."
        );
        this.syncAllData().catch((err) => {
          console.error("Erreur lors de la synchronisation au retour:", err);
        });
      }
    });

    // Synchroniser lorsque la connexion réseau est rétablie
    window.addEventListener("online", () => {
      console.log("Connexion réseau rétablie, synchronisation en cours...");
      this.notifySyncListeners("networkStatusChange", { isOnline: true });
      this.processQueuedSyncs(); // Traiter d'abord ce qui est en attente
      this.syncAllData().catch((err) => {
        // Puis synchroniser le reste
        console.error(
          "Erreur lors de la synchronisation après connexion:",
          err
        );
      });
    });

    // Gérer le mode hors ligne
    window.addEventListener("offline", () => {
      console.log("Mode hors ligne détecté");
      this.notifySyncListeners("networkStatusChange", { isOnline: false });
    });
  }

  /**
   * Initialiser la synchronisation automatique
   */
  initAutoSync() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }

    const syncSettings = this.getSyncSettings();
    const intervalMinutes = syncSettings.syncFrequency || 5;

    console.log(
      `Synchronisation automatique configurée: toutes les ${intervalMinutes} minutes`
    );

    this.syncInterval = setInterval(() => {
      this.autoSyncInterval();
    }, intervalMinutes * 60 * 1000);

    // Effectuer une synchronisation initiale au démarrage (après un court délai)
    if (syncSettings.syncOnStartup) {
      setTimeout(() => {
        this.syncAllData().catch((err) => {
          console.error("Erreur lors de la synchronisation initiale:", err);
          // Pas besoin d'ajouter à la queue ici, autoSyncInterval le fera
        });
      }, 5000);
    }
  }

  /**
   * Obtenir les paramètres de synchronisation depuis localStorage
   */
  getSyncSettings() {
    try {
      const settings = JSON.parse(
        localStorage.getItem("sync_settings") || "{}"
      );
      // Fournir des valeurs par défaut robustes
      return {
        autoSyncEnabled:
          settings.autoSyncEnabled !== undefined
            ? settings.autoSyncEnabled
            : true,
        syncFrequency: settings.syncFrequency || 5,
        syncOnStartup:
          settings.syncOnStartup !== undefined ? settings.syncOnStartup : true,
        syncOnShutdown:
          settings.syncOnShutdown !== undefined
            ? settings.syncOnShutdown
            : true, // Note: la synchro réelle on shutdown est limitée
        syncWifiOnly: settings.syncWifiOnly || false, // Non implémenté dans ce code
        syncAppointmentDays: settings.syncAppointmentDays || 90,
        syncTransactionDays: settings.syncTransactionDays || 90,
        conflictResolution: settings.conflictResolution || "useServer", // 'useServer' est souvent plus simple
      };
    } catch (erreur) {
      console.error(
        "Erreur lors de la récupération des paramètres de synchronisation:",
        erreur
      );
      // Retourner des défauts sûrs
      return {
        autoSyncEnabled: true,
        syncFrequency: 5,
        syncOnStartup: true,
        syncOnShutdown: true,
        syncWifiOnly: false,
        syncAppointmentDays: 90,
        syncTransactionDays: 90,
        conflictResolution: "useServer",
      };
    }
  }

  /**
   * Mettre à jour les paramètres de synchronisation
   */
  updateSyncSettings(newSettings) {
    try {
      const currentSettings = this.getSyncSettings();
      const updatedSettings = { ...currentSettings, ...newSettings };
      localStorage.setItem("sync_settings", JSON.stringify(updatedSettings));
      this.initAutoSync(); // Redémarrer la synchro avec les nouveaux paramètres
      return updatedSettings;
    } catch (erreur) {
      console.error(
        "Erreur lors de la mise à jour des paramètres de synchronisation:",
        erreur
      );
      throw erreur;
    }
  }

  /**
   * Exécuté à l'intervalle de synchronisation automatique
   */
  async autoSyncInterval() {
    console.log("Exécution de la synchronisation automatique planifiée");
    const settings = this.getSyncSettings();
    if (!settings.autoSyncEnabled) {
      console.log("Synchronisation automatique désactivée.");
      return;
    }
    if (this.isSyncing) {
      console.log("Synchronisation déjà en cours, intervalle ignoré.");
      return;
    }
    if (!navigator.onLine) {
      console.log("Mode hors ligne, synchronisation automatique reportée.");
      return;
    }

    try {
      await this.syncAllData();
    } catch (erreur) {
      console.error("Erreur lors de la synchronisation automatique:", erreur);
      // L'erreur est déjà loguée dans syncAllData, pas besoin d'ajouter à la queue ici
    }
  }

  /**
   * Ajouter un écouteur d'événements de synchronisation
   */
  addSyncListener(callback) {
    if (typeof callback === "function") {
      this.syncListeners.push(callback);
      // Retourner une fonction pour se désabonner
      return () => {
        this.syncListeners = this.syncListeners.filter((cb) => cb !== callback);
      };
    }
    return () => {}; // Retourner une fonction vide si callback invalide
  }

  /**
   * Notifier tous les écouteurs d'un événement de synchronisation
   */
  notifySyncListeners(evenement, donnees = {}) {
    // Copier la liste pour éviter les problèmes si un listener se désabonne pendant la notification
    const listeners = [...this.syncListeners];
    listeners.forEach((callback) => {
      try {
        callback(evenement, donnees);
      } catch (erreur) {
        console.error(
          `Erreur dans l'écouteur de synchronisation pour l'événement ${evenement}:`,
          erreur
        );
      }
    });
  }

  /**
   * Récupérer le token CSRF (moins pertinent pour JWT mais peut être utile)
   */
  getCsrfToken() {
    return (
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrftoken="))
        ?.split("=")[1] || ""
    );
  }

  /**
   * Requête HTTP sécurisée avec gestion avancée des erreurs et refresh token
   */
  async requeteSecurisee(urlPath, options = {}) {
    // Vérifier la connexion internet avant toute tentative
    if (!navigator.onLine) {
      console.warn(`Mode hors ligne: Requête vers ${urlPath} annulée.`);
      throw new Error("Mode hors ligne, impossible de contacter le serveur");
    }

    try {
      const fullUrl = `${this.serverUrl}${urlPath}`; // Construire l'URL complète
      const accessToken = localStorage.getItem("accessToken");

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json", // Toujours accepter JSON
        // 'X-CSRFToken': this.getCsrfToken(), // Moins utile avec JWT Bearer
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }), // Ajouter le token si présent
        ...(options.headers || {}), // Fusionner avec les headers personnalisés
      };

      const configRequete = {
        ...options,
        headers: headers,
        credentials: "include", // Important pour les cookies (CSRF, session si utilisée)
      };

      // Ajouter un timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 secondes
      configRequete.signal = controller.signal;

      console.debug(`Requête ${options.method || "GET"} vers ${fullUrl}`);
      const reponse = await fetch(fullUrl, configRequete);
      clearTimeout(timeoutId);

      // Gérer les réponses HTTP
      if (reponse.status === 401) {
        console.warn(
          `Erreur 401 (Non autorisé) pour ${fullUrl}. Tentative de rafraîchissement du token...`
        );
        this.notifySyncListeners("authExpired", {});
        const refreshed = await this.refreshAccessToken();
        if (refreshed) {
          console.info(
            "Token rafraîchi avec succès. Nouvelle tentative de requête..."
          );
          // Réessayer la requête avec le nouveau token (appel récursif)
          return this.requeteSecurisee(urlPath, options);
        } else {
          console.error(
            "Échec du rafraîchissement du token. Déconnexion probable nécessaire."
          );
          this.notifySyncListeners("authFailed", {});
          throw new Error(
            "Session expirée ou invalide. Veuillez vous reconnecter."
          );
        }
      }

      if (reponse.status === 403) {
        console.error(`Erreur 403 (Interdit) pour ${fullUrl}.`);
        this.notifySyncListeners("authForbidden", {});
        throw new Error(
          "Accès refusé. Vous n'avez pas les permissions nécessaires."
        );
      }

      if (reponse.status === 404) {
        console.error(
          `Erreur 404 (Non trouvé) pour ${fullUrl}. L'endpoint API semble manquant.`
        );
        // Retourner un objet spécifique pour que l'appelant puisse gérer ce cas
        return { notFound: true, status: 404 };
      }

      // Gérer les autres erreurs client/serveur
      if (!reponse.ok) {
        // Essayer de lire le message d'erreur du corps JSON
        let errorMessage = `Erreur HTTP: ${reponse.status} ${reponse.statusText}`;
        try {
          const errorBody = await reponse.json();
          // Chercher des messages d'erreur courants dans les réponses DRF
          errorMessage =
            errorBody.detail || errorBody.message || JSON.stringify(errorBody);
          console.error(
            `Erreur serveur (${reponse.status}) pour ${fullUrl}:`,
            errorBody
          );
        } catch (e) {
          // Ignorer si le corps n'est pas JSON ou vide
          console.error(
            `Erreur serveur (${reponse.status}) pour ${fullUrl}. Impossible de lire le corps de la réponse.`
          );
        }
        throw new Error(errorMessage);
      }

      // Gérer les réponses sans contenu (ex: DELETE réussi)
      if (reponse.status === 204) {
        return { success: true, status: 204 };
      }

      // Traiter les réponses JSON valides
      const contentType = reponse.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        const data = await reponse.json();
        return data; // Retourner les données JSON parsées
      } else {
        // Si ce n'est pas JSON mais la réponse est OK (ex: 200, 201)
        console.warn(
          `Réponse non-JSON reçue pour ${fullUrl} (Content-Type: ${contentType})`
        );
        return { success: true, status: reponse.status };
      }
    } catch (erreur) {
      // Gérer les erreurs réseau, timeout, etc.
      console.error(
        `Erreur lors de la requête sécurisée vers ${urlPath}:`,
        erreur
      );
      if (erreur.name === "AbortError") {
        throw new Error("La requête vers le serveur a expiré (timeout).");
      }
      // Si l'erreur est déjà une erreur spécifique lancée plus haut, la relancer
      if (
        erreur.message.startsWith("Mode hors ligne") ||
        erreur.message.startsWith("Session expirée") ||
        erreur.message.startsWith("Accès refusé") ||
        erreur.message.startsWith("Erreur HTTP")
      ) {
        throw erreur;
      }
      // Pour les autres erreurs (TypeError réseau, etc.), lancer une erreur générique
      throw new Error("Erreur de connexion au serveur. Vérifiez votre réseau.");
    }
  }

  /**
   * Rafraîchir le token d'accès en utilisant le refresh token
   */
  async refreshAccessToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.warn("Aucun refresh token trouvé pour rafraîchir la session.");
      return false;
    }

    try {
      // Utiliser fetch directement ici pour éviter la récursion infinie en cas d'erreur 401 sur le refresh
      const response = await fetch(`${this.serverUrl}/api/token/refresh/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        console.info("Token d'accès rafraîchi avec succès.");
        this.notifySyncListeners("authRefreshed", {});
        return true;
      } else {
        // Si le refresh token lui-même est invalide/expiré
        console.error(
          "Échec du rafraîchissement du token. Statut:",
          response.status
        );
        // Supprimer les tokens invalides et notifier
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("currentUser");
        this.notifySyncListeners("authFailed", { reason: "refresh_failed" });
        return false;
      }
    } catch (error) {
      console.error("Erreur réseau lors du rafraîchissement du token:", error);
      this.notifySyncListeners("networkError", { context: "refresh_token" });
      return false;
    }
  }

  /**
   * Ajouter une tâche à la file d'attente de synchronisation (persistée)
   */
  addToSyncQueue(type, data = {}) {
    try {
      // Charger la file d'attente actuelle
      let currentQueue = [];
      try {
        currentQueue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
        if (!Array.isArray(currentQueue)) currentQueue = []; // S'assurer que c'est un tableau
      } catch (e) {
        console.error(
          "Erreur de parsing de la file d'attente existante, réinitialisation.",
          e
        );
        currentQueue = [];
      }

      // Créer l'élément de file d'attente
      const queueItem = {
        id: `task_${type}_${Date.now()}_${Math.random()
          .toString(36)
          .substring(2, 7)}`, // ID unique
        type,
        data,
        timestamp: new Date().toISOString(),
        attempts: 0, // Compteur de tentatives
      };

      // Ajouter à la file
      currentQueue.push(queueItem);
      this.syncQueue = currentQueue; // Mettre à jour la version en mémoire

      // Sauvegarder dans localStorage
      localStorage.setItem("sync_queue", JSON.stringify(currentQueue));
      console.log(
        `Tâche de synchronisation ajoutée à la file d'attente: ${type} (ID: ${queueItem.id})`
      );
      this.notifySyncListeners("queueUpdated", {
        queueLength: currentQueue.length,
      });
    } catch (error) {
      // Gérer les erreurs potentielles de localStorage (quota, etc.)
      console.error(
        "Erreur critique lors de l'ajout à la file d'attente:",
        error
      );
      this.notifySyncListeners("syncError", {
        message: "Impossible de sauvegarder la tâche en file d'attente.",
      });
    }
  }

  /**
   * Traiter la file d'attente de synchronisation (persistée)
   */
  async processQueuedSyncs() {
    if (!navigator.onLine) {
      console.log("Mode hors ligne, traitement de la file d'attente reporté.");
      return;
    }

    // Charger la file d'attente depuis localStorage
    let queue = [];
    try {
      queue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      if (!Array.isArray(queue)) queue = [];
    } catch (error) {
      console.error("Erreur lors du chargement de la file d'attente:", error);
      localStorage.setItem("sync_queue", "[]"); // Réinitialiser si corrompue
      return;
    }

    this.syncQueue = queue; // Mettre à jour la version en mémoire

    if (this.syncQueue.length === 0) {
      console.debug("File d'attente de synchronisation vide.");
      return;
    }

    console.log(
      `Traitement de ${this.syncQueue.length} tâche(s) en file d'attente...`
    );
    this.notifySyncListeners("queueProcessingStart", {
      queueLength: this.syncQueue.length,
    });

    const initialQueueLength = this.syncQueue.length;
    let processedCount = 0;
    const remainingQueue = []; // Construire la nouvelle file

    for (const task of this.syncQueue) {
      let success = false;
      try {
        task.attempts = (task.attempts || 0) + 1; // Incrémenter le compteur de tentatives

        // Appeler la fonction push appropriée
        switch (task.type) {
          case "transaction":
            // Utiliser la version simple car elle est plus robuste pour l'instant
            await this.pushSimpleTransactionToServer(task.data);
            success = true;
            break;
          case "client":
            await this.pushClientToServer(task.data);
            success = true;
            break;
          case "appointment":
            await this.pushAppointmentToServer(task.data);
            success = true;
            break;
          case "service":
            await this.pushServiceToServer(task.data);
            success = true;
            break;
          case "stock":
            await this.pushStockItemToServer(task.data);
            success = true;
            break;
          case "user": // Pour la mise à jour de profil par exemple
            await this.updateUserProfile(task.data); // Assurez-vous que cette fonction existe et gère l'envoi
            success = true;
            break;
          // Ajouter d'autres types si nécessaire
          default:
            console.warn(`Type de tâche inconnu dans la file: ${task.type}`);
            success = true; // Marquer comme succès pour la retirer
        }

        console.log(`Tâche ${task.id} (${task.type}) traitée avec succès.`);
        processedCount++;
      } catch (error) {
        console.error(
          `Échec du traitement de la tâche ${task.id} (${task.type}), tentative ${task.attempts}:`,
          error
        );
        // Si l'erreur est due à l'authentification, arrêter le traitement de la file pour l'instant
        if (
          error.message.includes("Session expirée") ||
          error.message.includes("Accès refusé")
        ) {
          console.warn(
            "Erreur d'authentification, arrêt du traitement de la file."
          );
          remainingQueue.push(
            task,
            ...this.syncQueue.slice(this.syncQueue.indexOf(task) + 1)
          ); // Remettre la tâche et les suivantes
          break; // Sortir de la boucle
        }

        // Si le nombre max de tentatives est atteint, abandonner la tâche
        if (task.attempts >= this.maxRetries) {
          console.error(
            `Tâche ${task.id} abandonnée après ${task.attempts} tentatives.`
          );
          this.notifySyncListeners("taskFailed", {
            task,
            error: error.message,
          });
          // Ne pas remettre la tâche dans remainingQueue
        } else {
          // Sinon, la remettre dans la file pour un nouvel essai plus tard
          remainingQueue.push(task);
        }
      }
    } // fin de la boucle for

    // Mettre à jour la file d'attente en mémoire et dans localStorage
    this.syncQueue = remainingQueue;
    try {
      localStorage.setItem("sync_queue", JSON.stringify(this.syncQueue));
    } catch (storageError) {
      console.error(
        "Erreur lors de la sauvegarde de la file d'attente mise à jour:",
        storageError
      );
    }

    console.log(
      `${processedCount} tâche(s) traitée(s) avec succès, ${this.syncQueue.length} restante(s).`
    );
    this.notifySyncListeners("queueProcessingEnd", {
      processed: processedCount,
      remaining: this.syncQueue.length,
      initialSize: initialQueueLength,
    });
  }

  /**
   * Vérifier le statut des files d'attente (pour info)
   */
  checkQueueStatus() {
    try {
      // Note: Les files auth_request_queue et network_request_queue ne sont pas explicitement gérées
      // dans CE fichier mais pourraient l'être dans AuthReconnector ou ailleurs.
      // Ici, on se concentre sur la sync_queue gérée par ce service.
      const syncQueue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      const authQueue = JSON.parse(
        localStorage.getItem("auth_request_queue") || "[]"
      ); // Si gérée ailleurs
      const networkQueue = JSON.parse(
        localStorage.getItem("network_request_queue") || "[]"
      ); // Si gérée ailleurs

      return {
        hasPendingAuthRequests: authQueue.length > 0,
        authQueueLength: authQueue.length,
        hasPendingNetworkRequests: networkQueue.length > 0,
        networkQueueLength: networkQueue.length,
        hasPendingSyncRequests: syncQueue.length > 0,
        syncQueueLength: syncQueue.length,
        hasAnyPendingRequests:
          authQueue.length + networkQueue.length + syncQueue.length > 0,
      };
    } catch (error) {
      console.error(
        "Erreur lors de la vérification des files d'attente:",
        error
      );
      return {
        /* Retourner des valeurs par défaut */
      };
    }
  }

  /**
   * Traiter les requêtes en attente (générique - appelle processQueuedSyncs)
   * Les files auth et network sont supposées être gérées ailleurs (ex: AuthReconnector)
   */
  async processQueuedRequests() {
    console.warn(
      "processQueuedRequests appelé, traitement de sync_queue uniquement par ce service."
    );
    await this.processQueuedSyncs();
    // Si AuthReconnector gère les autres files, il devrait être appelé séparément si nécessaire.
  }

  // --- Fonctions de synchronisation spécifiques (simplifiées pour l'exemple) ---
  // Note: Ces fonctions devraient idéalement gérer la fusion de données (local vs serveur)
  // comme montré dans les exemples précédents, mais pour la concision, on se concentre
  // sur le fetch/push ici.

  async syncClients() {
    console.log("Synchro Clients...");
    // 1. Envoyer les clients locaux en attente (status: 'pending' ou dans la queue)
    await this.processQueuedItemsByType("client");
    // 2. Récupérer les clients récents du serveur
    const serverClients = await this.requeteSecurisee("/api/clients/"); // Ajouter gestion last_sync si besoin
    // 3. Fusionner et sauvegarder localement (logique de merge à implémenter)
    const localClients = JSON.parse(localStorage.getItem("clients") || "[]");
    const mergedClients = this.mergeGenericData(
      localClients,
      serverClients?.results || [],
      "client"
    );
    localStorage.setItem("clients", JSON.stringify(mergedClients));
  }

  async syncPrestations() {
    console.log("Synchro Prestations...");
    await this.processQueuedItemsByType("service");
    const response = await this.requeteSecurisee("/api/services/");
    const localServices = JSON.parse(
      localStorage.getItem("prestations") || "[]"
    );
    const mergedServices = this.mergeGenericData(
      localServices,
      response?.services || [],
      "service"
    ); // Adapter la clé si besoin
    localStorage.setItem("prestations", JSON.stringify(mergedServices));
    // Gérer les catégories aussi
    const categoriesResponse = await this.requeteSecurisee(
      "/api/services/categories/"
    );
    const localCategories = JSON.parse(
      localStorage.getItem("categories") || "[]"
    );
    const mergedCategories = this.mergeGenericData(
      localCategories,
      categoriesResponse?.results || [],
      "category"
    );
    localStorage.setItem("categories", JSON.stringify(mergedCategories));
  }

  async syncStock() {
    console.log("Synchro Stock...");
    await this.processQueuedItemsByType("stock");
    const response = await this.requeteSecurisee("/api/stock/"); // Endpoint pour lister le stock
    const localStock = JSON.parse(localStorage.getItem("stock_items") || "[]");
    const mergedStock = this.mergeGenericData(
      localStock,
      response?.results || [],
      "stock"
    );
    localStorage.setItem("stock_items", JSON.stringify(mergedStock));
  }

  async syncAppointments() {
    console.log("Synchro Rendez-vous...");
    await this.processQueuedItemsByType("appointment");
    const settings = this.getSyncSettings();
    const syncDays = settings.syncAppointmentDays || 90;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - syncDays);
    const response = await this.requeteSecurisee(
      `/api/appointments/?since=${cutoffDate.toISOString()}`
    );
    const localAppointments = JSON.parse(
      localStorage.getItem("appointments") || "[]"
    );
    const mergedAppointments = this.mergeGenericData(
      localAppointments,
      response?.results || [],
      "appointment"
    );
    localStorage.setItem("appointments", JSON.stringify(mergedAppointments));
  }

  async syncTransactions() {
    console.log("Synchro Transactions...");
    // IMPORTANT: Traiter d'abord la file pour envoyer les transactions locales créées hors ligne
    await this.processQueuedItemsByType("transaction");

    // Ensuite, récupérer les transactions récentes du serveur (si nécessaire pour historique)
    const settings = this.getSyncSettings();
    const syncDays = settings.syncTransactionDays || 90;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - syncDays);

    try {
      const response = await this.requeteSecurisee(
        `/api/caisse/transactions/?since=${cutoffDate.toISOString()}`
      );

      if (response && Array.isArray(response)) {
        // L'API renvoie directement un tableau
        const serverTransactions = response;
        const localTransactions = JSON.parse(
          localStorage.getItem("transactions") || "[]"
        );

        // Fusionner: Prioriser les transactions locales qui étaient en attente, puis celles du serveur
        const mergedTransactions = this.mergeTransactionData(
          localTransactions,
          serverTransactions
        ); // Utiliser une logique de fusion spécifique si besoin

        localStorage.setItem(
          "transactions",
          JSON.stringify(mergedTransactions)
        );
        localStorage.setItem("last_tx_sync", new Date().toISOString()); // Mettre à jour la date de synchro Tx
      } else if (response.notFound) {
        console.warn("Endpoint de liste des transactions non trouvé.");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des transactions serveur:",
        error
      );
      // Ne pas écraser les transactions locales en cas d'erreur serveur
    }
  }

  /**
   * Fusionne les données de transaction (exemple simple)
   */
  mergeTransactionData(localTx, serverTx) {
    if (!Array.isArray(localTx)) localTx = [];
    if (!Array.isArray(serverTx)) serverTx = [];

    const mergedMap = new Map();

    // D'abord les locales (elles peuvent avoir un statut 'pending')
    localTx.forEach((tx) => {
      if (tx && tx.id) mergedMap.set(tx.id.toString(), tx);
    });

    // Ensuite les serveur, écrasent si ID existe et local n'est pas pending
    serverTx.forEach((tx) => {
      if (tx && tx.id) {
        const localExisting = mergedMap.get(tx.id.toString());
        if (!localExisting || localExisting.status !== "pending") {
          mergedMap.set(tx.id.toString(), tx);
        }
      }
    });
    return Array.from(mergedMap.values());
  }

  /**
   * Fusionne des données génériques (local vs serveur) en priorisant le serveur
   * si les dates de modification sont identiques ou serveur plus récent.
   */
  mergeGenericData(localData, serverData, type) {
    if (!Array.isArray(localData)) localData = [];
    if (!Array.isArray(serverData)) serverData = [];

    const mergedMap = new Map();

    // Indexer les données locales par ID
    localData.forEach((item) => {
      if (item && item.id) {
        mergedMap.set(item.id.toString(), item);
      }
    });

    // Traiter les données serveur
    serverData.forEach((serverItem) => {
      if (serverItem && serverItem.id) {
        const itemId = serverItem.id.toString();
        const localItem = mergedMap.get(itemId);
        let useServerVersion = true;

        if (localItem) {
          const serverDate = new Date(
            serverItem.updated_at || serverItem.modified || 0
          );
          const localDate = new Date(
            localItem.updated_at || localItem.modified || 0
          );

          if (localDate > serverDate) {
            // La version locale est plus récente, il faut la renvoyer
            console.log(
              `Version locale de ${type} ${itemId} plus récente, mise en file pour envoi.`
            );
            this.pushItemToServerByType(type, localItem); // Tenter d'envoyer la version locale
            useServerVersion = false; // Garder la locale pour l'instant
          } else if (localItem.status === "pending") {
            // Si l'élément local est en attente d'envoi, ne pas l'écraser
            console.log(
              `${type} ${itemId} en attente d'envoi, version locale conservée.`
            );
            useServerVersion = false;
          }
        }

        if (useServerVersion) {
          // Si nouveau ou version serveur >= locale (et locale non pending)
          mergedMap.set(itemId, serverItem);
        }
      }
    });

    return Array.from(mergedMap.values());
  }

  /**
   * Traite spécifiquement les éléments d'un certain type dans la file d'attente.
   * Utile avant de fetch les données de ce type depuis le serveur.
   */
  async processQueuedItemsByType(itemType) {
    // Charger la file d'attente
    let queue = [];
    try {
      queue = JSON.parse(localStorage.getItem("sync_queue") || "[]");
      if (!Array.isArray(queue)) queue = [];
    } catch (error) {
      console.error(
        "Erreur lors du chargement de la file d'attente pour traitement par type:",
        error
      );
      return;
    }

    const itemsToProcess = queue.filter((task) => task.type === itemType);
    if (itemsToProcess.length === 0) return;

    console.log(
      `Traitement de ${itemsToProcess.length} tâche(s) de type '${itemType}' en file d'attente...`
    );

    let remainingQueue = queue.filter((task) => task.type !== itemType); // Garder les autres types
    let processedCount = 0;

    for (const task of itemsToProcess) {
      let success = false;
      try {
        task.attempts = (task.attempts || 0) + 1;
        await this.pushItemToServerByType(task.type, task.data);
        success = true;
        console.log(`Tâche ${task.id} (${task.type}) traitée avec succès.`);
        processedCount++;
      } catch (error) {
        console.error(
          `Échec du traitement de la tâche ${task.id} (${task.type}), tentative ${task.attempts}:`,
          error
        );
        if (task.attempts >= this.maxRetries) {
          console.error(
            `Tâche ${task.id} abandonnée après ${task.attempts} tentatives.`
          );
        } else {
          remainingQueue.push(task); // Remettre dans la file pour réessayer
        }
      }
    }

    // Mettre à jour la file d'attente
    this.syncQueue = remainingQueue;
    try {
      localStorage.setItem("sync_queue", JSON.stringify(this.syncQueue));
    } catch (storageError) {
      console.error(
        "Erreur sauvegarde file après traitement par type:",
        storageError
      );
    }
    console.log(`${processedCount} tâche(s) de type '${itemType}' traitée(s).`);
  }

  /**
   * Helper pour appeler la bonne fonction push en fonction du type.
   */
  async pushItemToServerByType(type, data) {
    switch (type) {
      case "transaction":
        return this.pushSimpleTransactionToServer(data);
      case "client":
        return this.pushClientToServer(data);
      case "appointment":
        return this.pushAppointmentToServer(data);
      case "service":
        return this.pushServiceToServer(data);
      case "stock":
        return this.pushStockItemToServer(data);
      case "user":
        return this.updateUserProfile(data);
      default:
        console.warn(`Type non géré pour push: ${type}`);
        return Promise.resolve();
    }
  }

  // --- Fonctions de push spécifiques ---
  // Ces fonctions encapsulent l'appel API pour chaque type de donnée

  async pushClientToServer(client) {
    if (!client) throw new Error("Client data is required");
    const method = client.server_id ? "PUT" : "POST"; // Utilise server_id si dispo
    const url = client.server_id
      ? `/api/clients/${client.server_id}/`
      : "/api/clients/";
    // Formatter les données pour l'API (ex: first_name, last_name)
    const apiData = {
      first_name: client.prenom || "",
      last_name: client.nom || "",
      email: client.email || "",
      phone: client.telephone || "",
      notes: client.notes || "",
      // Ajouter d'autres champs si nécessaire
    };
    return this.requeteSecurisee(url, {
      method,
      body: JSON.stringify(apiData),
    });
  }

  async pushAppointmentToServer(appointment) {
    if (!appointment) throw new Error("Appointment data is required");
    const method = appointment.server_id ? "PUT" : "POST";
    const url = appointment.server_id
      ? `/api/appointments/${appointment.server_id}/`
      : "/api/appointments/";
    // Assurez-vous que le format correspond à ce que l'API Appointment attend
    const apiData = {
      /* Mappez les champs locaux vers les champs API */
    };
    return this.requeteSecurisee(url, {
      method,
      body: JSON.stringify(appointment),
    }); // Adapter le body si nécessaire
  }

  async pushSimpleTransactionToServer(transaction) {
    if (!transaction) throw new Error("Transaction data is required");
    // Utiliser l'endpoint simple qui est plus flexible
    const url = `/api/caisse/transactions/simple/`;
    // Préparer le payload (sans ID serveur car c'est une création)
    const payload = {
      // Utiliser l'ID local comme référence possible si besoin côté serveur
      // mais le serveur générera son propre ID primaire.
      id_local: transaction.id?.toString(),
      client_info: transaction.client_info || transaction.client, // Adapter les noms si besoin
      items: transaction.items,
      subtotal: transaction.subtotal,
      subtotal_ht: transaction.subtotal_ht || transaction.subtotalHT,
      discount: transaction.discount,
      use_free_sale: transaction.use_free_sale || transaction.useFreeSale,
      free_item_id: transaction.free_item_id || transaction.freeItemId,
      total: transaction.total,
      total_ht: transaction.total_ht || transaction.totalHT,
      payment_method: transaction.payment_method || transaction.paymentMethod,
      type: transaction.type,
      refund_reason: transaction.refund_reason || transaction.refundReason,
      refunded_transaction_local_id: transaction.refunded_transaction, // Envoyer l'ID local de la transaction remboursée
    };
    console.log("Payload envoyé à /simple/:", payload);
    return this.requeteSecurisee(url, {
      method: "POST",
      body: JSON.stringify(payload),
    });
  }

  async pushServiceToServer(service) {
    if (!service) throw new Error("Service data is required");
    const method = service.server_id ? "PUT" : "POST";
    const url = service.server_id
      ? `/api/services/${service.server_id}/`
      : "/api/services/";
    // Formatter les données pour l'API
    const apiData = {
      /* Mappez les champs locaux vers les champs API */
    };
    return this.requeteSecurisee(url, {
      method,
      body: JSON.stringify(apiData),
    });
  }

  async pushStockItemToServer(item) {
    if (!item) throw new Error("Stock item data is required");
    const method = item.server_id ? "PUT" : "POST";
    const url = item.server_id
      ? `/api/stock/${item.server_id}/`
      : "/api/stock/";
    // Formatter les données pour l'API
    const apiData = {
      /* Mappez les champs locaux vers les champs API */
    };
    return this.requeteSecurisee(url, {
      method,
      body: JSON.stringify(apiData),
    });
  }

  async updateUserProfile(userData) {
    if (!userData) throw new Error("User data is required");
    // Endpoint spécifique pour le profil de l'utilisateur connecté
    const url = "/api/users/me/update/"; // Assurez-vous que cet endpoint existe
    return this.requeteSecurisee(url, {
      method: "PUT",
      body: JSON.stringify(userData),
    });
  }

  // --- Synchronisation des paramètres ---
  async syncAgendaSettings() {
    console.log("Synchro Paramètres Agenda...");
    const localSettings = JSON.parse(
      localStorage.getItem("agenda_settings") || "{}"
    );
    try {
      const serverSettings = await this.requeteSecurisee(
        "/api/settings/agenda/"
      ); // Adapter l'URL si elle est différente
      if (serverSettings && !serverSettings.notFound) {
        // Logique de fusion simple : le serveur gagne en cas de conflit pour les settings
        const mergedSettings = { ...localSettings, ...serverSettings };
        localStorage.setItem("agenda_settings", JSON.stringify(mergedSettings));
      }
    } catch (error) {
      console.error("Erreur synchro paramètres agenda:", error);
      // Garder les paramètres locaux en cas d'erreur
    }
  }

  async syncCaisseSettings() {
    console.log("Synchro Paramètres Caisse...");
    const localSettings = JSON.parse(
      localStorage.getItem("caisse_settings") || "{}"
    );
    try {
      const serverSettings = await this.requeteSecurisee(
        "/api/caisse/settings/"
      );
      if (serverSettings && !serverSettings.notFound) {
        const mergedSettings = { ...localSettings, ...serverSettings };
        localStorage.setItem("caisse_settings", JSON.stringify(mergedSettings));
      }
    } catch (error) {
      console.error("Erreur synchro paramètres caisse:", error);
    }
  }

  /**
   * Synchroniser toutes les données (appel principal)
   */
  async syncAllData() {
    if (this.isSyncing) {
      console.log("syncAllData: Synchronisation déjà en cours.");
      return;
    }
    if (!navigator.onLine) {
      console.log("syncAllData: Mode hors ligne, synchronisation annulée.");
      return;
    }

    this.isSyncing = true;
    this.notifySyncListeners("syncStart");
    console.log("Démarrage de la synchronisation complète...");

    const results = { success: [], errors: [] };
    const syncOperations = [
      { name: "clients", func: this.syncClients.bind(this) },
      { name: "prestations", func: this.syncPrestations.bind(this) },
      { name: "stock", func: this.syncStock.bind(this) },
      { name: "appointments", func: this.syncAppointments.bind(this) },
      { name: "transactions", func: this.syncTransactions.bind(this) }, // Va traiter la queue puis fetch
      { name: "agendaSettings", func: this.syncAgendaSettings.bind(this) },
      { name: "caisseSettings", func: this.syncCaisseSettings.bind(this) },
    ];

    for (const op of syncOperations) {
      try {
        await op.func();
        results.success.push(op.name);
      } catch (error) {
        console.error(
          `Erreur lors de la synchronisation de ${op.name}:`,
          error
        );
        results.errors.push({ name: op.name, error: error.message });
      }
    }

    this.isSyncing = false;
    const syncTime = new Date().toISOString();
    this.lastSync = syncTime;
    localStorage.setItem("last_sync", syncTime);

    console.log("Synchronisation complète terminée.", results);
    this.notifySyncListeners("syncComplete", {
      timestamp: syncTime,
      results: results,
    });

    // Si des erreurs persistent après la synchro complète
    if (results.errors.length > 0) {
      this.notifySyncListeners("syncError", { errors: results.errors });
    }

    // Relancer le traitement de la file au cas où de nouvelles tâches seraient arrivées pendant la synchro
    // ou si des erreurs d'authentification ont été résolues.
    await this.processQueuedSyncs();

    return results;
  }

  // --- Fonctions de Backup/Restore (simplifiées) ---
  async createBackup(name = "") {
    /* ... Logique de backup ... */
  }
  async restoreFromBackup(backupId) {
    /* ... Logique de restauration ... */
  }
  async getSyncStatus() {
    /* ... Logique statut ... */
  }
  async resolveConflict(conflictId, resolution) {
    /* ... Logique conflit ... */
  }
} // Fin de la classe DataSyncServiceClass

// Créer et exporter l'instance unique
export const DataSyncService = new DataSyncServiceClass();
export default DataSyncService;
