import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import "./Agenda.css";

export const WeekView = ({
  currentDate,
  settings = {}, // Valeur par défaut si settings est undefined
  appointments,
  onAppointmentClick,
  onTimeSlotClick,
}) => {
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);
  const [selectionStartTime, setSelectionStartTime] = useState(null);
  const [selectionEndTime, setSelectionEndTime] = useState(null);
  const [draggedAppointment, setDraggedAppointment] = useState(null);
  const [dragOverInfo, setDragOverInfo] = useState(null);
  const [selectionDuration, setSelectionDuration] = useState(null);
  const startSlotRef = useRef(null);
  const weekGridRef = useRef(null);

  // Fonction utilitaire pour ajuster une heure "HH:mm:ss" -> "HH:mm"
  const normalizeTime = (timeStr) => timeStr?.slice(0, 5);

  // Fonction pour formater une date en YYYY-MM-DD
  const formatDateForAttribute = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formatted = `${yyyy}-${mm}-${dd}`;
    return formatted;
  };

  // Fonction utilitaire améliorée pour normaliser une date
  const normalizeDateFormat = (dateString) => {
    // Vérification des entrées null, undefined ou vides
    if (!dateString) {
      return "";
    }

    // Si la date est déjà au format YYYY-MM-DD, la retourner directement
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    // Sinon, essayer de la convertir en gérant les erreurs
    try {
      // Vérifier si c'est une date valide
      if (isNaN(Date.parse(dateString))) {
        return "";
      }

      const date = new Date(dateString);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const result = `${yyyy}-${mm}-${dd}`;
      return result;
    } catch (e) {
      return "";
    }
  };

  // Obtenir le premier jour de la semaine (lundi)
  const getFirstDayOfWeek = () => {
    const firstDayOfWeek = new Date(currentDate);
    const day = currentDate.getDay(); // 0 = Dimanche, 1 = Lundi, etc.
    const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1); // Ajuster si c'est dimanche

    firstDayOfWeek.setDate(diff);
    return firstDayOfWeek;
  };

  // Générer les jours de la semaine
  const getWeekDays = () => {
    const premierJourSemaine = getFirstDayOfWeek();
    const joursSemaine = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(premierJourSemaine);
      date.setDate(date.getDate() + i);

      const formattedDate = formatDateForAttribute(date);

      joursSemaine.push({
        date,
        dayName: date.toLocaleDateString("fr-FR", { weekday: "short" }),
        dayNumber: date.getDate(),
        month: date.toLocaleDateString("fr-FR", { month: "short" }),
        isToday: isToday(date),
        isWeekend: date.getDay() === 0 || date.getDay() === 6,
        // Gestion sécurisée de closedDays
        isClosed:
          settings && settings.closedDays
            ? settings.closedDays.includes(date.getDay())
            : false,
        formattedDate: formattedDate,
      });
    }

    return joursSemaine;
  };

  // Vérifier si une date est aujourd'hui
  const isToday = (date) => {
    const today = new Date();
    const result =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    return result;
  };

  // Vérifier si un créneau horaire est dans une période de fermeture pour un jour spécifique
  const isClosedTimeSlot = (dayIndex, timeSlot) => {
    const weekDays = getWeekDays();
    const day = weekDays[dayIndex];

    // Si le jour est entièrement fermé
    if (day.isClosed) {
      return true;
    }

    // S'assurer que settings existe
    if (!settings) {
      return false;
    }

    const dayOfWeek = day.date.getDay().toString(); // Convertir en chaîne
    const closingHours = settings.closingHours || {};
    const dayClosingHours = closingHours[dayOfWeek] || [];

    if (dayClosingHours.length === 0) {
      return false;
    }

    // Convertir le créneau horaire en minutes pour faciliter la comparaison
    const [hours, minutes] = timeSlot.split(":").map(Number);
    const timeInMinutes = hours * 60 + minutes;

    // Vérifier si le créneau est dans une plage de fermeture
    const isClosed = dayClosingHours.some((range) => {
      if (!range || !range.start || !range.end) return false;

      const [startHours, startMinutes] = range.start.split(":").map(Number);
      const [endHours, endMinutes] = range.end.split(":").map(Number);

      const startInMinutes = startHours * 60 + startMinutes;
      const endInMinutes = endHours * 60 + endMinutes;

      return timeInMinutes >= startInMinutes && timeInMinutes < endInMinutes;
    });

    return isClosed;
  };

  // Vérifier si l'heure actuelle est visible et dans la semaine affichée
  const isCurrentTimeVisible = () => {
    const now = new Date();
    const currentHour = now.getHours();

    // Vérifier si l'heure actuelle est dans la plage d'heures affichée
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut

    if (currentHour < startHour || currentHour > endHour) {
      return false;
    }

    // Vérifier si aujourd'hui est dans la semaine affichée
    const firstDayOfWeek = getFirstDayOfWeek();
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    return now >= firstDayOfWeek && now <= lastDayOfWeek;
  };

  // Fonction pour convertir un créneau horaire en minutes depuis minuit
  const timeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Fonction pour convertir des minutes depuis minuit en créneau horaire
  const minutesToTime = (minutes) => {
    const heures = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${heures.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  // Générer les créneaux horaires - avec valeurs par défaut
  const generateTimeSlots = (step = settings.timeStep || 30) => {
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut
    const slotsPerHour = 60 / step;

    const creneaux = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let slot = 0; slot < slotsPerHour; slot++) {
        const minutes = slot * step;
        const timeString = `${hour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        creneaux.push(timeString);
      }
    }

    return creneaux;
  };

  // Obtenir les créneaux horaires avec un pas plus fin pour la sélection
  const getSelectionTimeSlots = () => {
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut
    const slotsPerHour = 12; // 60 / 5 = 12 créneaux par heure, pour assurer un pas de 5 minutes

    const creneauxSelection = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let slot = 0; slot < slotsPerHour; slot++) {
        const minutes = slot * 5; // Pas fixe de 5 minutes
        const timeString = `${hour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        creneauxSelection.push(timeString);
      }
    }

    return creneauxSelection;
  };

  // Mise en cache des créneaux horaires
  const timeSlots = useMemo(
    () => generateTimeSlots(),
    [settings.startHour, settings.endHour, settings.timeStep]
  );

  const selectionTimeSlots = useMemo(
    () => getSelectionTimeSlots(),
    [settings.startHour, settings.endHour]
  );

  // Création d'une Map pour accélérer l'accès aux créneaux horaires
  const createTimeSlotMap = useCallback(() => {
    const mapCreneaux = new Map();
    let slotMinutes;

    for (const slot of selectionTimeSlots) {
      slotMinutes = timeToMinutes(slot);
      mapCreneaux.set(slotMinutes, slot);
    }

    return mapCreneaux;
  }, [selectionTimeSlots]);

  const timeSlotMap = useMemo(() => createTimeSlotMap(), [createTimeSlotMap]);

  // Optimisation pour la vérification des créneaux sélectionnés
  const selectedSlotsMap = useMemo(() => {
    const mapSelections = new Map();
    for (const slot of selectedSlots) {
      mapSelections.set(`${slot.dayIndex}-${slot.timeSlot}`, true);
    }
    return mapSelections;
  }, [selectedSlots]);

  const isSlotSelected = useCallback(
    (dayIndex, timeSlot) => {
      return selectedSlotsMap.has(`${dayIndex}-${timeSlot}`);
    },
    [selectedSlotsMap]
  );

  // Cache des rendez-vous indexés par date
  const appointmentsByDate = useMemo(() => {
    const cache = {};

    if (appointments && appointments.length > 0) {
      // Récupérer les jours de la semaine pour validation
      const weekDaysArray = getWeekDays();
      const weekDayDates = weekDaysArray.map((day) => day.formattedDate);

      // Prétraiter tous les rendez-vous
      appointments.forEach((appointment) => {
        // S'assurer que la date est normalisée correctement
        const normalizedDate = normalizeDateFormat(appointment.date || "");
        if (!normalizedDate) {
          return;
        }

        // Ajouter au cache même si pas dans la semaine (utile pour débogage)
        if (!cache[normalizedDate]) {
          cache[normalizedDate] = [];
        }

        // Ajouter une copie pour éviter des références croisées
        cache[normalizedDate].push({ ...appointment });
      });

      // Vérifier que tous les jours de la semaine sont dans le cache (même vides)
      weekDayDates.forEach((date) => {
        if (!cache[date]) {
          cache[date] = [];
        }
      });
    }

    return cache;
  }, [appointments]);

  // Gestionnaire pour commencer la sélection
  const handleMouseDown = (e, dayIndex, timeSlot) => {
    e.preventDefault();

    // Vérifier si on clique sur un rendez-vous ou si le créneau est fermé
    if (
      e.target.closest(".appointment") ||
      isClosedTimeSlot(dayIndex, timeSlot)
    ) {
      return;
    }

    setIsSelecting(true);
    startSlotRef.current = { dayIndex, timeSlot };
    setCurrentDay(dayIndex);
    setSelectionStartTime(timeSlot);
    setSelectionEndTime(timeSlot);
    setSelectedSlots([{ dayIndex, timeSlot }]);
    setSelectionDuration(5); // Durée initiale de 5 minutes
  };

  // Gestionnaire pour continuer la sélection
  const handleMouseOver = useCallback(
    (dayIndex, timeSlot) => {
      if (!isSelecting || !startSlotRef.current || dayIndex !== currentDay) {
        return;
      }

      // Si le créneau est fermé, ne pas le sélectionner
      if (isClosedTimeSlot(dayIndex, timeSlot)) {
        return;
      }

      // Mettre à jour l'heure de fin de la sélection
      setSelectionEndTime(timeSlot);

      // Calculer tous les créneaux entre le début et la fin
      const startMinutes = timeToMinutes(startSlotRef.current.timeSlot);
      const currentMinutes = timeToMinutes(timeSlot);

      const minMinutes = Math.min(startMinutes, currentMinutes);
      const maxMinutes = Math.max(startMinutes, currentMinutes);

      // Optimisation: utiliser de simples boucles pour créer les sélections
      const filteredSlots = [];
      let duration = 0;

      // Parcourir toutes les minutes par pas de 5
      for (let min = minMinutes; min <= maxMinutes; min += 5) {
        const slot = timeSlotMap.get(min);
        if (slot && !isClosedTimeSlot(dayIndex, slot)) {
          filteredSlots.push({
            dayIndex,
            timeSlot: slot,
          });
          duration += 5;
        }
      }

      setSelectionDuration(duration);
      setSelectedSlots(filteredSlots);
    },
    [isSelecting, currentDay, timeSlotMap]
  );

  // Gestionnaire pour terminer la sélection
  const handleMouseUp = () => {
    if (!isSelecting || selectedSlots.length === 0) {
      return;
    }

    setIsSelecting(false);
    setSelectionDuration(null);

    // Si des slots sont sélectionnés, ouvrir le modal de rendez-vous
    if (selectedSlots.length > 0) {
      const weekDays = getWeekDays();
      const dayIndex = selectedSlots[0].dayIndex;
      const date = weekDays[dayIndex].formattedDate;
      const startTime = selectedSlots[0].timeSlot;

      // Ajustement pour avoir un incrément exact de 5 minutes
      const startMinutes = timeToMinutes(startTime);
      const lastSlotTime = selectedSlots[selectedSlots.length - 1].timeSlot;
      const endMinutes = timeToMinutes(lastSlotTime) + 5; // Ajouter 5 minutes pour la fin

      // Arrondir à 5 minutes près
      const roundedDuration = Math.ceil((endMinutes - startMinutes) / 5) * 5;

      onTimeSlotClick(date, startTime, roundedDuration);

      // Effacer la sélection
      setSelectedSlots([]);
      setSelectionStartTime(null);
      setSelectionEndTime(null);
    }

    startSlotRef.current = null;
    setCurrentDay(null);
  };

  // Effet pour nettoyer la sélection en cas de clic en dehors de la grille
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isSelecting) {
        setIsSelecting(false);
        setSelectedSlots([]);
        startSlotRef.current = null;
        setCurrentDay(null);
        setSelectionStartTime(null);
        setSelectionEndTime(null);
        setSelectionDuration(null);
      }

      // Réinitialiser l'état de glisser-déposer
      if (draggedAppointment) {
        setDraggedAppointment(null);
        setDragOverInfo(null);
      }
    };

    document.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [isSelecting, draggedAppointment]);

  // Filtrer les rendez-vous pour la semaine
  const getWeekAppointments = () => {
    const weekDays = getWeekDays();
    let allWeekAppointments = [];

    for (const day of weekDays) {
      // Utiliser le cache pour récupérer les rendez-vous
      const dayAppointments = appointmentsByDate[day.formattedDate] || [];
      allWeekAppointments = [...allWeekAppointments, ...dayAppointments];
    }

    return allWeekAppointments;
  };

  // Obtenir les rendez-vous pour un jour et une heure spécifiques
  const getAppointmentsForSlot = (dayIndex, timeSlot) => {
    // Vérifier les index invalides
    if (dayIndex < 0 || dayIndex >= getWeekDays().length) {
      return [];
    }

    const weekDays = getWeekDays();
    const formattedDate = weekDays[dayIndex].formattedDate;

    // Vérification explicite dans le cache
    if (!appointmentsByDate[formattedDate]) {
      return [];
    }

    // Utiliser le cache pour récupérer les rendez-vous du jour
    const dayAppointments = appointmentsByDate[formattedDate];

    if (dayAppointments.length === 0) {
      return [];
    }

    // Filtrer tous les rendez-vous qui chevauchent ce créneau horaire avec normalizeTime
    const overlappingAppointments = dayAppointments.filter((appointment) => {
      // S'assurer que les propriétés start et duration existent
      if (!appointment.start || !appointment.duration) {
        return false;
      }

      const appointmentStart = normalizeTime(appointment.start);

      // Vérifier si ce rendez-vous commence à ce créneau
      if (appointmentStart === timeSlot) {
        return true;
      }

      // Sinon, vérifier si ce rendez-vous est en cours à ce créneau
      const appointmentStartTime = timeToMinutes(appointmentStart);
      const appointmentEndTime =
        appointmentStartTime + parseInt(appointment.duration);
      const slotTime = timeToMinutes(timeSlot);

      // Si le créneau est après le début et avant la fin du rendez-vous
      const overlaps =
        slotTime > appointmentStartTime && slotTime < appointmentEndTime;

      return overlaps;
    });

    // Si aucun rendez-vous ne chevauche ce créneau, retourner un tableau vide
    if (overlappingAppointments.length === 0) {
      return [];
    }

    // Si aucun ou un seul rendez-vous commence à ce créneau spécifique, vérifier s'il faut le montrer
    const appsStartingAtTimeSlot = overlappingAppointments.filter(
      (app) => normalizeTime(app.start) === timeSlot
    );

    // S'il n'y a pas de rendez-vous commençant à ce créneau, nous ne montrons rien
    if (appsStartingAtTimeSlot.length === 0) {
      return [];
    }

    // Déterminer les groupes de chevauchement pour tous les rendez-vous du jour
    const overlapGroups = [];
    let currentGroup = [];

    // Trier les rendez-vous par heure de début avec normalizeTime
    const sortedAppointments = [...dayAppointments].sort(
      (a, b) =>
        timeToMinutes(normalizeTime(a.start)) -
        timeToMinutes(normalizeTime(b.start))
    );

    // Algorithme pour déterminer les groupes qui se chevauchent
    for (const appointment of sortedAppointments) {
      const startTime = timeToMinutes(normalizeTime(appointment.start));
      const endTime = startTime + parseInt(appointment.duration);

      // Vérifier si ce rendez-vous chevauche un rendez-vous du groupe actuel
      const overlapsWithCurrentGroup = currentGroup.some((app) => {
        const groupAppStartTime = timeToMinutes(normalizeTime(app.start));
        const groupAppEndTime = groupAppStartTime + parseInt(app.duration);
        return startTime < groupAppEndTime && endTime > groupAppStartTime;
      });

      if (overlapsWithCurrentGroup) {
        // Ajouter au groupe actuel s'il y a chevauchement
        currentGroup.push(appointment);
      } else {
        // Si le groupe actuel a des rendez-vous, l'ajouter à la liste des groupes
        if (currentGroup.length > 0) {
          overlapGroups.push([...currentGroup]);
        }
        // Commencer un nouveau groupe avec ce rendez-vous
        currentGroup = [appointment];
      }
    }

    // Ajouter le dernier groupe s'il n'est pas vide
    if (currentGroup.length > 0) {
      overlapGroups.push(currentGroup);
    }

    // Trouver à quel groupe appartiennent les rendez-vous qui commencent à ce créneau
    let appointmentGroup = [];
    let totalInGroup = 1;

    for (const group of overlapGroups) {
      // Vérifier si des rendez-vous de ce créneau sont dans ce groupe
      const found = appsStartingAtTimeSlot.some((app) =>
        group.some((groupApp) => groupApp.id === app.id)
      );
      if (found) {
        appointmentGroup = group;
        totalInGroup = group.length;
        break;
      }
    }

    // Ajouter des informations sur la position pour chaque rendez-vous qui commence à ce créneau
    const appointments_with_overlap = appsStartingAtTimeSlot.map((app) => {
      // Trouver la position de ce rendez-vous dans son groupe
      const position = appointmentGroup.findIndex(
        (groupApp) => groupApp.id === app.id
      );

      return {
        ...app,
        overlap: {
          position: position, // Position de ce rendez-vous dans le groupe (0, 1, 2, etc.)
          total: totalInGroup, // Nombre total de rendez-vous dans le groupe
        },
      };
    });

    return appointments_with_overlap;
  };

  // Calculer la position de l'indicateur d'heure actuelle
  const calculateTimeIndicatorPosition = () => {
    if (!isCurrentTimeVisible()) {
      return null;
    }

    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut

    if (currentHour >= startHour && currentHour <= endHour) {
      const minutesSinceStart = (currentHour - startHour) * 60 + currentMinute;
      const totalMinutes = (endHour - startHour) * 60;
      const percentage = (minutesSinceStart / totalMinutes) * 100;
      return `${percentage}%`;
    }

    return null;
  };

  // Obtenir le jour de la semaine actuel (0-6, où 0 est lundi dans notre affichage)
  const getCurrentDayIndex = () => {
    const today = new Date();
    const day = today.getDay(); // 0 = Dimanche, 1 = Lundi, etc.
    const index = day === 0 ? 6 : day - 1; // Convertir en 0 = Lundi, 6 = Dimanche
    return index;
  };

  // Gestion du glisser-déposer pour les rendez-vous
  const handleDragStart = (e, appointment) => {
    setDraggedAppointment(appointment);

    // Ajouter une classe pour le style
    if (e.target.classList) {
      e.target.classList.add("appointment-dragging");
    }

    // Définir les données de transfert pour le drag & drop HTML5
    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", appointment.id);
    }
  };

  const handleDragOver = (e, dayIndex, timeSlot) => {
    e.preventDefault();

    if (!draggedAppointment) return;

    // Mettre à jour l'information de survol
    setDragOverInfo({ dayIndex, timeSlot });
  };

  const handleDrop = (e, dayIndex, timeSlot) => {
    e.preventDefault();

    if (!draggedAppointment) {
      return;
    }

    // Obtenir la date à partir de l'index du jour
    const weekDays = getWeekDays();
    const newDate = weekDays[dayIndex].formattedDate;

    // Mettre à jour l'heure de début du rendez-vous
    const updatedAppointment = {
      ...draggedAppointment,
      date: newDate,
      start: timeSlot,
    };

    // Éditer le rendez-vous (ouvre la modal pour confirmer)
    onAppointmentClick(draggedAppointment.id, updatedAppointment);

    // Réinitialiser l'état
    setDraggedAppointment(null);
    setDragOverInfo(null);
  };

  const handleDragEnd = (e) => {
    // Nettoyer les styles
    if (e.target.classList) {
      e.target.classList.remove("appointment-dragging");
    }

    setDraggedAppointment(null);
    setDragOverInfo(null);
  };

  // Préchargement des rendez-vous de la semaine
  const preloadedAppointments = useMemo(() => {
    return getWeekAppointments();
  }, [appointments]);

  // Affichage des créneaux horaires et des jours
  const weekDays = getWeekDays();
  const currentTimePosition = calculateTimeIndicatorPosition();
  const timeStep = settings?.timeStep || 30; // Valeur par défaut pour timeStep

  return (
    <div id="week-view" className="calendar-view active" ref={weekGridRef}>
      <div className="week-container">
        {/* En-tête avec les jours de la semaine - reste fixe */}
        <div className="week-header">
          <div className="time-header-cell"></div>
          {weekDays.map((day, index) => (
            <div
              key={`header-${index}`}
              className={`day-header-cell ${day.isToday ? "today" : ""} ${
                day.isWeekend ? "weekend" : ""
              } ${day.isClosed ? "closed-day" : ""}`}
            >
              <div className="day-name">{day.dayName}</div>
              <div className="day-date">{day.dayNumber}</div>
              <div className="day-month">{day.month}</div>
            </div>
          ))}
        </div>

        {/* Grille avec défilement */}
        <div className="week-grid">
          {/* Colonne des heures */}
          <div className="time-column">
            {timeSlots.map((time, index) => {
              // N'afficher que les heures complètes et les demi-heures pour plus de clarté
              const [_, minute] = time.split(":").map(Number);
              if (minute === 0 || (minute === 30 && timeStep <= 30)) {
                return (
                  <div className="time-cell" key={`time-${index}`}>
                    <span>{time}</span>
                  </div>
                );
              }
              return (
                <div className="time-cell empty" key={`time-${index}`}></div>
              );
            })}
          </div>

          {/* Colonnes des jours */}
          {weekDays.map((day, dayIndex) => {
            // Compter les rendez-vous pour ce jour
            const dayFormattedDate = day.formattedDate;
            const dayAppointments = appointmentsByDate[dayFormattedDate] || [];
            const dayAppointmentsCount = dayAppointments.length;

            return (
              <div
                key={`day-column-${dayIndex}`}
                className={`day-column ${day.isToday ? "today" : ""} ${
                  day.isWeekend ? "weekend" : ""
                } ${day.isClosed ? "closed-day" : ""}`}
                data-day-index={dayIndex}
                data-date={dayFormattedDate}
              >
                {timeSlots.map((timeSlot, timeIndex) => {
                  // Obtenir les rendez-vous pour ce créneau horaire
                  const appointmentsForSlot = getAppointmentsForSlot(
                    dayIndex,
                    timeSlot
                  );
                  const hasAppointments = appointmentsForSlot.length > 0;

                  return (
                    <div
                      key={`slot-${dayIndex}-${timeIndex}`}
                      className={`time-cell
                        ${isSlotSelected(dayIndex, timeSlot) ? "selected" : ""}
                        ${
                          isSelecting && isSlotSelected(dayIndex, timeSlot)
                            ? "selecting"
                            : ""
                        }
                        ${
                          dragOverInfo &&
                          dragOverInfo.dayIndex === dayIndex &&
                          dragOverInfo.timeSlot === timeSlot
                            ? "drag-over"
                            : ""
                        }
                        ${
                          isClosedTimeSlot(dayIndex, timeSlot)
                            ? "closed-time"
                            : ""
                        }
                        ${hasAppointments ? "has-appointments" : ""}
                      `}
                      data-day={dayIndex}
                      data-time={timeSlot}
                      onMouseDown={(e) =>
                        !day.isClosed && handleMouseDown(e, dayIndex, timeSlot)
                      }
                      onMouseOver={() =>
                        !day.isClosed && handleMouseOver(dayIndex, timeSlot)
                      }
                      onMouseUp={handleMouseUp}
                      onDragOver={(e) =>
                        !day.isClosed && handleDragOver(e, dayIndex, timeSlot)
                      }
                      onDrop={(e) =>
                        !day.isClosed && handleDrop(e, dayIndex, timeSlot)
                      }
                    >
                      {isClosedTimeSlot(dayIndex, timeSlot) &&
                        !day.isClosed && (
                          <div className="closed-indicator">Fermé</div>
                        )}

                      {appointmentsForSlot.map((rendezvous) => {
                        const couleurBordure = rendezvous.color
                          ? adjustColor(rendezvous.color, -20)
                          : "#b7a382";

                        // Calcul de la largeur et de la position si le rendez-vous fait partie d'un groupe qui se chevauche
                        const aChevauchement =
                          rendezvous.overlap && rendezvous.overlap.total > 1;
                        // Réduire légèrement la largeur pour laisser un petit espace entre les rendez-vous
                        const pourcentageLargeur = aChevauchement
                          ? 100 / rendezvous.overlap.total - 1
                          : 95;
                        const largeur = `${pourcentageLargeur}%`;
                        const position = aChevauchement
                          ? `${
                              (rendezvous.overlap.position * 100) /
                              rendezvous.overlap.total
                            }%`
                          : "2.5%";

                        // Calcul de la hauteur en pixels
                        const hauteurEnPixels = Math.max(
                          20,
                          (parseInt(rendezvous.duration) / timeStep) *
                            (60 / (60 / timeStep))
                        );

                        return (
                          <div
                            key={rendezvous.id}
                            className="appointment"
                            data-appointment-id={rendezvous.id}
                            data-client={rendezvous.client_name}
                            data-day={dayIndex}
                            data-time={timeSlot}
                            style={{
                              position: "absolute !important",
                              display: "block !important",
                              visibility: "visible !important",
                              opacity: "1 !important",
                              height: `${hauteurEnPixels}px`,
                              minHeight: "50px", // Hauteur minimale augmentée pour voir le nom et la prestation
                              backgroundColor: rendezvous.color || "#c6b291",
                              width: largeur,
                              left: position,
                              zIndex: aChevauchement
                                ? 10 + rendezvous.overlap.position
                                : 999,
                              borderLeft: `3px solid ${couleurBordure}`,
                              color: "white !important",
                              padding: "5px 8px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              cursor: "pointer",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              boxSizing: "border-box",
                              whiteSpace: "nowrap",
                              top: 0,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onAppointmentClick(rendezvous.id);
                            }}
                            draggable={true}
                            onDragStart={(e) => handleDragStart(e, rendezvous)}
                            onDragEnd={handleDragEnd}
                          >
                            <div className="appointment-time">
                              {normalizeTime(rendezvous.start)}
                            </div>
                            <div className="appointment-client">
                              <strong>
                                {rendezvous.client_name || "Client"}
                              </strong>
                            </div>
                            <div className="appointment-type">
                              {rendezvous.type || "Sans prestation"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                {/* Indicateur d'heure actuelle */}
                {day.isToday && currentTimePosition && (
                  <div
                    className="current-time-indicator"
                    style={{ top: currentTimePosition }}
                  />
                )}

                {/* Indicateur de durée de sélection */}
                {isSelecting &&
                  currentDay === dayIndex &&
                  selectionDuration && (
                    <div className="selection-duration">
                      {selectionDuration} min
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Fonction utilitaire pour ajuster la couleur (assombrir ou éclaircir)
function adjustColor(couleur, montant) {
  return (
    "#" +
    couleur.replace(/^#/, "").replace(/../g, (couleur) => {
      const valeurCouleur = parseInt(couleur, 16);
      const nouvelleValeur = Math.max(
        Math.min(valeurCouleur + montant, 255),
        0
      );
      return nouvelleValeur.toString(16).padStart(2, "0");
    })
  );
}
