import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

export const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  // Écran de chargement
  if (loading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Chargement en cours...</p>
      </div>
    );
  }

  // Vérification de l'authentification
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Vérification des droits administrateur si nécessaire
  if (requireAdmin && user.role !== 'admin') {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

// Page non autorisée
export const UnauthorizedPage = () => {
  const { logout } = useAuth();

  return (
    <div className="unauthorized-page">
      <h1>Accès non autorisé</h1>
      <p>Vous n'avez pas les permissions nécessaires pour accéder à cette page.</p>
      <button onClick={logout}>Se déconnecter</button>
    </div>
  );
};