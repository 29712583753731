import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import DataSyncService from '../../services/DataSyncService';
import './UserProfile.css';

export const UserProfile = ({ onClose }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    companyName: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setFormData(prevState => ({
        ...prevState,
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        birthDate: currentUser.birthDate || '',
        companyName: currentUser.companyName || '',
        email: currentUser.email || ''
      }));
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setError('');
    setSuccess('');
  };

  const validateForm = () => {
    // Vérification des champs obligatoires
    if (!formData.firstName || !formData.lastName || !formData.birthDate || !formData.email || !formData.companyName) {
      setError("Tous les champs sont obligatoires");
      return false;
    }

    // Validation du format de date JJ/MM/AAAA
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!dateRegex.test(formData.birthDate)) {
      setError('La date de naissance doit être au format JJ/MM/AAAA');
      return false;
    }

    // Vérification du format de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Format d'email invalide");
      return false;
    }

    // Si l'utilisateur souhaite changer son mot de passe
    if (formData.newPassword || formData.confirmPassword || formData.currentPassword) {
      // Tous les champs de mot de passe doivent être remplis
      if (!formData.currentPassword || !formData.newPassword || !formData.confirmPassword) {
        setError("Tous les champs de mot de passe sont obligatoires");
        return false;
      }

      // Les nouveaux mots de passe doivent correspondre
      if (formData.newPassword !== formData.confirmPassword) {
        setError("Les nouveaux mots de passe ne correspondent pas");
        return false;
      }

      // Le nouveau mot de passe doit être différent de l'ancien
      if (formData.newPassword === formData.currentPassword) {
        setError("Le nouveau mot de passe doit être différent de l'ancien");
        return false;
      }

      // Le nouveau mot de passe doit avoir au moins 6 caractères
      if (formData.newPassword.length < 6) {
        setError("Le nouveau mot de passe doit contenir au moins 6 caractères");
        return false;
      }

      // Vérifier que le mot de passe actuel est correct
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      const user = users.find(u => u.id === currentUser.id);
      
      if (!user || user.password !== formData.currentPassword) {
        setError("Le mot de passe actuel est incorrect");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      // Récupérer les utilisateurs depuis le localStorage
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      
      // Trouver l'utilisateur actuel
      const userIndex = users.findIndex(u => u.id === currentUser.id);
      
      if (userIndex === -1) {
        throw new Error("Utilisateur non trouvé");
      }

      // Préparer les données utilisateur mises à jour
      const updatedUser = {
        ...users[userIndex],
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: `${formData.firstName} ${formData.lastName}`,
        birthDate: formData.birthDate,
        companyName: formData.companyName,
        email: formData.email
      };

      // Mise à jour du mot de passe si nécessaire
      if (formData.newPassword) {
        updatedUser.password = formData.newPassword;
      }

      // Tenter de synchroniser avec le serveur
      try {
        await DataSyncService.updateUserProfile(updatedUser);
      } catch (syncError) {
        console.warn('Synchronisation du profil échouée:', syncError);
        // Continuer avec la mise à jour locale
      }

      // Mettre à jour l'utilisateur dans la liste locale
      users[userIndex] = updatedUser;
      localStorage.setItem('users', JSON.stringify(users));
      
      // Mettre à jour l'utilisateur courant dans le localStorage
      const updatedCurrentUser = {
        id: currentUser.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: `${formData.firstName} ${formData.lastName}`,
        birthDate: formData.birthDate,
        companyName: formData.companyName,
        email: formData.email,
        role: currentUser.role
      };
      localStorage.setItem('currentUser', JSON.stringify(updatedCurrentUser));
      
      // Réinitialiser les champs de mot de passe
      setFormData(prevState => ({
        ...prevState,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
      
      // Afficher un message de succès
      setSuccess("Profil mis à jour avec succès");
      
      // Fermer le modal après un délai
      setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 2000);
    } catch (submitError) {
      setError(submitError.message || "Erreur lors de la mise à jour du profil");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-profile-container">
      <h2>Mon Profil</h2>
      
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">Prénom</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Votre prénom"
              required
              disabled={isLoading}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="lastName">Nom</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Votre nom"
              required
              disabled={isLoading}
            />
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="birthDate">Date de naissance</label>
          <input
            type="text"
            id="birthDate"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleInputChange}
            placeholder="JJ/MM/AAAA"
            required
            disabled={isLoading}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="companyName">Nom de l'entreprise</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            placeholder="Nom de votre entreprise"
            required
            disabled={isLoading}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Votre email"
            required
            disabled={isLoading}
          />
        </div>
        
        <div className="password-section">
          <h3>Changer le mot de passe</h3>
          <p className="password-info">Remplissez ces champs uniquement si vous souhaitez changer votre mot de passe</p>
          
          <div className="form-group">
            <label htmlFor="currentPassword">Mot de passe actuel</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleInputChange}
              placeholder="Votre mot de passe actuel"
              disabled={isLoading}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="newPassword">Nouveau mot de passe</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
              placeholder="Nouveau mot de passe"
              disabled={isLoading}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmer le nouveau mot de passe</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirmer le nouveau mot de passe"
              disabled={isLoading}
            />
          </div>
        </div>
        
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        <div className="form-actions">
          {onClose && (
            <button 
              type="button" 
              className="cancel-btn" 
              onClick={onClose}
              disabled={isLoading}
            >
              Annuler
            </button>
          )}
          <button 
            type="submit" 
            className="submit-btn"
            disabled={isLoading}
          >
            {isLoading ? 'Enregistrement...' : 'Enregistrer les modifications'}
          </button>
        </div>
      </form>
    </div>
  );
};