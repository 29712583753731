import React, { useState } from 'react';
import * as XLSX from 'xlsx';

export const ClientImportExport = ({ clients, onClose, onImport }) => {
  const [importFormat, setImportFormat] = useState('csv');
  const [exportFormat, setExportFormat] = useState('csv');
  const [importStatus, setImportStatus] = useState('');
  const [exportStatus, setExportStatus] = useState('');
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  // Gérer l'import de fichier
  const handleFileImport = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImportStatus('loading');

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const fileContent = event.target.result;
        let importedData;

        // Traiter selon le format
        if (importFormat === 'csv') {
          importedData = parseCSV(fileContent);
        } else if (importFormat === 'json') {
          importedData = JSON.parse(fileContent);
        } else if (importFormat === 'xlsx') {
          // Traitement des fichiers Excel
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          importedData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
        }

        if (validateImportedData(importedData)) {
          onImport(importedData);
          setImportStatus('success');
          // Réinitialiser l'input file
          setFileInputKey(Date.now());
        } else {
          setImportStatus('error');
          alert('Le format des données importées est incorrect.');
        }
      } catch (e) {
        console.error('Erreur lors de l\'import:', e);
        setImportStatus('error');
        alert('Erreur lors de l\'import: ' + e.message);
      }
    };

    reader.onerror = () => {
      setImportStatus('error');
      alert('Erreur lors de la lecture du fichier');
    };

    if (importFormat === 'xlsx') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
  };

  // Parser un fichier CSV
  const parseCSV = (csvText) => {
    // Fonction de parsing CSV simple
    // Dans un cas réel, utilisez une bibliothèque comme PapaParse
    const lines = csvText.split('\n');
    const headers = lines[0].split(',').map(h => h.trim());
    
    return lines.slice(1).filter(line => line.trim()).map(line => {
      const values = line.split(',').map(v => v.trim());
      const obj = {};
      
      headers.forEach((header, index) => {
        try {
          // Tenter de parser les valeurs numériques et booléennes
          if (values[index] === 'true') obj[header] = true;
          else if (values[index] === 'false') obj[header] = false;
          else if (!isNaN(values[index]) && values[index] !== '') obj[header] = Number(values[index]);
          else obj[header] = values[index];
        } catch (e) {
          obj[header] = values[index];
        }
      });
      
      return obj;
    });
  };

  // Valider les données importées
  const validateImportedData = (data) => {
    if (!Array.isArray(data)) return false;
    
    // Vérifier que chaque élément a au moins les champs requis
    return data.every(item => 
      typeof item === 'object' && 
      item !== null && 
      (item.nom || item.prenom)
    );
  };

  // Préparer les données pour l'export
  const prepareDataForExport = () => {
    // Adapter les données pour l'export
    return clients.map(client => {
      // Convertir les réseaux sociaux en format plat pour CSV & Excel
      const flattenedClient = { ...client };
      
      if (exportFormat !== 'json' && client.socialNetworks && Array.isArray(client.socialNetworks)) {
        client.socialNetworks.forEach(network => {
          if (network.type && network.value) {
            flattenedClient[`social_${network.type}`] = network.value;
          }
        });
        delete flattenedClient.socialNetworks;
      }
      
      return flattenedClient;
    });
  };

  // Exporter les données
  const handleExport = () => {
    try {
      const dataToExport = prepareDataForExport();
      let filename;
      
      if (exportFormat === 'csv') {
        // Exporter en CSV
        const csvData = convertToCSV(dataToExport);
        const blob = new Blob([csvData], { type: 'text/csv' });
        filename = `clients_${formatDate(new Date())}.csv`;
        downloadBlob(blob, filename);
      } else if (exportFormat === 'json') {
        // Exporter en JSON
        const jsonData = JSON.stringify(dataToExport, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        filename = `clients_${formatDate(new Date())}.json`;
        downloadBlob(blob, filename);
      } else if (exportFormat === 'xlsx') {
        // Exporter en XLSX avec SheetJS
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");
        
        // Générer le fichier XLSX
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        filename = `clients_${formatDate(new Date())}.xlsx`;
        downloadBlob(blob, filename);
      }
      
      setExportStatus('success');
    } catch (e) {
      console.error('Erreur lors de l\'export:', e);
      setExportStatus('error');
      alert('Erreur lors de l\'export: ' + e.message);
    }
  };

  // Télécharger un blob
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Convertir un array d'objets en CSV
  const convertToCSV = (objArray) => {
    // Simple conversion en CSV
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    
    // Récupérer tous les en-têtes possibles
    const headers = new Set();
    array.forEach(obj => {
      Object.keys(obj).forEach(key => headers.add(key));
    });
    
    const headerRow = Array.from(headers).join(',');
    const rows = array.map(obj => {
      return Array.from(headers).map(key => {
        // Échapper les virgules et les guillemets
        let value = obj[key] === undefined ? '' : obj[key];
        if (typeof value === 'string') {
          value = value.replace(/"/g, '""'); // Doubler les guillemets
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            value = `"${value}"`; // Entourer de guillemets si nécessaire
          }
        }
        return value;
      }).join(',');
    }).join('\n');
    
    return headerRow + '\n' + rows;
  };

  // Formater la date pour le nom de fichier
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="import-export-modal-overlay">
      <div className="import-export-modal-content">
        <div className="import-export-modal-header">
          <h2>Import / Export de Clients</h2>
          <button className="close-modal" onClick={onClose}>&times;</button>
        </div>
        
        <div className="import-export-body">
          <div className="import-section">
            <h3>Importer des clients</h3>
            
            <div className="import-form">
              <div className="form-group">
                <label htmlFor="import-format">Format:</label>
                <select 
                  id="import-format"
                  value={importFormat}
                  onChange={(e) => setImportFormat(e.target.value)}
                >
                  <option value="csv">CSV</option>
                  <option value="json">JSON</option>
                  <option value="xlsx">Excel (XLSX)</option>
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="import-file">Fichier:</label>
                <input 
                  type="file" 
                  id="import-file"
                  key={fileInputKey}
                  accept={
                    importFormat === 'csv' 
                      ? ".csv" 
                      : importFormat === 'json' 
                        ? ".json" 
                        : ".xlsx, .xls"
                  }
                  onChange={handleFileImport}
                />
              </div>
              
              {importStatus === 'loading' && (
                <div className="status-message loading">
                  Importation en cours...
                </div>
              )}
              
              {importStatus === 'success' && (
                <div className="status-message success">
                  Importation réussie !
                </div>
              )}
              
              {importStatus === 'error' && (
                <div className="status-message error">
                  Erreur lors de l'importation.
                </div>
              )}
            </div>
          </div>
          
          <div className="export-section">
            <h3>Exporter les clients ({clients.length})</h3>
            
            <div className="export-form">
              <div className="form-group">
                <label htmlFor="export-format">Format:</label>
                <select 
                  id="export-format"
                  value={exportFormat}
                  onChange={(e) => setExportFormat(e.target.value)}
                >
                  <option value="csv">CSV</option>
                  <option value="json">JSON</option>
                  <option value="xlsx">Excel (XLSX)</option>
                </select>
              </div>
              
              <button 
                className="export-btn"
                onClick={handleExport}
                disabled={clients.length === 0}
              >
                Exporter {clients.length} client(s)
              </button>
              
              {exportStatus === 'success' && (
                <div className="status-message success">
                  Exportation réussie !
                </div>
              )}
              
              {exportStatus === 'error' && (
                <div className="status-message error">
                  Erreur lors de l'exportation.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};