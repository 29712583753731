import React, { useState, useEffect, useRef } from 'react';

export const ClientSelector = ({ 
  isOpen, 
  onClose, 
  onSelectClient, 
  clients, 
  clientAppointments, 
  loadClientAppointments,
  addRdvToPanier,
  settings
}) => {
  const [clientType, setClientType] = useState('agenda');
  const [selectedClient, setSelectedClient] = useState('');
  const [passageNom, setPassageNom] = useState('');
  const [passagePrenom, setPassagePrenom] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1);
  const suggestionsRef = useRef(null);
  const searchInputRef = useRef(null);

  // Réinitialiser les filtres quand le modal s'ouvre
  useEffect(() => {
    if (isOpen) {
      setClientType('agenda');
      setSelectedClient('');
      setPassageNom('');
      setPassagePrenom('');
      setSearchTerm('');
      setFilteredClients(clients);
      setShowSuggestions(false);
      setFocusedSuggestionIndex(-1);
    }
  }, [isOpen, clients]);

  // Filtrer les clients quand le terme de recherche change
  useEffect(() => {
    if (clientType === 'agenda') {
      const term = searchTerm.toLowerCase();
      if (term.trim() === '') {
        setFilteredClients(clients);
        setShowSuggestions(false);
      } else {
        const filtered = clients.filter(client => 
          (client.nom && client.nom.toLowerCase().includes(term)) ||
          (client.prenom && client.prenom.toLowerCase().includes(term)) ||
          (client.telephone && client.telephone.includes(term)) ||
          (client.email && client.email.toLowerCase().includes(term))
        );
        setFilteredClients(filtered);
        setShowSuggestions(true);
      }
    }
  }, [searchTerm, clients, clientType]);

  // Gérer le clic en dehors des suggestions pour les fermer
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) &&
          searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Gérer la navigation au clavier dans les suggestions
  const handleKeyDown = (e) => {
    // Si les suggestions ne sont pas affichées, ne rien faire
    if (!showSuggestions) return;

    // Flèche du bas: passer à la suggestion suivante
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedSuggestionIndex(prev => 
        prev < filteredClients.length - 1 ? prev + 1 : prev
      );
    }
    // Flèche du haut: passer à la suggestion précédente
    else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedSuggestionIndex(prev => prev > 0 ? prev - 1 : 0);
    }
    // Entrée: sélectionner la suggestion en surbrillance
    else if (e.key === 'Enter' && focusedSuggestionIndex >= 0) {
      e.preventDefault();
      const selectedClient = filteredClients[focusedSuggestionIndex];
      if (selectedClient) {
        handleClientSelection(selectedClient.id);
      }
    }
    // Échap: fermer les suggestions
    else if (e.key === 'Escape') {
      e.preventDefault();
      setShowSuggestions(false);
    }
  };

  // Gérer le changement de client
  const handleClientChange = (e) => {
    const clientId = e.target.value;
    handleClientSelection(clientId);
  };

  // Gérer la sélection d'un client par ID
  const handleClientSelection = (clientId) => {
    setSelectedClient(clientId);
    loadClientAppointments(clientId);
    setShowSuggestions(false);
    setSearchTerm('');
    
    // Trouver les infos du client sélectionné pour affichage
    const client = clients.find(c => c.id == clientId);
    if (client) {
      setSearchTerm(`${client.prenom || ''} ${client.nom || ''}`.trim());
    }
  };

  // Gérer la sélection d'un client par suggestion
  const handleSuggestionClick = (clientId) => {
    handleClientSelection(clientId);
  };

  // Gérer la sélection finale du client
  const handleSelectClient = () => {
    if (clientType === 'agenda') {
      if (!selectedClient && settings.clientRequired) {
        alert('Veuillez sélectionner un client.');
        return;
      }
      
      // Passer le client sélectionné au composant parent
      onSelectClient({
        type: 'agenda',
        clientId: selectedClient
      });
    } else {
      // Vérifier les champs obligatoires pour un client de passage
      if (settings.passageNomRequired && !passageNom) {
        alert('Veuillez saisir le nom du client de passage.');
        return;
      }
      
      if (settings.passagePrenomRequired && !passagePrenom) {
        alert('Veuillez saisir le prénom du client de passage.');
        return;
      }
      
      // Passer les informations du client de passage au composant parent
      onSelectClient({
        type: 'passage',
        nom: passageNom,
        prenom: passagePrenom
      });
    }
    
    // Fermer le modal
    onClose();
  };

  // Formatter une date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    });
  };

  // Appliquer la classe pour les champs obligatoires
  const getRequiredClass = (fieldName) => {
    // Vérifier si le champ est obligatoire selon les paramètres
    if ((fieldName === 'client' && settings.clientRequired) ||
        (fieldName === 'passageNom' && settings.passageNomRequired && clientType === 'passage') ||
        (fieldName === 'passagePrenom' && settings.passagePrenomRequired && clientType === 'passage')) {
      return 'required';
    }
    return '';
  };

  // Créer un texte surligné avec les termes de recherche
  const highlightText = (text, term) => {
    if (!term || !text) return text;
    
    const lowerText = text.toLowerCase();
    const lowerTerm = term.toLowerCase();
    if (!lowerText.includes(lowerTerm)) return text;
    
    const startIndex = lowerText.indexOf(lowerTerm);
    const endIndex = startIndex + lowerTerm.length;
    
    return (
      <>
        {text.substring(0, startIndex)}
        <span className="highlight">{text.substring(startIndex, endIndex)}</span>
        {text.substring(endIndex)}
      </>
    );
  };

  return (
    isOpen && (
      <div className="client-selector-overlay">
        <div className="client-selector-content">
          <div className="client-selector-header">
            <h2>Sélection du client</h2>
            <button className="close-selector" onClick={onClose}>&times;</button>
          </div>
          
          <div className="client-selector-body">
            <div className="client-tabs">
              <div 
                className={`client-tab ${clientType === 'agenda' ? 'active' : ''}`}
                onClick={() => setClientType('agenda')}
              >
                Client de l'agenda
              </div>
              <div 
                className={`client-tab ${clientType === 'passage' ? 'active' : ''}`}
                onClick={() => setClientType('passage')}
              >
                Client de passage
              </div>
            </div>
            
            <div className="client-selection-form">
              {clientType === 'agenda' ? (
                <div className="agenda-client-selection">
                  <div className="search-container">
                    <input 
                      type="text" 
                      ref={searchInputRef}
                      placeholder="Rechercher un client..." 
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onFocus={() => searchTerm && setShowSuggestions(true)}
                      onKeyDown={handleKeyDown}
                    />
                    
                    {/* Suggestions de clients (autocomplétion) */}
                    {showSuggestions && filteredClients.length > 0 && (
                      <div className="client-suggestions" ref={suggestionsRef}>
                        {filteredClients.slice(0, 10).map((client, index) => (
                          <div 
                            key={client.id} 
                            className={`client-suggestion ${focusedSuggestionIndex === index ? 'focused' : ''}`}
                            onClick={() => handleSuggestionClick(client.id)}
                          >
                            <div className="client-suggestion-name">
                              {highlightText(`${client.prenom || ''} ${client.nom || ''}`.trim(), searchTerm)}
                            </div>
                            {client.telephone && (
                              <div className="client-suggestion-phone">
                                {highlightText(client.telephone, searchTerm)}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  <div className={`form-group ${getRequiredClass('client')}`}>
                    <label htmlFor="client-select">Sélectionner un client:</label>
                    <select 
                      id="client-select" 
                      value={selectedClient}
                      onChange={handleClientChange}
                    >
                      <option value="">Choisir un client</option>
                      {filteredClients.map(client => (
                        <option key={client.id} value={client.id}>
                          {`${client.prenom || ''} ${client.nom || ''}`.trim()} 
                          {client.telephone ? ` - ${client.telephone}` : ''}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  {selectedClient && clientAppointments.length > 0 && (
                    <div className="rendez-vous-section">
                      <h3>Rendez-vous du client</h3>
                      <div className="rdv-list">
                        {clientAppointments.map(rdv => (
                          <div key={rdv.id} className="rdv-item">
                            <div className="rdv-info">
                              <div className="rdv-date">{formatDate(rdv.date)} à {rdv.start}</div>
                              <div className="rdv-type">{rdv.type || 'Non spécifié'}</div>
                            </div>
                            <button 
                              className="add-rdv-btn" 
                              onClick={() => addRdvToPanier(rdv.id)}
                            >
                              Ajouter
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="passage-client-form">
                  <div className={`form-group ${getRequiredClass('passageNom')}`}>
                    <label htmlFor="passage-nom">Nom:</label>
                    <input 
                      type="text" 
                      id="passage-nom" 
                      placeholder="Nom du client"
                      value={passageNom}
                      onChange={(e) => setPassageNom(e.target.value)}
                    />
                  </div>
                  <div className={`form-group ${getRequiredClass('passagePrenom')}`}>
                    <label htmlFor="passage-prenom">Prénom:</label>
                    <input 
                      type="text" 
                      id="passage-prenom" 
                      placeholder="Prénom du client"
                      value={passagePrenom}
                      onChange={(e) => setPassagePrenom(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="passage-telephone">Téléphone:</label>
                    <input 
                      type="tel" 
                      id="passage-telephone" 
                      placeholder="Numéro de téléphone"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="passage-email">Email:</label>
                    <input 
                      type="email" 
                      id="passage-email" 
                      placeholder="Adresse email"
                    />
                  </div>
                </div>
              )}
            </div>
            
            <div className="form-actions">
              <button className="submit-btn" onClick={handleSelectClient}>
                Valider la sélection
              </button>
              <button className="cancel-btn" onClick={onClose}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};