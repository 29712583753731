import React from 'react';
import './ClientsList.css';

export const ClientsList = ({ clients, onEdit, onDelete }) => {
  // Formatter un numéro de téléphone pour l'affichage
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    
    // Si le numéro de téléphone commence par un '+', on garde le format international
    if (phoneNumber.startsWith('+')) {
      return phoneNumber;
    }
    
    // Sinon, on essaie de formater en format français
    // Supprimer tous les espaces, tirets et points
    const cleaned = phoneNumber.replace(/\s+|-|\./g, '');
    
    // Si le numéro a 10 chiffres et commence par '0'
    if (cleaned.length === 10 && cleaned.startsWith('0')) {
      return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    }
    
    // Sinon, retourner tel quel
    return phoneNumber;
  };

  return (
    <div className="clients-table-container">
      <table className="clients-table">
        <thead>
          <tr>
            <th width="50">Photo</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th width="20">Sync</th>
            <th width="120">Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.length === 0 ? (
            <tr>
              <td colSpan="7" className="no-clients-message">
                Aucun client trouvé. Créez votre premier client en cliquant sur l'onglet "Nouveau Client".
              </td>
            </tr>
          ) : (
            clients.map(client => (
              <tr key={client.id}>
                <td>
                  <div className="client-photo-small">
                    {client.photo ? (
                      <img 
                        src={client.photo} 
                        alt={`${client.prenom} ${client.nom}`} 
                      />
                    ) : (
                      client.prenom && client.nom 
                        ? `${client.prenom.charAt(0)}${client.nom.charAt(0)}`.toUpperCase()
                        : '👤'
                    )}
                  </div>
                </td>
                <td>{client.nom || ''}</td>
                <td>{client.prenom || ''}</td>
                <td>{formatPhoneNumber(client.telephone) || ''}</td>
                <td>{client.email || ''}</td>
                <td>
                  {client.server_id ? (
                    <span className="sync-status-icon synced" title="Synchronisé avec le serveur">✓</span>
                  ) : (
                    <span className="sync-status-icon not-synced" title="Non synchronisé (local uniquement)">⚠️</span>
                  )}
                </td>
                <td>
                  <div className="action-btns">
                    <button 
                      className="action-btn edit-btn" 
                      onClick={() => onEdit(client.id)}
                      aria-label="Modifier"
                    >
                      Modifier
                    </button>
                    <button 
                      className="action-btn delete-btn" 
                      onClick={() => onDelete(client.id)}
                      aria-label="Supprimer"
                    >
                      Supprimer
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};