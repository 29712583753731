import React, { useState, useEffect } from 'react';

export const GiftCardSelector = ({ 
  isOpen, 
  onClose, 
  onSelectGiftCard, 
  giftCards,
  clients = [],
  selectedClient = null,
  onCreateClient = null
}) => {
  const [filteredGiftCards, setFilteredGiftCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [giftCardTypes, setGiftCardTypes] = useState([]);
  const [customAmount, setCustomAmount] = useState('');
  
  // États pour la création de carte cadeau nominative
  const [showNominativeForm, setShowNominativeForm] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [showClientSuggestions, setShowClientSuggestions] = useState(false);
  const [selectedGiftCardClient, setSelectedGiftCardClient] = useState(null);
  
  // États pour la création d'un nouveau client
  const [showNewClientForm, setShowNewClientForm] = useState(false);
  const [newClientNom, setNewClientNom] = useState('');
  const [newClientPrenom, setNewClientPrenom] = useState('');
  const [newClientTelephone, setNewClientTelephone] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');

  // Charger les types de cartes cadeaux au chargement
  useEffect(() => {
    if (!isOpen) return;
    
    // Extraire les types uniques
    const uniqueTypes = [...new Set(giftCards.map(card => card.type))].filter(Boolean);
    setGiftCardTypes(uniqueTypes);
    
    // Réinitialiser les filtres
    setSelectedType('');
    setSearchTerm('');
    setCustomAmount('');
    setShowNominativeForm(false);
    setShowNewClientForm(false);
    setClientSearchTerm('');
    setFilteredClients([]);
    setShowClientSuggestions(false);
    
    // Si un client est déjà sélectionné dans la caisse, le précharger
    if (selectedClient) {
      setSelectedGiftCardClient(selectedClient);
      
      // Pré-remplir le champ de recherche client
      if (selectedClient.prenom || selectedClient.nom) {
        setClientSearchTerm(`${selectedClient.prenom || ''} ${selectedClient.nom || ''}`.trim());
      }
    } else {
      setSelectedGiftCardClient(null);
    }
    
    // Afficher toutes les cartes cadeaux
    setFilteredGiftCards(giftCards);
  }, [isOpen, giftCards, selectedClient]);
  
  // Mettre à jour les cartes cadeaux filtrées lorsque les filtres changent
  useEffect(() => {
    let result = [...giftCards];
    
    // Appliquer le filtre de type
    if (selectedType) {
      result = result.filter(card => card.type === selectedType);
    }
    
    // Appliquer le filtre de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(card => 
        (card.nom && card.nom.toLowerCase().includes(term)) ||
        (card.description && card.description.toLowerCase().includes(term)) ||
        (card.type && card.type.toLowerCase().includes(term))
      );
    }
    
    setFilteredGiftCards(result);
  }, [selectedType, searchTerm, giftCards]);
  
  // Filtrer les clients lors de la recherche
  useEffect(() => {
    if (clientSearchTerm.trim() === '') {
      setFilteredClients([]);
      setShowClientSuggestions(false);
      return;
    }
    
    const term = clientSearchTerm.toLowerCase();
    const filtered = clients.filter(client => 
      (client.nom && client.nom.toLowerCase().includes(term)) ||
      (client.prenom && client.prenom.toLowerCase().includes(term)) ||
      (client.telephone && client.telephone.includes(term)) ||
      (client.email && client.email.toLowerCase().includes(term))
    );
    
    setFilteredClients(filtered);
    setShowClientSuggestions(filtered.length > 0);
  }, [clientSearchTerm, clients]);
  
  // Formater le prix en euros
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(price || 0);
  };
  
  // Gérer la sélection d'une carte cadeau
  const handleSelectGiftCard = (giftCard) => {
    onSelectGiftCard(giftCard);
    
    // Notification ajout
    const giftCardName = `Carte Cadeau ${giftCard.montant}€ - ${giftCard.type}`;
    const notification = document.createElement('div');
    notification.className = 'giftcard-added-notification';
    notification.innerHTML = `<span>${giftCardName}</span> ajoutée au panier`;
    
    // Ajouter au DOM
    document.body.appendChild(notification);
    
    // Animation et suppression après un délai
    setTimeout(() => {
      notification.classList.add('show');
      setTimeout(() => {
        notification.classList.remove('show');
        setTimeout(() => {
          document.body.removeChild(notification);
        }, 300);
      }, 2000);
    }, 10);
  };

  // Gérer la sélection d'un client pour la carte cadeau
  const handleClientSelection = (client) => {
    setSelectedGiftCardClient(client);
    setClientSearchTerm(`${client.prenom || ''} ${client.nom || ''}`.trim());
    setShowClientSuggestions(false);
  };
  
  // Créer un nouveau client
  const handleCreateNewClient = () => {
    if (!newClientNom) {
      alert('Le nom du client est obligatoire');
      return;
    }
    
    const newClient = {
      id: Date.now(), // ID unique
      nom: newClientNom,
      prenom: newClientPrenom,
      telephone: newClientTelephone,
      email: newClientEmail,
      dateCreation: new Date().toISOString()
    };
    
    // Appeler la fonction de création fournie par le parent
    if (onCreateClient) {
      onCreateClient(newClient);
    }
    
    // Sélectionner ce client pour la carte cadeau
    setSelectedGiftCardClient(newClient);
    setClientSearchTerm(`${newClient.prenom || ''} ${newClient.nom || ''}`.trim());
    
    // Réinitialiser le formulaire de création
    setNewClientNom('');
    setNewClientPrenom('');
    setNewClientTelephone('');
    setNewClientEmail('');
    setShowNewClientForm(false);
  };
  
  // Gérer la création d'une carte cadeau personnalisée
  const handleCreateCustomGiftCard = () => {
    if (!customAmount || customAmount <= 0) {
      alert('Veuillez entrer un montant valide pour la carte cadeau');
      return;
    }
    
    const amount = parseFloat(customAmount);
    
    // Si nous sommes en mode nominatif mais aucun client n'est sélectionné
    if (showNominativeForm && !selectedGiftCardClient) {
      alert('Veuillez sélectionner ou créer un client pour la carte cadeau nominative');
      return;
    }
    
    // Créer la carte cadeau (nominative ou standard)
    const customGiftCard = {
      id: Date.now(), // ID unique
      type: selectedType || 'Standard',
      montant: amount,
      nom: showNominativeForm && selectedGiftCardClient 
        ? `Carte Cadeau ${amount}€ pour ${selectedGiftCardClient.prenom || ''} ${selectedGiftCardClient.nom || ''}`.trim()
        : `Carte Cadeau ${amount}€`,
      description: showNominativeForm && selectedGiftCardClient
        ? `Carte cadeau nominative - Client ${selectedGiftCardClient.id}`
        : 'Carte cadeau personnalisée',
      clientId: showNominativeForm && selectedGiftCardClient ? selectedGiftCardClient.id : null,
      expiration: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(), // 1 an d'expiration
      estNominative: showNominativeForm && selectedGiftCardClient ? true : false
    };
    
    onSelectGiftCard(customGiftCard);
    
    // Notification ajout
    const giftCardName = customGiftCard.nom;
    const notification = document.createElement('div');
    notification.className = 'giftcard-added-notification';
    notification.innerHTML = `<span>${giftCardName}</span> ajoutée au panier`;
    
    // Ajouter au DOM
    document.body.appendChild(notification);
    
    // Animation et suppression après un délai
    setTimeout(() => {
      notification.classList.add('show');
      setTimeout(() => {
        notification.classList.remove('show');
        setTimeout(() => {
          document.body.removeChild(notification);
        }, 300);
      }, 2000);
    }, 10);
    
    // Réinitialiser le montant personnalisé
    setCustomAmount('');
  };
  
  return (
    isOpen && (
      <div className="giftcard-selector-overlay">
        <div className="giftcard-selector-content">
          <div className="giftcard-selector-header">
            <h2>Sélectionner une carte cadeau</h2>
            <button className="close-selector" onClick={onClose}>&times;</button>
          </div>
          
          <div className="giftcard-selector-filters">
            <div className="search-container">
              <input 
                type="text" 
                placeholder="Rechercher une carte cadeau..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="category-filters">
              <select 
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">Tous les types</option>
                {giftCardTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          {/* Section pour créer une carte cadeau personnalisée */}
          <div className="custom-giftcard-section">
            <h3>Créer une carte cadeau personnalisée</h3>
            
            {/* Onglets pour choisir entre carte standard et nominative */}
            <div className="giftcard-tabs">
              <div 
                className={`giftcard-tab ${!showNominativeForm ? 'active' : ''}`}
                onClick={() => setShowNominativeForm(false)}
              >
                Carte standard
              </div>
              <div 
                className={`giftcard-tab ${showNominativeForm ? 'active' : ''}`}
                onClick={() => setShowNominativeForm(true)}
              >
                Carte nominative
              </div>
            </div>
            
            <div className="custom-giftcard-form">
              <div className="form-group">
                <label htmlFor="custom-amount">Montant :</label>
                <div className="price-input">
                  <input 
                    type="number" 
                    id="custom-amount" 
                    value={customAmount}
                    onChange={(e) => setCustomAmount(e.target.value)}
                    min="5"
                    step="5"
                    placeholder="50"
                  />
                  <span className="price-symbol">€</span>
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="giftcard-type">Type :</label>
                <select 
                  id="giftcard-type"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="">Type standard</option>
                  {giftCardTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Section pour la carte nominative */}
              {showNominativeForm && (
                <div className="nominative-section">
                  <div className="client-search-container">
                    <label htmlFor="client-search">Client :</label>
                    <div className="client-search">
                      <input 
                        type="text" 
                        id="client-search" 
                        placeholder="Rechercher un client..." 
                        value={clientSearchTerm}
                        onChange={(e) => setClientSearchTerm(e.target.value)}
                        onFocus={() => clientSearchTerm && setShowClientSuggestions(true)}
                      />
                      
                      {showClientSuggestions && filteredClients.length > 0 && (
                        <div className="client-suggestions">
                          {filteredClients.slice(0, 5).map(client => (
                            <div 
                              key={client.id} 
                              className="client-suggestion"
                              onClick={() => handleClientSelection(client)}
                            >
                              <div className="client-suggestion-name">
                                {`${client.prenom || ''} ${client.nom || ''}`.trim()}
                              </div>
                              {client.telephone && (
                                <div className="client-suggestion-phone">
                                  {client.telephone}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    
                    <button 
                      className="new-client-btn"
                      onClick={() => setShowNewClientForm(!showNewClientForm)}
                    >
                      {showNewClientForm ? 'Annuler' : 'Nouveau client'}
                    </button>
                  </div>
                  
                  {/* Formulaire de création d'un nouveau client */}
                  {showNewClientForm && (
                    <div className="new-client-form">
                      <h4>Nouveau client</h4>
                      <div className="form-row">
                        <div className="form-group">
                          <label htmlFor="client-nom">Nom* :</label>
                          <input 
                            type="text" 
                            id="client-nom" 
                            placeholder="Nom du client"
                            value={newClientNom}
                            onChange={(e) => setNewClientNom(e.target.value)}
                            required
                          />
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="client-prenom">Prénom :</label>
                          <input 
                            type="text" 
                            id="client-prenom" 
                            placeholder="Prénom du client"
                            value={newClientPrenom}
                            onChange={(e) => setNewClientPrenom(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="form-row">
                        <div className="form-group">
                          <label htmlFor="client-telephone">Téléphone :</label>
                          <input 
                            type="tel" 
                            id="client-telephone" 
                            placeholder="Numéro de téléphone"
                            value={newClientTelephone}
                            onChange={(e) => setNewClientTelephone(e.target.value)}
                          />
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="client-email">Email :</label>
                          <input 
                            type="email" 
                            id="client-email" 
                            placeholder="Adresse email"
                            value={newClientEmail}
                            onChange={(e) => setNewClientEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="form-actions">
                        <button 
                          className="create-client-btn"
                          onClick={handleCreateNewClient}
                          disabled={!newClientNom}
                        >
                          Créer le client
                        </button>
                      </div>
                    </div>
                  )}
                  
                  {/* Affichage du client sélectionné */}
                  {selectedGiftCardClient && !showNewClientForm && (
                    <div className="selected-client">
                      <div className="selected-client-info">
                        <span className="selected-client-name">
                          {`${selectedGiftCardClient.prenom || ''} ${selectedGiftCardClient.nom || ''}`.trim()}
                        </span>
                        {selectedGiftCardClient.telephone && (
                          <span className="selected-client-phone">
                            {selectedGiftCardClient.telephone}
                          </span>
                        )}
                      </div>
                      <button 
                        className="remove-client-btn"
                        onClick={() => {
                          setSelectedGiftCardClient(null);
                          setClientSearchTerm('');
                        }}
                      >
                        ×
                      </button>
                    </div>
                  )}
                </div>
              )}
              
              <button 
                className="create-giftcard-btn"
                onClick={handleCreateCustomGiftCard}
                disabled={!customAmount || customAmount <= 0 || (showNominativeForm && !selectedGiftCardClient)}
              >
                Ajouter au panier
              </button>
            </div>
          </div>
          
          <h3>Cartes cadeaux prédéfinies</h3>
          <div className="giftcard-selector-grid">
            {filteredGiftCards.length === 0 ? (
              <div className="no-giftcards">
                <p>Aucune carte cadeau prédéfinie ne correspond à votre recherche.</p>
              </div>
            ) : (
              filteredGiftCards.map(giftCard => (
                <div 
                  key={giftCard.id} 
                  className="giftcard-item"
                  onClick={() => handleSelectGiftCard(giftCard)}
                >
                  <div className="giftcard-item-image">
                    {giftCard.image ? (
                      <img src={giftCard.image} alt={giftCard.nom} />
                    ) : (
                      <div className="placeholder-image">
                        🎁
                      </div>
                    )}
                  </div>
                  <div className="giftcard-item-details">
                    <h3 className="giftcard-item-name">
                      {giftCard.nom || `Carte Cadeau ${formatPrice(giftCard.montant)}`}
                    </h3>
                    {giftCard.type && (
                      <div className="giftcard-item-type">
                        <span className="type-badge">
                          {giftCard.type}
                        </span>
                      </div>
                    )}
                    <div className="giftcard-item-price">
                      {formatPrice(giftCard.montant)}
                    </div>
                    {giftCard.description && (
                      <div className="giftcard-item-description">
                        {giftCard.description.length > 70 
                          ? giftCard.description.substring(0, 70) + '...'
                          : giftCard.description
                        }
                      </div>
                    )}
                    {giftCard.expiration && (
                      <div className="giftcard-item-expiration">
                        Expire le: {new Date(giftCard.expiration).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );
};