// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthAdapter } from '../../services/AuthAdapter';
import { DataSyncService } from '../../services/DataSyncService';
import { AuthReconnector } from '../../services/AuthReconnector';

// Création du contexte d'authentification
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [reconnectMessage, setReconnectMessage] = useState(null);
  
  const navigate = useNavigate();
  const location = useLocation();
  
  // Initialiser l'état d'authentification au chargement
  useEffect(() => {
    const initAuth = async () => {
      console.log('🔄 Initialisation du contexte d\'authentification');
      setLoading(true);
      
      try {
        // Vérifier s'il y a un utilisateur stocké localement
        const storedUser = localStorage.getItem('currentUser');
        console.log('📋 Utilisateur récupéré du localStorage');
        
        if (storedUser) {
          const userData = JSON.parse(storedUser);
          console.log('👤 Utilisateur trouvé dans le stockage', userData);
          
          setUser(userData);
          setIsAuthenticated(true);
          
          const accessToken = localStorage.getItem('accessToken');
          const refreshToken = localStorage.getItem('refreshToken');
          
          if (accessToken && refreshToken) {
            // Valider le token en arrière-plan
            validateToken(accessToken, refreshToken, userData);
          } else {
            console.log('⚠️ Tokens manquants, session expirée');
            // Tentative de reconnexion silencieuse
            attemptSilentReconnect(userData);
          }
        } else {
          console.log('❌ Aucun utilisateur trouvé, déconnecté');
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        console.error('Erreur lors de l\'initialisation de l\'authentification:', error);
        setError('Erreur lors de l\'initialisation de l\'authentification');
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    
    initAuth();
  }, []);
  
  // S'abonner aux événements d'authentification depuis le service de synchronisation
  useEffect(() => {
    console.log('📡 Configuration des écouteurs d\'événements d\'authentification');
    
    // Initialiser le service de reconnexion automatique
    console.log('🔑 Initialisation du service de reconnexion automatique');
    
    // S'abonner aux événements d'authentification
    const unsubscribe = DataSyncService.addSyncListener((event, data) => {
      console.log(`🔔 Événement reçu: ${event}`, data);
      
      switch (event) {
        case 'authExpired':
          setReconnectMessage('Votre session a expiré. Tentative de reconnexion automatique...');
          setIsReconnecting(true);
          // La tentative de reconnexion est gérée par AuthReconnector
          break;
          
        case 'userLoggedOut':
          handleLogout(false);
          break;
          
        case 'reconnectFailed':
          // La reconnexion automatique a échoué, forcer la déconnexion
          setReconnectMessage(null);
          setIsReconnecting(false);
          handleLogout(true, 'Votre session a expiré. Veuillez vous reconnecter.');
          break;
          
        case 'reconnectSuccess':
          // Reconnexion réussie
          setReconnectMessage('Reconnexion réussie!');
          setIsReconnecting(false);
          
          // Rafraîchir les informations utilisateur
          const storedUser = localStorage.getItem('currentUser');
          if (storedUser) {
            setUser(JSON.parse(storedUser));
            setIsAuthenticated(true);
          }
          
          // Masquer le message après 3 secondes
          setTimeout(() => {
            setReconnectMessage(null);
          }, 3000);
          break;
          
        default:
          break;
      }
    });
    
    // Nettoyage lors du démontage du composant
    return () => {
      unsubscribe();
      AuthReconnector.cleanup();
    };
  }, [navigate]);
  
  /**
   * Valider le token d'accès
   */
  const validateToken = async (accessToken, refreshToken, userData) => {
    try {
      // Simuler une validation de token (à implémenter avec une vraie vérification API)
      if (accessToken && refreshToken) {
        console.log('✅ Tokens trouvés, utilisateur authentifié');
        setUser(userData);
        setIsAuthenticated(true);
        
        // Si nous étions sur la page de login, rediriger vers la page d'accueil
        if (location.pathname === '/login' || location.pathname === '/register') {
          navigate('/');
        }
      } else {
        console.log('⚠️ Tokens invalides, tentative de rafraîchissement');
        // Tentative de reconnexion silencieuse
        attemptSilentReconnect(userData);
      }
    } catch (error) {
      console.error('Erreur lors de la validation du token:', error);
      // Tentative de reconnexion silencieuse
      attemptSilentReconnect(userData);
    }
  };
  
  /**
   * Tentative de reconnexion silencieuse
   */
  const attemptSilentReconnect = async (userData) => {
    setIsReconnecting(true);
    setReconnectMessage('Tentative de reconnexion automatique...');
    
    try {
      // Tenter de rafraîchir le token via DataSyncService
      const success = await DataSyncService.refreshAccessToken();
      
      if (success) {
        console.log('🔄 Reconnexion silencieuse réussie');
        setUser(userData);
        setIsAuthenticated(true);
        setReconnectMessage('Reconnexion réussie!');
        
        // Masquer le message après 3 secondes
        setTimeout(() => {
          setReconnectMessage(null);
        }, 3000);
      } else {
        console.log('❌ Échec de la reconnexion silencieuse');
        // Laisser AuthReconnector gérer les tentatives supplémentaires
        // Si elles échouent, l'événement reconnectFailed sera émis
      }
    } catch (error) {
      console.error('Erreur lors de la tentative de reconnexion silencieuse:', error);
    } finally {
      setIsReconnecting(false);
    }
  };
  
  /**
   * Connecter un utilisateur
   */
  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await AuthAdapter.loginUser(email, password);
      
      if (result && result.user) {
        setUser(result.user);
        setIsAuthenticated(true);
        
        // Stocker les tokens et l'utilisateur
        localStorage.setItem('accessToken', result.tokens.access);
        localStorage.setItem('refreshToken', result.tokens.refresh);
        localStorage.setItem('currentUser', JSON.stringify(result.user));
        
        console.log('✅ Connexion réussie', result.user);
        
        return true;
      }
      
      throw new Error('Échec de la connexion');
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setError(error.message || 'Échec de la connexion');
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  /**
   * Enregistrer un nouvel utilisateur
   */
  const register = async (userData) => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await AuthAdapter.registerUser(userData);
      
      if (result && result.user) {
        console.log('✅ Enregistrement réussi');
        // Rediriger vers la page de connexion
        navigate('/login', { state: { message: 'Inscription réussie. Veuillez vous connecter.' } });
        return true;
      }
      
      throw new Error('Échec de l\'enregistrement');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement:', error);
      setError(error.message || 'Échec de l\'enregistrement');
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  /**
   * Déconnecter l'utilisateur
   */
  const handleLogout = (redirect = true, message = null) => {
    // Supprimer les tokens et l'utilisateur
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentUser');
    
    setUser(null);
    setIsAuthenticated(false);
    
    console.log('🚪 Déconnexion effectuée');
    
    // Rediriger vers la page de connexion si demandé
    if (redirect) {
      navigate('/login', { state: { message } });
    }
  };
  
  /**
   * Déconnecter l'utilisateur (fonction publique)
   */
  const logout = () => {
    handleLogout(true, 'Vous avez été déconnecté avec succès.');
  };
  
  /**
   * Vérifier si c'est le premier utilisateur
   */
  const checkFirstUser = async () => {
    try {
      return await AuthAdapter.checkFirstUser();
    } catch (error) {
      console.error('Erreur lors de la vérification du premier utilisateur:', error);
      return { is_first_user: false };
    }
  };
  
  /**
   * Vérifier si l'utilisateur est un administrateur
   */
  const isAdmin = () => {
    return user && user.role === 'admin';
  };
  
  // Valeurs exposées par le contexte
  const value = {
    user,
    loading,
    error,
    isAuthenticated,
    isReconnecting,
    reconnectMessage,
    login,
    register,
    logout,
    checkFirstUser,
    setError,
    isAdmin,
    currentUser: user // Pour compatibilité avec le code existant
  };
  
  return (
    <AuthContext.Provider value={value}>
      {children}
      
      {/* Bannière de reconnexion */}
      {isReconnecting && reconnectMessage && (
        <div className="reconnect-banner">
          <p>{reconnectMessage}</p>
        </div>
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;