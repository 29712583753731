import React from 'react';
import './Agenda.css';

export const MonthView = ({ currentDate, settings, appointments, onAppointmentClick, onDateClick }) => {
  // Fonction pour formater une date en YYYY-MM-DD pour l'attribut data-date
  const formatDateForAttribute = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  // Obtenir le premier jour du mois
  const getMonthData = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    
    // Premier jour du mois
    const firstDay = new Date(year, month, 1);
    
    // Dernier jour du mois
    const lastDay = new Date(year, month + 1, 0);
    
    // Jour de la semaine du premier jour (0 = dimanche, 1 = lundi, etc.)
    let firstDayOfWeek = firstDay.getDay();
    if (firstDayOfWeek === 0) firstDayOfWeek = 7; // Ajuster pour que lundi soit le premier jour (1-7)
    
    // Jours du mois précédent pour compléter la première semaine
    const prevMonthDays = [];
    const prevMonth = new Date(year, month, 0);
    const daysInPrevMonth = prevMonth.getDate();
    
    for (let i = firstDayOfWeek - 1; i > 0; i--) {
      const day = daysInPrevMonth - i + 1;
      const date = new Date(year, month - 1, day);
      prevMonthDays.push({
        date,
        day,
        currentMonth: false,
        formattedDate: formatDateForAttribute(date)
      });
    }
    
    // Jours du mois actuel
    const currentMonthDays = [];
    for (let i = 1; i <= lastDay.getDate(); i++) {
      const date = new Date(year, month, i);
      currentMonthDays.push({
        date,
        day: i,
        currentMonth: true,
        today: isToday(date),
        formattedDate: formatDateForAttribute(date)
      });
    }
    
    // Jours du mois suivant pour compléter la grille (6 semaines)
    const nextMonthDays = [];
    const totalDaysShown = 42; // 6 semaines x 7 jours
    const remainingDays = totalDaysShown - (prevMonthDays.length + currentMonthDays.length);
    
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(year, month + 1, i);
      nextMonthDays.push({
        date,
        day: i,
        currentMonth: false,
        formattedDate: formatDateForAttribute(date)
      });
    }
    
    return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
  };
  
  // Vérifier si une date est aujourd'hui
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  
  // Vérifier si un jour est fermé selon les paramètres
  const isClosedDay = (date) => {
    const dayOfWeek = date.getDay(); // 0-6 (dimanche-samedi)
    return settings.closedDays.includes(dayOfWeek);
  };
  
  // Obtenir les rendez-vous pour une date donnée
  const getAppointmentsForDate = (formattedDate) => {
    return appointments.filter(appointment => appointment.date === formattedDate);
  };
  
  // Générer les jours du mois
  const monthDays = getMonthData();
  
  // Jours de la semaine pour les en-têtes
  const weekdays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  
  return (
    <div id="month-view" className="calendar-view active">
      <div className="month-grid">
        {/* En-têtes des jours de la semaine */}
        {weekdays.map((day, index) => (
          <div key={`weekday-${index}`} className="weekday-header">
            {day}
          </div>
        ))}
        
        {/* Jours du mois */}
        {monthDays.map((dayData, index) => (
          <div 
            key={`day-${index}`} 
            className={`month-day ${dayData.currentMonth ? '' : 'next-month'} ${dayData.today ? 'today' : ''} ${isClosedDay(dayData.date) ? 'closed-day' : ''}`}
            data-date={dayData.formattedDate}
            onClick={() => onDateClick(dayData.formattedDate)}
          >
            {dayData.day}
            
            {/* Afficher les rendez-vous du jour (limité aux 3 premiers pour l'espace) */}
            {dayData.currentMonth && getAppointmentsForDate(dayData.formattedDate).slice(0, 3).map(appointment => (
              <div 
                key={appointment.id} 
                className={`appointment ${appointment.type || ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onAppointmentClick(appointment.id);
                }}
              >
                {appointment.start} - {appointment.client_name || 'Client'}
              </div>
            ))}
            
            {/* Indicateur de rendez-vous supplémentaires */}
            {dayData.currentMonth && getAppointmentsForDate(dayData.formattedDate).length > 3 && (
              <div className="more-appointments">
                +{getAppointmentsForDate(dayData.formattedDate).length - 3} autres
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};