// Utilitaire pour gérer l'historique des actions

/**
 * Enregistre une action dans l'historique
 * @param {string} module - Le module concerné (agenda, caisse, clients, prestations, stock)
 * @param {string} action - Le type d'action (create, update, delete, payment, refund, etc.)
 * @param {string} description - Description de l'action
 * @param {string} details - Détails supplémentaires (optionnel)
 */
export const logAction = (module, action, description, details = null) => {
    // Récupérer l'historique existant
    const history = JSON.parse(localStorage.getItem('app_history') || '[]');
    
    // Créer un nouvel élément d'historique
    const historyItem = {
      timestamp: new Date().toISOString(),
      module,
      action,
      description,
      details
    };
    
    // Ajouter l'élément à l'historique
    history.unshift(historyItem);
    
    // Limiter la taille de l'historique à 1000 entrées pour éviter de surcharger le localStorage
    const trimmedHistory = history.slice(0, 1000);
    
    // Sauvegarder l'historique
    localStorage.setItem('app_history', JSON.stringify(trimmedHistory));
    
    return historyItem;
  };
  
  /**
   * Effacer l'historique complet
   */
  export const clearHistory = () => {
    localStorage.setItem('app_history', '[]');
  };
  
  /**
   * Supprimer une entrée spécifique de l'historique
   * @param {number} index - Index de l'entrée à supprimer
   */
  export const deleteHistoryItem = (index) => {
    const history = JSON.parse(localStorage.getItem('app_history') || '[]');
    history.splice(index, 1);
    localStorage.setItem('app_history', JSON.stringify(history));
  };
  
  /**
   * Exporter l'historique au format CSV
   */
  export const exportHistoryToCsv = () => {
    const history = JSON.parse(localStorage.getItem('app_history') || '[]');
    
    if (history.length === 0) {
      alert("L'historique est vide. Rien à exporter.");
      return;
    }
    
    // En-têtes du CSV
    const headers = ['Date', 'Module', 'Action', 'Description', 'Détails'];
    
    // Lignes du CSV
    const rows = history.map(item => [
      new Date(item.timestamp).toLocaleString('fr-FR'),
      item.module,
      item.action,
      item.description,
      item.details || ''
    ]);
    
    // Construire le contenu CSV
    const csvContent = [
      headers.join(';'),
      ...rows.map(row => row.join(';'))
    ].join('\n');
    
    // Créer un blob pour le téléchargement
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // Créer un lien de téléchargement
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `historique_${new Date().toISOString().slice(0, 10)}.csv`);
    link.style.visibility = 'hidden';
    
    // Ajouter au DOM et déclencher le téléchargement
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };