import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { WrappedAgenda } from './components/Agenda/WrappedAgenda';
import { Caisse } from './components/Caisse/Caisse';
import { Clients } from './components/Clients/Clients';
import { Prestations } from './components/Prestations/Prestations';
import { Stock } from './components/Stock/Stock';
import { Synthese } from './components/Synthese/Synthese';
import { UserManagement } from './components/Auth/UserManagement';
import { UserProfile } from './components/Auth/UserProfile';
import { Login } from './components/Auth/Login';
import { AuthProvider, useAuth } from './components/Auth/AuthContext';
import { ProtectedRoute, UnauthorizedPage } from './components/Auth/ProtectedRoute';
import './App.css';

// Composant pour afficher les notifications de reconnexion
const ReconnectionNotifier = () => {
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    // Importer dynamiquement pour éviter des problèmes de dépendances circulaires
    import('./services/DataSyncService').then(module => {
      const DataSyncService = module.default;
      
      const unsubscribe = DataSyncService.addSyncListener((event, data) => {
        if (event === 'authExpired') {
          setMessage('Votre session a expiré. Tentative de reconnexion automatique...');
          setVisible(true);
        } else if (event === 'reconnectSuccess') {
          setMessage('Reconnexion réussie');
          setVisible(true);
          
          // Masquer après 3 secondes
          setTimeout(() => {
            setVisible(false);
          }, 3000);
        } else if (event === 'reconnectFailed') {
          setMessage('Échec de la reconnexion. Redirection vers la page de connexion...');
          setVisible(true);
        }
      });
      
      return () => unsubscribe && unsubscribe();
    });
  }, []);
  
  if (!visible || !message) return null;
  
  return (
    <div className="reconnection-notifier">
      <p>{message}</p>
    </div>
  );
};

const AppContent = () => {
  const { currentUser, logout, isAdmin } = useAuth();
  const [activeSection, setActiveSection] = useState('agenda');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [syncStatus, setSyncStatus] = useState({
    isSyncing: false,
    lastSync: null,
    pendingRequests: 0
  });

  // Initialiser les données par défaut si nécessaire
  useEffect(() => {
    initializeAppData();
    
    // Si l'utilisateur est connecté, synchroniser les données
    if (currentUser) {
      // Importer dynamiquement le service de synchronisation
      import('./services/DataSyncService').then(module => {
        const dataSyncService = module.default;
        
        // Effectuer une synchronisation systématique au démarrage
        dataSyncService.syncAllData().catch(error => {
          console.error('Erreur lors de la synchronisation initiale:', error);
        });
        
        // Initialiser le service de reconnexion automatique
        import('./services/AuthReconnector').then(reconnectorModule => {
          // Ne nécessite aucune action, juste s'assurer qu'il est chargé
          console.log('Service de reconnexion automatique initialisé');
        });
        
        // S'abonner aux événements de synchronisation pour mettre à jour l'interface
        const unsubscribe = dataSyncService.addSyncListener((event, data) => {
          if (event === 'syncStart') {
            setSyncStatus(prev => ({ ...prev, isSyncing: true }));
          } else if (event === 'syncComplete') {
            setSyncStatus(prev => ({ 
              ...prev, 
              isSyncing: false,
              lastSync: data.timestamp
            }));
          } else if (event === 'syncError' || event === 'syncNetworkError') {
            setSyncStatus(prev => ({ ...prev, isSyncing: false }));
          }
        });
        
        // Vérifier s'il y a des requêtes en attente
        dataSyncService.getSyncStatus().then(status => {
          setSyncStatus(prev => ({
            ...prev,
            lastSync: status.lastSync,
            pendingRequests: (status.authQueueLength || 0) + 
                            (status.networkQueueLength || 0) + 
                            (status.queueLength || 0)
          }));
        });
        
        return () => {
          unsubscribe();
        };
      });
    }
  }, [currentUser]);

  // Gestion de la fermeture de l'application
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Tentative de synchronisation rapide avant fermeture
      import('./services/DataSyncService').then(module => {
        const dataSyncService = module.default;
        if (navigator.onLine) {
          dataSyncService.quickSync().catch(err => {
            console.error('Erreur lors de la synchronisation rapide:', err);
          });
        }
      });
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Gestionnaire pour fermer le menu lorsqu'on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.user-dropdown')) {
        setIsMenuOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  // Fonction pour initialiser l'application avec les données par défaut
  const initializeAppData = () => {
    // Vérifier si c'est la première utilisation de l'application
    const isFirstUse = !localStorage.getItem("app_initialized");

    if (isFirstUse) {
      // Marquer l'application comme initialisée
      localStorage.setItem("app_initialized", "true");

      // Les catégories par défaut sont supprimées - les nouvelles catégories seront créées par l'utilisateur
      localStorage.setItem("categories", JSON.stringify([]));

      // Créer des prestations par défaut (si aucune n'existe déjà)
      const prestations = JSON.parse(localStorage.getItem("prestations") || "[]");
      if (prestations.length === 0) {
        // Note: Nous n'ajoutons pas de prestations par défaut
        // L'utilisateur va créer ses propres prestations avec leurs catégories
        localStorage.setItem("prestations", JSON.stringify([]));
      }
      
      // Initialiser les stocks vides
      localStorage.setItem("stock_items", JSON.stringify([]));
    }
  };

  // Fonction pour gérer le changement de section
  const handleSectionChange = (sectionId) => {
    // Si l'utilisateur tente d'accéder à la synthèse mais n'est pas admin, 
    // ne pas changer la section active
    if (sectionId === 'synthese' && !isAdmin()) {
      return;
    }
    
    setActiveSection(sectionId);
  };

  // Fonction pour gérer la déconnexion
  const handleLogout = () => {
    logout();
  };

  // Fonction pour afficher le modal de profil
  const handleShowProfile = () => {
    setShowProfileModal(true);
    setIsMenuOpen(false);
  };

  // Fonction pour fermer le modal de profil
  const handleCloseProfile = () => {
    setShowProfileModal(false);
  };

  // Fonction pour basculer l'état du menu déroulant
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="app">
      {/* Barre de navigation */}
      <nav className="navbar">
        <div className="nav-container">
          <div className="nav-buttons">
            <button 
              className={`nav-btn ${activeSection === 'agenda' ? 'active' : ''}`} 
              onClick={() => handleSectionChange('agenda')}
            >
              Agenda
            </button>
            <button 
              className={`nav-btn ${activeSection === 'caisse' ? 'active' : ''}`} 
              onClick={() => handleSectionChange('caisse')}
            >
              Caisse
            </button>
            <button 
              className={`nav-btn ${activeSection === 'clients' ? 'active' : ''}`} 
              onClick={() => handleSectionChange('clients')}
            >
              Clients
            </button>
            <button 
              className={`nav-btn ${activeSection === 'prestations' ? 'active' : ''}`} 
              onClick={() => handleSectionChange('prestations')}
            >
              Prestations
            </button>
            <button 
              className={`nav-btn ${activeSection === 'stock' ? 'active' : ''}`} 
              onClick={() => handleSectionChange('stock')}
            >
              Stock
            </button>
            {/* N'afficher le bouton Synthèse que pour les administrateurs */}
            {isAdmin() && (
              <button 
                className={`nav-btn ${activeSection === 'synthese' ? 'active' : ''}`} 
                onClick={() => handleSectionChange('synthese')}
              >
                Synthèse
              </button>
            )}
            {isAdmin() && (
              <button 
                className={`nav-btn ${activeSection === 'users' ? 'active' : ''}`} 
                onClick={() => handleSectionChange('users')}
              >
                Utilisateurs
              </button>
            )}
          </div>
          <div className="user-info">
            <div className="company-name-display">
              {currentUser.companyName && <span className="company-name">{currentUser.companyName}</span>}
            </div>
            <div className="user-dropdown">
              <button 
                className="user-dropdown-btn" 
                onClick={toggleMenu}
              >
                <span className="user-name">{currentUser.name}</span>
                <span className="dropdown-arrow">▼</span>
              </button>
              {isMenuOpen && (
                <div className="user-dropdown-content">
                  <button 
                    className="dropdown-item profile-btn" 
                    onClick={handleShowProfile}
                  >
                    Mon profil
                  </button>
                  <button 
                    className="dropdown-item logout-btn" 
                    onClick={handleLogout}
                  >
                    Déconnexion
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Contenu principal */}
      <main className="main-content">
        {activeSection === 'agenda' && <WrappedAgenda />}
        {activeSection === 'caisse' && <Caisse />}
        {activeSection === 'clients' && <Clients />}
        {activeSection === 'prestations' && <Prestations />}
        {activeSection === 'stock' && <Stock />}
        {/* N'afficher le contenu de Synthèse que pour les administrateurs */}
        {activeSection === 'synthese' && isAdmin() && <Synthese />}
        {activeSection === 'users' && isAdmin() && <UserManagement />}
      </main>

      {/* Modal de profil utilisateur */}
      {showProfileModal && (
        <div className="modal profile-modal">
          <div className="modal-content">
            <span className="close-modal" onClick={handleCloseProfile}>&times;</span>
            <UserProfile onClose={handleCloseProfile} />
          </div>
        </div>
      )}
      
      {/* Notificateur de reconnexion */}
      <ReconnectionNotifier />
    </div>
  );
};

const App = () => {
  // Initialisation du service de reconnexion au démarrage de l'application
  useEffect(() => {
    const initializeServices = async () => {
      try {
        // Importer les services nécessaires
        const { default: AuthReconnector } = await import('./services/AuthReconnector');
        
        // Vérifier si on a des requêtes en attente au démarrage
        const DataSyncServiceModule = await import('./services/DataSyncService');
        const DataSyncService = DataSyncServiceModule.default;
        
        // Vérifier que la méthode existe avant de l'appeler
        if (DataSyncService && typeof DataSyncService.checkQueueStatus === 'function') {
          const queueStatus = DataSyncService.checkQueueStatus();
          
          if (queueStatus.hasPendingAuthRequests || queueStatus.hasPendingNetworkRequests) {
            console.log('Files d\'attente détectées au démarrage:', queueStatus);
          }
        }
        
        // Surveiller les changements de connexion
        window.addEventListener('online', () => {
          console.log('Connexion Internet rétablie');
        });
        
        window.addEventListener('offline', () => {
          console.log('Connexion Internet perdue');
        });
      } catch (error) {
        console.error('Erreur lors de l\'initialisation des services:', error);
      }
    };
  }, []);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/" element={
          <ProtectedRoute>
            <AppContent />
          </ProtectedRoute>
        } />
        <Route path="/admin/users" element={
          <ProtectedRoute requireAdmin={true}>
            <UserManagement />
          </ProtectedRoute>
        } />
        {/* Route protégée pour la synthèse qui nécessite des droits d'administrateur */}
        <Route path="/admin/synthese" element={
          <ProtectedRoute requireAdmin={true}>
            <Synthese />
          </ProtectedRoute>
        } />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;