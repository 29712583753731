// src/services/AuthReconnector.js

import { DataSyncService } from './DataSyncService';

/**
 * Service de reconnexion silencieuse
 * Gère les tentatives de reconnexion automatique en arrière-plan
 */
class AuthReconnectorClass {
  constructor() {
    this.isAttemptingReconnect = false;
    this.reconnectTimer = null;
    this.maxRetries = 3;
    this.retryCount = 0;
    this.retryDelay = 30000; // 30 secondes entre les tentatives
    
    // S'abonner aux événements de synchronisation
    this.unsubscribe = DataSyncService.addSyncListener(this.handleSyncEvent.bind(this));
    
    // Écouter les événements de changement de réseau
    window.addEventListener('online', this.handleOnline.bind(this));
  }
  
  /**
   * Gérer les événements de synchronisation
   */
  handleSyncEvent(event, data) {
    if (event === 'authExpired' || event === 'userLoggedOut') {
      // Si l'authentification a expiré, planifier une tentative de reconnexion
      this.scheduleReconnect();
    }
    
    if (event === 'syncComplete') {
      // Si une synchronisation réussit, réinitialiser le compteur de tentatives
      this.retryCount = 0;
    }
  }
  
  /**
   * Gérer le retour de la connexion internet
   */
  handleOnline() {
    console.log('Connexion internet rétablie');
    
    // Vérifier s'il y a des requêtes en attente
    const authQueue = JSON.parse(localStorage.getItem('auth_request_queue') || '[]');
    const networkQueue = JSON.parse(localStorage.getItem('network_request_queue') || '[]');
    
    if (authQueue.length > 0 || networkQueue.length > 0) {
      console.log('Des requêtes sont en attente, tentative de reconnexion silencieuse');
      this.attemptSilentReconnect();
    }
  }
  
  /**
   * Planifier une tentative de reconnexion
   */
  scheduleReconnect() {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    
    if (this.retryCount < this.maxRetries) {
      console.log(`Planification d'une reconnexion dans ${this.retryDelay / 1000} secondes`);
      this.reconnectTimer = setTimeout(() => {
        this.attemptSilentReconnect();
      }, this.retryDelay);
    } else {
      console.log('Nombre maximum de tentatives de reconnexion atteint');
      // Notifier l'interface utilisateur qu'une intervention manuelle est nécessaire
      DataSyncService.notifySyncListeners('reconnectFailed', {
        message: 'Impossible de se reconnecter automatiquement. Veuillez vous reconnecter manuellement.'
      });
    }
  }
  
  /**
   * Tenter une reconnexion silencieuse
   */
  async attemptSilentReconnect() {
    if (this.isAttemptingReconnect) {
      return;
    }
    
    this.isAttemptingReconnect = true;
    
    try {
      console.log('Tentative de reconnexion silencieuse...');
      this.retryCount++;
      
      // Récupérer les informations de connexion stockées
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        console.log('Aucun refresh token disponible, connexion manuelle nécessaire');
        this.isAttemptingReconnect = false;
        return;
      }
      
      // Tenter de rafraîchir le token
      const success = await DataSyncService.refreshAccessToken();
      
      if (success) {
        console.log('Reconnexion silencieuse réussie');
        // Traiter les requêtes en attente
        await DataSyncService.processQueuedRequests();
        
        // Réinitialiser les compteurs
        this.retryCount = 0;
        
        // Notifier l'interface utilisateur
        DataSyncService.notifySyncListeners('reconnectSuccess', {
          message: 'Reconnexion réussie'
        });
      } else {
        console.log('Échec de la reconnexion silencieuse');
        // Planifier une nouvelle tentative
        this.scheduleReconnect();
      }
    } catch (error) {
      console.error('Erreur lors de la tentative de reconnexion:', error);
      this.scheduleReconnect();
    } finally {
      this.isAttemptingReconnect = false;
    }
  }
  
  /**
   * Nettoyer les écouteurs d'événements
   */
  cleanup() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    
    window.removeEventListener('online', this.handleOnline);
  }
}

// Créer une instance unique
export const AuthReconnector = new AuthReconnectorClass();

export default AuthReconnector;