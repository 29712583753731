import React, { useState, useEffect } from 'react';
import './Agenda.css';

export const YearView = ({ currentDate, settings, appointments, onAppointmentClick, onDateClick }) => {
  const [yearData, setYearData] = useState([]);
  const [appointmentCounts, setAppointmentCounts] = useState({});
  const [yearAppointments, setYearAppointments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  
  // Noms des mois en français
  const monthNames = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];
  
  // Jours de la semaine en français (abrégés)
  const weekdays = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'];
  
  // Fonction pour vérifier si une date est aujourd'hui
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  
  // Fonction pour formater une date en YYYY-MM-DD
  const formatDateForAttribute = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };
  
  // Vérifier si un jour est fermé selon les paramètres
  const isClosedDay = (date) => {
    const dayOfWeek = date.getDay(); // 0-6 (dimanche-samedi)
    return settings.closedDays.includes(dayOfWeek);
  };
  
  // Générer les données du calendrier pour l'année entière
  useEffect(() => {
    const year = currentDate.getFullYear();
    const months = [];
    
    // Pour chaque mois de l'année
    for (let month = 0; month < 12; month++) {
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const firstDayOfMonth = new Date(year, month, 1);
      let firstDayOfWeek = firstDayOfMonth.getDay(); // 0 = dimanche, 1 = lundi, etc.
      
      // Ajuster pour que lundi soit le premier jour (1-7)
      if (firstDayOfWeek === 0) firstDayOfWeek = 7;
      
      const monthData = {
        month,
        name: monthNames[month],
        days: []
      };
      
      // Ajouter les jours du mois précédent pour compléter la première semaine
      const prevMonth = month === 0 ? 11 : month - 1;
      const prevMonthYear = month === 0 ? year - 1 : year;
      const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();
      
      for (let i = firstDayOfWeek - 1; i > 0; i--) {
        const day = daysInPrevMonth - i + 1;
        const date = new Date(prevMonthYear, prevMonth, day);
        monthData.days.push({
          date,
          day,
          currentMonth: false,
          formattedDate: formatDateForAttribute(date)
        });
      }
      
      // Ajouter les jours du mois courant
      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(year, month, i);
        monthData.days.push({
          date,
          day: i,
          currentMonth: true,
          today: isToday(date),
          closed: isClosedDay(date),
          formattedDate: formatDateForAttribute(date)
        });
      }
      
      // Ajouter les jours du mois suivant pour compléter la dernière semaine
      const nextMonth = month === 11 ? 0 : month + 1;
      const nextMonthYear = month === 11 ? year + 1 : year;
      const totalDaysShown = Math.ceil(monthData.days.length / 7) * 7;
      const remainingDays = totalDaysShown - monthData.days.length;
      
      for (let i = 1; i <= remainingDays; i++) {
        const date = new Date(nextMonthYear, nextMonth, i);
        monthData.days.push({
          date,
          day: i,
          currentMonth: false,
          formattedDate: formatDateForAttribute(date)
        });
      }
      
      months.push(monthData);
    }
    
    setYearData(months);
  }, [currentDate, settings]);
  
  // Filtrer les rendez-vous pour l'année en cours
  useEffect(() => {
    const year = currentDate.getFullYear();
    
    // Filtrer les rendez-vous de l'année
    const filteredAppointments = appointments.filter(appointment => {
      const appointmentDate = new Date(appointment.date);
      return appointmentDate.getFullYear() === year;
    });
    
    setYearAppointments(filteredAppointments);
    
    // Calculer le nombre de rendez-vous par jour
    const counts = {};
    filteredAppointments.forEach(appointment => {
      if (!counts[appointment.date]) {
        counts[appointment.date] = 1;
      } else {
        counts[appointment.date]++;
      }
    });
    
    setAppointmentCounts(counts);
  }, [appointments, currentDate]);
  
  // Obtenir la classe CSS en fonction du nombre de rendez-vous
  const getHeatmapClass = (date) => {
    if (!date) return '';
    
    const formattedDate = formatDateForAttribute(date);
    const count = appointmentCounts[formattedDate] || 0;
    
    if (count === 0) return '';
    if (count === 1) return 'appointment-level-1';
    if (count === 2) return 'appointment-level-2';
    if (count === 3) return 'appointment-level-3';
    return 'appointment-level-4';
  };
  
  // Gérer le clic sur un jour
  const handleDayClick = (date, currentMonth) => {
    if (!currentMonth) return; // Ne pas permettre de cliquer sur les jours hors du mois
    
    const formattedDate = formatDateForAttribute(date);
    onDateClick(formattedDate);
  };
  
  // Gérer le clic sur un mois pour afficher/masquer les détails
  const handleMonthClick = (monthIndex) => {
    setSelectedMonth(selectedMonth === monthIndex ? null : monthIndex);
  };
  
  // Obtenir les rendez-vous pour un jour spécifique
  const getAppointmentsForDate = (formattedDate) => {
    return yearAppointments.filter(appointment => appointment.date === formattedDate);
  };
  
  return (
    <div id="year-view" className="calendar-view active">
      <div className="year-header">
        <h2>{currentDate.getFullYear()}</h2>
      </div>
      
      <div className="year-grid">
        {yearData.map((month, index) => (
          <div 
            key={`month-${index}`} 
            className={`year-month ${selectedMonth === index ? 'expanded' : ''}`}
            onClick={() => handleMonthClick(index)}
          >
            <div className="month-header">
              <h3>{month.name}</h3>
            </div>
            
            <div className="month-calendar">
              <div className="month-weekdays">
                {weekdays.map((day, i) => (
                  <div key={`weekday-${i}`} className="weekday-header-mini">
                    {day}
                  </div>
                ))}
              </div>
              
              <div className="month-days">
                {month.days.map((dayData, dayIndex) => (
                  <div 
                    key={`day-${dayIndex}`} 
                    className={`month-day-mini 
                      ${dayData.currentMonth ? '' : 'other-month'} 
                      ${dayData.today ? 'today' : ''} 
                      ${dayData.closed ? 'closed-day' : ''}
                      ${getHeatmapClass(dayData.currentMonth ? dayData.date : null)}
                    `}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDayClick(dayData.date, dayData.currentMonth);
                    }}
                  >
                    {dayData.day}
                  </div>
                ))}
              </div>
            </div>
            
            {/* Détails du mois lorsqu'il est sélectionné */}
            {selectedMonth === index && (
              <div className="month-details" onClick={e => e.stopPropagation()}>
                <h4>Rendez-vous en {month.name}</h4>
                
                <div className="month-appointments-list">
                  {month.days
                    .filter(dayData => dayData.currentMonth)
                    .map(dayData => {
                      const formattedDate = formatDateForAttribute(dayData.date);
                      const dayAppointments = getAppointmentsForDate(formattedDate);
                      
                      if (dayAppointments.length === 0) return null;
                      
                      return (
                        <div key={formattedDate} className="day-appointments">
                          <div className="day-appointments-header">
                            {dayData.date.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric' })}
                          </div>
                          
                          <div className="day-appointments-list">
                            {dayAppointments.map(appointment => (
                              <div 
                                key={appointment.id} 
                                className="year-appointment"
                                style={{ borderLeftColor: appointment.color || '#c6b291' }}
                                onClick={() => onAppointmentClick(appointment.id)}
                              >
                                <div className="year-appointment-time">{appointment.start}</div>
                                <div className="year-appointment-client">{appointment.client_name}</div>
                                <div className="year-appointment-type">{appointment.type}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })
                    .filter(Boolean)}
                    
                  {!month.days
                    .filter(dayData => dayData.currentMonth)
                    .some(dayData => {
                      const formattedDate = formatDateForAttribute(dayData.date);
                      return getAppointmentsForDate(formattedDate).length > 0;
                    }) && (
                      <div className="no-appointments">
                        Aucun rendez-vous pour ce mois
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};