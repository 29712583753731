import React, { useState, useEffect } from 'react';
import { ClientForm } from './ClientForm';
import { ClientsList } from './ClientsList';
import { ClientImportExport } from './ClientImportExport';
import { ClientService } from '../../services/ClientService';
import './Clients.css';

export const Clients = () => {
  const [activeTab, setActiveTab] = useState('liste-clients');
  const [clients, setClients] = useState([]);
  const [editClient, setEditClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showImportExport, setShowImportExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState('');

  // Charger les clients au chargement du composant
  useEffect(() => {
    const loadClients = async () => {
      setIsLoading(true);
      
      // Charger d'abord depuis le localStorage pour un affichage rapide
      const storedClients = JSON.parse(localStorage.getItem('clients') || '[]');
      setClients(storedClients);
      
      try {
        // Synchroniser avec le serveur
        setSyncStatus('syncing');
        const syncedClients = await ClientService.syncClientsWithServer();
        setClients(syncedClients);
        setSyncStatus('synced');
      } catch (error) {
        console.error("Erreur lors de la synchronisation:", error);
        setSyncStatus('error');
      } finally {
        setIsLoading(false);
      }
    };
    
    loadClients();
  }, []);

  // Fonction pour ajouter un nouveau client
  const addClient = async (clientData) => {
    try {
      setIsLoading(true);
      
      // Créer un ID local temporaire
      const newClient = {
        ...clientData,
        id: Date.now()
      };
      
      // Ajouter à la liste locale avant la synchronisation
      const updatedClients = [...clients, newClient];
      setClients(updatedClients);
      localStorage.setItem('clients', JSON.stringify(updatedClients));
      
      // Essayer de créer sur le serveur
      const createdClient = await ClientService.createClient(newClient);
      
      // Mettre à jour avec l'ID serveur
      const finalClients = clients.map(c => 
        c.id === newClient.id ? createdClient : c
      );
      setClients(finalClients);
      localStorage.setItem('clients', JSON.stringify(finalClients));
      
      // Réinitialiser l'état d'édition
      setEditClient(null);
      
      // Passer à l'onglet liste après l'ajout
      setActiveTab('liste-clients');
      
      // Afficher un message de confirmation
      alert('Client enregistré avec succès !');
    } catch (error) {
      console.error("Erreur lors de l'ajout du client:", error);
      alert('Le client a été enregistré localement, mais n\'a pas pu être synchronisé avec le serveur.');
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour modifier un client existant
  const updateClient = async (clientData) => {
    try {
      setIsLoading(true);
      
      // Mettre à jour localement d'abord
      const updatedClients = clients.map(client => 
        client.id === clientData.id ? clientData : client
      );
      
      setClients(updatedClients);
      localStorage.setItem('clients', JSON.stringify(updatedClients));
      
      // Si le client a déjà un ID serveur, le mettre à jour sur le serveur
      if (clientData.server_id) {
        await ClientService.updateClient(clientData);
      } else {
        // Sinon, essayer de le créer
        const createdClient = await ClientService.createClient(clientData);
        
        // Mettre à jour avec l'ID serveur
        const finalClients = updatedClients.map(c => 
          c.id === clientData.id ? {...c, server_id: createdClient.server_id} : c
        );
        setClients(finalClients);
        localStorage.setItem('clients', JSON.stringify(finalClients));
      }
      
      // Réinitialiser l'état d'édition
      setEditClient(null);
      
      // Passer à l'onglet liste après la modification
      setActiveTab('liste-clients');
      
      // Afficher un message de confirmation
      alert('Client modifié avec succès !');
    } catch (error) {
      console.error("Erreur lors de la modification du client:", error);
      alert('Le client a été mis à jour localement, mais n\'a pas pu être synchronisé avec le serveur.');
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer un client
  const deleteClient = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
      try {
        setIsLoading(true);
        
        // Trouver le client pour obtenir son server_id
        const clientToDelete = clients.find(client => client.id === id);
        
        // Supprimer localement d'abord
        const updatedClients = clients.filter(client => client.id !== id);
        setClients(updatedClients);
        localStorage.setItem('clients', JSON.stringify(updatedClients));
        
        // Si le client a un ID serveur, le supprimer sur le serveur
        if (clientToDelete && clientToDelete.server_id) {
          await ClientService.deleteClient(id, clientToDelete.server_id);
        }
        
        // Afficher un message de confirmation
        alert('Client supprimé avec succès !');
      } catch (error) {
        console.error("Erreur lors de la suppression du client:", error);
        alert('Le client a été supprimé localement, mais n\'a pas pu être supprimé du serveur.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fonction pour éditer un client
  const editClientHandler = (id) => {
    const clientToEdit = clients.find(client => client.id === id);
    if (clientToEdit) {
      setEditClient(clientToEdit);
      setActiveTab('fiche-client');
    }
  };

  // Fonction pour importer des clients
  const handleImportClients = async (importedClients) => {
    try {
      setIsLoading(true);
      
      // Fusion avec les clients existants en évitant les doublons par ID
      const existingIds = clients.map(client => client.id);
      const newClients = importedClients.filter(client => !existingIds.includes(client.id));
      
      // Si pas de nouveaux clients à ajouter
      if (newClients.length === 0) {
        alert('Aucun nouveau client à importer.');
        setIsLoading(false);
        return;
      }
      
      // Mettre à jour la liste des clients localement
      const updatedClients = [...clients, ...newClients];
      setClients(updatedClients);
      localStorage.setItem('clients', JSON.stringify(updatedClients));
      
      // Essayer de synchroniser avec le serveur
      setSyncStatus('syncing');
      await ClientService.syncClientsWithServer();
      setSyncStatus('synced');
      
      // Afficher un message de confirmation
      alert(`${newClients.length} client(s) importé(s) avec succès !`);
      
      // Fermer le modal d'import/export
      setShowImportExport(false);
    } catch (error) {
      console.error("Erreur lors de l'importation des clients:", error);
      alert('Les clients ont été importés localement, mais n\'ont pas pu être synchronisés avec le serveur.');
      setSyncStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  // Forcer une nouvelle synchronisation avec le serveur
  const forceSync = async () => {
    try {
      setIsLoading(true);
      setSyncStatus('syncing');
      
      const syncedClients = await ClientService.syncClientsWithServer();
      setClients(syncedClients);
      
      setSyncStatus('synced');
      alert('Synchronisation terminée avec succès !');
    } catch (error) {
      console.error("Erreur lors de la synchronisation forcée:", error);
      setSyncStatus('error');
      alert('Erreur lors de la synchronisation avec le serveur.');
    } finally {
      setIsLoading(false);
    }
  };

  // Filtrer les clients selon le terme de recherche
  const filteredClients = clients.filter(client => {
    const term = searchTerm.toLowerCase();
    return (
      (client.nom && client.nom.toLowerCase().includes(term)) ||
      (client.prenom && client.prenom.toLowerCase().includes(term)) ||
      (client.telephone && client.telephone.includes(term)) ||
      (client.email && client.email.toLowerCase().includes(term))
    );
  });

  return (
    <section className="content-section active">
      <h1>Clients</h1>
      
      {/* Indicateur de statut de synchronisation */}
      {syncStatus === 'syncing' && (
        <div className="sync-status syncing">Synchronisation en cours...</div>
      )}
      {syncStatus === 'synced' && (
        <div className="sync-status synced">Synchronisé avec le serveur</div>
      )}
      {syncStatus === 'error' && (
        <div className="sync-status error">
          Erreur de synchronisation
          <button onClick={forceSync} className="retry-sync-btn">Réessayer</button>
        </div>
      )}
      
      {/* Indicateur de chargement */}
      {isLoading && <div className="loading-overlay">Chargement...</div>}
      
      {/* Onglets de navigation secondaire */}
      <div className="tabs-container">
        <button 
          className={`tab-btn ${activeTab === 'liste-clients' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('liste-clients');
            setEditClient(null);
          }}
        >
          Liste des Clients
        </button>
        <button 
          className={`tab-btn ${activeTab === 'fiche-client' ? 'active' : ''}`}
          onClick={() => {
            setEditClient(null); // Réinitialiser pour un nouveau client
            setActiveTab('fiche-client');
          }}
        >
          Nouveau Client
        </button>
      </div>
      
      {/* Contenu des onglets */}
      <div className="tab-content-container">
        {/* Liste des clients */}
        {activeTab === 'liste-clients' && (
          <div id="liste-clients" className="tab-content active">
            <h2>Liste des Clients</h2>
            
            {/* Barre de recherche et boutons d'import/export */}
            <div className="clients-header">
              <div className="search-container">
                <input 
                  type="text" 
                  id="search-client" 
                  placeholder="Rechercher un client..." 
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="import-export-container">
                <button 
                  className="import-export-btn"
                  onClick={() => setShowImportExport(true)}
                >
                  <i>📥</i> Import/Export
                </button>
                <button 
                  className="sync-btn"
                  onClick={forceSync}
                  disabled={isLoading || syncStatus === 'syncing'}
                >
                  <i>🔄</i> Synchroniser
                </button>
              </div>
            </div>
            
            <ClientsList 
              clients={filteredClients}
              onEdit={editClientHandler}
              onDelete={deleteClient}
            />
          </div>
        )}
        
        {/* Fiche client */}
        {activeTab === 'fiche-client' && (
          <div id="fiche-client" className="tab-content active">
            <h2>{editClient ? 'Modifier un Client' : 'Nouveau Client'}</h2>
            <ClientForm 
              initialData={editClient}
              onSave={editClient ? updateClient : addClient}
              onCancel={() => {
                setEditClient(null);
                // Réinitialiser le formulaire ou revenir à la liste
                if (editClient) {
                  setActiveTab('liste-clients');
                }
              }}
            />
          </div>
        )}
      </div>
      
      {/* Modal d'import/export */}
      {showImportExport && (
        <ClientImportExport 
          clients={clients}
          onClose={() => setShowImportExport(false)}
          onImport={handleImportClients}
        />
      )}
    </section>
  );
};