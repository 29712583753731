import React, { useState, useEffect, useRef, useCallback } from "react";
import { DayView } from "./DayView";
import { WeekView } from "./WeekView";
import { MonthView } from "./MonthView";
import { YearView } from "./YearView";
import { AppointmentModal } from "./AppointmentModal";
import { SettingsModal } from "./SettingsModal";
import { AgendaService } from "./AgendaService";
import { useSyncRendezVous, useSyncAgendaSettings } from "./SyncService";
import "./Agenda.css";

export const Agenda = ({ initialView = null, initialDate = null }) => {
  // États pour l'agenda
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("day"); // 'day', 'week', 'month', ou 'year'
  const [appointments, setAppointments] = useState([]);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    date: null,
    time: null,
    duration: null,
    appointmentId: null,
  });
  const [agendaSettings, setAgendaSettings] = useState({
    startHour: 8,
    endHour: 18,
    timeStep: 15,
    closedDays: [0, 6], // Dimanche (0) et Samedi (6) par défaut
    closingHours: {}, // Nouveau: heures de fermeture par jour
    enableRecurrence: false,
    enableExport: false,
    enableClientIntegration: false,
    enableReminders: false,
    enableYearView: false,
    defaultReminderTime: 60, // 60 minutes par défaut
  });
  const [isLoading, setIsLoading] = useState(false);

  // Animation de changement de vue
  const [animation, setAnimation] = useState(null);

  // Référence pour le timer de l'indicateur d'heure actuelle
  const timeIndicatorInterval = useRef(null);

  // Utilisation des hooks de synchronisation
  const { syncAppointments, saveAndSyncAppointment, deleteAndSyncAppointment } =
    useSyncRendezVous(setAppointments);

  const { syncSettings, saveAndSyncSettings } =
    useSyncAgendaSettings(setAgendaSettings);

  // Initialiser la vue à partir des props si fournies
  useEffect(() => {
    if (initialView) {
      setCurrentView(initialView);
    }
  }, [initialView]);

  // Gérer la restauration de la date si fournie
  useEffect(() => {
    if (initialDate) {
      // Tentative de parsing de la date depuis le format affiché
      try {
        // Analyse un format comme "15 mars 2025" ou similaire
        const dateParts = initialDate.match(/(\d+)\s+([^\s]+)\s+(\d{4})/);
        if (dateParts) {
          const day = parseInt(dateParts[1]);
          const month = getMonthIndex(dateParts[2]);
          const year = parseInt(dateParts[3]);
          if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            const newDate = new Date(year, month, day);
            setCurrentDate(newDate);
          }
        }
      } catch (error) {
        console.error("Erreur lors de l'analyse de la date:", error);
      }
    }
  }, [initialDate]);

  // Fonction utilitaire pour obtenir l'index du mois à partir du nom en français
  const getMonthIndex = (monthName) => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    return months.findIndex((m) => monthName.toLowerCase().includes(m));
  };

  // Mettre à jour l'indicateur d'heure actuelle toutes les minutes
  useEffect(() => {
    timeIndicatorInterval.current = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    // Nettoyage
    return () => {
      if (timeIndicatorInterval.current) {
        clearInterval(timeIndicatorInterval.current);
      }
    };
  }, []);

  // Surveiller les changements dans les paramètres pour mettre à jour la variable CSS
  useEffect(() => {
    // Calculer le nombre d'heures de travail
    const workingHours = agendaSettings.endHour - agendaSettings.startHour;

    // Mettre à jour la variable CSS pour la hauteur
    document.documentElement.style.setProperty("--working-hours", workingHours);
  }, [agendaSettings.startHour, agendaSettings.endHour]);

  // Fonction pour formater la date d'affichage selon la vue actuelle
  const formatDisplayDate = useCallback(() => {
    if (currentView === "day") {
      const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return currentDate.toLocaleDateString("fr-FR", options);
    } else if (currentView === "week") {
      // Obtenir le premier jour de la semaine (lundi)
      const firstDayOfWeek = new Date(currentDate);
      const day = currentDate.getDay(); // 0 = Dimanche, 1 = Lundi, etc.
      const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1); // Ajuster si c'est dimanche
      firstDayOfWeek.setDate(diff);

      // Obtenir le dernier jour de la semaine (dimanche)
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      // Formater les dates
      const firstDay = firstDayOfWeek.getDate();
      const lastDay = lastDayOfWeek.getDate();
      const firstMonth = firstDayOfWeek.toLocaleDateString("fr-FR", {
        month: "long",
      });
      const lastMonth = lastDayOfWeek.toLocaleDateString("fr-FR", {
        month: "long",
      });
      const year = firstDayOfWeek.getFullYear();

      // Si les mois sont différents, afficher les deux
      if (firstMonth !== lastMonth) {
        return `${firstDay} ${firstMonth} - ${lastDay} ${lastMonth} ${year}`;
      } else {
        return `${firstDay} - ${lastDay} ${firstMonth} ${year}`;
      }
    } else if (currentView === "month") {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      return `${
        monthNames[currentDate.getMonth()]
      } ${currentDate.getFullYear()}`;
    } else if (currentView === "year") {
      // Afficher seulement l'année pour la vue annuelle
      return `${currentDate.getFullYear()}`;
    }
    return "";
  }, [currentDate, currentView]);

  // Naviguer entre les dates avec animation
  const navigateDate = (direction) => {
    // Définir l'animation
    setAnimation(direction === "prev" ? "slide-right" : "slide-left");

    // Une fois l'animation définie, créer la nouvelle date
    const newDate = new Date(currentDate);
    if (currentView === "day") {
      newDate.setDate(newDate.getDate() + (direction === "prev" ? -1 : 1));
    } else if (currentView === "week") {
      newDate.setDate(newDate.getDate() + (direction === "prev" ? -7 : 7));
    } else if (currentView === "month") {
      newDate.setMonth(newDate.getMonth() + (direction === "prev" ? -1 : 1));
    } else if (currentView === "year") {
      newDate.setFullYear(
        newDate.getFullYear() + (direction === "prev" ? -1 : 1)
      );
    }

    // Après un court délai, mettre à jour la date et réinitialiser l'animation
    setTimeout(() => {
      setCurrentDate(newDate);
      setTimeout(() => {
        setAnimation(null);
      }, 300); // Durée de l'animation
    }, 10);
  };

  // Changer de vue avec animation
  const changeView = (newView) => {
    if (newView === currentView) return;

    setAnimation(
      newView === "day" ? "zoom-in" : newView === "month" ? "zoom-out" : "fade"
    );

    setTimeout(() => {
      setCurrentView(newView);
      setTimeout(() => {
        setAnimation(null);
      }, 300);
    }, 10);
  };

  // Ouvrir le modal pour un nouveau rendez-vous
  const openAppointmentModal = (
    date = null,
    time = null,
    duration = null,
    appointmentId = null
  ) => {
    // Si une date est spécifiée, l'utiliser, sinon utiliser la date actuelle
    let appointmentDate = date;
    if (!appointmentDate) {
      const yyyy = currentDate.getFullYear();
      const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
      const dd = String(currentDate.getDate()).padStart(2, "0");
      appointmentDate = `${yyyy}-${mm}-${dd}`;
    }

    setModalData({ date: appointmentDate, time, duration, appointmentId });
    setIsAppointmentModalOpen(true);
  };

  // Sauvegarder un rendez-vous - MISE À JOUR
  const saveAppointment = async (appointmentData) => {
    try {
      setIsLoading(true);

      console.log("Données du rendez-vous à enregistrer:", appointmentData);

      // Utiliser le service de synchronisation
      const savedAppointment = await saveAndSyncAppointment(
        appointmentData,
        modalData.appointmentId
      );

      console.log("Rendez-vous enregistré:", savedAppointment);

      // Fermer le modal
      setIsAppointmentModalOpen(false);

      // Afficher une notification de confirmation
      showNotification(
        modalData.appointmentId
          ? "Rendez-vous mis à jour avec succès"
          : "Nouveau rendez-vous créé"
      );

      // Synchroniser explicitement pour s'assurer que l'interface est à jour
      await syncAppointments();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du rendez-vous:", error);
      showNotification(
        "Erreur de connexion, sauvegarde locale uniquement",
        "warning"
      );

      // Sauvegarde de secours en localStorage pendant la transition
      const newAppointmentData = {
        ...appointmentData,
        id: appointmentData.id || Date.now(),
      };

      let updatedAppointments;
      if (modalData.appointmentId) {
        // Mise à jour locale d'un rendez-vous existant
        updatedAppointments = appointments.map((appointment) =>
          appointment.id === modalData.appointmentId
            ? newAppointmentData
            : appointment
        );
      } else {
        // Ajout local d'un nouveau rendez-vous
        updatedAppointments = [...appointments, newAppointmentData];
      }

      setAppointments(updatedAppointments);
      localStorage.setItem("appointments", JSON.stringify(updatedAppointments));
      setIsAppointmentModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Sauvegarder les paramètres de l'agenda - MISE À JOUR
  const saveAgendaSettings = async (settings) => {
    try {
      setIsLoading(true);

      // Utiliser le service de synchronisation
      await saveAndSyncSettings(settings);

      // Calculer le nombre d'heures de travail
      const workingHours = settings.endHour - settings.startHour;

      // Mettre à jour la variable CSS pour la hauteur
      document.documentElement.style.setProperty(
        "--working-hours",
        workingHours
      );

      setIsSettingsModalOpen(false);

      // Forcer un rendu des vues pour appliquer les nouveaux paramètres
      setCurrentDate(new Date(currentDate));

      // Afficher une notification de confirmation
      showNotification("Paramètres de l'agenda mis à jour");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des paramètres:", error);
      showNotification(
        "Erreur de connexion, sauvegarde locale uniquement",
        "warning"
      );

      // Sauvegarde locale de secours
      // Calculer le nombre d'heures de travail
      const workingHours = settings.endHour - settings.startHour;

      // Mettre à jour la variable CSS pour la hauteur
      document.documentElement.style.setProperty(
        "--working-hours",
        workingHours
      );

      setAgendaSettings(settings);
      localStorage.setItem("agenda_settings", JSON.stringify(settings));
      setIsSettingsModalOpen(false);

      // Forcer un rendu des vues pour appliquer les nouveaux paramètres
      setCurrentDate(new Date(currentDate));
    } finally {
      setIsLoading(false);
    }
  };

  // Éditer un rendez-vous existant
  const editAppointment = (id, updatedData = null) => {
    const appointment = appointments.find((app) => app.id === id);
    if (appointment) {
      if (updatedData) {
        // Si des données mises à jour sont fournies (glisser-déposer)
        const updatedAppointment = { ...appointment, ...updatedData };
        openAppointmentModal(
          updatedAppointment.date,
          updatedAppointment.start,
          updatedAppointment.duration,
          id
        );
      } else {
        // Édition normale
        openAppointmentModal(
          appointment.date,
          appointment.start,
          appointment.duration,
          id
        );
      }
    }
  };

  // Supprimer un rendez-vous - MISE À JOUR
  const deleteAppointment = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce rendez-vous ?")) {
      try {
        setIsLoading(true);

        // Utiliser le service de synchronisation
        await deleteAndSyncAppointment(id);

        // Afficher une notification de confirmation
        showNotification("Rendez-vous supprimé");

        // Synchroniser explicitement pour s'assurer que l'interface est à jour
        await syncAppointments();
      } catch (error) {
        console.error("Erreur lors de la suppression du rendez-vous:", error);
        showNotification(
          "Erreur de connexion, suppression locale uniquement",
          "warning"
        );

        // Suppression locale de secours
        const updatedAppointments = appointments.filter(
          (appointment) => appointment.id !== id
        );
        setAppointments(updatedAppointments);
        localStorage.setItem(
          "appointments",
          JSON.stringify(updatedAppointments)
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Rendre la fonction deleteAppointment accessible globalement pour le modal
  useEffect(() => {
    window.deleteAppointment = deleteAppointment;

    return () => {
      delete window.deleteAppointment;
    };
  }, [appointments]); // Dépendance mise à jour pour éviter des problèmes de stale closures

  // Système de notification
  const [notification, setNotification] = useState(null);

  const showNotification = (message, type = "success") => {
    setNotification({ message, type });

    // Masquer la notification après 3 secondes
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  // Fonctions d'export/import
  // Exporter les rendez-vous au format CSV
  const exportAppointmentsCSV = async () => {
    try {
      // Synchroniser d'abord pour avoir les données les plus récentes
      await syncAppointments();

      const appointmentsArray = appointments;

      if (appointmentsArray.length === 0) {
        alert("Aucun rendez-vous à exporter");
        return;
      }

      // Colonnes CSV
      const csvColumns = [
        "id",
        "date",
        "start",
        "duration",
        "client_name",
        "client_phone",
        "client_email",
        "type",
        "notes",
        "color",
        "is_recurring",
        "recurrence_type",
        "recurrence_interval",
        "recurrence_end_type",
        "recurrence_end_date",
        "recurrence_end_after",
        "reminder",
        "reminder_time",
      ];

      // Entête du CSV
      let csvContent = csvColumns.join(",") + "\n";

      // Contenu du CSV
      appointmentsArray.forEach((appointment) => {
        const values = csvColumns.map((column) => {
          // Obtenir la valeur ou une chaîne vide si elle n'existe pas
          const value =
            appointment[column] !== undefined ? appointment[column] : "";

          // Échapper les virgules et les guillemets
          if (
            typeof value === "string" &&
            (value.includes(",") || value.includes('"'))
          ) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        });

        csvContent += values.join(",") + "\n";
      });

      // Créer un blob et télécharger le fichier
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `rendez-vous_${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showNotification("Les rendez-vous ont été exportés avec succès");
    } catch (error) {
      console.error("Erreur lors de l'exportation des rendez-vous:", error);
      showNotification("Erreur lors de l'exportation", "error");
    }
  };

  // Exporter les rendez-vous au format iCalendar (ics)
  const exportAppointmentsICS = async () => {
    try {
      // Synchroniser d'abord pour avoir les données les plus récentes
      await syncAppointments();

      const appointmentsArray = appointments;

      if (appointmentsArray.length === 0) {
        alert("Aucun rendez-vous à exporter");
        return;
      }

      // Entête du fichier ICS
      let icsContent = "BEGIN:VCALENDAR\n";
      icsContent += "VERSION:2.0\n";
      icsContent += "PRODID:-//Agenda//FR\n";
      icsContent += "CALSCALE:GREGORIAN\n";
      icsContent += "METHOD:PUBLISH\n";

      // Ajouter chaque rendez-vous
      appointmentsArray.forEach((appointment) => {
        icsContent += "BEGIN:VEVENT\n";

        // Générer un UID unique pour l'événement
        icsContent += `UID:${appointment.id}@agenda.app\n`;

        // Formater la date de début (au format UTC)
        const [year, month, day] = appointment.date.split("-");
        const [startHour, startMinute] = appointment.start.split(":");
        const startDate = new Date(
          year,
          month - 1,
          day,
          startHour,
          startMinute
        );
        const formattedStart = startDate
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/, "");

        // Formater la date de fin
        const endDate = new Date(
          startDate.getTime() + appointment.duration * 60000
        );
        const formattedEnd = endDate
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/, "");

        icsContent += `DTSTART:${formattedStart}\n`;
        icsContent += `DTEND:${formattedEnd}\n`;

        // Date de création (maintenant)
        const now = new Date()
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/, "");
        icsContent += `DTSTAMP:${now}\n`;

        // Titre et description
        icsContent += `SUMMARY:${escapeICS(
          appointment.type || "Rendez-vous"
        )} - ${escapeICS(appointment.client_name)}\n`;
        if (appointment.notes) {
          icsContent += `DESCRIPTION:${escapeICS(appointment.notes)}\n`;
        }

        // Gestion de la récurrence
        if (appointment.is_recurring || appointment.isRecurring) {
          let rrule = "RRULE:FREQ=";
          const recurrenceType =
            appointment.recurrence_type || appointment.recurrenceType;
          const recurrenceInterval =
            appointment.recurrence_interval || appointment.recurrenceInterval;

          switch (recurrenceType) {
            case "daily":
              rrule += "DAILY";
              break;
            case "weekly":
              rrule += "WEEKLY";
              break;
            case "biweekly":
              rrule += "WEEKLY;INTERVAL=2";
              break;
            case "monthly":
              rrule += "MONTHLY";
              break;
            case "custom":
              rrule += `DAILY;INTERVAL=${recurrenceInterval}`;
              break;
            default:
              rrule += "DAILY"; // Cas par défaut
              break;
          }

          // Ajouter l'intervalle s'il n'est pas déjà défini
          if (!rrule.includes("INTERVAL=") && recurrenceInterval > 1) {
            rrule += `;INTERVAL=${recurrenceInterval}`;
          }

          // Ajouter la date de fin ou le nombre d'occurrences
          const recurrenceEndType =
            appointment.recurrence_end_type || appointment.recurrenceEndType;
          const recurrenceEndDate =
            appointment.recurrence_end_date || appointment.recurrenceEndDate;
          const recurrenceEndAfter =
            appointment.recurrence_end_after || appointment.recurrenceEndAfter;

          if (recurrenceEndType === "on" && recurrenceEndDate) {
            const untilDate = recurrenceEndDate.replace(/-/g, "") + "T235959Z";
            rrule += `;UNTIL=${untilDate}`;
          } else if (recurrenceEndType === "after" && recurrenceEndAfter) {
            rrule += `;COUNT=${recurrenceEndAfter}`;
          }

          icsContent += rrule + "\n";
        }

        // Rappel
        const reminder = appointment.reminder || appointment.has_reminder;
        const reminderTime =
          appointment.reminder_time || appointment.reminderTime;

        if (reminder) {
          icsContent += "BEGIN:VALARM\n";
          icsContent += "ACTION:DISPLAY\n";
          icsContent += `DESCRIPTION:Rappel: ${escapeICS(
            appointment.type || "Rendez-vous"
          )} - ${escapeICS(appointment.client_name)}\n`;
          icsContent += `TRIGGER:-PT${reminderTime}M\n`;
          icsContent += "END:VALARM\n";
        }

        icsContent += "END:VEVENT\n";
      });

      icsContent += "END:VCALENDAR";

      // Créer un blob et télécharger le fichier
      const blob = new Blob([icsContent], {
        type: "text/calendar;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `rendez-vous_${new Date().toISOString().split("T")[0]}.ics`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showNotification("Les rendez-vous ont été exportés au format iCalendar");
    } catch (error) {
      console.error("Erreur lors de l'exportation des rendez-vous:", error);
      showNotification("Erreur lors de l'exportation", "error");
    }
  };

  // Fonction utilitaire pour échapper les caractères spéciaux en iCalendar
  const escapeICS = (text) => {
    if (!text) return "";
    return text
      .replace(/\\/g, "\\\\")
      .replace(/;/g, "\\;")
      .replace(/,/g, "\\,")
      .replace(/\n/g, "\\n");
  };

  // Importer des rendez-vous depuis un fichier CSV
  const importAppointmentsCSV = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const csvData = e.target.result;
        const lines = csvData.split("\n");

        // Vérifier si le fichier est vide
        if (lines.length < 2) {
          alert("Le fichier CSV est vide ou invalide");
          return;
        }

        // Obtenir les entêtes
        const headers = lines[0].split(",");

        // Analyser les lignes de données
        const importedAppointments = [];

        for (let i = 1; i < lines.length; i++) {
          if (!lines[i].trim()) continue; // Ignorer les lignes vides

          // Gérer les champs avec des guillemets et des virgules
          const values = [];
          let currentValue = "";
          let insideQuotes = false;

          for (let char of lines[i]) {
            if (char === '"') {
              insideQuotes = !insideQuotes;
            } else if (char === "," && !insideQuotes) {
              values.push(currentValue);
              currentValue = "";
            } else {
              currentValue += char;
            }
          }
          values.push(currentValue); // Ajouter la dernière valeur

          // Créer l'objet rendez-vous en associant les entêtes aux valeurs
          const appointment = {};

          headers.forEach((header, index) => {
            if (index < values.length) {
              // Nettoyer les guillemets
              let value = values[index]
                .replace(/^"(.*)"$/, "$1")
                .replace(/""/g, '"');

              // Convertir les valeurs booléennes et numériques
              if (value === "true") value = true;
              else if (value === "false") value = false;
              else if (
                !isNaN(value) &&
                value !== "" &&
                !header.includes("phone")
              ) {
                value = Number(value);
              }

              appointment[header] = value;
            }
          });

          importedAppointments.push(appointment);
        }

        // Vérifier s'il y a des rendez-vous à importer
        if (importedAppointments.length === 0) {
          alert("Aucun rendez-vous à importer");
          return;
        }

        // Demander confirmation avant d'importer
        const confirmation = window.confirm(
          `Êtes-vous sûr de vouloir importer ${importedAppointments.length} rendez-vous ?`
        );

        if (confirmation) {
          setIsLoading(true);

          try {
            // Importer les rendez-vous un par un
            for (const appointmentData of importedAppointments) {
              // Formatage des données pour l'API
              const apiData = {
                client_name: appointmentData.client_name,
                client_phone: appointmentData.client_phone,
                client_email: appointmentData.client_email,
                date: appointmentData.date,
                start: appointmentData.start,
                duration: appointmentData.duration,
                type: appointmentData.type,
                notes: appointmentData.notes || "",
                color: appointmentData.color || "#c6b291",
                is_recurring: appointmentData.is_recurring || false,
                recurrence_type: appointmentData.recurrence_type || null,
                recurrence_interval:
                  appointmentData.recurrence_interval || null,
                recurrence_end_date:
                  appointmentData.recurrence_end_date || null,
                recurrence_end_after:
                  appointmentData.recurrence_end_after || null,
                recurrence_end_type:
                  appointmentData.recurrence_end_type || null,
                reminder: appointmentData.reminder || false,
                reminder_time: appointmentData.reminder_time || null,
              };

              await AgendaService.createAppointment(apiData);
            }

            // Synchroniser pour mettre à jour l'interface
            await syncAppointments();

            showNotification(
              `${importedAppointments.length} rendez-vous importés avec succès`
            );
          } catch (error) {
            console.error("Erreur lors de l'importation vers l'API:", error);
            showNotification(
              "Erreur lors de l'importation vers le serveur",
              "error"
            );

            // Sauvegarde locale en cas d'échec
            const updatedAppointments = [
              ...appointments,
              ...importedAppointments,
            ];
            setAppointments(updatedAppointments);
            localStorage.setItem(
              "appointments",
              JSON.stringify(updatedAppointments)
            );

            showNotification(
              `${importedAppointments.length} rendez-vous importés localement`,
              "warning"
            );
          }
        }
      } catch (error) {
        console.error("Erreur lors de l'importation CSV:", error);
        alert(
          "Erreur lors de l'importation. Vérifiez le format du fichier CSV."
        );
      } finally {
        setIsLoading(false);
      }

      // Réinitialiser l'input file
      event.target.value = null;
    };

    reader.readAsText(file);
  };

  // Composant pour les boutons d'export/import
  const ExportImportButtons = ({ onExportCSV, onExportICS, onImportCSV }) => {
    const fileInputRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);

    // Gérer le clic en dehors du popup pour le fermer
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      };

      if (showPopup) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showPopup]);

    return (
      <div className="export-import-container">
        <button className="export-btn" onClick={() => setShowPopup(true)}>
          Export/Import
        </button>

        {showPopup && (
          <div className="export-popup" ref={popupRef}>
            <div className="popup-header">
              <h3>Export/Import</h3>
              <button
                className="close-popup"
                onClick={() => setShowPopup(false)}
              >
                ×
              </button>
            </div>
            <div className="popup-content">
              <h4>Exporter des rendez-vous</h4>
              <div className="export-buttons">
                <button
                  onClick={() => {
                    onExportCSV();
                    setShowPopup(false);
                  }}
                >
                  Exporter en CSV
                </button>
                <button
                  onClick={() => {
                    onExportICS();
                    setShowPopup(false);
                  }}
                >
                  Exporter en iCalendar
                </button>
              </div>

              <h4>Importer des rendez-vous</h4>
              <button
                onClick={() => {
                  fileInputRef.current.click();
                  setShowPopup(false);
                }}
              >
                Importer depuis CSV
              </button>
            </div>
          </div>
        )}

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".csv"
          onChange={onImportCSV}
        />
      </div>
    );
  };

  // Synchronisation initiale des rendez-vous au chargement
  useEffect(() => {
    const loadData = async () => {
      try {
        // Chargement des paramètres de l'agenda
        await syncSettings();

        // Chargement des rendez-vous
        await syncAppointments();

        console.log("Chargement initial des données terminé");
      } catch (error) {
        console.error("Erreur lors du chargement initial des données:", error);
        showNotification("Erreur lors du chargement des données", "error");
      }
    };

    loadData();
  }, []);

  return (
    <section className="content-section active agenda-container">
      <h1>Agenda</h1>

      {/* Contrôles de l'agenda */}
      <div className="agenda-controls">
        <div className="view-selector">
          <button
            className={`view-btn ${currentView === "day" ? "active" : ""}`}
            onClick={() => changeView("day")}
          >
            Jour
          </button>
          <button
            className={`view-btn ${currentView === "week" ? "active" : ""}`}
            onClick={() => changeView("week")}
          >
            Semaine
          </button>
          <button
            className={`view-btn ${currentView === "month" ? "active" : ""}`}
            onClick={() => changeView("month")}
          >
            Mois
          </button>
          {agendaSettings.enableYearView && (
            <button
              className={`view-btn ${currentView === "year" ? "active" : ""}`}
              onClick={() => changeView("year")}
            >
              Année
            </button>
          )}
        </div>

        <div className="date-navigation">
          <button className="nav-date-btn" onClick={() => navigateDate("prev")}>
            &#9664;
          </button>
          <span className="current-date">{formatDisplayDate()}</span>
          <button className="nav-date-btn" onClick={() => navigateDate("next")}>
            &#9654;
          </button>
          <button
            className="today-btn"
            onClick={() => setCurrentDate(new Date())}
          >
            Aujourd'hui
          </button>
        </div>

        <div className="agenda-actions">
          <button className="new-btn" onClick={() => openAppointmentModal()}>
            <span>+</span> Nouveau rendez-vous
          </button>

          {agendaSettings.enableExport && (
            <ExportImportButtons
              onExportCSV={exportAppointmentsCSV}
              onExportICS={exportAppointmentsICS}
              onImportCSV={importAppointmentsCSV}
            />
          )}

          <button
            className="settings-btn"
            onClick={() => setIsSettingsModalOpen(true)}
          >
            <i className="settings-icon">⚙️</i> Paramètres
          </button>
        </div>
      </div>

      {/* Affichage de la vue active avec animation */}
      <div className={`calendar-container ${animation ? animation : ""}`}>
        {currentView === "day" && (
          <DayView
            currentDate={currentDate}
            settings={agendaSettings}
            appointments={appointments}
            onAppointmentClick={editAppointment}
            onTimeSlotClick={openAppointmentModal}
          />
        )}

        {currentView === "week" && (
          <WeekView
            currentDate={currentDate}
            settings={agendaSettings}
            appointments={appointments}
            onAppointmentClick={editAppointment}
            onTimeSlotClick={openAppointmentModal}
          />
        )}

        {currentView === "month" && (
          <MonthView
            currentDate={currentDate}
            settings={agendaSettings}
            appointments={appointments}
            onAppointmentClick={editAppointment}
            onDateClick={openAppointmentModal}
          />
        )}

        {currentView === "year" && (
          <YearView
            currentDate={currentDate}
            settings={agendaSettings}
            appointments={appointments}
            onAppointmentClick={editAppointment}
            onDateClick={openAppointmentModal}
          />
        )}
      </div>

      {/* Notification */}
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}

      {/* Indicateur de chargement */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Chargement...</div>
        </div>
      )}

      {/* Modal de création/modification de rendez-vous */}
      {isAppointmentModalOpen && (
        <AppointmentModal
          isOpen={isAppointmentModalOpen}
          onClose={() => setIsAppointmentModalOpen(false)}
          onSave={saveAppointment}
          initialData={modalData}
          settings={agendaSettings}
          existingAppointment={
            modalData.appointmentId
              ? appointments.find((app) => app.id === modalData.appointmentId)
              : null
          }
        />
      )}

      {/* Modal des paramètres */}
      {isSettingsModalOpen && (
        <SettingsModal
          isOpen={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
          settings={agendaSettings}
          onSave={saveAgendaSettings}
        />
      )}
    </section>
  );
};
