import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import "./Synthese.css";
import { HistoryComponent } from "./HistoryComponent";
import { exportHistoryToCsv } from "./HistoryUtils";
import "./HistoryStyles.css";
import StockSyncService from '../../services/StockSyncService';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from "recharts";

export const Synthese = () => {
  const { currentUser, isAdmin } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [clients, setClients] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [stats, setStats] = useState({
    clientsCount: 0,
    appointmentsCount: 0,
    appointmentsThisMonth: 0,
    transactionsTotal: 0,
    transactionsThisMonth: 0,
    topCategories: [],
    popularServices: [],
    revenueByMonth: [],
  });
  const [exportPeriod, setExportPeriod] = useState("current-month");
  const [exportFormat, setExportFormat] = useState("csv");
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [syncStatus, setSyncStatus] = useState({
    lastSync: null,
    isSyncing: false,
  });

  // Nouvelles variables d'état pour la sélection de période et les visualisations
  const [selectedPeriodType, setSelectedPeriodType] = useState("month");
  const [selectedPeriod, setSelectedPeriod] = useState({
    day: new Date().getDate(),
    week: getWeekNumber(new Date()),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [viewMode, setViewMode] = useState("real"); // 'real', 'forecast', 'comparison'
  const [activeChartTab, setActiveChartTab] = useState("revenue"); // 'revenue', 'appointments', 'categories'

  // Données prévisionnelles (simulées)
  const [forecastData, setForecastData] = useState(null);

  // Générer les options pour les sélecteurs
  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - 2 + i
  );
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const weeks = Array.from({ length: 53 }, (_, i) => i + 1);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  // Charger les données au chargement du composant
  useEffect(() => {
    if (!isAdmin()) {
      return;
    }

    // Charger les rendez-vous
    const storedAppointments = JSON.parse(
      localStorage.getItem("appointments") || "[]"
    );
    setAppointments(storedAppointments);

    // Charger les clients
    const storedClients = JSON.parse(localStorage.getItem("clients") || "[]");
    setClients(storedClients);

    // Charger les transactions
    const storedTransactions = JSON.parse(
      localStorage.getItem("transactions") || "[]"
    );
    setTransactions(storedTransactions);

    // Charger les articles de stock
    const storedStockItems = JSON.parse(
      localStorage.getItem("stock_items") || "[]"
    );
    setStockItems(storedStockItems);

    // Générer des données prévisionnelles simulées
    generateForecastData();

    // Charger le statut de synchronisation
    loadSyncStatus();
  }, [isAdmin]);

  // Calculer les statistiques lorsque les données ou la période changent
  useEffect(() => {
    if (!isAdmin()) {
      return;
    }

    calculateStats();
  }, [
    appointments,
    clients,
    transactions,
    stockItems,
    selectedPeriod,
    selectedPeriodType,
    isAdmin,
  ]);

  // Fonction pour obtenir le numéro de semaine d'une date
  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  }

  // Fonction pour générer des données prévisionnelles simulées
  const generateForecastData = () => {
    // Simuler des revenus prévisionnels par mois pour l'année en cours
    const currentYear = new Date().getFullYear();
    const monthlyForecast = Array.from({ length: 12 }, (_, month) => {
      // Valeur de base + variations saisonnières
      const baseValue = 5000 + Math.random() * 2000;
      const seasonality =
        month >= 5 && month <= 7
          ? 1.5 // Été: +50%
          : month >= 10 || month <= 1
          ? 1.3 // Vacances d'hiver: +30%
          : 1;

      return {
        month,
        monthYear: `${month + 1}/${currentYear}`,
        forecast: Math.round(baseValue * seasonality),
        // Les rendez-vous prévisionnels
        appointmentsForecast:
          Math.round((baseValue * seasonality) / 100) +
          Math.floor(Math.random() * 10),
      };
    });

    // Catégories prévisionnelles
    const categoriesForecast = [
      { name: "Massage", forecast: 35 },
      { name: "Soins du visage", forecast: 25 },
      { name: "Manucure", forecast: 20 },
      { name: "Pédicure", forecast: 15 },
      { name: "Coiffure", forecast: 5 },
    ];

    setForecastData({
      monthly: monthlyForecast,
      categories: categoriesForecast,
    });
  };

  // Fonction pour calculer les statistiques
  const calculateStats = () => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    // Filtrer les données en fonction de la période sélectionnée
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.date);

      if (selectedPeriodType === "year") {
        return transactionDate.getFullYear() === selectedPeriod.year;
      } else if (selectedPeriodType === "month") {
        return (
          transactionDate.getMonth() === selectedPeriod.month &&
          transactionDate.getFullYear() === selectedPeriod.year
        );
      } else if (selectedPeriodType === "week") {
        return (
          getWeekNumber(transactionDate) === selectedPeriod.week &&
          transactionDate.getFullYear() === selectedPeriod.year
        );
      } else if (selectedPeriodType === "day") {
        return (
          transactionDate.getDate() === selectedPeriod.day &&
          transactionDate.getMonth() === selectedPeriod.month &&
          transactionDate.getFullYear() === selectedPeriod.year
        );
      }

      return true;
    });

    const filteredAppointments = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.date);

      if (selectedPeriodType === "year") {
        return appointmentDate.getFullYear() === selectedPeriod.year;
      } else if (selectedPeriodType === "month") {
        return (
          appointmentDate.getMonth() === selectedPeriod.month &&
          appointmentDate.getFullYear() === selectedPeriod.year
        );
      } else if (selectedPeriodType === "week") {
        return (
          getWeekNumber(appointmentDate) === selectedPeriod.week &&
          appointmentDate.getFullYear() === selectedPeriod.year
        );
      } else if (selectedPeriodType === "day") {
        return (
          appointmentDate.getDate() === selectedPeriod.day &&
          appointmentDate.getMonth() === selectedPeriod.month &&
          appointmentDate.getFullYear() === selectedPeriod.year
        );
      }

      return true;
    });

    // Statistiques de base
    const clientsCount = clients.length;
    const appointmentsCount = filteredAppointments.length;

    // Rendez-vous du mois en cours
    const appointmentsThisMonth = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.date);
      return (
        appointmentDate.getMonth() === currentMonth &&
        appointmentDate.getFullYear() === currentYear
      );
    }).length;

    // Statistiques des transactions
    const transactionsTotal = filteredTransactions.reduce(
      (sum, transaction) => sum + (parseFloat(transaction.total) || 0),
      0
    );

    // Transactions du mois en cours
    const transactionsThisMonth = transactions
      .filter((transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getMonth() === currentMonth &&
          transactionDate.getFullYear() === currentYear
        );
      })
      .reduce(
        (sum, transaction) => sum + (parseFloat(transaction.total) || 0),
        0
      );

    // Catégories les plus populaires (basé sur les rendez-vous)
    const categories = {};
    filteredAppointments.forEach((appointment) => {
      if (appointment.type) {
        categories[appointment.type] = (categories[appointment.type] || 0) + 1;
      }
    });

    const topCategories = Object.entries(categories)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([category, count]) => ({ category, count, value: count })); // 'value' pour les graphiques

    // Services les plus populaires (basé sur les transactions)
    const services = {};
    filteredTransactions.forEach((transaction) => {
      transaction.items?.forEach((item) => {
        if (item.nom) {
          services[item.nom] = (services[item.nom] || 0) + 1;
        }
      });
    });

    const popularServices = Object.entries(services)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([name, count]) => ({ name, count, value: count })); // 'value' pour les graphiques

    // Revenus par période (par jour, semaine, mois ou année selon la sélection)
    let revenueData = [];

    if (selectedPeriodType === "year") {
      // Revenus mensuels pour l'année sélectionnée
      const monthlyRevenue = {};

      filteredTransactions.forEach((transaction) => {
        const date = new Date(transaction.date);
        const month = date.getMonth();

        monthlyRevenue[month] =
          (monthlyRevenue[month] || 0) + (parseFloat(transaction.total) || 0);
      });

      revenueData = Array.from({ length: 12 }, (_, month) => ({
        month,
        monthName: months[month],
        real: monthlyRevenue[month] || 0,
        forecast:
          forecastData?.monthly.find((m) => m.month === month)?.forecast || 0,
      }));
    } else if (selectedPeriodType === "month") {
      // Revenus par jour pour le mois sélectionné
      const dailyRevenue = {};

      filteredTransactions.forEach((transaction) => {
        const date = new Date(transaction.date);
        const day = date.getDate();

        dailyRevenue[day] =
          (dailyRevenue[day] || 0) + (parseFloat(transaction.total) || 0);
      });

      const daysInMonth = new Date(
        selectedPeriod.year,
        selectedPeriod.month + 1,
        0
      ).getDate();

      revenueData = Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1;
        // Pour le prévisionnel journalier, on divise le mensuel par le nombre de jours
        // et on ajoute une variation aléatoire
        const monthForecast =
          forecastData?.monthly.find((m) => m.month === selectedPeriod.month)
            ?.forecast || 0;
        const dailyForecast =
          (monthForecast / daysInMonth) * (0.7 + Math.random() * 0.6);

        return {
          day,
          dayName: `${day}/${selectedPeriod.month + 1}`,
          real: dailyRevenue[day] || 0,
          forecast: Math.round(dailyForecast),
        };
      });
    } else if (selectedPeriodType === "week") {
      // Pour la semaine, on simule des revenus par jour de la semaine
      const daysOfWeekRevenue = {};

      filteredTransactions.forEach((transaction) => {
        const date = new Date(transaction.date);
        const dayOfWeek = date.getDay(); // 0 = dimanche, 1 = lundi, ...

        daysOfWeekRevenue[dayOfWeek] =
          (daysOfWeekRevenue[dayOfWeek] || 0) +
          (parseFloat(transaction.total) || 0);
      });

      const daysOfWeekNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];

      revenueData = Array.from({ length: 7 }, (_, i) => {
        // Pour le prévisionnel par jour de semaine, on divise le mensuel par 4 (semaines) puis par 7 (jours)
        // et on ajoute une variation selon le jour (plus occupé le week-end, moins le lundi)
        const monthForecast =
          forecastData?.monthly.find((m) => m.month === selectedPeriod.month)
            ?.forecast || 0;
        const dayMultiplier =
          i === 0 || i === 6
            ? 1.5 // Week-end
            : i === 1
            ? 0.7 // Lundi
            : 1; // Autres jours
        const dailyForecast = (monthForecast / 4 / 7) * dayMultiplier;

        return {
          dayOfWeek: i,
          dayName: daysOfWeekNames[i],
          real: daysOfWeekRevenue[i] || 0,
          forecast: Math.round(dailyForecast),
        };
      });
    }

    // Mettre à jour les statistiques
    setStats({
      clientsCount,
      appointmentsCount,
      appointmentsThisMonth,
      transactionsTotal,
      transactionsThisMonth,
      topCategories,
      popularServices,
      revenueByMonth: revenueData,
    });
  };

  // Charger le statut de synchronisation
  const loadSyncStatus = async () => {
    try {
      const status = await StockSyncService.getSyncStatus();
      setSyncStatus({
        lastSync: status.lastSynthesisSync,
        isSyncing: false,
      });
    } catch (error) {
      console.error(
        "Erreur lors du chargement du statut de synchronisation:",
        error
      );
    }
  };

  // Synchroniser les données de synthèse avec le serveur
  const syncSynthesisWithServer = async () => {
    if (syncStatus.isSyncing) {
      alert("Une synchronisation est déjà en cours.");
      return;
    }

    try {
      setSyncStatus({ ...syncStatus, isSyncing: true });

      // Calcul des statistiques de stock
      const outOfStockCount = stockItems.filter(
        (item) => item.quantite <= 0
      ).length;
      const lowStockCount = stockItems.filter(
        (item) => item.quantite > 0 && item.quantite <= item.quantiteMin
      ).length;

      // Créer l'objet de synthèse à partir des données actuelles
      const synthesisData = {
        period: `${selectedPeriod.month + 1}/${selectedPeriod.year}`,
        clientsCount: stats.clientsCount,
        appointmentsCount: stats.appointmentsCount,
        transactionsTotal: stats.transactionsTotal,
        topCategories: stats.topCategories,
        stockStats: {
          totalItems: stockItems.length,
          outOfStockCount: outOfStockCount,
          lowStockCount: lowStockCount,
        },
      };

      await StockSyncService.syncSynthesis(synthesisData);

      // Mettre à jour le statut après synchronisation
      loadSyncStatus();

      alert("Synchronisation des données de synthèse réussie!");
    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      alert(`Erreur lors de la synchronisation: ${error.message}`);
    } finally {
      setSyncStatus((prev) => ({ ...prev, isSyncing: false }));
    }
  };

  // Formatage des nombres en euros
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  // Récupérer le nom du mois à partir du numéro
  const getMonthName = (monthNumber) => {
    return months[monthNumber - 1] || "";
  };

  // Format de date pour l'export
  const formatDateForExport = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  // Fonction pour filtrer les transactions selon la période sélectionnée
  const filterTransactionsByPeriod = () => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.date);

      if (exportPeriod === "current-month") {
        return (
          transactionDate.getMonth() === currentMonth &&
          transactionDate.getFullYear() === currentYear
        );
      } else if (exportPeriod === "current-year") {
        return transactionDate.getFullYear() === currentYear;
      } else if (exportPeriod === "last-month") {
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear =
          currentMonth === 0 ? currentYear - 1 : currentYear;
        return (
          transactionDate.getMonth() === lastMonth &&
          transactionDate.getFullYear() === lastMonthYear
        );
      } else if (exportPeriod === "last-year") {
        return transactionDate.getFullYear() === currentYear - 1;
      }

      return true; // 'all' - toutes les transactions
    });
  };

  // Fonction pour formater une période pour le nom de fichier
  const formatPeriodForFilename = () => {
    const now = new Date();

    if (exportPeriod === "current-month") {
      return `${getMonthName(
        now.getMonth() + 1
      ).toLowerCase()}-${now.getFullYear()}`;
    } else if (exportPeriod === "current-year") {
      return `annee-${now.getFullYear()}`;
    } else if (exportPeriod === "last-month") {
      const lastMonth = now.getMonth() === 0 ? 12 : now.getMonth();
      const lastMonthYear =
        now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
      return `${getMonthName(lastMonth).toLowerCase()}-${lastMonthYear}`;
    } else if (exportPeriod === "last-year") {
      return `annee-${now.getFullYear() - 1}`;
    }

    return `complet-${now.getFullYear()}`;
  };

  // Exporter pour le comptable - format CSV
  const exportCSVForAccountant = () => {
    const filteredTransactions = filterTransactionsByPeriod();

    if (filteredTransactions.length === 0) {
      alert("Aucune transaction à exporter pour cette période.");
      return;
    }

    // Définir les en-têtes CSV
    const headers = [
      "Date",
      "Ref. Transaction",
      "Client",
      "Prestation",
      "Montant HT",
      "TVA",
      "Montant TTC",
      "Mode de Paiement",
    ];

    let csvContent = headers.join(";") + "\n";

    // Pour chaque transaction, générer une ligne par article
    filteredTransactions.forEach((transaction) => {
      const date = formatDateForExport(transaction.date);
      const reference = `TR-${transaction.id}`;
      const clientName = transaction.client
        ? `${transaction.client.prenom || ""} ${
            transaction.client.nom || ""
          }`.trim()
        : "Client anonyme";
      const paymentMethod = translatePaymentMethod(transaction.paymentMethod);

      // Si la transaction a des items
      if (transaction.items && transaction.items.length > 0) {
        transaction.items.forEach((item) => {
          // Calculer montant HT (on considère un taux de TVA standard de 20%)
          const ttc = item.prix || 0;
          const ht = parseFloat((ttc / 1.2).toFixed(2));
          const tva = parseFloat((ttc - ht).toFixed(2));

          const line = [
            date,
            reference,
            clientName,
            item.nom || "Prestation non spécifiée",
            ht.toString().replace(".", ","), // Format français
            tva.toString().replace(".", ","), // Format français
            ttc.toString().replace(".", ","), // Format français
            paymentMethod,
          ];

          csvContent += line.join(";") + "\n";
        });
      } else {
        // Si pas d'items, une seule ligne pour toute la transaction
        const ttc = transaction.total || 0;
        const ht = parseFloat((ttc / 1.2).toFixed(2));
        const tva = parseFloat((ttc - ht).toFixed(2));

        const line = [
          date,
          reference,
          clientName,
          "Divers",
          ht.toString().replace(".", ","), // Format français
          tva.toString().replace(".", ","), // Format français
          ttc.toString().replace(".", ","), // Format français
          paymentMethod,
        ];

        csvContent += line.join(";") + "\n";
      }
    });

    // Créer un blob et télécharger le fichier
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `export-comptable-${formatPeriodForFilename()}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Notifier l'utilisateur
    alert("L'export comptable a été généré avec succès.");
  };

  // Exporter pour le comptable - format Excel (simulé avec CSV)
  const exportExcelForAccountant = () => {
    // Utiliser le même format CSV mais avec un nom de fichier différent
    const filteredTransactions = filterTransactionsByPeriod();

    if (filteredTransactions.length === 0) {
      alert("Aucune transaction à exporter pour cette période.");
      return;
    }

    // Définir les en-têtes CSV
    const headers = [
      "Date",
      "Ref. Transaction",
      "Client",
      "Prestation",
      "Montant HT",
      "TVA",
      "Montant TTC",
      "Mode de Paiement",
    ];

    let csvContent = headers.join(";") + "\n";

    // Pour chaque transaction, générer une ligne par article
    filteredTransactions.forEach((transaction) => {
      const date = formatDateForExport(transaction.date);
      const reference = `TR-${transaction.id}`;
      const clientName = transaction.client
        ? `${transaction.client.prenom || ""} ${
            transaction.client.nom || ""
          }`.trim()
        : "Client anonyme";
      const paymentMethod = translatePaymentMethod(transaction.paymentMethod);

      // Si la transaction a des items
      if (transaction.items && transaction.items.length > 0) {
        transaction.items.forEach((item) => {
          // Calculer montant HT (on considère un taux de TVA standard de 20%)
          const ttc = item.prix || 0;
          const ht = parseFloat((ttc / 1.2).toFixed(2));
          const tva = parseFloat((ttc - ht).toFixed(2));

          const line = [
            date,
            reference,
            clientName,
            item.nom || "Prestation non spécifiée",
            ht.toString().replace(".", ","), // Format français
            tva.toString().replace(".", ","), // Format français
            ttc.toString().replace(".", ","), // Format français
            paymentMethod,
          ];

          csvContent += line.join(";") + "\n";
        });
      } else {
        // Si pas d'items, une seule ligne pour toute la transaction
        const ttc = transaction.total || 0;
        const ht = parseFloat((ttc / 1.2).toFixed(2));
        const tva = parseFloat((ttc - ht).toFixed(2));

        const line = [
          date,
          reference,
          clientName,
          "Divers",
          ht.toString().replace(".", ","), // Format français
          tva.toString().replace(".", ","), // Format français
          ttc.toString().replace(".", ","), // Format français
          paymentMethod,
        ];

        csvContent += line.join(";") + "\n";
      }
    });

    // Créer un blob et télécharger le fichier
    const blob = new Blob([csvContent], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `export-comptable-${formatPeriodForFilename()}.xls`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Notifier l'utilisateur
    alert("L'export comptable a été généré avec succès.");
  };

  // Fonction pour traduire les méthodes de paiement en français
  const translatePaymentMethod = (method) => {
    const methods = {
      cash: "Espèces",
      card: "Carte bancaire",
      cheque: "Chèque",
    };

    return methods[method] || method;
  };

  // Fonction principale d'export qui détermine quel type d'export utiliser
  const handleExport = () => {
    if (exportFormat === "csv") {
      exportCSVForAccountant();
    } else {
      exportExcelForAccountant();
    }
  };

  // Fonction pour exporter l'historique
  const handleExportHistory = () => {
    exportHistoryToCsv();
  };

  // Changer d'onglet
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Changer d'onglet pour les graphiques
  const handleChartTabChange = (tab) => {
    setActiveChartTab(tab);
  };

  // Gérer le changement de période
  const handlePeriodTypeChange = (type) => {
    setSelectedPeriodType(type);
  };

  // Gérer le changement de période spécifique
  const handlePeriodChange = (key, value) => {
    setSelectedPeriod((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Gérer le changement de mode de visualisation
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  // Couleurs pour les graphiques
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#0088FE",
    "#00C49F",
  ];
  const REAL_COLOR = "#8884d8";
  const FORECAST_COLOR = "#82ca9d";

  // Rediriger si l'utilisateur n'est pas admin - placé après tous les hooks
  if (!isAdmin()) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <section className="content-section active">
      <h1>
        Synthèse <span className="admin-only-badge">Admin uniquement</span>
      </h1>

      {/* Indicateur de synchronisation */}
      <div className="sync-status-indicator">
        <span>
          Dernière synchronisation:{" "}
          {syncStatus.lastSync
            ? new Date(syncStatus.lastSync).toLocaleString()
            : "Jamais"}
        </span>
        <button
          className="sync-button"
          onClick={syncSynthesisWithServer}
          disabled={syncStatus.isSyncing}
        >
          {syncStatus.isSyncing
            ? "Synchronisation..."
            : "Sauvegarder sur le serveur"}
        </button>
      </div>

      {/* Onglets de navigation pour la Synthèse */}
      <div className="synthese-tabs">
        <div
          className={`synthese-tab ${
            activeTab === "dashboard" ? "active" : ""
          }`}
          onClick={() => handleTabChange("dashboard")}
        >
          Tableau de bord
        </div>
        <div
          className={`synthese-tab ${activeTab === "history" ? "active" : ""}`}
          onClick={() => handleTabChange("history")}
        >
          Historique
        </div>
      </div>

      {/* Contenu de l'onglet Tableau de bord */}
      <div
        className={`synthese-tab-content ${
          activeTab === "dashboard" ? "active" : ""
        }`}
      >
        {/* Sélecteurs de période et mode de visualisation */}
        <div className="period-selector-container">
          <div className="period-type-selector">
            <label>Période :</label>
            <div className="period-buttons">
              <button
                className={`period-btn ${
                  selectedPeriodType === "day" ? "active" : ""
                }`}
                onClick={() => handlePeriodTypeChange("day")}
              >
                Jour
              </button>
              <button
                className={`period-btn ${
                  selectedPeriodType === "week" ? "active" : ""
                }`}
                onClick={() => handlePeriodTypeChange("week")}
              >
                Semaine
              </button>
              <button
                className={`period-btn ${
                  selectedPeriodType === "month" ? "active" : ""
                }`}
                onClick={() => handlePeriodTypeChange("month")}
              >
                Mois
              </button>
              <button
                className={`period-btn ${
                  selectedPeriodType === "year" ? "active" : ""
                }`}
                onClick={() => handlePeriodTypeChange("year")}
              >
                Année
              </button>
            </div>
          </div>

          <div className="specific-period-selector">
            {selectedPeriodType === "day" && (
              <>
                <div className="period-select">
                  <label>Jour :</label>
                  <select
                    value={selectedPeriod.day}
                    onChange={(e) =>
                      handlePeriodChange("day", parseInt(e.target.value))
                    }
                  >
                    {days.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="period-select">
                  <label>Mois :</label>
                  <select
                    value={selectedPeriod.month}
                    onChange={(e) =>
                      handlePeriodChange("month", parseInt(e.target.value))
                    }
                  >
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {selectedPeriodType === "week" && (
              <>
                <div className="period-select">
                  <label>Semaine :</label>
                  <select
                    value={selectedPeriod.week}
                    onChange={(e) =>
                      handlePeriodChange("week", parseInt(e.target.value))
                    }
                  >
                    {weeks.map((week) => (
                      <option key={week} value={week}>
                        Semaine {week}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {selectedPeriodType === "month" && (
              <>
                <div className="period-select">
                  <label>Mois :</label>
                  <select
                    value={selectedPeriod.month}
                    onChange={(e) =>
                      handlePeriodChange("month", parseInt(e.target.value))
                    }
                  >
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {selectedPeriodType !== "day" && (
              <div className="period-select">
                <label>Année :</label>
                <select
                  value={selectedPeriod.year}
                  onChange={(e) =>
                    handlePeriodChange("year", parseInt(e.target.value))
                  }
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className="view-mode-selector">
            <label>Affichage :</label>
            <div className="view-mode-buttons">
              <button
                className={`view-mode-btn ${
                  viewMode === "real" ? "active" : ""
                }`}
                onClick={() => handleViewModeChange("real")}
              >
                Réel
              </button>
              <button
                className={`view-mode-btn ${
                  viewMode === "forecast" ? "active" : ""
                }`}
                onClick={() => handleViewModeChange("forecast")}
              >
                Prévisionnel
              </button>
              <button
                className={`view-mode-btn ${
                  viewMode === "comparison" ? "active" : ""
                }`}
                onClick={() => handleViewModeChange("comparison")}
              >
                Comparaison
              </button>
            </div>
          </div>
        </div>

        {/* Onglets pour les différents graphiques */}
        <div className="chart-tabs">
          <div
            className={`chart-tab ${
              activeChartTab === "revenue" ? "active" : ""
            }`}
            onClick={() => handleChartTabChange("revenue")}
          >
            Revenus
          </div>
          <div
            className={`chart-tab ${
              activeChartTab === "appointments" ? "active" : ""
            }`}
            onClick={() => handleChartTabChange("appointments")}
          >
            Rendez-vous
          </div>
          <div
            className={`chart-tab ${
              activeChartTab === "categories" ? "active" : ""
            }`}
            onClick={() => handleChartTabChange("categories")}
          >
            Catégories
          </div>
          <div
            className={`chart-tab ${
              activeChartTab === "services" ? "active" : ""
            }`}
            onClick={() => handleChartTabChange("services")}
          >
            Services
          </div>
        </div>

        {/* Zone de graphiques */}
        <div className="charts-container">
          {/* Graphique des revenus */}
          {activeChartTab === "revenue" && (
            <div className="chart-section">
              <h2>
                Revenus{" "}
                {selectedPeriodType === "year"
                  ? `${selectedPeriod.year}`
                  : selectedPeriodType === "month"
                  ? `${months[selectedPeriod.month]} ${selectedPeriod.year}`
                  : selectedPeriodType === "week"
                  ? `Semaine ${selectedPeriod.week} de ${selectedPeriod.year}`
                  : `${selectedPeriod.day} ${months[selectedPeriod.month]} ${
                      selectedPeriod.year
                    }`}
              </h2>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={stats.revenueByMonth}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={
                        selectedPeriodType === "year"
                          ? "monthName"
                          : selectedPeriodType === "week"
                          ? "dayName"
                          : "dayName"
                      }
                    />
                    <YAxis />
                    <Tooltip formatter={(value) => formatCurrency(value)} />
                    <Legend />
                    {viewMode === "real" || viewMode === "comparison" ? (
                      <Bar
                        dataKey="real"
                        name="Chiffre réel"
                        fill={REAL_COLOR}
                      />
                    ) : null}
                    {viewMode === "forecast" || viewMode === "comparison" ? (
                      <Bar
                        dataKey="forecast"
                        name="Prévisionnel"
                        fill={FORECAST_COLOR}
                      />
                    ) : null}
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="chart-summary">
                <div className="summary-card">
                  <h3>Total Réel</h3>
                  <div className="summary-value">
                    {formatCurrency(
                      stats.revenueByMonth.reduce(
                        (sum, item) => sum + (item.real || 0),
                        0
                      )
                    )}
                  </div>
                </div>
                <div className="summary-card">
                  <h3>Total Prévisionnel</h3>
                  <div className="summary-value">
                    {formatCurrency(
                      stats.revenueByMonth.reduce(
                        (sum, item) => sum + (item.forecast || 0),
                        0
                      )
                    )}
                  </div>
                </div>
                <div className="summary-card">
                  <h3>Écart</h3>
                  <div className="summary-value">
                    {formatCurrency(
                      stats.revenueByMonth.reduce(
                        (sum, item) => sum + (item.real || 0),
                        0
                      ) -
                        stats.revenueByMonth.reduce(
                          (sum, item) => sum + (item.forecast || 0),
                          0
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Graphique des rendez-vous */}
          {activeChartTab === "appointments" && (
            <div className="chart-section">
              <h2>
                Rendez-vous{" "}
                {selectedPeriodType === "year"
                  ? `${selectedPeriod.year}`
                  : selectedPeriodType === "month"
                  ? `${months[selectedPeriod.month]} ${selectedPeriod.year}`
                  : selectedPeriodType === "week"
                  ? `Semaine ${selectedPeriod.week} de ${selectedPeriod.year}`
                  : `${selectedPeriod.day} ${months[selectedPeriod.month]} ${
                      selectedPeriod.year
                    }`}
              </h2>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    data={stats.revenueByMonth.map((item) => ({
                      ...item,
                      appointmentsReal: item.real / 100, // Simuler un nombre de rendez-vous basé sur le revenu
                      appointmentsForecast:
                        forecastData?.monthly.find(
                          (m) =>
                            m.month ===
                            (selectedPeriodType === "year"
                              ? item.month
                              : selectedPeriod.month)
                        )?.appointmentsForecast || 0,
                    }))}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={
                        selectedPeriodType === "year"
                          ? "monthName"
                          : selectedPeriodType === "week"
                          ? "dayName"
                          : "dayName"
                      }
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {viewMode === "real" || viewMode === "comparison" ? (
                      <Line
                        type="monotone"
                        dataKey="appointmentsReal"
                        name="Rendez-vous réels"
                        stroke={REAL_COLOR}
                        activeDot={{ r: 8 }}
                      />
                    ) : null}
                    {viewMode === "forecast" || viewMode === "comparison" ? (
                      <Line
                        type="monotone"
                        dataKey="appointmentsForecast"
                        name="Rendez-vous prévisionnels"
                        stroke={FORECAST_COLOR}
                      />
                    ) : null}
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="chart-summary">
                <div className="summary-card">
                  <h3>Total Réel</h3>
                  <div className="summary-value">
                    {stats.appointmentsCount} rendez-vous
                  </div>
                </div>
                <div className="summary-card">
                  <h3>Moyenne mensuelle</h3>
                  <div className="summary-value">
                    {Math.round(
                      stats.appointmentsCount /
                        (selectedPeriodType === "year" ? 12 : 1)
                    )}{" "}
                    rendez-vous
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Graphique des catégories */}
          {activeChartTab === "categories" && (
            <div className="chart-section">
              <h2>Répartition par catégories</h2>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={stats.topCategories}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) =>
                        `${name}: ${(percent * 100).toFixed(0)}%`
                      }
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey={viewMode === "forecast" ? "forecast" : "count"}
                      nameKey="category"
                    >
                      {stats.topCategories.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value, name, props) => [
                        value,
                        props.payload.category,
                      ]}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="categories-legend">
                {stats.topCategories.map((category, index) => (
                  <div key={index} className="category-legend-item">
                    <div
                      className="color-box"
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    ></div>
                    <div className="category-name">{category.category}</div>
                    <div className="category-count">
                      {category.count} rendez-vous
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Graphique des services */}
          {activeChartTab === "services" && (
            <div className="chart-section">
              <h2>Services les plus populaires</h2>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={stats.popularServices}
                    layout="vertical"
                    margin={{
                      top: 20,
                      right: 30,
                      left: 100,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="count"
                      name="Nombre de ventes"
                      fill="#8884d8"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </div>

        <div className="export-section">
          <button
            className="export-btn"
            onClick={() => setShowExportOptions(!showExportOptions)}
          >
            Export pour comptable
          </button>

          {showExportOptions && (
            <div className="export-options">
              <div className="export-option-group">
                <label htmlFor="export-period">Période:</label>
                <select
                  id="export-period"
                  value={exportPeriod}
                  onChange={(e) => setExportPeriod(e.target.value)}
                >
                  <option value="current-month">Mois en cours</option>
                  <option value="last-month">Mois précédent</option>
                  <option value="current-year">Année en cours</option>
                  <option value="last-year">Année précédente</option>
                  <option value="all">Toutes les transactions</option>
                </select>
              </div>

              <div className="export-option-group">
                <label htmlFor="export-format">Format:</label>
                <select
                  id="export-format"
                  value={exportFormat}
                  onChange={(e) => setExportFormat(e.target.value)}
                >
                  <option value="csv">CSV</option>
                  <option value="excel">Excel</option>
                </select>
              </div>

              <button className="export-confirm-btn" onClick={handleExport}>
                Générer l'export
              </button>
            </div>
          )}
        </div>

        <div className="synthese-container">
          {/* Statistiques générales */}
          <div className="stats-section">
            <h2>Statistiques générales</h2>
            <div className="stats-grid">
              <div className="stat-card">
                <h3>Clients</h3>
                <div className="stat-value">{stats.clientsCount}</div>
              </div>
              <div className="stat-card">
                <h3>Rendez-vous</h3>
                <div className="stat-value">{stats.appointmentsCount}</div>
                <div className="stat-subtitle">
                  Ce mois-ci: {stats.appointmentsThisMonth}
                </div>
              </div>
              <div className="stat-card">
                <h3>Chiffre d'affaires</h3>
                <div className="stat-value">
                  {formatCurrency(stats.transactionsTotal)}
                </div>
                <div className="stat-subtitle">
                  Ce mois-ci: {formatCurrency(stats.transactionsThisMonth)}
                </div>
              </div>
            </div>
          </div>

          {/* Top catégories */}
          <div className="stats-section">
            <h2>Catégories les plus demandées</h2>
            <div className="top-list">
              {stats.topCategories.length > 0 ? (
                <ul className="stats-list">
                  {stats.topCategories.map((item, index) => (
                    <li key={index} className="stats-list-item">
                      <span className="stats-list-name">{item.category}</span>
                      <span className="stats-list-value">
                        {item.count} rendez-vous
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no-data">Aucune donnée disponible</p>
              )}
            </div>
          </div>

          {/* Services populaires */}
          <div className="stats-section">
            <h2>Services populaires</h2>
            <div className="top-list">
              {stats.popularServices.length > 0 ? (
                <ul className="stats-list">
                  {stats.popularServices.map((item, index) => (
                    <li key={index} className="stats-list-item">
                      <span className="stats-list-name">{item.name}</span>
                      <span className="stats-list-value">
                        {item.count} ventes
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no-data">Aucune donnée disponible</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Contenu de l'onglet Historique */}
      <div
        className={`synthese-tab-content ${
          activeTab === "history" ? "active" : ""
        }`}
      >
        <div className="export-section">
          <button className="export-btn" onClick={handleExportHistory}>
            Exporter l'historique
          </button>
        </div>

        <HistoryComponent />
      </div>
    </section>
  );
};
