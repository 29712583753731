import React, { useState, useEffect } from 'react';

export const PrestationSelector = ({ isOpen, onClose, onSelectPrestation, prestations }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredPrestations, setFilteredPrestations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Charger les catégories au chargement
  useEffect(() => {
    if (!isOpen) return;
    
    // Extraire les catégories uniques
    const uniqueCategories = [...new Set(prestations.map(item => item.categorie))].filter(Boolean);
    setCategories(uniqueCategories);
    
    // Réinitialiser les filtres
    setSelectedCategory('');
    setSearchTerm('');
    
    // Afficher toutes les prestations
    setFilteredPrestations(prestations);
  }, [isOpen, prestations]);
  
  // Mettre à jour les prestations filtrées lorsque les filtres changent
  useEffect(() => {
    let result = [...prestations];
    
    // Appliquer le filtre de catégorie
    if (selectedCategory) {
      result = result.filter(item => item.categorie === selectedCategory);
    }
    
    // Appliquer le filtre de recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(item => 
        (item.nom && item.nom.toLowerCase().includes(term)) ||
        (item.description && item.description.toLowerCase().includes(term))
      );
    }
    
    setFilteredPrestations(result);
  }, [selectedCategory, searchTerm, prestations]);
  
  // Formater le prix en euros
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(price || 0);
  };
  
  // Formater la durée en heures et minutes
  const formatDuration = (minutes) => {
    if (!minutes) return 'Non spécifiée';
    
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours === 0) {
      return `${mins} min`;
    } else if (mins === 0) {
      return `${hours} h`;
    } else {
      return `${hours}h${mins}`;
    }
  };
  
  // Gérer la sélection d'une prestation
  const handleSelectPrestation = (prestation) => {
    onSelectPrestation(prestation);
    
    // Notification d'ajout au panier
    const prestationName = prestation.nom || 'Prestation';
    const notification = document.createElement('div');
    notification.className = 'prestation-added-notification';
    notification.innerHTML = `<i>✓</i> <span>${prestationName}</span> ajoutée au panier`;
    
    // Ajouter au DOM
    document.body.appendChild(notification);
    
    // Animation et suppression après un délai
    setTimeout(() => {
      notification.classList.add('show');
      setTimeout(() => {
        notification.classList.remove('show');
        setTimeout(() => {
          document.body.removeChild(notification);
        }, 300);
      }, 2000);
    }, 10);
    
    // Ajouter une classe d'animation à la prestation sélectionnée
    const element = document.querySelector(`[data-prestation-id="${prestation.id}"]`);
    if (element) {
      element.classList.add('item-added-animation');
      setTimeout(() => {
        element.classList.remove('item-added-animation');
      }, 1000);
    }
  };
  
  return (
    isOpen && (
      <div className="prestation-selector-overlay">
        <div className="prestation-selector-content">
          <div className="prestation-selector-header">
            <h2>Sélectionner une prestation</h2>
            <button className="close-selector" onClick={onClose}>&times;</button>
          </div>
          
          <div className="prestation-selector-filters">
            <div className="search-container">
              <input 
                type="text" 
                placeholder="Rechercher une prestation..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="category-filters">
              <select 
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Toutes les catégories</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="prestation-selector-grid">
            {filteredPrestations.length === 0 ? (
              <div className="no-prestations">
                <p>Aucune prestation ne correspond à votre recherche.</p>
              </div>
            ) : (
              filteredPrestations.map(prestation => (
                <div 
                  key={prestation.id} 
                  className="prestation-item"
                  onClick={() => handleSelectPrestation(prestation)}
                  style={{ borderLeft: `4px solid ${prestation.couleur || '#c6b291'}` }}
                  data-prestation-id={prestation.id}
                >
                  <div className="prestation-item-image">
                    {prestation.image ? (
                      <img src={prestation.image} alt={prestation.nom} />
                    ) : (
                      <div className="placeholder-image" style={{ backgroundColor: prestation.couleur || '#c6b291' }}>
                        {prestation.nom?.charAt(0) || 'P'}
                      </div>
                    )}
                  </div>
                  <div className="prestation-item-details">
                    <h3 className="prestation-item-name">{prestation.nom}</h3>
                    {prestation.categorie && (
                      <div className="prestation-item-category">
                        <span className="category-badge">
                          {prestation.categorie.charAt(0).toUpperCase() + prestation.categorie.slice(1)}
                        </span>
                      </div>
                    )}
                    <div className="prestation-item-price-duration">
                      <div className="prestation-item-price">
                        {formatPrice(prestation.prixTTC || prestation.prix || 0)}
                      </div>
                      <div className="prestation-item-duration">
                        <i className="duration-icon">⏱️</i>
                        <span>{formatDuration(prestation.duree)}</span>
                      </div>
                    </div>
                    {prestation.description && (
                      <div className="prestation-item-description">
                        {prestation.description.length > 70 
                          ? prestation.description.substring(0, 70) + '...'
                          : prestation.description
                        }
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );
};