// src/services/AuthAdapter.js

export const AuthAdapter = {
  // URL de base API - Utilise le chemin relatif en production
  get API_BASE_URL() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
      ? 'http://localhost:8000/api'
      : '/api';  // Chemin relatif pour passer par le proxy nginx
  },
  
  // Obtenir un jeton CSRF des cookies
  getCsrfToken() {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1] || '';
  },
  
  // Vérifier s'il s'agit du premier utilisateur
  checkFirstUser: async () => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      
      try {
        const response = await fetch(`${AuthAdapter.API_BASE_URL}/users/check-first-user/`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'
          },
          signal: controller.signal
        });
        
        clearTimeout(timeoutId);
        
        if (response.ok) {
          const data = await response.json();
          return data;
        }
        
        throw new Error('Erreur serveur lors de la vérification');
      } catch (networkError) {
        if (networkError.name === 'AbortError') {
          throw new Error('La connexion au serveur a expiré. Veuillez réessayer.');
        }
        
        if (networkError instanceof TypeError) {
          console.log("Mode hors-ligne activé, serveur distant non disponible:", networkError);
        }
      }
      
      // Vérification locale comme fallback
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      return { is_first_user: users.length === 0 };
    } catch (error) {
      console.error("Erreur lors de la vérification du premier utilisateur:", error);
      
      // Toujours retourner une réponse valide
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      return { is_first_user: users.length === 0 };
    }
  },
  
  // Enregistrer un nouvel utilisateur - Mise à jour avec CSRF
  registerUser: async (userData) => {
    try {
      console.log("Tentative d'enregistrement utilisateur:", userData);
      
      // Vérification locale des emails dupliqués
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      const emailExists = users.some(user => 
        user.email.toLowerCase() === userData.email.toLowerCase()
      );
      
      if (emailExists) {
        throw new Error('Cet email est déjà utilisé');
      }
      
      // Tentative d'enregistrement sur le serveur
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000);
      
      try {
        // Obtenir un jeton CSRF pour la requête
        const csrfToken = AuthAdapter.getCsrfToken();
        
        const response = await fetch(`${AuthAdapter.API_BASE_URL}/users/register/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRFToken': csrfToken
          },
          credentials: 'include',  // Important pour inclure les cookies
          body: JSON.stringify(userData),
          signal: controller.signal
        });
        
        clearTimeout(timeoutId);
        
        if (response.ok) {
          const data = await response.json();
          console.log("Enregistrement serveur réussi:", data);
          
          // Adapter le format utilisateur
          const user = {
            id: data.user.id || Date.now().toString(),
            firstName: userData.first_name,
            lastName: userData.last_name,
            name: `${userData.first_name} ${userData.last_name}`,
            email: userData.email,
            role: userData.role === 'employee' ? 'personnel' : userData.role,
            companyName: userData.company_name,
            birthDate: userData.birth_date,
            dateCreated: new Date().toISOString()
          };
          
          // Sauvegarder localement
          users.push(user);
          localStorage.setItem('users', JSON.stringify(users));
          
          return { user };
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || "Erreur lors de l'enregistrement");
        }
      } catch (networkError) {
        if (networkError.name === 'AbortError') {
          throw new Error('La connexion au serveur a expiré. Veuillez réessayer.');
        }
        
        if (networkError instanceof TypeError) {
          console.log("Mode local activé pour l'enregistrement:", networkError);
          
          // Création locale
          const userId = Date.now().toString();
          const newUser = {
            id: userId,
            firstName: userData.first_name,
            lastName: userData.last_name,
            name: `${userData.first_name} ${userData.last_name}`,
            email: userData.email,
            role: userData.role === 'employee' ? 'personnel' : userData.role,
            companyName: userData.company_name,
            birthDate: userData.birth_date,
            dateCreated: new Date().toISOString()
          };
          
          users.push(newUser);
          localStorage.setItem('users', JSON.stringify(users));
          
          return { user: newUser };
        }
        
        throw networkError;
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'utilisateur:", error);
      throw error;
    }
  },
  
  // Connecter un utilisateur - Mise à jour avec CSRF
  loginUser: async (email, password) => {
    try {
      console.log("Tentative de connexion pour:", email);
      
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      
      try {
        // Obtenir un jeton CSRF pour la requête
        const csrfToken = AuthAdapter.getCsrfToken();
        
        const response = await fetch(`${AuthAdapter.API_BASE_URL}/token/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRFToken': csrfToken
          },
          credentials: 'include',
          // CORRECTION ICI: Nous utilisons explicitement email comme clé puisque c'est
          // ce que votre backend Django attend (USERNAME_FIELD = 'email')
          body: JSON.stringify({ 
            email: email,  // Assurez-vous d'utiliser email comme clé
            password: password 
          }),
          signal: controller.signal
        });
        
        clearTimeout(timeoutId);
        
        if (response.ok) {
          const tokenData = await response.json();
          console.log("Connexion serveur réussie");
          
          localStorage.setItem('accessToken', tokenData.access);
          localStorage.setItem('refreshToken', tokenData.refresh);
          
          const userResponse = await fetch(`${AuthAdapter.API_BASE_URL}/users/me/`, {
            headers: {
              'Authorization': `Bearer ${tokenData.access}`,
              'Accept': 'application/json',
              'X-CSRFToken': csrfToken
            },
            credentials: 'include'
          });
          
          if (userResponse.ok) {
            const userData = await userResponse.json();
            
            const user = {
              id: userData.id,
              firstName: userData.first_name,
              lastName: userData.last_name,
              name: `${userData.first_name} ${userData.last_name}`,
              email: userData.email,
              role: userData.role === 'employee' ? 'personnel' : userData.role,
              companyName: userData.company_name,
              birthDate: userData.birth_date
            };
            
            localStorage.setItem('currentUser', JSON.stringify(user));
            
            const users = JSON.parse(localStorage.getItem('users') || '[]');
            const existingUserIndex = users.findIndex(u => u.email.toLowerCase() === email.toLowerCase());
            
            if (existingUserIndex >= 0) {
              users[existingUserIndex] = { ...users[existingUserIndex], ...user };
            } else {
              users.push(user);
            }
            
            localStorage.setItem('users', JSON.stringify(users));
            
            return { 
              user, 
              tokens: { 
                access: tokenData.access, 
                refresh: tokenData.refresh 
              }
            };
          }
        }
        
        throw new Error('Échec de la connexion');
      } catch (networkError) {
        if (networkError.name === 'AbortError') {
          throw new Error('La connexion au serveur a expiré. Veuillez réessayer.');
        }
        
        if (networkError instanceof TypeError) {
          console.log("Mode local activé pour la connexion:", networkError);
          
          const users = JSON.parse(localStorage.getItem('users') || '[]');
          const user = users.find(u => 
            u.email.toLowerCase() === email.toLowerCase() && u.password === password
          );
          
          if (!user) {
            throw new Error("Identifiants incorrects");
          }
          
          const fakeTokens = {
            access: `local_access_token_${Date.now()}`,
            refresh: `local_refresh_token_${Date.now()}`
          };
          
          localStorage.setItem('accessToken', fakeTokens.access);
          localStorage.setItem('refreshToken', fakeTokens.refresh);
          localStorage.setItem('currentUser', JSON.stringify(user));
          
          return { 
            user, 
            tokens: fakeTokens
          };
        }
        
        throw networkError;
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      throw error;
    }
  }
};

export function authFetch(url, options = {}) {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
    ...options.headers,
  };

  return fetch(url, {
    ...options,
    headers,
  });
}
