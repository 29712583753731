// ClientService.js

export const ClientService = {
    // Convertir les données client du format frontend vers le format API
    formatClientForAPI: (client) => {
      return {
        first_name: client.prenom || '',
        last_name: client.nom || '',
        email: client.email || '',
        phone: client.telephone || '',
        notes: client.notes || ''
      };
    },
  
    // Convertir les données client du format API vers le format frontend
    formatClientFromAPI: (apiClient) => {
      return {
        id: apiClient.id,
        server_id: apiClient.id, // Garder une référence à l'ID serveur
        prenom: apiClient.first_name || '',
        nom: apiClient.last_name || '',
        email: apiClient.email || '',
        telephone: apiClient.phone || '',
        notes: apiClient.notes || '',
        // Conserver les autres propriétés spécifiques au frontend
        photo: null,
        'date-naissance': '',
        adresse: '',
        socialNetworks: []
      };
    },
  
    // Obtenir tous les clients depuis l'API
    getClients: async () => {
      try {
        const response = await fetch('/api/clients/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
  
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
  
        const data = await response.json();
        
        // Convertir les données au format frontend
        const apiClients = Array.isArray(data) ? data : data.results || [];
        return apiClients.map(ClientService.formatClientFromAPI);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
        return [];
      }
    },
  
    // Créer un client sur le serveur
    createClient: async (clientData) => {
      try {
        const response = await fetch('/api/clients/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          body: JSON.stringify(ClientService.formatClientForAPI(clientData))
        });
  
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
  
        const data = await response.json();
        return {
          ...clientData,
          server_id: data.id
        };
      } catch (error) {
        console.error("Erreur lors de la création du client:", error);
        throw error;
      }
    },
  
    // Mettre à jour un client sur le serveur
    updateClient: async (clientData) => {
      // Utiliser server_id s'il existe, sinon utiliser id
      const clientId = clientData.server_id || clientData.id;
      
      try {
        const response = await fetch(`/api/clients/${clientId}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          },
          body: JSON.stringify(ClientService.formatClientForAPI(clientData))
        });
  
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
  
        const data = await response.json();
        return {
          ...clientData,
          server_id: data.id
        };
      } catch (error) {
        console.error("Erreur lors de la mise à jour du client:", error);
        throw error;
      }
    },
  
    // Supprimer un client sur le serveur
    deleteClient: async (clientId, serverId) => {
      // Utiliser server_id s'il existe
      const id = serverId || clientId;
      
      try {
        const response = await fetch(`/api/clients/${id}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
  
        if (!response.ok && response.status !== 404) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
  
        return true;
      } catch (error) {
        console.error("Erreur lors de la suppression du client:", error);
        return false;
      }
    },
  
    // Synchroniser les clients locaux avec le serveur
    syncClientsWithServer: async () => {
      try {
        // Récupérer les clients locaux
        const localClients = JSON.parse(localStorage.getItem('clients') || '[]');
        
        // Récupérer les clients du serveur
        const serverClients = await ClientService.getClients();
        
        // Créer une map des clients du serveur par email pour faciliter la recherche
        const serverClientsByEmail = {};
        serverClients.forEach(client => {
          if (client.email) {
            serverClientsByEmail[client.email.toLowerCase()] = client;
          }
        });
        
        // Parcourir les clients locaux pour les synchroniser
        const updatedLocalClients = [];
        
        for (const localClient of localClients) {
          let updatedClient = { ...localClient };
          
          // Si le client a déjà un server_id, il est déjà synchronisé
          if (localClient.server_id) {
            updatedLocalClients.push(updatedClient);
            continue;
          }
          
          // Si le client a un email, chercher une correspondance sur le serveur
          if (localClient.email) {
            const serverMatch = serverClientsByEmail[localClient.email.toLowerCase()];
            if (serverMatch) {
              // Associer l'ID serveur au client local
              updatedClient.server_id = serverMatch.id;
              updatedLocalClients.push(updatedClient);
              continue;
            }
          }
          
          // Si aucune correspondance, créer le client sur le serveur
          try {
            const createdClient = await ClientService.createClient(localClient);
            updatedLocalClients.push(createdClient);
          } catch (error) {
            // En cas d'erreur, conserver le client local tel quel
            updatedLocalClients.push(updatedClient);
          }
        }
        
        // Mettre à jour le localStorage avec les clients mis à jour
        localStorage.setItem('clients', JSON.stringify(updatedLocalClients));
        
        return updatedLocalClients;
      } catch (error) {
        console.error("Erreur lors de la synchronisation des clients:", error);
        return JSON.parse(localStorage.getItem('clients') || '[]');
      }
    },
  
    // Obtenir l'ID serveur d'un client local
    getServerIdForClient: (clientId) => {
      try {
        const clients = JSON.parse(localStorage.getItem('clients') || '[]');
        const client = clients.find(c => c.id === clientId);
        return client ? client.server_id : null;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID serveur:", error);
        return null;
      }
    }
  };
  
  export default ClientService;