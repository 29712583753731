import React, { useState, useEffect } from 'react';
import { clearHistory, deleteHistoryItem } from './HistoryUtils';

export const HistoryComponent = () => {
  const [historyItems, setHistoryItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterAction, setFilterAction] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmClear, setShowConfirmClear] = useState(false);
  
  // Charger l'historique au chargement du composant
  useEffect(() => {
    loadHistory();
  }, []);
  
  // Fonction pour charger l'historique
  const loadHistory = () => {
    const storedHistory = JSON.parse(localStorage.getItem('app_history') || '[]');
    setHistoryItems(storedHistory);
  };
  
  // Filtrer l'historique selon les critères
  const filteredHistory = historyItems.filter(item => {
    // Filtre par terme de recherche
    const matchesSearch = searchTerm === '' || 
      (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.details && item.details.toLowerCase().includes(searchTerm.toLowerCase()));
    
    // Filtre par module
    const matchesType = filterType === 'all' || item.module === filterType;
    
    // Filtre par type d'action
    const matchesAction = filterAction === 'all' || item.action === filterAction;
    
    // Filtre par date
    let matchesDateRange = true;
    if (startDate) {
      const itemDate = new Date(item.timestamp);
      const filterStartDate = new Date(startDate);
      filterStartDate.setHours(0, 0, 0, 0);
      matchesDateRange = itemDate >= filterStartDate;
    }
    
    if (endDate && matchesDateRange) {
      const itemDate = new Date(item.timestamp);
      const filterEndDate = new Date(endDate);
      filterEndDate.setHours(23, 59, 59, 999);
      matchesDateRange = itemDate <= filterEndDate;
    }
    
    return matchesSearch && matchesType && matchesAction && matchesDateRange;
  });
  
  // Trier par date (du plus récent au plus ancien)
  const sortedHistory = [...filteredHistory].sort((a, b) => 
    new Date(b.timestamp) - new Date(a.timestamp)
  );
  
  // Formater la date pour l'affichage
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Obtenir l'icône appropriée selon le type d'action
  const getActionIcon = (type) => {
    switch (type) {
      case 'create':
        return '➕';
      case 'update':
        return '✏️';
      case 'delete':
        return '🗑️';
      case 'payment':
        return '💰';
      case 'refund':
        return '↩️';
      case 'stock':
        return '📦';
      default:
        return 'ℹ️';
    }
  };
  
  // Obtenir la couleur du badge selon le module
  const getModuleColor = (module) => {
    switch (module) {
      case 'agenda':
        return '#5bc0de';
      case 'caisse':
        return '#5cb85c';
      case 'clients':
        return '#f0ad4e';
      case 'prestations':
        return '#d9534f';
      case 'stock':
        return '#9370db';
      default:
        return '#777777';
    }
  };
  
  // Obtenir le libellé du type d'action
  const getActionLabel = (action) => {
    switch (action) {
      case 'create':
        return 'Création';
      case 'update':
        return 'Modification';
      case 'delete':
        return 'Suppression';
      case 'payment':
        return 'Paiement';
      case 'refund':
        return 'Remboursement';
      case 'stock':
        return 'Stock';
      default:
        return action.charAt(0).toUpperCase() + action.slice(1);
    }
  };
  
  // Gérer la suppression d'un élément de l'historique
  const handleDeleteItem = (index) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette entrée de l\'historique ?')) {
      deleteHistoryItem(index);
      loadHistory();
    }
  };
  
  // Gérer l'effacement complet de l'historique
  const handleClearHistory = () => {
    setShowConfirmClear(true);
  };
  
  // Confirmer l'effacement complet de l'historique
  const confirmClearHistory = () => {
    clearHistory();
    loadHistory();
    setShowConfirmClear(false);
  };
  
  // Voir les détails d'un élément
  const viewItemDetails = (item) => {
    setSelectedItem(item);
  };
  
  // Réinitialiser les filtres
  const resetFilters = () => {
    setSearchTerm('');
    setFilterType('all');
    setFilterAction('all');
    setStartDate('');
    setEndDate('');
  };
  
  return (
    <div className="history-container">
      <div className="history-header">
        <div className="history-actions">
          <button 
            className="toggle-filters-btn"
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            {showAdvancedFilters ? 'Masquer les filtres avancés' : 'Afficher les filtres avancés'}
          </button>
          
          <button 
            className="clear-history-btn"
            onClick={handleClearHistory}
          >
            Effacer l'historique
          </button>
        </div>
        
        <div className="history-filters">
          <div className="search-container">
            <input 
              type="text" 
              placeholder="Rechercher dans l'historique..." 
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="filter-container">
            <select 
              value={filterType}
              onChange={e => setFilterType(e.target.value)}
            >
              <option value="all">Tous les modules</option>
              <option value="agenda">Agenda</option>
              <option value="caisse">Caisse</option>
              <option value="clients">Clients</option>
              <option value="prestations">Prestations</option>
              <option value="stock">Stock</option>
            </select>
          </div>
        </div>
        
        {showAdvancedFilters && (
          <div className="advanced-filters">
            <div className="filter-row">
              <div className="filter-group">
                <label>Type d'action:</label>
                <select 
                  value={filterAction}
                  onChange={e => setFilterAction(e.target.value)}
                >
                  <option value="all">Toutes les actions</option>
                  <option value="create">Création</option>
                  <option value="update">Modification</option>
                  <option value="delete">Suppression</option>
                  <option value="payment">Paiement</option>
                  <option value="refund">Remboursement</option>
                  <option value="stock">Stock</option>
                </select>
              </div>
              
              <div className="filter-group">
                <label>Du:</label>
                <input 
                  type="date" 
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
              </div>
              
              <div className="filter-group">
                <label>Au:</label>
                <input 
                  type="date" 
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </div>
              
              <button className="reset-filters-btn" onClick={resetFilters}>
                Réinitialiser
              </button>
            </div>
            
            <div className="filter-summary">
              {filteredHistory.length} entrée(s) trouvée(s) sur {historyItems.length} au total
            </div>
          </div>
        )}
      </div>
      
      {sortedHistory.length === 0 ? (
        <div className="no-history">
          <p>Aucun historique trouvé. Les actions effectuées dans l'application seront enregistrées ici.</p>
        </div>
      ) : (
        <div className="history-list">
          {sortedHistory.map((item, index) => (
            <div key={index} className="history-item">
              <div className="history-item-icon">
                {getActionIcon(item.action)}
              </div>
              <div className="history-item-content">
                <div className="history-item-header">
                  <span 
                    className="module-badge"
                    style={{ backgroundColor: getModuleColor(item.module) }}
                  >
                    {item.module}
                  </span>
                  <span className="action-badge">
                    {getActionLabel(item.action)}
                  </span>
                  <span className="history-item-date">{formatDate(item.timestamp)}</span>
                </div>
                <div className="history-item-description">
                  {item.description}
                </div>
                {item.details && (
                  <div className="history-item-details">
                    {item.details}
                  </div>
                )}
              </div>
              <div className="history-item-actions">
                <button 
                  className="view-details-btn"
                  onClick={() => viewItemDetails(item)}
                  title="Voir les détails"
                >
                  🔍
                </button>
                <button 
                  className="delete-item-btn"
                  onClick={() => handleDeleteItem(index)}
                  title="Supprimer de l'historique"
                >
                  🗑️
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      
      {/* Modal de confirmation pour effacer l'historique */}
      {showConfirmClear && (
        <div className="modal">
          <div className="modal-content confirm-modal">
            <h3>Confirmation</h3>
            <p>Êtes-vous sûr de vouloir effacer tout l'historique ? Cette action est irréversible.</p>
            <div className="modal-actions">
              <button className="confirm-btn" onClick={confirmClearHistory}>
                Confirmer
              </button>
              <button className="cancel-btn" onClick={() => setShowConfirmClear(false)}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Modal de détails d'un élément */}
      {selectedItem && (
        <div className="modal">
          <div className="modal-content details-modal">
            <span className="close-modal" onClick={() => setSelectedItem(null)}>&times;</span>
            <h3>Détails de l'action</h3>
            
            <div className="item-details">
              <div className="detail-row">
                <span className="detail-label">Date:</span>
                <span className="detail-value">{formatDate(selectedItem.timestamp)}</span>
              </div>
              
              <div className="detail-row">
                <span className="detail-label">Module:</span>
                <span className="detail-value">{selectedItem.module}</span>
              </div>
              
              <div className="detail-row">
                <span className="detail-label">Action:</span>
                <span className="detail-value">{getActionLabel(selectedItem.action)}</span>
              </div>
              
              <div className="detail-row">
                <span className="detail-label">Description:</span>
                <span className="detail-value">{selectedItem.description}</span>
              </div>
              
              {selectedItem.details && (
                <div className="detail-row">
                  <span className="detail-label">Détails:</span>
                  <div className="detail-value details-block">{selectedItem.details}</div>
                </div>
              )}
            </div>
            
            <div className="modal-actions">
              <button className="delete-btn" onClick={() => {
                handleDeleteItem(historyItems.findIndex(i => i === selectedItem));
                setSelectedItem(null);
              }}>
                Supprimer de l'historique
              </button>
              <button className="close-btn" onClick={() => setSelectedItem(null)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};