import React, { useState, useEffect, useRef } from 'react';
import './Prestations.css';

export const PrestationForm = ({ initialData, onSave, onCancel, existingCategories = [] }) => {
  const defaultFormData = {
    nom: '',
    categorieId: '',
    nouvelleCategorie: '',
    prixHT: '',
    prixTTC: '',
    duree: 30,
    description: '',
    couleur: '#c6b291', // Couleur par défaut
    image: ''
  };

  // État principal et références
  const [formData, setFormData] = useState({...defaultFormData});
  const [imagePreview, setImagePreview] = useState(null);
  const [isCategorieInputVisible, setIsCategorieInputVisible] = useState(false);
  const [categories, setCategories] = useState(existingCategories);
  const imageInputRef = useRef(null);
  const isInitialRender = useRef(true);

  // Initialisation du formulaire - uniquement au montage ou changement d'initialData
  useEffect(() => {
    // Si c'est une modification, charger les données existantes
    if (initialData) {
      const newFormData = {...initialData};
      
      // Gérer la catégorie
      if (initialData.categorie) {
        if (existingCategories.includes(initialData.categorie)) {
          newFormData.categorieId = initialData.categorie;
          setIsCategorieInputVisible(false);
        } else {
          newFormData.categorieId = 'nouvelle';
          newFormData.nouvelleCategorie = initialData.categorie;
          setIsCategorieInputVisible(true);
        }
      }
      
      setFormData(newFormData);
      
      if (initialData.image) {
        setImagePreview(initialData.image);
      }
    } else {
      // Nouveau formulaire
      setFormData({...defaultFormData});
      setImagePreview(null);
      setIsCategorieInputVisible(false);
    }
    
    isInitialRender.current = false;
  }, [initialData, existingCategories]); // Ajout de existingCategories comme dépendance

  // Gérer le changement des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    
    // Pour les champs numériques, convertir en nombre
    if (name === 'prixHT' || name === 'prixTTC' || name === 'duree') {
      const numValue = value === '' ? '' : parseFloat(value);
      let updatedData = { ...formData, [name]: numValue };
      
      // Calculer automatiquement le prix TTC à partir du prix HT (avec TVA à 20%)
      if (name === 'prixHT' && value !== '') {
        const prixTTC = parseFloat(value) * 1.2;
        updatedData.prixTTC = Math.round(prixTTC * 100) / 100;
      }
      
      // Calculer automatiquement le prix HT à partir du prix TTC (avec TVA à 20%)
      if (name === 'prixTTC' && value !== '') {
        const prixHT = parseFloat(value) / 1.2;
        updatedData.prixHT = Math.round(prixHT * 100) / 100;
      }
      
      setFormData(updatedData);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Gérer le changement de catégorie
  const handleCategorieChange = (e) => {
    const value = e.target.value;
    
    // Si l'utilisateur sélectionne "Nouvelle catégorie", afficher le champ de saisie
    if (value === "nouvelle") {
      setIsCategorieInputVisible(true);
      setFormData({
        ...formData,
        categorieId: 'nouvelle',
        nouvelleCategorie: ''
      });
    } else {
      setIsCategorieInputVisible(false);
      setFormData({
        ...formData,
        categorieId: value,
        nouvelleCategorie: ''
      });
    }
  };

  // Gérer l'ajout d'une nouvelle catégorie dans le formulaire
  const handleAddNewCategory = () => {
    if (!formData.nouvelleCategorie.trim()) {
      alert('Veuillez entrer un nom de catégorie');
      return;
    }
    
    // Vérifier si la catégorie existe déjà
    if (categories.includes(formData.nouvelleCategorie.trim().toLowerCase())) {
      alert('Cette catégorie existe déjà');
      return;
    }
    
    const newCategory = formData.nouvelleCategorie.trim().toLowerCase();
    const updatedCategories = [...categories, newCategory];
    
    // Mettre à jour les catégories
    setCategories(updatedCategories);
    
    // Sauvegarder les catégories (si nécessaire, vous pouvez implémenter un callback pour cela)
    localStorage.setItem('categories', JSON.stringify(updatedCategories));
    
    // Mettre à jour le formulaire pour sélectionner la nouvelle catégorie
    setFormData({
      ...formData,
      categorieId: newCategory,
      nouvelleCategorie: ''
    });
    
    // Cacher l'input de nouvelle catégorie
    setIsCategorieInputVisible(false);
    
    // Afficher un message de confirmation
    alert('Catégorie ajoutée avec succès !');
  };

  // Gérer l'upload d'image
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      
      reader.onload = function (event) {
        const imageData = event.target.result;
        setImagePreview(imageData);
        setFormData({ ...formData, image: imageData });
      };
      
      reader.readAsDataURL(file);
    }
  };

  // Fonction pour simuler un clic sur l'input de type file
  const triggerImageUpload = () => {
    imageInputRef.current.click();
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Préparer les données pour la sauvegarde
    const saveData = {
      ...formData,
      id: formData.id || Date.now(), // Utiliser l'ID existant ou en créer un nouveau
      categorie: isCategorieInputVisible ? formData.nouvelleCategorie.toLowerCase() : formData.categorieId
    };
    
    onSave(saveData);
  };

  return (
    <form className="prestation-form" onSubmit={handleSubmit}>
      <div className="form-layout">
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="nom">Nom de la prestation*:</label>
            <input 
              type="text" 
              id="nom" 
              name="nom" 
              required
              value={formData.nom}
              onChange={handleInputChange}
              placeholder="Ex: Soin visage hydratant"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="categorie">Catégorie*:</label>
            <select 
              id="categorie" 
              name="categorieId" 
              required={!isCategorieInputVisible}
              value={formData.categorieId}
              onChange={handleCategorieChange}
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat.charAt(0).toUpperCase() + cat.slice(1)}
                </option>
              ))}
              <option value="nouvelle">+ Nouvelle catégorie</option>
            </select>
          </div>
          
          {isCategorieInputVisible && (
            <div className="form-group">
              <label htmlFor="nouvelle-categorie">Nouvelle catégorie*:</label>
              <div className="category-input-group">
                <input 
                  type="text" 
                  id="nouvelle-categorie" 
                  name="nouvelleCategorie" 
                  required
                  value={formData.nouvelleCategorie}
                  onChange={handleInputChange}
                  placeholder="Ex: Massage, Épilation, Soins..."
                />
                <button 
                  type="button" 
                  className="add-btn"
                  onClick={handleAddNewCategory}
                >
                  Ajouter
                </button>
              </div>
            </div>
          )}
          
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="prixHT">Prix HT*:</label>
              <div className="price-input">
                <input 
                  type="number" 
                  id="prixHT" 
                  name="prixHT" 
                  required
                  value={formData.prixHT}
                  onChange={handleInputChange}
                  step="0.01"
                  min="0"
                  placeholder="0.00"
                />
                <span className="price-symbol">€</span>
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="prixTTC">Prix TTC*:</label>
              <div className="price-input">
                <input 
                  type="number" 
                  id="prixTTC" 
                  name="prixTTC" 
                  required
                  value={formData.prixTTC}
                  onChange={handleInputChange}
                  step="0.01"
                  min="0"
                  placeholder="0.00"
                />
                <span className="price-symbol">€</span>
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="duree">Durée (minutes)*:</label>
            <input 
              type="number" 
              id="duree" 
              name="duree" 
              required
              value={formData.duree}
              onChange={handleInputChange}
              min="5"
              step="5"
            />
          </div>
        </div>
        
        <div className="form-column">
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea 
              id="description" 
              name="description" 
              rows="4"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Décrivez cette prestation..."
            ></textarea>
          </div>
          
          <div className="form-group">
            <label htmlFor="couleur">Couleur (affichage dans l'agenda):</label>
            <input 
              type="color" 
              id="couleur" 
              name="couleur" 
              value={formData.couleur}
              onChange={handleInputChange}
              className="color-picker"
            />
            <div className="color-preview" style={{ backgroundColor: formData.couleur }}>
              {formData.nom || "Aperçu"}
            </div>
          </div>
          
          <div className="form-group">
            <label>Image (pour la prestation):</label>
            <div className="image-upload-container">
              <div 
                className="image-preview" 
                onClick={triggerImageUpload}
              >
                {imagePreview ? (
                  <img 
                    src={imagePreview} 
                    alt="Aperçu" 
                    className="preview-img"
                  />
                ) : (
                  <div className="no-image">
                    <i className="image-icon">📷</i>
                    <span>Cliquez pour ajouter une image</span>
                  </div>
                )}
              </div>
              <input 
                type="file" 
                id="prestation-image" 
                accept="image/*" 
                onChange={handleImageChange}
                ref={imageInputRef}
                style={{ display: 'none' }}
              />
              <button 
                type="button" 
                className="image-btn"
                onClick={triggerImageUpload}
              >
                {imagePreview ? 'Changer l\'image' : 'Ajouter une image'}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="form-actions">
        <button type="submit" className="submit-btn">
          {initialData ? 'Mettre à jour' : 'Enregistrer'}
        </button>
        <button type="button" className="cancel-btn" onClick={onCancel}>
          Annuler
        </button>
      </div>
    </form>
  );
};