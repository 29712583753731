import React, { useState } from "react";
import "./Agenda.css";

export const SettingsModal = ({ isOpen, onClose, settings, onSave }) => {
  // État local pour les paramètres
  const [formData, setFormData] = useState({
    startHour: settings.startHour,
    endHour: settings.endHour,
    timeStep: settings.timeStep,
    closedDays: [...settings.closedDays], // Copie du tableau pour éviter la mutation
    closingHours: settings.closingHours || {}, // Heures de fermeture par jour
    enableRecurrence: settings.enableRecurrence || false,
    enableExport: settings.enableExport || false,
    enableClientIntegration: settings.enableClientIntegration || false,
    enableReminders: settings.enableReminders || false,
    enableYearView: settings.enableYearView || false,
    defaultReminderTime: settings.defaultReminderTime || 60, // 60 minutes par défaut
  });

  // État pour afficher le panneau des heures de fermeture
  const [activeClosingDay, setActiveClosingDay] = useState(null);
  const [showClosingHours, setShowClosingHours] = useState(false);

  // Générer des options d'heures de 0 à 23
  const generateHourOptions = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  // Générer des options de pas de temps
  const timeStepOptions = [5, 10, 15, 30, 60];

  // Générer des options de temps de rappel
  const reminderTimeOptions = [
    { value: 15, label: "15 minutes avant" },
    { value: 30, label: "30 minutes avant" },
    { value: 60, label: "1 heure avant" },
    { value: 120, label: "2 heures avant" },
    { value: 1440, label: "1 jour avant" },
    { value: 2880, label: "2 jours avant" },
  ];

  // Gérer les changements de champs du formulaire
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Pour les checkbox, utiliser 'checked' comme valeur
    const newValue = type === "checkbox" ? checked : parseInt(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  // Gérer les changements de jours fermés
  const handleClosedDayChange = (e) => {
    const { value, checked } = e.target;
    const dayValue = parseInt(value);

    setFormData((prevData) => {
      if (checked) {
        // Ajouter le jour s'il n'est pas déjà présent
        if (!prevData.closedDays.includes(dayValue)) {
          return {
            ...prevData,
            closedDays: [...prevData.closedDays, dayValue],
          };
        }
      } else {
        // Retirer le jour s'il est présent
        return {
          ...prevData,
          closedDays: prevData.closedDays.filter((day) => day !== dayValue),
        };
      }
      return prevData;
    });
  };

  // Gérer l'ajout d'une nouvelle plage d'heures de fermeture
  const addClosingHoursRange = (day) => {
    setFormData((prevData) => {
      const dayClosingHours = prevData.closingHours[day] || [];
      
      // Ajouter une nouvelle plage par défaut (fermé de 12h à 14h)
      const updatedClosingHours = {
        ...prevData.closingHours,
        [day]: [
          ...dayClosingHours,
          { start: "12:00", end: "14:00" }
        ]
      };
      
      return {
        ...prevData,
        closingHours: updatedClosingHours
      };
    });
  };

  // Gérer la suppression d'une plage d'heures de fermeture
  const removeClosingHoursRange = (day, index) => {
    setFormData((prevData) => {
      const dayClosingHours = [...prevData.closingHours[day]];
      dayClosingHours.splice(index, 1);
      
      const updatedClosingHours = {
        ...prevData.closingHours,
        [day]: dayClosingHours
      };
      
      return {
        ...prevData,
        closingHours: updatedClosingHours
      };
    });
  };

  // Gérer les changements des plages d'heures de fermeture
  const handleClosingHoursChange = (day, index, field, value) => {
    setFormData((prevData) => {
      const dayClosingHours = [...prevData.closingHours[day]];
      dayClosingHours[index] = {
        ...dayClosingHours[index],
        [field]: value
      };
      
      const updatedClosingHours = {
        ...prevData.closingHours,
        [day]: dayClosingHours
      };
      
      return {
        ...prevData,
        closingHours: updatedClosingHours
      };
    });
  };

  // Fonction pour obtenir le nom du jour de la semaine
  const getDayName = (dayNumber) => {
    const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    return days[dayNumber];
  };

  // Validation des paramètres avant soumission
  const validateSettings = () => {
    // Vérifier que l'heure de fin est supérieure à l'heure de début
    if (formData.endHour <= formData.startHour) {
      alert("L'heure de fin doit être supérieure à l'heure de début.");
      return false;
    }

    // Validation des plages d'heures de fermeture
    for (const day in formData.closingHours) {
      const ranges = formData.closingHours[day];
      for (const range of ranges) {
        // Vérifier que chaque plage a une heure de début et de fin
        if (!range.start || !range.end) {
          alert(`Plage d'heures invalide pour ${getDayName(day)}`);
          return false;
        }
        
        // Vérifier que l'heure de fin est après l'heure de début
        const [startHour, startMinute] = range.start.split(':').map(Number);
        const [endHour, endMinute] = range.end.split(':').map(Number);
        
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;
        
        if (endMinutes <= startMinutes) {
          alert(`Pour ${getDayName(day)}, l'heure de fin (${range.end}) doit être après l'heure de début (${range.start})`);
          return false;
        }
      }
    }

    return true;
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateSettings()) {
      return;
    }

    // Sauvegarder l'état de l'agenda avant de recharger
    const currentView =
      document
        .querySelector(".view-btn.active")
        ?.textContent.trim()
        .toLowerCase() || "jour";
    const currentDate =
      document.querySelector(".current-date")?.textContent || "";

    // Sauvegarder temporairement l'état de navigation
    localStorage.setItem(
      "agenda_temp_view",
      currentView === "jour"
        ? "day"
        : currentView === "semaine"
        ? "week"
        : currentView === "année"
        ? "year"
        : "month"
    );
    localStorage.setItem("agenda_temp_date", currentDate);

    // Appeler onSave pour mettre à jour l'état React
    onSave(formData);
  };

  // Si le modal n'est pas ouvert, ne rien afficher
  if (!isOpen) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content settings-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal" onClick={onClose}>
          &times;
        </span>
        <h2>Paramètres de l'agenda</h2>

        <form id="settings-form" onSubmit={handleSubmit}>
          <div className="settings-group">
            <h3>Horaires de travail</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="start-hour">Heure de début:</label>
                <select
                  id="start-hour"
                  name="startHour"
                  value={formData.startHour}
                  onChange={handleInputChange}
                >
                  {generateHourOptions().map((hour) => (
                    <option key={`start-${hour}`} value={hour}>
                      {hour.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="end-hour">Heure de fin:</label>
                <select
                  id="end-hour"
                  name="endHour"
                  value={formData.endHour}
                  onChange={handleInputChange}
                >
                  {generateHourOptions().map((hour) => (
                    <option key={`end-${hour}`} value={hour}>
                      {hour.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="settings-group">
            <h3>Pas de temps</h3>
            <div className="form-group">
              <label htmlFor="time-step">
                Intervalle des créneaux (minutes):
              </label>
              <select
                id="time-step"
                name="timeStep"
                value={formData.timeStep}
                onChange={handleInputChange}
              >
                {timeStepOptions.map((step) => (
                  <option key={`step-${step}`} value={step}>
                    {step} minutes
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="settings-group">
            <h3>Jours et heures de fermeture</h3>
            <div className="days-selection">
              {[1, 2, 3, 4, 5, 6, 0].map((dayNumber) => (
                <div key={`day-${dayNumber}`} className="day-checkbox-container">
                  <div className="day-checkbox">
                    <input
                      type="checkbox"
                      id={`close-day-${dayNumber}`}
                      name="closedDays"
                      value={dayNumber}
                      checked={formData.closedDays.includes(dayNumber)}
                      onChange={handleClosedDayChange}
                    />
                    <label htmlFor={`close-day-${dayNumber}`}>{getDayName(dayNumber)}</label>
                  </div>
                  
                  {!formData.closedDays.includes(dayNumber) && (
                    <button
                      type="button"
                      className="closing-hours-btn"
                      onClick={() => {
                        setActiveClosingDay(dayNumber);
                        setShowClosingHours(true);
                      }}
                    >
                      Heures de fermeture
                    </button>
                  )}
                  
                  {/* Affichage des plages d'heures de fermeture configurées */}
                  {!formData.closedDays.includes(dayNumber) && 
                   formData.closingHours[dayNumber] && 
                   formData.closingHours[dayNumber].length > 0 && (
                    <div className="closing-hours-preview">
                      {formData.closingHours[dayNumber].map((range, idx) => (
                        <span key={idx} className="closing-range">
                          {range.start}-{range.end}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            
            {/* Panel des heures de fermeture */}
            {showClosingHours && activeClosingDay !== null && (
              <div className="closing-hours-panel">
                <div className="closing-hours-header">
                  <h4>Heures de fermeture - {getDayName(activeClosingDay)}</h4>
                  <button 
                    type="button" 
                    className="close-panel-btn"
                    onClick={() => setShowClosingHours(false)}
                  >
                    &times;
                  </button>
                </div>
                
                <div className="closing-hours-content">
                  {(!formData.closingHours[activeClosingDay] || 
                    formData.closingHours[activeClosingDay].length === 0) && (
                    <p className="no-ranges">Aucune plage horaire de fermeture configurée</p>
                  )}
                  
                  {formData.closingHours[activeClosingDay] && 
                   formData.closingHours[activeClosingDay].map((range, index) => (
                    <div key={index} className="closing-range-item">
                      <div className="closing-range-times">
                        <div className="form-group">
                          <label>Début:</label>
                          <input 
                            type="time" 
                            value={range.start}
                            onChange={(e) => handleClosingHoursChange(
                              activeClosingDay, 
                              index, 
                              'start', 
                              e.target.value
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <label>Fin:</label>
                          <input 
                            type="time" 
                            value={range.end}
                            onChange={(e) => handleClosingHoursChange(
                              activeClosingDay, 
                              index, 
                              'end', 
                              e.target.value
                            )}
                          />
                        </div>
                        <button 
                          type="button" 
                          className="remove-range-btn"
                          onClick={() => removeClosingHoursRange(activeClosingDay, index)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  ))}
                  
                  <button 
                    type="button" 
                    className="add-range-btn"
                    onClick={() => addClosingHoursRange(activeClosingDay)}
                  >
                    + Ajouter une plage horaire
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Fonctionnalités avancées */}
          <div className="settings-group">
            <h3>Fonctionnalités avancées</h3>

            <div className="feature-option">
              <div className="feature-checkbox">
                <input
                  type="checkbox"
                  id="enable-recurrence"
                  name="enableRecurrence"
                  checked={formData.enableRecurrence}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feature-content">
                <div className="feature-title">Rendez-vous récurrents</div>
                <p className="feature-description">
                  Permet de créer des rendez-vous répétitifs (hebdomadaires,
                  mensuels, etc.)
                </p>
              </div>
            </div>

            <div className="feature-option">
              <div className="feature-checkbox">
                <input
                  type="checkbox"
                  id="enable-export"
                  name="enableExport"
                  checked={formData.enableExport}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feature-content">
                <div className="feature-title">Export/Import</div>
                <p className="feature-description">
                  Permet d'exporter et d'importer des rendez-vous (formats iCal,
                  CSV)
                </p>
              </div>
            </div>

            <div className="feature-option">
              <div className="feature-checkbox">
                <input
                  type="checkbox"
                  id="enable-client-integration"
                  name="enableClientIntegration"
                  checked={formData.enableClientIntegration}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feature-content">
                <div className="feature-title">Intégration clients</div>
                <p className="feature-description">
                  Lie les rendez-vous à la base de données clients
                </p>
              </div>
            </div>

            <div className="feature-option">
              <div className="feature-checkbox">
                <input
                  type="checkbox"
                  id="enable-reminders"
                  name="enableReminders"
                  checked={formData.enableReminders}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feature-content">
                <div className="feature-title">Rappels et notifications</div>
                <p className="feature-description">
                  Système de rappels avant les rendez-vous
                </p>

                {formData.enableReminders && (
                  <div className="sub-option">
                    <label htmlFor="default-reminder-time">
                      Rappel par défaut:
                    </label>
                    <select
                      id="default-reminder-time"
                      name="defaultReminderTime"
                      value={formData.defaultReminderTime}
                      onChange={handleInputChange}
                    >
                      {reminderTimeOptions.map((option) => (
                        <option
                          key={`reminder-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="feature-option">
              <div className="feature-checkbox">
                <input
                  type="checkbox"
                  id="enable-year-view"
                  name="enableYearView"
                  checked={formData.enableYearView}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feature-content">
                <div className="feature-title">Vue annuelle</div>
                <p className="feature-description">
                  Ajoute une vue annuelle pour la planification à long terme
                </p>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-btn">
              Enregistrer
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};